import { keepPreviousData, useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import ApiRequest from '@/plugins/store/actions/api/ApiRequest';
import { SensorLocationHistory } from '@/types';

export const queryKeys = {
    getSensorLocationHistory: ['sensor-location-history'],
};

async function getSensorLocationHistory() {
    return new ApiRequest().send('get', `monitor/sensor-location-history`);
}

export function useSensorLocationHistory(options: UseQueryOptions<SensorLocationHistory> = {}) {
    return useQuery<SensorLocationHistory>({
        queryKey: queryKeys.getSensorLocationHistory,
        queryFn: getSensorLocationHistory,
        placeholderData: keepPreviousData,
        ...options,
    });
}
