import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rounded-full w-9 h-9 grid content-center justify-center border-solid border-2 text-sm border-light-blue-500 text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.initials.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.initials), 1))
    : _createCommentVNode("", true)
}