import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-shrink-0" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "sr-only" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "sr-only" }

import { HelpWidgetItem } from '@/types';
import HelpVideoModal from '@/components/common/helpWidget/HelpVideoModal.vue';
import { HelpItemType, HelpWidgetRouteItem, HelpWidgetVideoItem } from '@/types/HelpWidget';
import { useGetAndOpenRouteItem } from './composables/useGetAndOpenRouteItem';
import Spinner from '@/components/ui/Spinner.vue';
import InfoIcon from '@/components/icons/InfoIcon.vue';

interface Props {
    item: HelpWidgetItem;
    iconSizeClass?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpWidgetButton',
  props: {
    item: {},
    iconSizeClass: { default: 'size-6' }
  },
  setup(__props: any) {



const { getAndOpenRouteItem, isLoading } = useGetAndOpenRouteItem();

function onSelectRouteItem(routeItem: HelpWidgetRouteItem) {
    if (isLoading.value) {
        return;
    }

    getAndOpenRouteItem(routeItem);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.item.type === _unref(HelpItemType).Video)
      ? (_openBlock(), _createBlock(HelpVideoModal, {
          key: 0,
          item: (_ctx.item as HelpWidgetVideoItem)
        }, {
          default: _withCtx(({ open }) => [
            _createElementVNode("button", {
              class: "rounded-full p-1 hover:bg-gray-100 transition-colors",
              onClick: _withModifiers(open, ["stop"])
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.text), 1),
              _createVNode(InfoIcon, {
                class: _normalizeClass(_ctx.iconSizeClass),
                "aria-hidden": "true"
              }, null, 8, ["class"])
            ], 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["item"]))
      : (_ctx.item.type === _unref(HelpItemType).Url)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: _ctx.item.url,
            target: "_blank",
            class: "rounded-full p-1 hover:bg-gray-100 transition-colors",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.item.text), 1),
            _createVNode(InfoIcon, {
              class: _normalizeClass(_ctx.iconSizeClass),
              "aria-hidden": "true"
            }, null, 8, ["class"])
          ], 8, _hoisted_4))
        : (_ctx.item.type === _unref(HelpItemType).Route)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              disabled: _unref(isLoading),
              class: "rounded-full p-1 hover:bg-gray-100 transition-colors",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (onSelectRouteItem(_ctx.item as HelpWidgetRouteItem)), ["stop"]))
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.item.text), 1),
              (_unref(isLoading))
                ? (_openBlock(), _createBlock(Spinner, {
                    key: 0,
                    class: _normalizeClass(_ctx.iconSizeClass)
                  }, null, 8, ["class"]))
                : (_openBlock(), _createBlock(InfoIcon, {
                    key: 1,
                    class: _normalizeClass(_ctx.iconSizeClass),
                    "aria-hidden": "true"
                  }, null, 8, ["class"]))
            ], 8, _hoisted_6))
          : _createCommentVNode("", true)
  ]))
}
}

})