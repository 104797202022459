import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "space-y-8" }

import { ref, onMounted, computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { Chart, ChartType } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { MutationType } from '@/plugins/store/mutations';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

useRefetchDataOnDashboardStateChanged(ActionType.GetDebugDashboardOverview, { watchSites: true });

const chart = ref({
    name: Chart.Debug,
    type: ChartType.ColumnChart,
    height: 400,
    fetchActionType: ActionType.GetDebugChart,
});
const playgroundModalInitialState = ref<any>({
    location: null,
    form: null,
    confirmed: false,
    farm: null,
    record_id: null,
    notification: null,
    event: null,
});

async function getForm() {
    const form = await store.dispatch(ActionType.GetForm, { form: { form_id: 999 } });

    playgroundModalInitialState.value.form = form;
}

const dashboardData = computed(() => store.state.debugDashboardOverview);
const dashboardLoading = computed(() => store.state.debugDashboardOverviewLoading);

onMounted(() => {
    getForm();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.debug.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MainViewCard, { class: "pt-4 pb-2 px-2" }, {
          default: _withCtx(() => [
            _createVNode(GenericChartWithApi, {
              "fetch-action-type": chart.value.fetchActionType,
              "chart-name": chart.value.name,
              height: chart.value.height,
              "chart-type": chart.value.type
            }, null, 8, ["fetch-action-type", "chart-name", "height", "chart-type"])
          ]),
          _: 1
        }),
        (playgroundModalInitialState.value.form)
          ? (_openBlock(), _createBlock(ActionModal, {
              key: 0,
              header: { icon_url: '', text: 'Playground' },
              "initial-step-index": 1,
              "initial-state": playgroundModalInitialState.value
            }, {
              default: _withCtx(({ open }) => [
                _createVNode(CustomButton, {
                  class: "w-40",
                  onClick: open
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Playground form ")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["initial-state"]))
          : _createCommentVNode("", true),
        _createVNode(GenericDashboard, {
          data: dashboardData.value,
          loading: dashboardLoading.value,
          "fetch-params": { initialRequest: false },
          "fetch-action-type": _unref(ActionType).GetDebugDashboardOverview,
          "state-mutation-type": _unref(MutationType).SetDebugDashboardOverview
        }, null, 8, ["data", "loading", "fetch-action-type", "state-mutation-type"])
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}
}

})