<template>
    <Modal close-esc-event="keydown" :close-button="false" :modal-class="`m-auto border-0 max-w-6xl transition-width duration-500`" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128">
                <MultiStepFormHeaderIcon :icon-source="IconSource.Food" :is-final-step="false"></MultiStepFormHeaderIcon>
                <h2 class="text-xl font-semibold text-center">{{ $t('views.feed.rawMaterials.deleteRawMaterialModal.header') }}</h2>
                <p class="text-center">
                    <span>{{ $t('views.feed.rawMaterials.deleteRawMaterialModal.message') }}</span> <span class="font-bold text-primary-700">{{ rawMaterial.name }}?</span>
                </p>
                <div class="flex space-x-4">
                    <CustomButton :disabled="disableButtons" color-preset="secondary" @click="scope.close">
                        {{ $t('views.feed.rawMaterials.deleteRawMaterialModal.cancelButtonLabel') }}
                    </CustomButton>
                    <CustomButton :disabled="disableButtons" color-preset="warning" @click="onDeleteRawMaterialClicked(scope.close)">
                        {{ $t('views.feed.rawMaterials.deleteRawMaterialModal.confirmButtonLabel') }}
                    </CustomButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CustomButton from '@/components/ui/CustomButton.vue';
import { RawMaterial } from '@/types';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, CustomButton, CloseModalButton },
    emits: ['submit'],
    props: {
        rawMaterial: {
            type: Object as () => RawMaterial,
            required: true,
        },
    },
    data() {
        return { disableButtons: false };
    },
    methods: {
        async onDeleteRawMaterialClicked(close: CallableFunction) {
            this.disableButtons = true;
            await store.dispatch(ActionType.DeleteRawMaterial, { form: { rawMaterial: this.rawMaterial } });
            this.$emit('submit');
            close();
        },
    },
});
</script>
