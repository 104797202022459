import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    ref: "input",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
    type: _ctx.type,
    class: _normalizeClass(["py-1 text-center outline-none rounded", [_ctx.backgroundColor]]),
    onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
  }, null, 42, _hoisted_1)), [
    [_vModelDynamic, _ctx.computedValue]
  ])
}