<template>
    <div class="relative form-month-picker">
        <FlatpickrWrapper
            v-model:value="computedValue"
            v-model:show="expandPicker"
            :disable-months="disableMonths"
            :wrapper-class="'flatpickr-component-month-wrapper'"
            :config="flatpickrConfig"
            auto-width
            mode="single"
        >
            <div class="relative overflow-hidden cursor-pointer">
                <CustomInput
                    ref="input-field"
                    v-model:value="inputValue"
                    :rules="[{ validate: (value) => Boolean(value), message: '' }]"
                    required
                    input-type="text"
                    :label="label"
                    readonly
                    cursor="cursor-pointer"
                    @submit="$emit('submit')"
                    @focus="expandPicker = true"
                    @keydown.esc.stop="onEscape"
                ></CustomInput>
                <span class="absolute h-full top-0 right-5 grid content-center -mt-2" @click="$refs['input-field'].focusInput()">
                    <Icon class="m-auto" :src="IconSource.DatePicker"></Icon>
                </span>
            </div>
        </FlatpickrWrapper>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import FlatpickrWrapper from '@/components/ui/FlatpickrWrapper.vue';
import CustomInput from '@/components/ui/CustomInput.vue';

export default defineComponent({
    components: { FlatpickrWrapper, CustomInput },
    emits: ['update:value', 'submit'],
    props: {
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        disableMonths: {
            type: Object as () => { [key: string]: number[] },
            default: {},
        },
    },
    data() {
        return {
            expandPicker: false,
        };
    },
    computed: {
        flatpickrConfig(): any {
            return {
                plugins: [new (MonthSelectPlugin as any)({ dateFormat: 'Z' })],
                weekNumbers: false,
            };
        },
        computedValue: {
            get(): string | null {
                return this.$props.value;
            },
            set(newDateString: string | null) {
                this.$emit('update:value', newDateString);
            },
        },
        inputValue(): string {
            if (this.computedValue) {
                return this.$d(new Date(this.computedValue), 'monthOfYearLong');
            }
            return '';
        },
    },
    methods: {
        onEscape() {
            this.expandPicker = false;
            (document.activeElement as HTMLElement)?.blur();
        },
        onClearClicked() {
            this.computedValue = null;
        },
    },
});
</script>
<style>
.form-month-picker .flatpickr-wrapper {
    top: 2rem;
}

.flatpickr-component-month-wrapper .flatpickr-months .flatpickr-prev-month,
.flatpickr-component-month-wrapper .flatpickr-months .flatpickr-next-month {
    position: inherit !important;
}

.flatpickr-component-month-wrapper .flatpickr-wrapper {
    position: absolute !important;
    z-index: 900;
}

.flatpickr-component-month-wrapper .flatpickr-months {
    padding: 0px 30px 0px 30px;
}

.flatpickr-component-month-wrapper .auto-width .flatpickr-calendar {
    width: 380px !important;
    padding: 20px 0 10px 0;
}

.flatpickr-component-month-wrapper .flatpickr-calendar:before,
.flatpickr-component-month-wrapper .flatpickr-calendar:after {
    display: none;
}

.flatpickr-component-month-wrapper .flatpickr-monthDropdown-months,
.flatpickr-component-month-wrapper .cur-year {
    font-weight: 500 !important;
}

.flatpickr-component-month-wrapper .flatpickr-monthSelect-month.selected {
    @apply bg-primary-500 border-primary-500;
    color: black !important;
}

.flatpickr-monthSelect-month.selected.disabled {
    color: #eee !important;
    background-color: white !important;
}
.flatpickr-monthSelect-month.disabled:hover {
    cursor: default;
}
</style>
