import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.notifications.header.heading'),
    "has-period-picker": false,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Notifications)
    ]),
    _: 1
  }, 8, ["heading"]))
}