import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import DirectoriesTable from '@/views/dashboard/settings/directories/DirectoriesTable.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.settings.directories.header.heading'),
    "has-period-picker": false,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      _createVNode(DirectoriesTable)
    ]),
    _: 1
  }, 8, ["heading"]))
}
}

})