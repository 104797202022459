import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "text-2xl font-semibold text-center" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "border border-gray-300 bg-gray-100 bg-opacity-50 rounded-2xl divide-gray-300 divide-y" }
const _hoisted_5 = { class: "py-2 px-4" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "py-2 px-4" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "py-2 px-4" }
const _hoisted_10 = { class: "font-bold" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "flex space-x-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultiStepFormHeaderIcon, {
      "is-final-step": false,
      "icon-source": _ctx.IconSource.CreateBatch
    }, null, 8, ["icon-source"]),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.batch.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('views.pigs.batches.batchDetailsModal.startDate')), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.$d(_ctx.batch.start_date, 'short')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('views.pigs.batches.batchDetailsModal.endDate')), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.batch.end_date === null ? _ctx.$t('views.pigs.batches.batchDetailsModal.missingEndDateMessage') : _ctx.$d(_ctx.batch.end_date, 'short')), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('views.pigs.batches.batchDetailsModal.supplierName')), 1),
          (_ctx.batch.supplier_numbers && _ctx.batch.supplier_numbers.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.batch.supplier_numbers, (supplier_number) => {
                  return (_openBlock(), _createElementBlock("div", { key: supplier_number }, _toDisplayString(supplier_number), 1))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('views.pigs.batches.batchDetailsModal.missingSupplierNameMessage')), 1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_CustomButton, {
        "color-preset": "secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.pigs.batches.batchDetailsModal.cancelButtonLabel')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ActionModal, {
        class: "w-full",
        "initial-step-index": 1,
        "initial-state": _ctx.updateBatchModalInitialState,
        "step-config": _ctx.updateBatchModalStepConfig,
        onClosing: _ctx.onUpdateBatchModalClose
      }, {
        default: _withCtx(({ open }) => [
          _createVNode(_component_CustomButton, {
            disabled: _ctx.disableButtons,
            onClick: ($event: any) => (_ctx.onUpdateBatchButtonClicked(open))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.pigs.batches.batchDetailsModal.confirmButtonLabel')), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["initial-state", "step-config", "onClosing"])
    ])
  ]))
}