<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.settings.setup.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <SetupFormsTable></SetupFormsTable>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import SetupFormsTable from '@/views/dashboard/settings/setup/SetupFormsTable.vue';

export default defineComponent({
    components: { DashboardContentLayout, SetupFormsTable },
});
</script>
