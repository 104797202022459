<template>
    <div class="contact-info py-4 flex flex-col text-center">
        <!-- <div v-if="footer" v-html="footer"></div> -->
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    computed: {
        footer() {
            return store.state.footer ? sanitizeHtml(store.state.footer.footer_html) : null;
        },
    },
    created() {
        store.dispatch(ActionType.GetFooter);
    },
});
</script>

<style>
.contact-info a {
    text-decoration: underline;
}
</style>
