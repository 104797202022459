import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref, watch, nextTick } from 'vue';
import { Module, HelpItemType, HelpWidgetData, HelpWidgetItem, HelpWidgetVideoItem as VideoItemType, HelpWidgetRouteItem as RouteItemType } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { IconSource } from '@/types/IconSource';
import HelpWidgetUrlItem from './items/HelpWidgetUrlItem.vue';
import HelpWidgetVideoItem from './items/HelpWidgetVideoItem.vue';
import HelpVideoModal from '@/components/common/helpWidget/HelpVideoModal.vue';
import HelpWidgetRouteItem from './items/HelpWidgetRouteItem.vue';
import { useGetAndOpenRouteItem } from '@/components/common/helpWidget/composables/useGetAndOpenRouteItem';


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpWidget',
  setup(__props) {

const hasHelpWidgetModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.HelpWidget));

const helpWidgetData = ref<HelpWidgetData | null>(null);

const items = computed(() => helpWidgetData.value?.items?.filter((i) => i.is_visible) ?? []);

const isOpen = ref(false);

async function getHelpWidgetData() {
    try {
        const data = await store.dispatch(ActionType.GetHelpWidget);

        helpWidgetData.value = data;
    } catch (err) {
        //
    }
}

function getItemComponent(item: HelpWidgetItem) {
    switch (item.type) {
        case HelpItemType.Url:
            return HelpWidgetUrlItem;
        case HelpItemType.Video:
            return HelpWidgetVideoItem;
        case HelpItemType.Route:
            return HelpWidgetRouteItem;
        default:
            return null;
    }
}

function onUpdateOpen(open: boolean) {
    if (isRouteItemLoading.value) {
        return;
    }

    isOpen.value = open;
}

const activeVideoItem = ref<VideoItemType>();

async function onSelectVideo(item: VideoItemType, openModal: CallableFunction) {
    activeVideoItem.value = item;

    await nextTick();

    openModal();
}

const { getAndOpenRouteItem, isLoading: isRouteItemLoading } = useGetAndOpenRouteItem();

const routeItemIndexLoading = ref<number | null>(null);

async function onSelectRoute(item: RouteItemType, itemIndex: number) {
    routeItemIndexLoading.value = itemIndex;

    getAndOpenRouteItem(item, {
        onSuccess: () => {
            isOpen.value = false;
        },
    });
}

const stop = watch(
    hasHelpWidgetModuleEnabled,
    (newValue) => {
        if (newValue) {
            getHelpWidgetData();
            stop();
        }
    },
    { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (items.value.length)
    ? (_openBlock(), _createBlock(HelpVideoModal, {
        key: 0,
        item: activeVideoItem.value
      }, {
        default: _withCtx(({ open }) => [
          _createVNode(_unref(DropdownMenu), {
            open: isOpen.value,
            modal: false,
            "onUpdate:open": onUpdateOpen
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(DropdownMenuTrigger), {
                "as-child": "",
                class: "fixed bottom-5 right-5 bg-primary-800 hover:bg-primary-700 transition-colors rounded-full p-2"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", null, [
                    _createVNode(_component_Icon, {
                      src: _unref(IconSource).HelpIcon,
                      class: "w-6 h-6"
                    }, null, 8, ["src"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_unref(DropdownMenuContent), {
                class: "min-w-48 space-y-1",
                side: "top",
                align: "end",
                "side-offset": 12,
                "hide-when-detached": ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, itemIndex) => {
                    return (_openBlock(), _createBlock(_resolveDynamicComponent(getItemComponent(item)), {
                      key: itemIndex,
                      item: (item as any),
                      disabled: _unref(isRouteItemLoading),
                      loading: _unref(isRouteItemLoading) && routeItemIndexLoading.value === itemIndex,
                      onSelectVideo: ($event: any) => (onSelectVideo(item as VideoItemType, open)),
                      onSelectRoute: ($event: any) => (onSelectRoute(item as RouteItemType, itemIndex))
                    }, null, 40, ["item", "disabled", "loading", "onSelectVideo", "onSelectRoute"]))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["open"])
        ]),
        _: 1
      }, 8, ["item"]))
    : _createCommentVNode("", true)
}
}

})