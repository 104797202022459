import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "space-y-2" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "justify-center flex flex-col" }
const _hoisted_4 = { class: "w-full flex justify-center my-4" }
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = { class: "flex flex-1 px-2 my-auto" }
const _hoisted_7 = { class: "flex w-10 px-2 my-auto justify-end" }
const _hoisted_8 = { class: "w-20" }
const _hoisted_9 = { class: "w-20" }
const _hoisted_10 = { class: "flex justify-end my-4 space-x-4" }
const _hoisted_11 = { class: "flex border-2 border-opacity-10 rounded-lg space-x-4 px-4 box-content" }
const _hoisted_12 = { class: "my-auto space-x-2" }
const _hoisted_13 = { for: "surcharge" }
const _hoisted_14 = { class: "my-auto font-medium" }
const _hoisted_15 = { class: "w-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_SimpleInput = _resolveComponent("SimpleInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_HomeMixRawMaterialsTable = _resolveComponent("HomeMixRawMaterialsTable")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.mixRawMaterials.header').replace('$MIX_NAME', _ctx.computedValue.name)), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AutoComplete, {
          class: "w-72",
          items: _ctx.filteredRawMaterials,
          "item-text": "name",
          "item-value": "id",
          label: _ctx.$t('views.feed.mixes.newMixModal.mixRawMaterials.autoCompleteFieldLabel'),
          onItemClicked: _ctx.onItemClicked
        }, null, 8, ["items", "label", "onItemClicked"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_HomeMixRawMaterialsTable, {
          value: _ctx.computedValue,
          "include-actions": ""
        }, {
          header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("th", { class: "flex w-10 px-2 justify-end" }, null, -1)
          ])),
          sum_name: _withCtx(({ sumHeader }) => [
            (_openBlock(), _createElementBlock("td", {
              key: sumHeader,
              style: {},
              class: "flex flex-1 px-2 my-auto word-break"
            }, _toDisplayString(_ctx.$t('views.feed.mixes.homeMixRawMaterialsTable.total')), 1))
          ]),
          admixture_percentage: _withCtx(({ item }) => [
            _createElementVNode("td", _hoisted_6, [
              _createVNode(_component_SimpleInput, {
                value: item.admixture_percentage,
                "onUpdate:value": ($event: any) => ((item.admixture_percentage) = $event),
                disabled: _ctx.computedValue.type.id === _ctx.componentMixTypeId,
                class: _normalizeClass(`${_ctx.computedValue.type.id === _ctx.componentMixTypeId ? 'text-gray-400' : ''} w-20`),
                "background-color": "bg-gray-100",
                type: "number",
                step: "any"
              }, null, 8, ["value", "onUpdate:value", "disabled", "class"])
            ])
          ]),
          actions: _withCtx(({ item }) => [
            _createElementVNode("td", _hoisted_7, [
              _createVNode(_component_Icon, {
                class: "cursor-pointer w-8 h-8",
                src: _ctx.IconSource.Delete,
                onClick: ($event: any) => (_ctx.onDeleteItemClicked(item))
              }, null, 8, ["src", "onClick"])
            ])
          ]),
          price: _withCtx(({ item, header }) => [
            (_openBlock(), _createElementBlock("td", {
              key: header.value,
              class: "flex flex-1 px-2 my-auto word-break"
            }, _toDisplayString(_ctx.$n(_ctx.getPrice(item.material)?.price || 0, 'price')), 1))
          ]),
          sum_components: _withCtx(({ sumHeader }) => [
            (_openBlock(), _createElementBlock("td", {
              key: sumHeader,
              class: _normalizeClass([{ 'text-red-600': _ctx.amountSum !== 100 }, "flex text-center flex-1 px-2 my-auto word-break"])
            }, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$n(_ctx.amountSum)), 1)
            ], 2))
          ]),
          sum_price: _withCtx(({ sumHeader }) => [
            (_openBlock(), _createElementBlock("td", {
              key: sumHeader,
              class: "flex flex-1 px-2 my-auto word-break"
            }, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$n(_ctx.value.prices[0].price, 'price')), 1)
            ]))
          ]),
          sum_actions: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("td", { class: "flex w-10 px-2 justify-end" }, null, -1)
          ])),
          _: 1
        }, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, [
            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.mixRawMaterials.surcharge')) + ":", 1),
            _createVNode(_component_SimpleInput, {
              id: "surcharge",
              value: _ctx.computedValue.surcharge,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue.surcharge) = $event)),
              class: "w-16",
              type: "number",
              name: "surcharge",
              step: "any"
            }, null, 8, ["value"])
          ]),
          _createElementVNode("span", _hoisted_14, _toDisplayString(`${_ctx.$t('views.feed.mixes.newMixModal.mixRawMaterials.totalPrice')}: ${_ctx.$n(_ctx.computedValue.prices[0].price + (_ctx.computedValue.surcharge || 0), 'price')}`), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_CustomButton, {
            disabled: _ctx.disableButtons,
            disable: !_ctx.validForm,
            onClick: _ctx.onSubmitButtonClicked
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.mixRawMaterials.confirmButtonLabel')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disable", "onClick"])
        ])
      ])
    ])
  ]))
}