<script setup lang="ts">
import { PopoverContent, type PopoverContentEmits, type PopoverContentProps, PopoverPortal, useForwardPropsEmits } from 'radix-vue';
import { cn } from '@/lib/utils';

const props = withDefaults(defineProps<PopoverContentProps & { class?: string; triggerWidth?: boolean }>(), {
    sideOffset: 4,
    class: '',
    triggerWidth: false,
});
const emits = defineEmits<PopoverContentEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
    <PopoverPortal>
        <PopoverContent
            v-bind="{ ...forwarded, ...$attrs }"
            :class="
                cn(
                    'z-50 rounded-md border bg-white p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                    props.class,
                    props.triggerWidth ? 'w-[var(--radix-popover-trigger-width)] max-w-[var(--radix-popover-trigger-width)]' : 'w-72 max-w-[100vw]'
                )
            "
        >
            <slot />
        </PopoverContent>
    </PopoverPortal>
</template>
