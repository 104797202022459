import { da, enGB, sv, es, pt } from 'date-fns/locale';

export function getLocale(locale: string): Locale | undefined {
    switch (locale) {
        case 'da': {
            return da;
        }
        case 'sv': {
            return sv;
        }
        case 'es': {
            return es;
        }
        case 'pt': {
            return pt;
        }
        default: {
            return enGB;
        }
    }
}
