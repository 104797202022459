<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.monitoring.main.header.heading')" has-pig-type-picker>
        <template #header-top-right>
            <div class="w-92 flex">
                <SearchInput v-model:value="searchQuery" class="my-auto" :label="$t('views.monitoring.main.tables.searchFieldLabel')" :background-color="'bg-white'" @on-escape="searchQuery = ''"></SearchInput>
            </div>
        </template>
        <MonitoringTabs :search-query="searchQuery" />
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import MonitoringTabs from '@/views/dashboard/monitoring/monitoring/MonitoringTabs.vue';

export default defineComponent({
    components: { DashboardContentLayout, MonitoringTabs, SearchInput },
    setup() {
        const searchQuery = ref('');

        return {
            searchQuery,
        };
    },
});
</script>
