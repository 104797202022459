import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute -top-0.25 right-0 h-3 w-3 bg-red-400 rounded-full border-gray-700 border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: '/notifications',
    style: {"width":"26px"},
    class: "grid relative"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, {
        class: "cursor-pointer m-auto",
        src: _ctx.IconSource.Notifications
      }, null, 8, ["src"]),
      (_ctx.pendingNotifications)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}