import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "w-full flex space-x-2 content-center" }
const _hoisted_3 = { class: "grid content-center h-7 min-w-4 justify-center my-auto" }
const _hoisted_4 = { class: "update-key-figures-button-text text-lg my-auto" }
const _hoisted_5 = { class: "bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128" }
const _hoisted_6 = { class: "text-xl font-semibold text-center" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "flex justify-center" }
const _hoisted_9 = { class: "min-w-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      "close-esc-event": "keydown",
      "close-button": false,
      "modal-class": "m-auto border-0",
      "mask-class": "grid",
      onClosed: _ctx.onModalClosed
    }, {
      toggle: _withCtx((scope) => [
        _createElementVNode("button", {
          class: "bg-primary-800 border-primary-700 border hover:bg-primary-700 transition-colors rounded-xl py-2 px-4 text-white cursor-pointer",
          onClick: scope.open
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_Icon, {
                class: "my-auto",
                style: {"width":"17px","height":"17px"},
                src: _ctx.IconSource.RefreshActivitiesLight
              }, null, 8, ["src"])
            ]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('layouts.dashboard.topBar.updateKeyFigures.buttonLabel')), 1)
          ])
        ], 8, _hoisted_1)
      ]),
      header: _withCtx((scope) => [
        _createVNode(_component_CloseModalButton, {
          onClose: scope.close
        }, null, 8, ["onClose"])
      ]),
      body: _withCtx((scope) => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('layouts.dashboard.topBar.updateKeyFigures.modal.header')), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('layouts.dashboard.topBar.updateKeyFigures.modal.message')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_CustomButton, {
                disabled: _ctx.disableButtons,
                onClick: ($event: any) => (_ctx.onConfirmClicked(scope.close))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('layouts.dashboard.topBar.updateKeyFigures.modal.confirmUpdateButtonLabel')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onClosed"])
  ]))
}