<script setup lang="ts">
import { useLoadingPageOverlayState } from '@/composables/useLoadingPageOverlayState';
import Spinner from '@/components/ui/Spinner.vue';

const { isLoading, text } = useLoadingPageOverlayState();
</script>

<template>
    <div v-if="isLoading" class="fixed z-[2000] inset-0 flex items-center justify-center h-full bg-background/50 backdrop-blur-sm">
        <div class="flex items-center gap-3 p-4">
            <div class="text-xl font-medium">{{ text }}</div>
            <Spinner class="size-6" aria-hidden="true" />
        </div>
    </div>
</template>
