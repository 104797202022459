<template>
    <router-link :to="'/notifications'" style="width: 26px" class="grid relative">
        <Icon class="cursor-pointer m-auto" :src="IconSource.Notifications"></Icon>
        <div v-if="pendingNotifications" class="absolute -top-0.25 right-0 h-3 w-3 bg-red-400 rounded-full border-gray-700 border"></div>
    </router-link>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { store } from '@/plugins/store';

export default defineComponent({
    setup() {
        const meta = computed(() => store.state.meta);
        const pendingNotifications = computed(() => Boolean(meta.value && (meta.value.notifications.unread || meta.value.notifications.unhandled)));

        return { meta, pendingNotifications };
    },
});
</script>
