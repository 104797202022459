import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 py-6 px-8 rounded-xl space-y-4" }

import { Modal } from '@makeabledk/vue-ui/components/modal';
import { useQueryClient } from '@tanstack/vue-query';
import { Notification } from '@/types';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { queryKeys } from '@/plugins/store/actions/queries/notifications';

interface Props {
    notification: Notification;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextNotificationModal',
  props: {
    notification: {}
  },
  setup(__props: any) {

const props = __props;

const queryClient = useQueryClient();

async function onModalClose() {
    try {
        if (props.notification.read) {
            return;
        }

        await store.dispatch(ActionType.EditNotification, { form: { notification: props.notification, update: [{ value: true, path: '/read', op: 'replace' }] } });
        await Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.notifications }), store.dispatch(ActionType.GetMeta, { options: { ignoreCache: true, skipLoadingIndicator: true } })]);
    } catch (err) {
        //
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_unref(Modal), {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "m-auto border-0",
    "mask-class": "grid",
    onClosing: onModalClose
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          class: "mx-auto w-12 h-12",
          src: _ctx.notification.icon_url
        }, null, 8, ["src"]),
        _createElementVNode("p", null, _toDisplayString(_ctx.notification.message), 1)
      ])
    ]),
    _: 3
  }))
}
}

})