<template>
    <div class="space-y-8">
        <TemplatesTable></TemplatesTable>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TemplatesTable from '@/views/dashboard/assignments/templates/TemplatesTable.vue';

export default defineComponent({
    components: { TemplatesTable },
    setup() {
        return { TemplatesTable };
    },
});
</script>
