<template>
    <div class="space-y-6">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.health.instructions.modals.createInstruction.header') }}</h2>
        <form class="flex flex-col h-full min-h-44">
            <CustomMultiSelect
                v-model:value="computedState.farms"
                class="self-start w-full"
                :items="farms"
                return-object
                required
                item-value="farm_id"
                item-text="name"
                dropdown-height-class="max-h-84"
                :rules="[{ validate: (value) => value && value.length > 0, message: '' }]"
                :label="$t('views.health.instructions.modals.createInstruction.fields.farms')"
                :disabled="false"
                show-required-indication
                :show-hint="false"
            ></CustomMultiSelect>
            <CustomCombobox
                v-model:value="computedState.resource"
                :items="drugs"
                item-value="id"
                item-text="name"
                :label="$t('views.health.instructions.modals.createInstruction.fields.drug')"
                :rules="[{ validate: (value) => value !== null, message: '' }]"
                show-required-indication
                required
            >
            </CustomCombobox>
            <CustomButton :loading="isLoading" :disabled="!validForm" @click="onSubmit">
                {{ $t('views.health.instructions.modals.createInstruction.confirmButtonLabel') }}
            </CustomButton>
        </form>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomCombobox from '@/components/ui/CustomCombobox.vue';

interface Form {
    farms:
        | {
              farm_id: number;
              name: string;
          }[]
        | null;
    resource: {
        id: number;
        name: string;
    } | null;
}

export default defineComponent({
    components: { CustomMultiSelect, CustomButton, CustomCombobox },
    props: {
        state: {
            type: Object as () => Form,
            required: true,
        },
    },
    setup(props, { emit }) {
        store.dispatch(ActionType.GetFarms);
        store.dispatch(ActionType.GetHealthDrugs);

        const farms = computed(() => store.state.farms);
        const drugs = computed(() => store.state.healthDrugs);

        const computedState = computed<Form>({
            get() {
                return props.state;
            },
            set(state) {
                emit('update:state', state);
            },
        });

        const validForm = computed(() => computedState.value.farms && computedState.value.farms.length > 0 && computedState.value.resource !== null);
        const isLoading = ref(false);

        async function onSubmit() {
            if (!validForm.value || isLoading.value) {
                return;
            }

            try {
                isLoading.value = true;

                const form = await store.dispatch(ActionType.GetForm, {
                    form: {
                        location_ids: computedState.value.farms?.map((f) => f.farm_id),
                        resource_id: computedState.value.resource?.id,
                        form_id: 32,
                    },
                });

                if (form) {
                    Object.assign(computedState.value, { form });

                    emit('submit');
                }
            } finally {
                isLoading.value = false;
            }
        }

        return {
            farms,
            computedState,
            drugs,
            validForm,
            onSubmit,
            isLoading,
        };
    },
});
</script>
