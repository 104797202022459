<template>
    <div class="grid">
        <label class="text-sm tracking-normal text-gray-400 min-h-4">
            <span>{{ label }}</span>
        </label>
        <span class="truncate">
            <span class="leading-none overflow-y-visible">
                <slot></slot>
            </span>
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
    },
});
</script>
