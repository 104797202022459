import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_AddGlobalPriceModal = _resolveComponent("AddGlobalPriceModal")!
  const _component_EditGlobalPriceModal = _resolveComponent("EditGlobalPriceModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AddGlobalPriceModal, { "step-config": _ctx.addGlobalPriceStepConfig }, {
      default: _withCtx((scope) => [
        _createVNode(_component_AddButton, {
          "text-class": "font-medium",
          onClick: scope.open
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('views.economy.status.modals.addGlobalPrice.buttonLabel')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["step-config"]),
    _createVNode(_component_EditGlobalPriceModal, { "step-config": _ctx.editGlobalPriceStepConfig }, {
      default: _withCtx((scope) => [
        _createVNode(_component_AddButton, {
          "text-class": "font-medium",
          onClick: scope.open
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('views.economy.status.modals.editGlobalPrice.buttonLabel')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["step-config"])
  ]))
}