import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["tabindex", "onClick"]
const _hoisted_2 = {
  style: {"grid-template-columns":"2em auto"},
  class: "grid gap-2"
}
const _hoisted_3 = { class: "grid content-center" }
const _hoisted_4 = { class: "h-full text-gray-800 inline-flex items-center text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createBlock(_component_DropDown, {
    expand: _ctx.expandDropdown,
    "onUpdate:expand": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandDropdown) = $event)),
    "dropdown-class": `mt-2 ${_ctx.dropdownClass}`
  }, {
    "dropdown-content": _withCtx(() => [
      _createVNode(_component_ActionModal, {
        "initial-step-index": 0,
        "initial-state": _ctx.actionModalInitialState,
        class: "divide-y"
      }, {
        default: _withCtx(({ open }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: event.id,
              class: "hover:bg-gray-100 first:rounded-t-md last:rounded-b-md cursor-pointer"
            }, [
              _createElementVNode("button", {
                class: "px-6 py-3 font-medium w-full",
                role: "menuitem",
                tabindex: index,
                onClick: ($event: any) => (_ctx.onEventClicked(event, open)),
                onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.expandDropdown = false), ["escape"]))
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_Icon, {
                      src: event.icon_url
                    }, null, 8, ["src"])
                  ]),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(event.name), 1)
                ])
              ], 40, _hoisted_1)
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["initial-state"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "button", {
        expandDropdown: _ctx.expandDropdown,
        toggleExpandDropdown: _ctx.toggleExpandDropdown
      })
    ]),
    _: 3
  }, 8, ["expand", "dropdown-class"]))
}