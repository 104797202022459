<template>
    <div class="h-full pb-6">
        <h2 class="text-xl font-semibold text-center">{{ message }}</h2>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { MultiStepFormState } from '@/types';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';

export default defineComponent({
    props: {
        state: {
            type: Object as PropType<MultiStepFormState>,
            required: true,
        },
        config: {
            type: Object as PropType<{ submissionText?: string; refetchActions: ActionType | ActionType[] }>,
            required: true,
        },
    },
    emits: ['close', 'submission'],
    computed: {
        message(): string {
            if (this.config.submissionText) {
                return this.config.submissionText;
            }

            return this.$t('global.forms.steps.submission.success');
        },
    },
    created() {
        if (this.config && this.config.refetchActions) {
            const actions = Array.isArray(this.config.refetchActions) ? this.config.refetchActions : [this.config.refetchActions];

            actions.forEach((action) => store.dispatch(action, { options: { ignoreCache: true } }));
        }

        this.$emit('submission');

        setTimeout(() => this.$emit('close'), 1700);
    },
});
</script>
