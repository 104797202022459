<template>
    <div class="relative form-date-picker">
        <FlatPickrWrapper v-model:value="computedValue" v-model:show="expandPicker" :disabled="disabled" :config="flatpickrConfig" auto-width mode="single">
            <div class="relative overflow-hidden">
                <CustomInput
                    ref="input-field"
                    v-model:value="inputValue"
                    :required="required"
                    :show-required-indication="required"
                    :rules="[]"
                    input-type="text"
                    :label="label"
                    :details="details"
                    :disabled="disabled"
                    readonly
                    cursor="cursor-pointer"
                    @submit="$emit('submit')"
                    @focus="expandPicker = true"
                    @keydown.esc.stop="onEscape"
                ></CustomInput>
                <span v-if="!required" :class="[{ 'cursor-default opacity-40': disabled }, details ? '-mt-3.5' : '-mt-2']" class="absolute h-full top-0 right-14 grid content-center" @click="onClearClicked">
                    <Icon class="m-auto" :src="IconSource.Close"></Icon>
                </span>
                <span :class="[{ 'cursor-default opacity-40': disabled }, details ? '-mt-3.5' : '-mt-2']" class="absolute h-full top-0 right-5 grid content-center" @click="$refs['input-field'].focusInput()">
                    <Icon class="m-auto" :src="IconSource.DatePicker"></Icon>
                </span>
            </div>
        </FlatPickrWrapper>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FlatPickrWrapper from '@/components/ui/FlatpickrWrapper.vue';
import CustomInput from '@/components/ui/CustomInput.vue';

export default defineComponent({
    components: { FlatPickrWrapper, CustomInput },
    emits: ['update:value', 'submit'],
    props: {
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: String,
            default: '',
        },
        max: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
        disableDates: {
            type: Array as () => Date[] | null,
            default: [],
        },
    },
    data() {
        return {
            expandPicker: false,
        };
    },
    computed: {
        flatpickrConfig(): any {
            return {
                maxDate: this.$props.max ? new Date(this.$props.max) : null,
                minDate: this.$props.min ? new Date(this.$props.min) : null,
                disable: this.$props.disableDates,
            };
        },
        computedValue: {
            get(): string | null {
                return this.$props.value;
            },
            set(newDateString: string | null) {
                this.$emit('update:value', newDateString);
            },
        },
        inputValue(): string {
            if (this.computedValue) {
                return this.$d(new Date(this.computedValue), 'short');
            }
            return '';
        },
    },
    methods: {
        onEscape() {
            this.expandPicker = false;
            (document.activeElement as HTMLElement)?.blur();
        },
        onClearClicked() {
            if (!this.$props.disabled) {
                this.computedValue = null;
            }
        },
    },
});
</script>
<style>
.form-date-picker .flatpickr-wrapper {
    top: 2rem;
}
</style>
