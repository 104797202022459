import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center space-x-3" }
const _hoisted_2 = { class: "flex items-center space-x-3" }
const _hoisted_3 = { class: "font-bold text-lg" }
const _hoisted_4 = { class: "text-sm text-gray-500" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "flex items-stretch space-x-3 pt-4"
}
const _hoisted_7 = {
  key: 0,
  class: "flex flex-1 border border-gray-200 rounded-lg p-2.5"
}
const _hoisted_8 = { class: "flex items-center space-x-3" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "text-gray-400 text-sm" }
const _hoisted_11 = {
  key: 1,
  class: "flex flex-1 border border-gray-200 rounded-lg p-2.5"
}
const _hoisted_12 = { class: "flex items-center space-x-3" }
const _hoisted_13 = { class: "font-bold" }
const _hoisted_14 = { class: "text-gray-400 text-sm" }
const _hoisted_15 = {
  key: 2,
  class: "flex flex-1 border border-gray-200 rounded-lg p-2.5"
}
const _hoisted_16 = { class: "flex items-center space-x-3" }
const _hoisted_17 = { class: "font-bold" }
const _hoisted_18 = { class: "text-gray-400 text-sm" }
const _hoisted_19 = {
  key: 3,
  class: "flex flex-1 border border-gray-200 rounded-lg p-2.5"
}
const _hoisted_20 = { class: "flex items-center space-x-3" }
const _hoisted_21 = { class: "font-medium" }
const _hoisted_22 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_23 = {
  key: 1,
  class: "text-yellow-400"
}
const _hoisted_24 = {
  key: 2,
  class: "text-green-400"
}
const _hoisted_25 = { class: "text-gray-400 text-sm" }
const _hoisted_26 = {
  key: 4,
  class: "flex flex-1 border border-gray-200 rounded-lg p-2.5"
}
const _hoisted_27 = { class: "flex items-center space-x-3" }
const _hoisted_28 = { class: "text-gray-400 text-sm" }
const _hoisted_29 = {
  key: 1,
  class: "text-center font-medium pt-6 pb-2"
}
const _hoisted_30 = { key: 0 }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { class: "flex items-center" }
const _hoisted_34 = { class: "w-6 flex-shrink-0 my-auto" }
const _hoisted_35 = {
  key: 0,
  class: "flex flex-shrink-0 justify-end"
}
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TreatmentsModal = _resolveComponent("TreatmentsModal")!
  const _component_AlertBadge = _resolveComponent("AlertBadge")!
  const _component_OverviewCharts = _resolveComponent("OverviewCharts")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["divide-y divide-gray-200 space-y-4 px-4 py-4", { 'expanded-barn': _ctx.isExpanded }]),
        style: {"scroll-margin-top":"120px"}
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_Icon, {
                class: "h-6",
                src: _ctx.IconSource.Location
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.barn.headers.barn_name), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.barn.rows?.length || 0) + " " + _toDisplayString(_ctx.barn.rows?.length === 1 ? _ctx.$t('views.monitoring.main.headings.section') : _ctx.$t('views.monitoring.main.headings.sectionPlural')), 1)
            ])
          ]),
          (_ctx.barn.rows && _ctx.barn.rows.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: "text-sky-500 hover:text-sky-700 transition-colors p-2 -mr-2",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleExpandBarn(_ctx.barn.headers.barn_id)), ["prevent"]))
                }, _toDisplayString(_ctx.isExpanded ? _ctx.$t('views.monitoring.main.buttons.hideSections') : _ctx.$t('views.monitoring.main.buttons.viewSections')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (
                    _ctx.barn.rows &&
                    _ctx.barn.rows.length &&
                    (_ctx.barn.headers.pigs.is_visible || _ctx.barn.headers.avg_weight.is_visible || _ctx.barn.headers.mortality.is_visible || _ctx.barn.headers.water_consumption.is_visible || _ctx.barn.headers.check_in.is_visible)
                )
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.barn.headers.pigs.is_visible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Icon, {
                        src: _ctx.barn.headers.pigs.icon_id,
                        class: "w-10 h-10"
                      }, null, 8, ["src"]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.barn.headers.pigs.number || ''), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.barn.headers.pigs.name), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.barn.headers.avg_weight.is_visible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_Icon, {
                        src: _ctx.barn.headers.avg_weight.icon_id,
                        class: "w-10 h-10"
                      }, null, 8, ["src"]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.barn.headers.avg_weight.number || ''), 1),
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.barn.headers.avg_weight.name), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.barn.headers.mortality.is_visible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_Icon, {
                        src: _ctx.barn.headers.mortality.icon_id,
                        class: "w-10 h-10"
                      }, null, 8, ["src"]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.barn.headers.mortality.number), 1),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.barn.headers.mortality.name), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.barn.headers.water_consumption.is_visible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_Icon, {
                        src: _ctx.barn.headers.water_consumption.icon_id,
                        class: "w-10 h-10"
                      }, null, 8, ["src"]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_21, [
                          (_ctx.barn.headers.water_consumption.alarm_severity === 3)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t('views.monitoring.main.tables.alarmStatus.alarm')), 1))
                            : (_ctx.barn.headers.water_consumption.alarm_severity === 2)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t('views.monitoring.main.tables.alarmStatus.attention')), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.$t('views.monitoring.main.tables.alarmStatus.OK')), 1))
                        ]),
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.barn.headers.water_consumption.name), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.barn.headers.check_in.is_visible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_Icon, {
                        src: _ctx.barn.headers.check_in.icon_id,
                        class: "w-10 h-10"
                      }, null, 8, ["src"]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["font-medium", { 'text-red-400': _ctx.hasCheckInAlarm }])
                        }, _toDisplayString(_ctx.barn.headers.check_in.max_elapsed_hours), 3),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.barn.headers.check_in.name), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.$t('views.monitoring.main.tables.noSectionsText')), 1))
      ], 2),
      _createVNode(_component_ExpandCollapseTransition, null, {
        default: _withCtx(() => [
          (_ctx.isExpanded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                _createVNode(_component_CustomTable, {
                  headers: _ctx.headers,
                  items: _ctx.items,
                  "expanded-items": _ctx.expandedRows,
                  rounded: false
                }, {
                  header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("th", { class: "w-6" }, null, -1)
                  ])),
                  _treatments: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                    _createElementVNode("td", {
                      class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
                      style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                    }, [
                      _createVNode(_component_TreatmentsModal, {
                        treatments: item._treatments,
                        "section-name": item.section_name,
                        "barn-name": _ctx.barn.headers.barn_name
                      }, {
                        default: _withCtx(({ open }) => [
                          _createElementVNode("button", {
                            class: "text-sky-500 hover:text-sky-700 underline transition-colors p-2 -ml-2",
                            style: {"text-underline-offset":"2px"},
                            onClick: open
                          }, _toDisplayString(item._treatments.length), 9, _hoisted_31)
                        ]),
                        _: 2
                      }, 1032, ["treatments", "section-name", "barn-name"])
                    ], 6)
                  ]),
                  _waterConsumption: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                    _createElementVNode("td", {
                      class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
                      style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                    }, [
                      _createElementVNode("div", null, [
                        (item.water_consumption.max_severity_level === null || Object.values(item.sensorHistory[_ctx.SensorHistoryType.WATER]).every((v) => v === false))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_32, "-"))
                          : (item.water_consumption.max_severity_level === 3)
                            ? (_openBlock(), _createBlock(_component_AlertBadge, {
                                key: 1,
                                type: "error",
                                class: "flex-shrink-0"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.main.tables.alarmStatus.alarm')), 1)
                                ]),
                                _: 1
                              }))
                            : (item.water_consumption.max_severity_level === 2)
                              ? (_openBlock(), _createBlock(_component_AlertBadge, {
                                  key: 2,
                                  type: "warning",
                                  class: "flex-shrink-0"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.main.tables.alarmStatus.attention')), 1)
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_AlertBadge, {
                                  key: 3,
                                  type: "success",
                                  class: "flex-shrink-0"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.main.tables.alarmStatus.OK')), 1)
                                  ]),
                                  _: 1
                                }))
                      ])
                    ], 6)
                  ]),
                  _checkIn: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                    _createElementVNode("td", {
                      class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
                      style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                    }, [
                      _createElementVNode("div", _hoisted_33, [
                        (item.check_in.is_red)
                          ? (_openBlock(), _createBlock(_component_Icon, {
                              key: 0,
                              src: _ctx.IconSource.AlarmIcon,
                              class: "h-6 w-6 mr-1"
                            }, null, 8, ["src"]))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(item.check_in.value), 1)
                      ])
                    ], 6)
                  ]),
                  actions: _withCtx(({ index, item }) => [
                    _createElementVNode("td", _hoisted_34, [
                      (Object.values(item.sensorHistory[_ctx.SensorHistoryType.OVERVIEW]).some((v) => v))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_35, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "p-2 -mr-2",
                              onClick: ($event: any) => (_ctx.toggleRowExpand(index))
                            }, [
                              _createVNode(_component_Icon, {
                                class: "h-3.5 w-3.5 select-none",
                                src: _ctx.expandedRows.includes(index) ? _ctx.IconSource.Close : _ctx.IconSource.Right
                              }, null, 8, ["src"])
                            ], 8, _hoisted_36)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  "expanded-row": _withCtx(({ item }) => [
                    _createElementVNode("td", {
                      colspan: _ctx.headers.length,
                      class: "w-full"
                    }, [
                      _createVNode(_component_OverviewCharts, {
                        "section-id": item.section_id,
                        section: item
                      }, null, 8, ["section-id", "section"])
                    ], 8, _hoisted_37)
                  ]),
                  _: 1
                }, 8, ["headers", "items", "expanded-items"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}