<template>
    <div class="h-full min-h-60 w-128 space-y-4">
        <h2 class="text-xl font-semibold text-center">{{ text }}</h2>
        <div class="grid grid-cols-2 gap-y-1 gap-x-2">
            <MonthPicker v-if="config.monthPicker" v-model:value="period" class="col-span-2" :label="config.periodFieldLabel" :disable-months="config.monthPicker.disableMonths"></MonthPicker>
            <CustomSelect
                v-if="config.yearPicker"
                v-model:value="period"
                :label="config.periodFieldLabel"
                :items="config.yearPicker.items"
                item-text="text"
                item-value="period"
                return-object
                :show-required-indication="true"
                :allow-empty="false"
                class="col-span-2"
                :rules="[{ validate: (value) => Boolean(value), message: '' }]"
            ></CustomSelect>
            <DatePicker v-if="config.datePicker" v-model:value="period" :disable-dates="config.datePicker.disable" required :max="config.datePicker.max" :label="config.periodFieldLabel" class="col-span-2">
            </DatePicker>
            <CustomInput
                v-for="numberField of config.numberFields"
                :key="numberField.value"
                v-model:value="form[numberField.value]"
                :class="[numberField.colSpan]"
                :label="numberField.label"
                input-type="number"
                required
                :rules="[{ validate: (value) => typeof value === 'number', message: '' }]"
            ></CustomInput>
        </div>
        <div class="flex space-x-4 self-end mt-4">
            <CustomButton :disabled="disableButtons" :disable="!validForm" @click="onSubmitClicked">
                {{ config.confirmButtonLabel }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import CustomInput from '@/components/ui/CustomInput.vue';
import MonthPicker from '@/components/ui/MonthPicker.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { CustomInput, MonthPicker, CustomButton, CustomSelect, DatePicker },
    emits: ['submit'],
    props: {
        text: {
            type: String,
            default: '',
        },
        config: {
            type: Object as () => {
                text: string;
                numberFields: { value: string; label: string; colSpan: string }[];
                transformPeriod: (period: any) => { [key: string]: any };
                submitActionType: ActionType;
                fetchActionType: ActionType;
                monthPicker?: { disableMonths: { [key: string]: number[] } };
                yearPicker?: { items: { period: string }[] };
                datePicker?: { max: string; disable?: Date[] };
                confirmButtonLabel: string;
                periodFieldLabel: string;
            },
            required: true,
        },
    },
    data() {
        return {
            form: {
                ...this.config?.numberFields.reduce((accum, current) => {
                    accum[current.value] = '';
                    return accum;
                }, {} as { [key: string]: string }),
            },
            period: null as null | string,
            disableButtons: false,
        };
    },
    computed: {
        validForm(): boolean {
            return Object.entries(this.form).every((currentEntry) => typeof currentEntry[1] === 'number') && Boolean(this.period);
        },
    },
    methods: {
        async onSubmitClicked() {
            if (this.disableButtons || !this.validForm) {
                return;
            }
            this.disableButtons = true;
            try {
                const submitForm = new Form({ ...this.form, ...this.$props.config.transformPeriod(this.period) });
                await store.dispatch(this.$props.config.submitActionType, { form: submitForm });
                if (submitForm.successful) {
                    store.dispatch(this.$props.config.fetchActionType, { options: { ignoreCache: true } });
                    this.$emit('submit');
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.disableButtons = false;
            }
        },
    },
});
</script>
