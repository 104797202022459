import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "h-52" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-center w-full h-52"
}
const _hoisted_3 = {
  key: 0,
  class: "flex space-x-2 h-52 overflow-x-auto"
}
const _hoisted_4 = ["src", "alt", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Spinner, { class: "h-10 w-10" })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.formattedImages.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedImages, (image, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: image.id,
                    class: "flex-shrink-0 h-full max-w-72 overflow-hidden rounded-lg p-2 border border-gray-200"
                  }, [
                    _createElementVNode("img", {
                      src: image.src,
                      class: "cursor-pointer hover:opacity-80 transition-opacity w-full h-full object-contain",
                      alt: `Image ${image.title}`,
                      onClick: ($event: any) => (_ctx.showImage(index))
                    }, null, 8, _hoisted_4)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_vue_easy_lightbox, {
      visible: _ctx.showLightbox,
      imgs: _ctx.formattedImages,
      index: _ctx.activeImageIndex,
      onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLightbox = false))
    }, null, 8, ["visible", "imgs", "index"])
  ]))
}