<template>
    <div class="max-w-76 w-full h-full">
        <h3 class="text-center pb-2 font-semibold">{{ $t('views.reports.selectDatesPage.selectDatesConditions') }}</h3>
        <div class="border-2 h-full overflow-y-auto border-opacity-10 rounded-lg py-2 max-h-80">
            <table class="w-full">
                <thead></thead>
                <tbody>
                    <tr v-for="date of state.selectableDates" :key="date" class="hover:bg-gray-100 cursor-pointer" @click="onDateClicked(date)">
                        <td :class="state.isBatchReport ? 'text-center px-4' : 'pl-4'" class="font-medium">{{ date }}</td>
                        <td v-if="!state.isBatchReport" class="pr-4">
                            <div class="flex justify-center">
                                <CustomCheckbox :checked="computedState.dates.includes(date)" @update:checked="onDateClicked(date)"></CustomCheckbox>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import { ReportViewState } from '@/types';

/* It was originally planned for step 2 to be shown both for farms and batches.
Step 2 has been kept generic in case we reintroduce step 2 for batches */
export default defineComponent({
    components: { CustomCheckbox },
    props: {
        state: {
            type: Object as () => ReportViewState,
            required: true,
        },
    },
    computed: {
        computedState: {
            get(): ReportViewState {
                return this.$props.state;
            },
            set(newValue: ReportViewState) {
                this.$emit('update:state', newValue);
            },
        },
    },
    methods: {
        onDateClicked(date: string) {
            const indexOfDate = this.computedState.dates.indexOf(date);
            if (indexOfDate >= 0) {
                this.computedState.dates.splice(indexOfDate, 1);
            } else {
                this.computedState.dates.push(date);
            }
        },
    },
});
</script>
