import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "absolute rounded-lg inset-0 flex justify-center items-center",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_AddButton = _resolveComponent("AddButton")!

  return (_ctx.element.type_settings.variant?.type || (_ctx.element.type_settings.variant?.color && Object.values(_ctx.element.type_settings.variant.color).some((color) => color)))
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: "button",
        class: _normalizeClass(["disabled:cursor-default transition-colors rounded-lg py-2 px-3 flex relative", _ctx.buttonClasses]),
        style: _normalizeStyle(_ctx.buttonStyles),
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onButtonClicked && _ctx.onButtonClicked(...args))),
        onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHovering = true)),
        onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHovering = false))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass({ invisible: _ctx.disabled })
        }, _toDisplayString(_ctx.element.label), 3),
        (_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Spinner, {
                class: _normalizeClass(["h-6 w-6 mx-auto", _ctx.spinnerTextClass]),
                style: _normalizeStyle({ color: _ctx.spinnerTextColor })
              }, null, 8, ["class", "style"])
            ]))
          : _createCommentVNode("", true)
      ], 46, _hoisted_1))
    : (_openBlock(), _createBlock(_component_AddButton, {
        key: 1,
        loading: _ctx.disabled,
        class: "mb-2",
        onClick: _ctx.onButtonClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.element.label), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"]))
}