import { UseQueryOptions, useQuery, keepPreviousData } from '@tanstack/vue-query';
import { Ref } from 'vue';
import { FilterableNotificationGroup, NotificationResponse, NotificationStatusType } from '@/types';
import ApiRequest from '@/plugins/store/actions/api/ApiRequest';

export const queryKeys = {
    notifications: ['notifications'],
    notificationsWithParams: (params: Ref<NotificationsFetchParams>) => ['notifications', params],
    filterableNotificationGroups: ['filterableNotificationGroups'],
};

export const GET_NOTIFICATIONS_ALLOWED_ORDER_BY = ['PublishedAt', 'Type', 'Message', 'Status'] as const;
export const GET_NOTIFICATIONS_ALLOWED_ORDER_DIRECTION = ['asc', 'desc'] as const;

export type NotificationsFetchParams = {
    pageSize: number;
    page: number;
    searchQuery: string;
    orderBy: (typeof GET_NOTIFICATIONS_ALLOWED_ORDER_BY)[number];
    orderDirection: (typeof GET_NOTIFICATIONS_ALLOWED_ORDER_DIRECTION)[number];
    typeIds: number[];
    statuses: NotificationStatusType[];
    isUnread?: boolean;
};

export function useNotifications(fetchParams: Ref<NotificationsFetchParams>, options: UseQueryOptions<NotificationResponse> = {}) {
    return useQuery<NotificationResponse>({
        queryKey: queryKeys.notificationsWithParams(fetchParams),
        queryFn: () => {
            let params = `?page_size=${fetchParams.value.pageSize}&page_number=${fetchParams.value.page}`;

            if (fetchParams.value.searchQuery) {
                params += `&search_query=${fetchParams.value.searchQuery}`;
            }

            if (fetchParams.value.orderBy) {
                params += `&sort=${fetchParams.value.orderBy} ${fetchParams.value.orderDirection}`;
            }

            if (fetchParams.value.typeIds?.length) {
                params += `&type_ids=${fetchParams.value.typeIds.join(',')}`;
            }

            if (fetchParams.value.statuses?.length) {
                params += `&status=${fetchParams.value.statuses.join(',')}`;
            }

            if (fetchParams.value.isUnread) {
                params += '&is_read=false';
            }

            return new ApiRequest().send('get', `notifications${params}`);
        },
        placeholderData: keepPreviousData,
        ...options,
    });
}

export function useFilterableNotificationGroups(options: UseQueryOptions<FilterableNotificationGroup[]> = {}) {
    return useQuery<FilterableNotificationGroup[]>({
        queryKey: queryKeys.filterableNotificationGroups,
        queryFn: () => new ApiRequest().send('get', 'notifications/types/filterable-groups'),
        ...options,
    });
}
