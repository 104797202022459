import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex font-medium" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "text-center w-full font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", {
        class: _normalizeClass(["flex my-2 gap-x-3 rounded text-[0.9rem] text-gray-500", _ctx.narrow ? 'px-4' : 'px-6'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
          return _renderSlot(_ctx.$slots, [`header_${header.value}`], {
            key: header.value,
            header: header,
            setItemRef: _ctx.setItemRef,
            getHeaderClass: _ctx.getHeaderClass,
            onHeaderClicked: _ctx.onHeaderClicked,
            getHeaderSortIcon: _ctx.getHeaderSortIcon
          }, () => [
            (_openBlock(), _createElementBlock("th", {
              key: header.value,
              ref_for: true,
              ref: (el) => _ctx.setItemRef(el, header),
              class: _normalizeClass(["flex flex-1", `${_ctx.getHeaderClass(header, header.sortable)}`]),
              onClick: ($event: any) => (_ctx.onHeaderClicked(header))
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", {
                  innerHTML: _ctx.sanitizeHtml(header.text)
                }, null, 8, _hoisted_4),
                (header.sortable)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["sort-icon flex ml-1.5", { visible: _ctx.getHeaderSortIcon(header).active }])
                    }, [
                      _createVNode(_component_Icon, {
                        class: "my-auto min-w-2 w-2 h-3.5",
                        src: _ctx.getHeaderSortIcon(header).icon
                      }, null, 8, ["src"])
                    ], 2))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_2))
          ], true)
        }), 128))
      ], 2)
    ]),
    (_ctx.sortedItems?.length)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedItems, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createElementVNode("tr", {
                class: _normalizeClass([[{ 'cursor-pointer': _ctx.clickableItems, 'odd:bg-dark-gray-200 rounded': !_ctx.expandItemsKey, 'border-t border-dark-gray-300': _ctx.expandItemsKey }, _ctx.narrow ? 'px-4' : 'px-6'], "flex py-4 gap-x-3 text-[0.9rem]"]),
                onClick: ($event: any) => (_ctx.clickableItems && _ctx.$emit('item-clicked', item))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                  return _renderSlot(_ctx.$slots, [`${header.value}`], {
                    key: `${index}_${header.value}`,
                    item: item,
                    getWidth: _ctx.getWidth,
                    getHeaderClass: _ctx.getHeaderClass,
                    header: header,
                    index: index,
                    getItemProperty: _ctx.getItemProperty,
                    expanded: _ctx.isExpanded(index)
                  }, () => [
                    (_openBlock(), _createElementBlock("td", {
                      key: `${index}_${header.value}`,
                      class: _normalizeClass(`${_ctx.getHeaderClass(header, _ctx.clickableItems)} ${header.cellClass ? header.cellClass : ''} my-auto break-words min-h-6.5`),
                      style: _normalizeStyle(`max-width: ${_ctx.getWidth(header)}px; width: 100%;`)
                    }, _toDisplayString(_ctx.getItemProperty(header, item, { numberFormat: header.numberFormat })), 7))
                  ], true)
                }), 128))
              ], 10, _hoisted_6),
              _createVNode(_component_ExpandCollapseTransition, null, {
                default: _withCtx(() => [
                  (_ctx.isExpanded(index) && item[_ctx.expandItemsKey]?.length)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["expand-container bg-dark-gray-200", { 'expand-container--last': index === _ctx.sortedItems.length - 1 }])
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item[_ctx.expandItemsKey], (expandItem, expandItemIndex) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `expand_${index}_${expandItemIndex}`,
                            class: _normalizeClass(["flex py-4 gap-x-3 bg-dark-gray-200 text-[0.9rem]", [{ 'border-t border-dark-gray-400': expandItemIndex !== 0 }, _ctx.narrow ? 'px-4' : 'px-6']])
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                              return _renderSlot(_ctx.$slots, [`${header.value}-expand`], {
                                key: `${index}_${header.value}`,
                                item: expandItem,
                                getWidth: _ctx.getWidth,
                                getHeaderClass: _ctx.getHeaderClass,
                                header: header,
                                index: index,
                                getItemProperty: _ctx.getItemProperty
                              }, () => [
                                (_openBlock(), _createElementBlock("td", {
                                  key: `${index}_${expandItemIndex}_${header.value}`,
                                  class: _normalizeClass(`${_ctx.getHeaderClass(header, _ctx.clickableItems)} my-auto break-words min-h-6.5`),
                                  style: _normalizeStyle(`max-width: ${_ctx.getWidth(header)}px; width: 100%;`)
                                }, _toDisplayString(_ctx.getItemProperty(header, expandItem, { numberFormat: header.numberFormat })), 7))
                              ], true)
                            }), 128))
                          ], 2))
                        }), 128))
                      ], 2))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ExpandCollapseTransition, null, {
                default: _withCtx(() => [
                  (_ctx.isExpanded(index) && !_ctx.expandItemsKey)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("tr", {
                          class: _normalizeClass(["flex py-4 border-t border-b border-t-gray-200 border-b-gray-200", _ctx.narrow ? 'px-4' : 'px-6'])
                        }, [
                          _renderSlot(_ctx.$slots, "expanded-row", { item: item }, undefined, true)
                        ], 2)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ], 64))
          }), 128)),
          _renderSlot(_ctx.$slots, "append-rows", {
            getWidth: _ctx.getWidth,
            getItemProperty: _ctx.getItemProperty,
            getHeaderClass: _ctx.getHeaderClass
          }, undefined, true)
        ]))
      : (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
          _createElementVNode("tr", {
            class: _normalizeClass(["rounded flex py-4 gap-x-3 odd:bg-dark-gray-200", _ctx.narrow ? 'px-4' : 'px-6'])
          }, [
            _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$t('views.activityTable.table.emptyTableMessage')), 1)
          ], 2)
        ]))
  ]))
}