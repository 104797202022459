<template>
    <MainViewCard class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.settings.setup.content.table.title') }}</h4>
        </div>
        <CustomTable expandable :items="items" :headers="headers">
            <template #header_actions>
                <th class="w-8"></th>
            </template>
            <template #actions="{ item }">
                <td class="w-8 my-auto">
                    <span class="flex justify-end">
                        <SettingsFormModal :header="settingsFormModalHeader" :initial-state="settingsFormModalInitialState">
                            <template #default="{ open }">
                                <button type="button" class="p-2 -mr-2" @click="onConfigEventClicked(item, open)">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </template>
                        </SettingsFormModal>
                    </span>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { ConfigEvent, TableHeader } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import { ActionType } from '@/plugins/store/actions';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';

export default defineComponent({
    components: { MainViewCard, CustomTable, SettingsFormModal },
    data() {
        return {
            settingsFormModalInitialState: {
                event: null as null | ConfigEvent,
                form: null,
                confirmed: false,
            },
            settingsFormModalHeader: {
                iconUrl: '',
            },
        };
    },
    computed: {
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.settings.setup.content.table.headers.name'),
                    value: 'name',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.settings.setup.content.table.headers.category'),
                    value: 'category',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'right',
                    sortable: false,
                },
            ];
        },
        items() {
            return store.state.configEvents;
        },
    },
    created() {
        store.dispatch(ActionType.GetConfigEvents);
    },
    methods: {
        onConfigEventClicked(item: ConfigEvent, open: CallableFunction) {
            this.settingsFormModalInitialState.event = item;
            this.settingsFormModalHeader.iconUrl = store.state.icons ? store.state.icons[`${item.icon_id}`] : '';
            open();
        },
    },
});
</script>
