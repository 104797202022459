<template>
    <MainViewCard class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ active ? $t('views.health.instructions.tables.activeHeader') : $t('views.health.instructions.tables.inactiveHeader') }}</h4>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.health.instructions.tables.searchFieldLabel')"></SearchInput>
            </span>
            <CreateInstructionModal>
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="open">
                        {{ $t('views.health.instructions.tables.addButtonLabel') }}
                    </AddButton>
                </template>
            </CreateInstructionModal>
        </div>
        <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader">
            <template #default="{ open }">
                <CustomTable :headers="headers" :items="filteredItems">
                    <template #header_actions>
                        <th class="w-8"></th>
                    </template>
                    <template #actions="{ item }">
                        <td class="w-8 my-auto">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="onEditClicked(item, open)">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </td>
                    </template>
                </CustomTable>
            </template>
        </ActionModal>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, computed, ref, Ref } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import AddButton from '@/components/ui/AddButton.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import CreateInstructionModal from '@/views/dashboard/health/instructions/createInstructionModal/createInstructionModal.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { Form, MultiStepFormState, IconSource, TableHeader } from '@/types';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, CustomTable, SearchInput, AddButton, CreateInstructionModal, ActionModal },
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const headers: TableHeader[] = [
            { text: i18n.global.t('views.health.instructions.tables.headings.farm'), value: 'farm_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.instructions.tables.headings.animalGroup'), value: 'animal_group', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.instructions.tables.headings.drug'), value: 'drug_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.instructions.tables.headings.diagnose'), value: 'diagnosis', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.instructions.tables.headings.expirationDate'), value: 'expires', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: '', value: 'actions', align: 'right', sortable: false, searchable: false },
        ];
        const items = computed(() => store.state.treatmentProcedures.filter((p) => p.is_active === props.active));
        const { search, filteredItems } = useFilterableTable(items, headers);

        const actionModalInitialState = ref<undefined | MultiStepFormState>(undefined);
        const actionModalHeader = ref<null | { icon_url: string; text: string }>(null);
        const actionModalStepConfig = ref([{}, { deleteButton: false, editable: true }, { disableRefetch: true, submitActionType: ActionType.PutForm }, {}]);

        const isLoadingForm = ref(false);

        const onEditClicked = async (item: any, open: CallableFunction) => {
            try {
                if (isLoadingForm.value) return;

                isLoadingForm.value = true;

                const form: Form = await store.dispatch(ActionType.GetForm, { form: { form_id: 33, resource_id: item.id } });

                if (form) {
                    actionModalInitialState.value = { location: null, form, confirmed: false, farm: null, event: null, notification: null };
                    actionModalHeader.value = { icon_url: IconSource.Treatment as any, text: form.sections?.[0]?.name || '' };

                    open();
                }
            } finally {
                isLoadingForm.value = false;
            }
        };

        return {
            onEditClicked,
            headers,
            search,
            filteredItems,
            actionModalInitialState,
            actionModalHeader,
            actionModalStepConfig,
        };
    },
});
</script>
