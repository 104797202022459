<template>
    <div class="relative raw-material-date-picker">
        <FlatPickrWrapper v-model:value="computedValue" v-model:show="expandPicker" :config="flatpickrConfig" auto-width mode="single">
            <input
                v-model="inputValue"
                :disabled="disabled"
                readonly
                type="string"
                :placeholder="placeholder"
                class="py-1 w-full cursor-text text-center outline-none bg-white rounded"
                @focus="expandPicker = true"
            />
        </FlatPickrWrapper>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FlatPickrWrapper from '@/components/ui/FlatpickrWrapper.vue';

export default defineComponent({
    components: { FlatPickrWrapper },
    props: {
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
    },
    emits: ['update:value'],
    data() {
        return {
            expandPicker: false,
            flatpickrConfig: {
                disable: [(date: Date) => date.getTime() > new Date().getTime()],
            },
        };
    },
    computed: {
        computedValue: {
            get(): string {
                return this.$props.value;
            },
            set(newDateString: string) {
                this.$emit('update:value', newDateString);
            },
        },
        inputValue(): string {
            if (this.computedValue) {
                return this.$d(new Date(this.computedValue), 'short');
            }
            return '';
        },
    },
});
</script>
<style>
.raw-material-date-picker .flatpickr-wrapper {
    top: 1.25rem;
    left: -9rem;
}
</style>
