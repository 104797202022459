<template>
    <DashboardContentLayout class="space-y-4" :heading="$t('views.economy.deaths.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <div class="space-y-6">
            <EconomyDeathsTable></EconomyDeathsTable>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import EconomyDeathsTable from '@/views/dashboard/economy/economyDeaths/EconomyDeathsTable.vue';

export default defineComponent({
    components: { DashboardContentLayout, EconomyDeathsTable },
});
</script>

<style scoped></style>
