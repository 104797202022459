<template>
    <div class="bg-gray-100 min-h-screen grid justify-center content-center">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
