import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { TooltipContent, type TooltipContentEmits, type TooltipContentProps, TooltipPortal, useForwardPropsEmits } from 'radix-vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'TooltipContent',
  props: {
    forceMount: { type: Boolean },
    ariaLabel: {},
    asChild: { type: Boolean },
    as: {},
    side: {},
    sideOffset: { default: 4 },
    align: {},
    alignOffset: {},
    avoidCollisions: { type: Boolean },
    collisionBoundary: {},
    collisionPadding: {},
    arrowPadding: {},
    sticky: {},
    hideWhenDetached: { type: Boolean }
  },
  emits: ["escapeKeyDown", "pointerDownOutside"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const forwarded = useForwardPropsEmits(props, emits);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TooltipPortal), null, {
    default: _withCtx(() => [
      _createVNode(_unref(TooltipContent), _mergeProps({ ..._unref(forwarded), ..._ctx.$attrs }, {
        "avoid-collisions": "",
        class: 
                _unref(cn)(
                    'z-[4000] overflow-hidden rounded-md border max-w-[100vw] font-medium bg-white px-3 py-1.5 text-normal shadow animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                    _ctx.$attrs.class ?? ''
                )
            
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 16, ["class"])
    ]),
    _: 3
  }))
}
}

})