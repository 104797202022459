import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

import { Search } from 'lucide-vue-next';
import { computed } from 'vue';
import { Input } from '@/components/ui/input';


export default /*@__PURE__*/_defineComponent({
  ...{
    inheritAttrs: false,
},
  __name: 'SearchInput',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const props = __props;

const emit = __emit;

const computedModelValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

function clear() {
    computedModelValue.value = '';
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Search), {
      class: "pointer-events-none absolute h-4 w-4 left-3 top-1/2 transform -translate-y-1/2 text-gray-500",
      "aria-hidden": "true"
    }),
    _createVNode(_unref(Input), _mergeProps({
      modelValue: computedModelValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((computedModelValue).value = $event)),
      class: "w-full pl-9"
    }, _ctx.$attrs, {
      onKeydown: _withKeys(_withModifiers(clear, ["stop"]), ["esc"])
    }), null, 16, ["modelValue", "onKeydown"])
  ]))
}
}

})