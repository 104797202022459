import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { TooltipTrigger, type TooltipTriggerProps } from 'radix-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TooltipTrigger',
  props: {
    asChild: { type: Boolean },
    as: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TooltipTrigger), _normalizeProps(_guardReactiveProps(props)), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}
}

})