import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  style: {"z-index":"9999"},
  class: "fixed w-full h-full left-0 top-0 grid bg-gray-100 justify-center content-center"
}
const _hoisted_3 = { class: "text-center grid" }
const _hoisted_4 = { class: "text-lg font-semibold" }
const _hoisted_5 = { class: "text-lg font-semibold my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('layouts.dashboard.warnings.screenSize.warningHeader')), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('layouts.dashboard.warnings.screenSize.warningSubheader')), 1)
      ])
    ])
  ]))
}