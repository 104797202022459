import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChartWithApiMultiselectOptions = _resolveComponent("GenericChartWithApiMultiselectOptions")!
  const _component_GenericChartWithApi = _resolveComponent("GenericChartWithApi")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.production.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_GenericChartWithApiMultiselectOptions, {
          chart: _ctx.dailyGainSpread.name,
          "options-callback": _ctx.dailyGainSpread.optionsCallback,
          "add-parameters-callback": _ctx.dailyGainSpread.addParametersCallback,
          "multiselect-options": _ctx.dailyGainSpread.multiselectOptions,
          options: _ctx.dailyGainSpread.options,
          "fetch-options": _ctx.dailyGainSpread.fetchOptions
        }, null, 8, ["chart", "options-callback", "add-parameters-callback", "multiselect-options", "options", "fetch-options"]),
        _createVNode(_component_MainViewCard, { class: "pt-4 pb-2 px-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_GenericChartWithApi, {
              "chart-name": _ctx.daysInBarn.name,
              "fetch-options": _ctx.daysInBarn.fetchOptions
            }, null, 8, ["chart-name", "fetch-options"])
          ]),
          _: 1
        }),
        _createVNode(_component_MainViewCard, { class: "pt-4 pb-2 px-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_GenericChartWithApi, {
              "chart-name": _ctx.weightSpanChart.name,
              "fetch-options": _ctx.weightSpanChart.fetchOptions
            }, null, 8, ["chart-name", "fetch-options"])
          ]),
          _: 1
        }),
        _createVNode(_component_MainViewCard, { class: "pt-4 pb-2 px-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_GenericChartWithApi, {
              "chart-name": _ctx.pigsTally.name,
              "chart-type": _ctx.pigsTally.chartType,
              "fetch-options": _ctx.pigsTally.fetchOptions
            }, null, 8, ["chart-name", "chart-type", "fetch-options"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}