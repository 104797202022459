import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-h-56 overflow-y-auto py-2 space-y-2 divide-y divide-gray-200" }
const _hoisted_2 = { class: "text-base font-semibold whitespace-nowrap" }
const _hoisted_3 = { class: "text-sm mt-0.5" }
const _hoisted_4 = { class: "space-y-0.5" }

import { computed } from 'vue';
import { Directory } from '@/types';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { i18n } from '@/plugins/internationalization/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'DirectoriesMoreInfoTooltip',
  props: {
    directory: {},
    enableTooltip: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const enableTooltip = computed(() => props.directory.search_topics.flatMap((topic) => topic.querables).length > 0);
const text = computed(() => {
    if (!enableTooltip.value) {
        return '-';
    }

    return i18n.global.t('views.settings.directories.content.table.moreInfoTableRow');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TooltipProvider), {
    disabled: !enableTooltip.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
        default: _withCtx(() => [
          _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass([{ 'underline decoration-dotted underline-offset-4': enableTooltip.value }])
              }, _toDisplayString(text.value), 3)
            ]),
            _: 1
          }),
          _createVNode(_unref(TooltipContent), { class: "overflow-hidden p-0 min-w-44" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.directory.search_topics, (searchTopic, searchTopicIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: searchTopic.name,
                    class: _normalizeClass(["px-3", { 'pt-2': searchTopicIndex !== 0 }])
                  }, [
                    _createElementVNode("h4", _hoisted_2, _toDisplayString(searchTopic.name), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("ul", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchTopic.querables, (querable) => {
                          return (_openBlock(), _createElementBlock("li", { key: querable }, _toDisplayString(querable), 1))
                        }), 128))
                      ])
                    ])
                  ], 2))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disabled"]))
}
}

})