import useDefaultChartOptions from '@/components/charts/composables/useDefaultChartOptions';
import { ChartType } from '@/types';

export default function useStockChartOptions(chartInstanceOptions: any) {
    return useDefaultChartOptions(chartInstanceOptions, {
        rangeSelector: {
            selected: 1,
        },
        type: ChartType.Stock,
        series: [
            {
                name: '',
                data: [],
            },
        ],
    });
}
