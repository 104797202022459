<template>
    <Combobox v-slot="{ open }" v-model="computedModelValue" nullable>
        <div class="relative">
            <div class="relative">
                <ComboboxInput
                    :display-value="computedDisplayValue"
                    autocomplete="off"
                    :class="[{ filled: computedModelValue || searchQuery.length || open }, borderClass, inputClass]"
                    class="custom-input text-base block w-full pt-6 pb-1 pl-5 pr-10 box-border outline-none rounded"
                    v-bind="$attrs"
                    @change="searchQuery = $event.target.value"
                    @focus="onFocus"
                />
                <ComboboxLabel v-if="label" :class="[labelClass]" class="custom-input-label text-gray-400 absolute top-4 left-5 transition-all truncate">
                    {{ label }}<span v-if="required && showRequiredIndication && !disabled" class="text-black">*</span>
                </ComboboxLabel>
                <button v-if="computedModelValue" key="clearButton" class="absolute inset-y-0 right-0 flex items-center pr-5" @click.stop="clear">
                    <Icon class="h-3 w-3 text-gray-400" :src="IconSource.Close" aria-hidden="true" />
                </button>
                <ComboboxButton v-else ref="boxButton" key="boxButton" class="absolute inset-y-0 -right-1 flex items-center pr-5">
                    <Spinner v-if="loading" class="w-5 h-5" :active="true" />
                    <Icon v-else class="h-5 w-5 text-gray-400" :src="IconSource.Search" aria-hidden="true" />
                </ComboboxButton>
            </div>
            <span class="min-h-4 flex text-xs text-left w-full ml-1" :class="{ 'text-red-600': hint?.type === 'error', 'text-gray-500': hint?.type === 'hint' }">{{ hint?.text || '' }}</span>
            <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-100 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <div v-show="open" class="absolute -mt-4 top-full left-0 w-full bg-white border z-30 rounded">
                    <ComboboxOptions class="max-h-60 outline-none overflow-y-auto" static>
                        <div v-if="filteredItems.length === 0 && searchQuery !== ''" class="text-base relative cursor-default select-none p-3 text-gray-500">
                            {{ $t('global.uiElements.combobox.noResults') }}
                        </div>
                        <ComboboxOption v-for="item in filteredItems" :key="item[itemValue]" v-slot="{ selected: selectedOption, active }" as="template" :value="item">
                            <li :class="[selectedOption ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : '']" class="text-base p-3 text-left w-full cursor-pointer">
                                <span class="block truncate" :class="{ 'font-medium': selectedOption, 'font-normal': !selectedOption }">
                                    <slot name="item" :item="item">
                                        {{ item[itemText] }}
                                    </slot>
                                </span>
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </transition>
        </div>
    </Combobox>
</template>

<script lang="ts">
import { Combobox, ComboboxLabel, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue';
import { defineComponent, computed, ref, watch } from 'vue';
import Icon from '@/components/icons/Icon.vue';
import { IconSource } from '@/types';
import Spinner from './Spinner.vue';

export default defineComponent({
    components: {
        Combobox,
        ComboboxLabel,
        ComboboxInput,
        ComboboxButton,
        ComboboxOptions,
        ComboboxOption,
        TransitionRoot,
        Icon,
        Spinner,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        itemText: {
            type: String,
            default: 'name',
        },
        itemValue: {
            type: String,
            default: 'id',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showRequiredIndication: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object,
            default: () => ({}),
        },
        rules: {
            type: Array,
            default: null,
        },
        displayValue: {
            type: Function,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const computedModelValue = computed({
            get() {
                return props.value;
            },
            set(value: any) {
                emit('update:value', value);
            },
        });

        const searchQuery = ref('');
        const validInput = ref(true);
        const errorMessage = ref('');
        const labelClass = computed(() => (props.disabled ? 'opacity-60' : ''));
        const inputClass = computed(() => (props.disabled ? 'bg-white text-gray-400' : 'bg-gray-100'));
        const filledClass = computed(() => (computedModelValue.value || searchQuery.value.length ? 'filled' : ''));
        const borderClass = computed(() => (validInput.value || computedModelValue.value ? 'border-green-200 border-b-2' : 'border-red-300 border-b-2'));
        const hint = computed(() => props.details || { type: 'hint', text: errorMessage.value });

        const computedDisplayValue = computed(() => {
            if (props.displayValue) {
                return props.displayValue(computedModelValue.value);
            }

            return (item: unknown) => (item as any)?.[props.itemText] || '';
        });

        const filteredItems = computed(() => {
            if (!searchQuery.value) {
                return props.items;
            }

            const searchString = searchQuery.value.trim().toLowerCase();

            return props.items.filter((item: any) => item[props.itemText].trim().toLowerCase().includes(searchString));
        });

        const boxButton = ref<any>(null);

        function onFocus() {
            if (computedModelValue.value) return;

            boxButton.value?.$el?.click();
        }

        function clear() {
            computedModelValue.value = null;
            searchQuery.value = '';
        }

        watch(
            () => props.value,
            (newValue: any) => {
                if (!props.rules.length) return;

                const firstInvalidRule = (props.rules as any[]).find((r: any) => !r.validate(newValue));

                if (firstInvalidRule) {
                    validInput.value = false;
                    errorMessage.value = firstInvalidRule.message;
                } else {
                    validInput.value = true;
                    errorMessage.value = '';
                }
            },
            { immediate: true }
        );

        return {
            computedModelValue,
            computedDisplayValue,
            searchQuery,
            labelClass,
            inputClass,
            filledClass,
            borderClass,
            filteredItems,
            onFocus,
            boxButton,
            hint,
            clear,
        };
    },
});
</script>

<style scoped>
.custom-input.filled + .custom-input-label,
.custom-input:focus + .custom-input-label {
    top: 0.4rem !important;
    font-size: 0.8125rem !important;
}
</style>
