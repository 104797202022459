import { computed } from 'vue';
import useDefaultChartOptions from '@/components/charts/composables/useDefaultChartOptions';
import { i18n } from '@/plugins/internationalization/i18n';

export default function useVariwideChartOptions(chartInstanceOptions: any) {
    const options = computed(() => ({
        chart: { type: 'variwide' },
        tooltip: {
            formatter() {
                return `<span style="font-size: 10px">${i18n.global.d((this as any).point.x, 'middle')} - ${i18n.global.d(
                    (this as any).point.x + (this as any).point.z,
                    'middle'
                )}</span><br/><span style="color:${(this as any).point.color}">●</span> ${(this as any).series.name}: <b>${i18n.global.n((this as any).point.y)}</b><br/>`;
            },
        },
    }));

    return useDefaultChartOptions(chartInstanceOptions, options);
}
