import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, isRef as _isRef, withKeys as _withKeys, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-5" }
const _hoisted_2 = { class: "space-y-5" }
const _hoisted_3 = { class: "flex items-end justify-between gap-x-3 gap-y-2" }
const _hoisted_4 = { class: "flex flex-wrap gap-2 items-center" }
const _hoisted_5 = {
  for: "unread",
  class: "cursor-pointer select-none text-normal font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
}
const _hoisted_6 = {
  key: 0,
  class: "flex gap-2 items-center"
}
const _hoisted_7 = {
  key: 1,
  class: "flex gap-2 items-center"
}
const _hoisted_8 = { class: "flex items-center gap-2" }
const _hoisted_9 = { class: "bg-gray-100 min-w-5 flex items-center justify-center rounded-[5px] px-1.5 py-px font-semibold" }
const _hoisted_10 = {
  key: 0,
  class: "flex items-center gap-2.5"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "flex items-center gap-2"
}
const _hoisted_13 = { class: "font-semibold" }
const _hoisted_14 = { class: "flex justify-end" }
const _hoisted_15 = {
  key: 1,
  class: "flex justify-end"
}

import { computed, ref, nextTick, h } from 'vue';
import { ChevronRight, ChevronDown } from 'lucide-vue-next';
import { ColumnDef } from '@tanstack/vue-table';
import { useQueryClient } from '@tanstack/vue-query';
import { DataTable, DataTableFilter } from '@/components/ui/data-table';
import { Card } from '@/components/ui/card';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { cn } from '@/lib/utils';
import { buttonVariants, Button } from '@/components/ui/button';
import { SearchInput } from '@/components/ui/input';
import Separator from '@/components/ui/separator/Separator.vue';
import { Form, Notification, NotificationStatusType } from '@/types';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import TextNotificationModal from '@/views/dashboard/notifications/notificationsIndex/TextNotificationModal.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { useFilterableNotificationGroups, useNotifications, queryKeys } from '@/plugins/store/actions/queries/notifications';
import { useNotificationsSearchParams } from '@/composables/notifications/useNotificationsSearchParams';
import StatusBadge from '@/views/dashboard/notifications/StatusBadge.vue';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import { Skeleton } from '@/components/ui/skeleton';
import { Checkbox } from '@/components/ui/checkbox';
import { i18n } from '@/plugins/internationalization/i18n';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useLoadingPageOverlayState } from '@/composables/useLoadingPageOverlayState';


export default /*@__PURE__*/_defineComponent({
  __name: 'Notifications',
  setup(__props) {

const queryClient = useQueryClient();

const { data: filterableNotificationGroups, isLoading: isFilterableNotificationGroupsLoading } = useFilterableNotificationGroups();
const typeOptions = computed(() => filterableNotificationGroups.value?.map((g) => ({ value: g.id.toString(), label: g.text, icon_id: g.icon_id })) || []);
const statusOptions = computed(() => [
    { value: NotificationStatusType.REQUIRES_ACTION, label: i18n.global.t('views.notifications.statusOptions.requiresAction'), type: NotificationStatusType.REQUIRES_ACTION },
    { value: NotificationStatusType.APPROVED, label: i18n.global.t('views.notifications.statusOptions.approved'), type: NotificationStatusType.APPROVED },
    { value: NotificationStatusType.REJECTED, label: i18n.global.t('views.notifications.statusOptions.rejected'), type: NotificationStatusType.REJECTED },
]);

const { fetchParams, searchQuery, fetchSearchQuery, page, pageSize, sorting, pageSizeOptions, types, statuses, unread } = useNotificationsSearchParams();

const computedTypes = computed({
    get() {
        return filterableNotificationGroups.value?.filter((g) => types.value?.includes(g.id.toString())).map((g) => g.id.toString()) || [];
    },
    set(value) {
        types.value = value;
    },
});
const computedStatuses = computed({
    get() {
        return statusOptions.value.filter((o) => statuses.value?.includes(o.value)).map((o) => o.value);
    },
    set(value) {
        statuses.value = value;
    },
});
const fetchTypesIds = computed(() => filterableNotificationGroups.value?.filter((g) => types.value?.includes(g.id.toString())).flatMap((g) => g.type_ids) || []);
const fetchStatuses = computed(() => statusOptions.value.filter((o) => statuses.value?.includes(o.value)).map((o) => o.value) as any);
const populatedFetchParams = computed(() => ({ ...fetchParams.value, typeIds: fetchTypesIds.value, statuses: fetchStatuses.value }));
const isQueryEnabled = computed(() => !isFilterableNotificationGroupsLoading.value);
const { data: notifications, isFetching: isNotificationsLoading } = useNotifications(populatedFetchParams, { enabled: isQueryEnabled });
const isLoading = computed(() => isFilterableNotificationGroupsLoading.value || isNotificationsLoading.value);

const notificationsData = computed(
    () =>
        notifications.value?.data.map((n) => ({
            ...n,
            _type: filterableNotificationGroups.value?.find((g) => g.type_ids.includes(n.type_id)),
        })) || []
);
const pagination = computed(() => ({
    totalPages: notifications.value?.total_pages || 0,
    totalRows: notifications.value?.total_rows || 0,
}));

const columns = computed<ColumnDef<Notification>[]>(() => [
    {
        id: '_select',
        header: ({ table }) =>
            h(Checkbox, {
                checked: table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
                'onUpdate:checked': (value) => table.toggleAllPageRowsSelected(!!value),
                ariaLabel: i18n.global.t('views.notifications.fields.selectAll.label'),
                class: 'mt-0.5',
            }),
        cell: ({ row }) =>
            h(Checkbox, {
                checked: row.getIsSelected(),
                'onUpdate:checked': (value) => row.toggleSelected(!!value),
                ariaLabel: i18n.global.t('views.notifications.fields.selectNotification.label'),
                class: 'mt-0.5',
            }),
    },
    {
        header: i18n.global.t('views.notifications.table.headers.type'),
        accessorKey: '_type',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.notifications.table.headers.message'),
        accessorKey: 'message',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.notifications.table.headers.status'),
        accessorKey: '_status',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.notifications.table.headers.publishDate'),
        accessorKey: 'publish_date',
        enableSorting: true,
    },
    {
        header: '',
        accessorKey: '_actions',
    },
]);
const rowSelection = ref<Record<number, boolean>>({});

const actionModalStepConfig = ref([{}, {}, { rejectable: true, submitActionType: ActionType.PutForm }, {}]);
const actionModalInitialState = ref<any>({
    location: null,
    form: null,
    confirmed: false,
    farm: null,
    record_id: null,
    event: null,
    notification: null,
});

const loadingNotificationId = ref<number | null>(null);

async function onOpenNotification(notification: Notification, open: CallableFunction) {
    if (!notification.resource || loadingNotificationId.value !== null) {
        return;
    }

    try {
        loadingNotificationId.value = notification.id;

        const form: Form = await store.dispatch(ActionType.GetForm, {
            form: { form_id: notification.resource.resource_id, nref: notification.resource.reference_id },
        });

        actionModalInitialState.value = {
            ...actionModalInitialState.value,
            form: form || null,
            notification,
        };

        await nextTick();

        open();
    } finally {
        loadingNotificationId.value = null;
    }
}

const { open: openLoadingPageOverlay, close: closeLoadingPageOverlay } = useLoadingPageOverlayState();

async function handleBulkMarkReadStatus(readStatus: boolean) {
    try {
        openLoadingPageOverlay(readStatus ? i18n.global.t('views.notifications.texts.markingAsRead') : i18n.global.t('views.notifications.texts.markingAsUnread'));

        await Promise.all([
            store.dispatch(ActionType.BulkMarkReadStatusNotifications, {
                form: {
                    notificationIds: Object.keys(rowSelection.value).map(Number),
                    readStatus,
                },
            }),
            // eslint-disable-next-line
            new Promise((resolve) => setTimeout(resolve, 1000)),
        ]);

        await queryClient.invalidateQueries({ queryKey: queryKeys.notifications });
    } catch (err) {
        //
    } finally {
        closeLoadingPageOverlay();
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Separator),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(SearchInput), {
            modelValue: _unref(searchQuery),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchQuery) ? (searchQuery).value = $event : null)),
            "aria-label": _ctx.$t('views.notifications.fields.search.label'),
            placeholder: _ctx.$t('views.notifications.fields.search.placeholder'),
            class: "w-64",
            onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (fetchSearchQuery.value = _unref(searchQuery)), ["enter"])),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (fetchSearchQuery.value = ''), ["esc"]))
          }, null, 8, ["modelValue", "aria-label", "placeholder"]),
          _createElementVNode("div", {
            class: _normalizeClass(["cursor-pointer", _unref(cn)(_unref(buttonVariants)({ variant: 'outline' }))]),
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (unread.value = !_unref(unread)), ["self"]))
          }, [
            _createVNode(_unref(Checkbox), {
              id: "unread",
              checked: _unref(unread),
              "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => (_isRef(unread) ? (unread).value = $event : null))
            }, null, 8, ["checked"]),
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('views.notifications.fields.unread.label')), 1)
          ], 2),
          _createVNode(FadeTransition, { mode: "out-in" }, {
            default: _withCtx(() => [
              (_unref(isFilterableNotificationGroupsLoading))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_unref(Skeleton), { class: "w-20 h-10 bg-gray-200" }),
                    _createVNode(_unref(Skeleton), { class: "w-24 h-10 bg-gray-200" })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_unref(DataTableFilter), {
                      modelValue: computedTypes.value,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((computedTypes).value = $event)),
                      options: typeOptions.value,
                      title: _ctx.$t('views.notifications.fields.type.label'),
                      "item-value": "value",
                      "item-label": "label",
                      "update-only-on-close": ""
                    }, {
                      item: _withCtx(({ item }) => [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_Icon, {
                            src: item.icon_id,
                            class: "w-6 h-6"
                          }, null, 8, ["src"]),
                          _createElementVNode("div", null, _toDisplayString(item.label), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "options", "title"]),
                    _createVNode(_unref(DataTableFilter), {
                      modelValue: computedStatuses.value,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((computedStatuses).value = $event)),
                      options: statusOptions.value,
                      title: _ctx.$t('views.notifications.fields.status.label'),
                      "item-value": "value",
                      "item-label": "label",
                      "update-only-on-close": ""
                    }, {
                      item: _withCtx(({ item }) => [
                        _createVNode(StatusBadge, {
                          type: item.value
                        }, null, 8, ["type"])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "options", "title"])
                  ]))
            ]),
            _: 1
          })
        ]),
        _createVNode(_unref(DropdownMenu), { modal: false }, {
          default: _withCtx(() => [
            _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  variant: "outline",
                  class: _normalizeClass(["transition-[opacity,background-color]", { 'visible opacity-100': Object.keys(rowSelection.value).length > 0, 'invisible opacity-0': Object.keys(rowSelection.value).length === 0 }])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.notifications.buttons.manageSelected')) + " ", 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(Object.keys(rowSelection.value).length), 1),
                    _createVNode(_unref(ChevronDown), {
                      class: "size-4",
                      "aria-hidden": "true"
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }),
            _createVNode(_unref(DropdownMenuContent), {
              class: "w-[var(--radix-dropdown-menu-trigger-width)]",
              align: "end"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(DropdownMenuItem), {
                  class: "cursor-pointer",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (handleBulkMarkReadStatus(true)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.notifications.buttons.markAsRead')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DropdownMenuItem), {
                  class: "cursor-pointer",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (handleBulkMarkReadStatus(false)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.notifications.buttons.markAsUnread')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_unref(Card), { class: "overflow-hidden" }, {
        default: _withCtx(() => [
          _createVNode(_unref(DataTable), {
            page: _unref(page),
            "onUpdate:page": _cache[9] || (_cache[9] = ($event: any) => (_isRef(page) ? (page).value = $event : null)),
            sorting: _unref(sorting),
            "onUpdate:sorting": _cache[10] || (_cache[10] = ($event: any) => (_isRef(sorting) ? (sorting).value = $event : null)),
            "page-size": _unref(pageSize),
            "onUpdate:pageSize": _cache[11] || (_cache[11] = ($event: any) => (_isRef(pageSize) ? (pageSize).value = $event : null)),
            "row-selection": rowSelection.value,
            "onUpdate:rowSelection": _cache[12] || (_cache[12] = ($event: any) => ((rowSelection).value = $event)),
            data: notificationsData.value,
            columns: columns.value,
            loading: isLoading.value,
            "page-size-options": _unref(pageSizeOptions),
            "total-pages": pagination.value.totalPages,
            "total-rows": pagination.value.totalRows,
            "server-side": ""
          }, {
            "item[_type]": _withCtx(({ item }) => [
              (item._type)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_Icon, {
                      src: item._type?.icon_id,
                      class: "w-9 h-9"
                    }, null, 8, ["src"]),
                    _createElementVNode("div", null, _toDisplayString(item._type?.text), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            "item[message]": _withCtx(({ item }) => [
              (item.read)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.message), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "h-2 w-2 bg-sky-500 rounded-full" }, null, -1)),
                    _createElementVNode("span", _hoisted_13, _toDisplayString(item.message), 1)
                  ]))
            ]),
            "item[_status]": _withCtx(({ item }) => [
              _createVNode(StatusBadge, { notification: item }, null, 8, ["notification"])
            ]),
            "item[publish_date]": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.$d(item.publish_date, 'long')), 1)
            ]),
            "item[_actions]": _withCtx(({ item }) => [
              _createElementVNode("div", _hoisted_14, [
                (item.category === 'NfnCategory.Form' && item.resource)
                  ? (_openBlock(), _createBlock(ActionModal, {
                      key: 0,
                      "step-config": actionModalStepConfig.value,
                      header: { icon_url: item.resource.icon_url, text: item.resource.text },
                      "initial-state": actionModalInitialState.value,
                      "initial-step-index": 2
                    }, {
                      default: _withCtx(({ open }) => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "hover:gray-300 rounded-full -mr-1",
                          disabled: loadingNotificationId.value !== null,
                          onClick: ($event: any) => (onOpenNotification(item, open))
                        }, {
                          default: _withCtx(() => [
                            (loadingNotificationId.value === item.id)
                              ? (_openBlock(), _createBlock(Spinner, {
                                  key: 0,
                                  class: "h-5 w-5",
                                  "aria-hidden": "true"
                                }))
                              : (_openBlock(), _createBlock(_unref(ChevronRight), {
                                  key: 1,
                                  class: "h-5 w-5",
                                  "aria-hidden": "true"
                                }))
                          ]),
                          _: 2
                        }, 1032, ["disabled", "onClick"])
                      ]),
                      _: 2
                    }, 1032, ["step-config", "header", "initial-state"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_15, [
                      _createVNode(TextNotificationModal, { notification: item }, {
                        default: _withCtx((scope) => [
                          _createVNode(_unref(Button), {
                            variant: "ghost",
                            size: "icon",
                            class: "hover:gray-300 rounded-full -mr-1",
                            disabled: loadingNotificationId.value !== null,
                            onClick: scope.open
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(ChevronRight), {
                                class: "h-5 w-5",
                                "aria-hidden": "true"
                              })
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"])
                        ]),
                        _: 2
                      }, 1032, ["notification"])
                    ]))
              ])
            ]),
            _: 1
          }, 8, ["page", "sorting", "page-size", "row-selection", "data", "columns", "loading", "page-size-options", "total-pages", "total-rows"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})