<script setup lang="ts">
import { HTMLAttributes, computed } from 'vue';
import { SelectScrollDownButton, SelectScrollDownButtonProps, useForwardProps } from 'radix-vue';
import { ChevronDown } from 'lucide-vue-next';
import { cn } from '@/lib/utils';

const props = defineProps<SelectScrollDownButtonProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <SelectScrollDownButton v-bind="forwardedProps" :class="cn('flex cursor-default items-center justify-center py-1', props.class)">
        <slot>
            <ChevronDown class="h-4 w-4" />
        </slot>
    </SelectScrollDownButton>
</template>
