import escapeRegExp from 'lodash-es/escapeRegExp';
import get from 'lodash-es/get';
import { Ref, ComputedRef, computed, unref, ref } from 'vue';
import { IconSource, TableHeader } from '@/types';
import NumberParser from '@/plugins/internationalization/NumberParser';
import { i18n } from '@/plugins/internationalization/i18n';

export default function useSortableTable(items: Ref<any[]> | ComputedRef<any[]>, headers: Ref<TableHeader[]> | ComputedRef<TableHeader[]> | TableHeader[]) {
    const sortByKey = ref('');

    const sortedItems = computed(() => {
        let sortedItems = [...items.value];

        if (sortByKey.value && sortedItems.length) {
            const [key, asc] = sortByKey.value.split('$_$');
            const header: TableHeader | undefined = (unref(headers) as TableHeader[]).find((currentHeader: TableHeader) => currentHeader.value === key);
            let sortComparison = header?.sortComparison;
            if (!sortComparison && header?.sorting_callback === 'Number_unit') {
                const numberParser = new NumberParser(i18n.global.locale);
                sortComparison = (a: any, b: any, orderMultiplier: number) => (numberParser.parse(get(a, key).split(' ')[0]) - numberParser.parse(get(b, key).split(' ')[0])) * orderMultiplier;
            }
            if (!sortComparison && typeof get(sortedItems[0], key) === 'number') {
                sortComparison = (a: any, b: any, orderMultiplier: number) => (get(a, key) - get(b, key)) * orderMultiplier;
            }
            const sortComparator = asc === 'ASC' ? 1 : -1;
            const collator = new Intl.Collator(undefined, { numeric: false, sensitivity: 'base' });

            sortedItems = sortedItems.sort((a: any, b: any) => (sortComparison ? sortComparison(a, b, sortComparator) : collator.compare(get(a, key), get(b, key)) * sortComparator));
        }

        return sortedItems;
    });

    function getHeaderSortIcon(header: TableHeader): { icon: string | number; active: boolean } {
        const escapedHeaderValue = escapeRegExp(header.value);
        const matches = new RegExp(`^${escapedHeaderValue}(\\$_\\$ASC)$|^${escapedHeaderValue}(\\$_\\$DESC)$`).exec(sortByKey.value);
        if (matches && matches.length > 1 && matches[1]) {
            return { icon: IconSource.SortDown, active: true };
        }
        if (matches && matches.length > 2 && matches[2]) {
            return { icon: IconSource.SortUp, active: true };
        }
        return { icon: IconSource.Sort, active: false };
    }

    function onHeaderClicked(header: TableHeader) {
        if (!header.sortable) {
            return;
        }

        if (!(sortByKey.value === `${header.value}$_$ASC` || sortByKey.value === `${header.value}$_$ASC`)) {
            sortByKey.value = `${header.value}$_$ASC`;
        } else if (sortByKey.value === `${header.value}$_$ASC`) {
            sortByKey.value = `${header.value}$_$DESC`;
        } else if (sortByKey.value === `${header.value}$_$DESC`) {
            sortByKey.value = '';
        }
    }

    return {
        sortByKey,
        sortedItems,
        getHeaderSortIcon,
        onHeaderClicked,
    };
}
