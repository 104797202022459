<template>
    <div class="h-full min-h-60 w-128 space-y-4">
        <h2 class="text-xl font-semibold text-center">{{ text }}</h2>
        <div class="grid grid-cols-2 gap-y-1 gap-x-2">
            <CustomSelect
                v-model:value="selectedItem"
                item-text="period"
                return-object
                item-value="id"
                :allow-empty="false"
                :label="config.periodFieldLabel"
                :items="uniqueItems"
                class="col-span-2"
                required
                :rules="[{ validate: (value) => Boolean(value), message: '' }]"
            ></CustomSelect>
            <CustomInput
                v-for="numberField of config.numberFields"
                :key="numberField.value"
                v-model:value="form[numberField.value]"
                :class="[numberField.colSpan]"
                :label="numberField.label"
                :disabled="!selectedItem"
                input-type="number"
                required
                :rules="[{ validate: (value) => typeof value === 'number', message: '' }]"
            ></CustomInput>
            <div class="col-span-2">
                <DeleteGlobalPriceConfirmationModal :item="selectedItem" :config="config.deleteModal" @submit="onPriceDeleted">
                    <template #default="{ open }">
                        <DeleteButton :disabled="!selectedItem" @click="onDeleteGlobalPriceClicked(open)">
                            {{ config.deleteButtonLabel }}
                        </DeleteButton>
                    </template>
                </DeleteGlobalPriceConfirmationModal>
            </div>
        </div>
        <div class="flex space-x-4 self-end mt-4">
            <CustomButton :disabled="disableButtons" :disable="!validForm" @click="onSubmitClicked">
                {{ config.confirmButtonLabel }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import DeleteGlobalPriceConfirmationModal from '@/components/common/economy/globalPrices/DeleteGlobalPriceConfirmationModal.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { EconomyDeadPayment, EconomyPostPayment, EconomyStatusEntry } from '@/types';

export default defineComponent({
    components: { CustomInput, CustomSelect, CustomButton, DeleteButton, DeleteGlobalPriceConfirmationModal },
    emits: ['submit', 'skip'],
    props: {
        text: {
            type: String,
            default: '',
        },
        config: {
            type: Object as () => {
                text: string;
                numberFields: { value: string; label: string; colSpan: string }[];
                updateActionType: ActionType;
                fetchActionType: ActionType;
                confirmButtonLabel: string;
                periodFieldLabel: string;
                deleteButtonLabel: string;
                items: (EconomyStatusEntry | EconomyPostPayment | EconomyDeadPayment)[];
                deleteModal: {
                    header: string;
                    message: string;
                    cancelButtonLabel: string;
                    confirmButtonLabel: string;
                    deleteActionType: ActionType;
                    fetchActionType: ActionType;
                };
            },
            required: true,
        },
    },
    data() {
        return {
            form: {
                ...this.config?.numberFields.reduce((accum, current) => {
                    accum[current.value] = '';
                    return accum;
                }, {} as { [key: string]: string }),
            },
            disableButtons: false,
            selectedItem: null as null | EconomyStatusEntry | EconomyPostPayment | EconomyDeadPayment,
        };
    },
    computed: {
        validForm(): boolean {
            return Object.entries(this.form).every((currentEntry) => typeof currentEntry[1] === 'number');
        },
        uniqueItems() {
            const uniqueItems = this.$props.config.items.reduce((accum, current) => {
                accum[current.period as string] = current;
                return accum;
            }, {} as { [key: string]: EconomyStatusEntry | EconomyPostPayment | EconomyDeadPayment });
            return Object.values(uniqueItems);
        },
    },
    watch: {
        selectedItem(newValue: EconomyStatusEntry | EconomyPostPayment | EconomyDeadPayment) {
            Object.keys(this.form).forEach((currentKey) => {
                this.form[currentKey] = (newValue as any)[currentKey];
            });
        },
    },
    methods: {
        async onSubmitClicked() {
            if (!this.selectedItem) {
                return;
            }
            await store.dispatch(this.$props.config.updateActionType, { form: { id: this.selectedItem.id, update: this.form } });
            store.dispatch(this.$props.config.fetchActionType, { options: { ignoreCache: true } });
            this.$emit('submit');
        },
        onDeleteGlobalPriceClicked(open: CallableFunction) {
            if (!this.selectedItem) {
                return;
            }
            open();
        },
        onPriceDeleted() {
            this.$emit('skip');
        },
    },
});
</script>
