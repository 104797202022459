<template>
    <div role="progressbar" :aria-label="qualityAssessmentCategory.name" :aria-valuenow="valueNow" aria-valuemin="100" aria-valuemax="100" class="flex flex-shrink-0 space-x-1.5 py-2" v-bind="$attrs">
        <template v-if="qualityAssessmentCategory.id === DataQualityCategoryStatus.Excellent">
            <div class="w-6 h-2 rounded-full bg-green-400"></div>
            <div class="w-6 h-2 rounded-full bg-green-400"></div>
            <div class="w-6 h-2 rounded-full bg-green-400"></div>
        </template>
        <template v-else-if="qualityAssessmentCategory.id === DataQualityCategoryStatus.Uncertain">
            <div class="w-6 h-2 rounded-full bg-yellow-500"></div>
            <div class="w-6 h-2 rounded-full bg-yellow-500"></div>
            <div class="w-6 h-2 rounded-full bg-gray-300 bg-opacity-60"></div>
        </template>
        <template v-else-if="qualityAssessmentCategory.id === DataQualityCategoryStatus.Inferior">
            <div class="w-6 h-2 rounded-full bg-red-400"></div>
            <div class="w-6 h-2 rounded-full bg-gray-300 bg-opacity-60"></div>
            <div class="w-6 h-2 rounded-full bg-gray-300 bg-opacity-60"></div>
        </template>
        <template v-else>
            <div class="w-6 h-2 rounded-full bg-gray-300 bg-opacity-60"></div>
            <div class="w-6 h-2 rounded-full bg-gray-300 bg-opacity-60"></div>
            <div class="w-6 h-2 rounded-full bg-gray-300 bg-opacity-60"></div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { DataQualityCategory } from '@/types';

interface Props {
    qualityAssessmentCategory: DataQualityCategory;
}

const props = defineProps<Props>();

/* eslint-disable */
enum DataQualityCategoryStatus {
    Excellent = 1,
    Uncertain = 2,
    Inferior = 3,
    Analyzing = 4,
    Unprocessable = 5,
}
/* eslint-enable */

const valueNow = computed(() => {
    switch (props.qualityAssessmentCategory.id) {
        case DataQualityCategoryStatus.Excellent:
            return 100;
        case DataQualityCategoryStatus.Uncertain:
            return 66;
        case DataQualityCategoryStatus.Inferior:
            return 33;
        case DataQualityCategoryStatus.Analyzing:
        case DataQualityCategoryStatus.Unprocessable:
        default:
            return 0;
    }
});
</script>
```
