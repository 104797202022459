import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "health-overview-tabs default-tabs" }
const _hoisted_2 = { class: "space-x-8 flex border-b border-black border-opacity-10" }
const _hoisted_3 = { class: "flex pr-4" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreatmentsTable = _resolveComponent("TreatmentsTable")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Tabs, {
        teleport: "#health-overview-tabs-target",
        "active-tab-class": "--active"
      }, {
        default: _withCtx(() => [
          _createElementVNode("nav", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Tab, {
                name: _ctx.$t('views.health.overview.tabs.ongoing'),
                default: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TreatmentsTable, {
                    items: _ctx.ongoingTreatments,
                    class: "mt-4"
                  }, null, 8, ["items"])
                ]),
                _: 1
              }, 8, ["name"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Tab, {
                name: _ctx.$t('views.health.overview.tabs.completed')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TreatmentsTable, {
                    items: _ctx.completedTreatments,
                    class: "mt-4",
                    completed: ""
                  }, null, 8, ["items"])
                ]),
                _: 1
              }, 8, ["name"])
            ])
          ])
        ]),
        _: 1
      }),
      _cache[0] || (_cache[0] = _createElementVNode("div", { id: "health-overview-tabs-target" }, null, -1))
    ])
  ]))
}