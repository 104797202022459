import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "flex flex-col h-full min-h-44" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.notes.modals.createNote.header')), 1),
    _createElementVNode("form", _hoisted_3, [
      _createVNode(_component_CustomMultiSelect, {
        value: _ctx.computedState.locations,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedState.locations) = $event)),
        class: "self-start w-full",
        items: _ctx.farms,
        "return-object": "",
        required: "",
        "item-value": "_id",
        "item-text": "name",
        "allow-empty": "",
        "dropdown-height-class": "max-h-84",
        rules: [{ validate: (value) => value !== null, message: '' }],
        label: _ctx.$t('global.forms.steps.location.selectLocationFieldLabel'),
        disabled: false,
        "show-required-indication": "",
        "show-hint": false,
        "show-select-all": "",
        groups: "",
        "group-key": "locations",
        "group-item-value": "barn_id"
      }, null, 8, ["value", "items", "rules", "label"]),
      _createVNode(_component_ExpandCollapseTransition, null, {
        default: _withCtx(() => [
          (_ctx.activeLocationSections)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_CustomMultiSelect, {
                  value: _ctx.selectedSections,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSections) = $event)),
                  class: "self-start w-full",
                  items: _ctx.activeLocationSections,
                  "return-object": "",
                  "item-value": "id",
                  "item-text": "name",
                  "dropdown-height-class": "max-h-84",
                  rules: [{ validate: (value) => value !== null, message: '' }],
                  label: _ctx.$t('global.forms.steps.location.selectSectionFieldLabel'),
                  disabled: false,
                  "show-hint": false,
                  "show-select-all": ""
                }, null, 8, ["value", "items", "rules", "label"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_CustomButton, {
        disabled: !_ctx.validForm,
        onClick: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.dynamicForm.nextButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}