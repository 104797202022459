<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.pigs.batches.header.heading')" :has-period-picker="true" :has-pig-type-picker="false">
        <div class="space-y-8">
            <MainViewCard>
                <PigsBatchesTable></PigsBatchesTable>
            </MainViewCard>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import PigsBatchesTable from '@/views/dashboard/pigs/pigsBatches/PigsBatchesTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';

export default defineComponent({
    components: { DashboardContentLayout, PigsBatchesTable, MainViewCard },
});
</script>
