<template>
    <div class="px-8 py-6 space-y-8 h-full overflow-hidden">
        <div>
            <h5 class="font-semibold">
                {{ $t('views.activityTable.calendarModal.activities.header') }}
            </h5>
        </div>
        <div class="pb-8 h-full overflow-auto">
            <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader">
                <template #default="{ open }">
                    <div class="space-y-4 overflow-auto max-h-full">
                        <button v-for="event of events" :key="event.id" type="button" class="flex space-x-4 bg-dark-gray-200 bg-opacity-80 py-2 px-4 w-full rounded-lg h-20" @click="onEventClicked(event, open)">
                            <div class="flex h-full my-auto">
                                <Icon class="my-auto h-12 w-12" :src="event.icon_url"></Icon>
                            </div>
                            <div class="flex flex-1 h-full my-auto">
                                <div class="my-auto">
                                    <div class="font-semibold text-left">{{ event.description }}</div>
                                    <div class="flex text-gray-400 text-sm">
                                        <span>{{ $d(event.date, 'middle') }}</span>
                                        <span class="rounded-full w-1 h-1 my-auto bg-gray-400 mx-2"></span>
                                        <span> {{ `${$t('views.activityTable.calendarModal.activities.week')} ${getWeekNumberfromISODateString(event.date)}` }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex h-full my-auto">
                                <Icon class="my-auto h-3 w-3 mr-2" :src="IconSource.Right"></Icon>
                            </div>
                        </button>
                    </div>
                </template>
            </ActionModal>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getWeek } from 'date-fns';
import { EconomyPosting, MultiStepFormState, Record, RecordForm } from '@/types';
import { getLocale } from '@/plugins/date-fns';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { ActionModal },
    props: {
        events: {
            type: Array as () => (Record | EconomyPosting)[],
            default: [],
        },
    },
    data() {
        return {
            actionModalStepConfig: [{}, { deleteButton: true, editable: true }, { submitActionType: ActionType.PutRecordForm }, {}],
            actionModalInitialState: undefined as undefined | MultiStepFormState,
            actionModalHeader: null as null | { icon_url: string; text: string },
        };
    },
    methods: {
        getWeekNumberfromISODateString(dateString: string) {
            return getWeek(new Date(dateString), { weekStartsOn: 1, locale: getLocale(this.$i18n.locale) });
        },
        async onEventClicked(event: Record | EconomyPosting, open: CallableFunction) {
            const recordForm: RecordForm = await store.dispatch(ActionType.GetRecordForm, { form: { record_id: event.id } });
            this.actionModalInitialState = { location: null, form: recordForm.form, confirmed: false, farm: null, event: null, record_id: event.id, notification: null };
            this.actionModalStepConfig[1].deleteButton = recordForm.deletable;
            this.actionModalStepConfig[1].editable = recordForm.editable;
            this.actionModalHeader = { icon_url: (event as Record).icon_url || '', text: (event as Record).name || (event as EconomyPosting).category || '' };
            open();
        },
    },
});
</script>
