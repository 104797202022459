<template>
    <img :src="resolvedSrc" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { store } from '@/plugins/store';

export default defineComponent({
    props: {
        src: {
            type: [String, Number],
            default: '',
        },
    },
    computed: {
        resolvedSrc() {
            switch (typeof this.src) {
                case 'number': {
                    return store.state.icons ? store.state.icons[this.$props.src] : '';
                }
                case 'string': {
                    return this.src;
                }
                default: {
                    return '';
                }
            }
        },
    },
});
</script>
