import { vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "relative overflow-hidden" }
const _hoisted_2 = ["id", "autocomplete", "disabled", "type", "step", "readonly", "tabIndex"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.uid,
      ref: "input-field",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
      class: _normalizeClass([[_ctx.filledClass, _ctx.borderClass, _ctx.inputClass, _ctx.paddingClass, _ctx.focusedClass, _ctx.cursorClass, _ctx.addInputClass], "custom-input block w-full box-border outline-none rounded"]),
      style: _normalizeStyle([_ctx.paddingStyle]),
      autocomplete: _ctx.autoComplete,
      disabled: _ctx.disabled,
      type: _ctx.inputType,
      step: _ctx.step,
      readonly: _ctx.readonly,
      tabIndex: _ctx.tabIndex,
      onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('submit')), ["enter"])),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('input'))),
      onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
    }, null, 46, _hoisted_2), [
      [_vModelDynamic, _ctx.computedValue],
      [_directive_focus, _ctx.focusOnRender]
    ]),
    (_ctx.prepend)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          ref: "prepend",
          class: "absolute text-dark-gray-600 left-5 top-3.5 font-semibold"
        }, _toDisplayString(_ctx.prepend), 513))
      : (_openBlock(), _createElementBlock("label", {
          key: 1,
          class: _normalizeClass([[_ctx.labelClass, _ctx.cursorClass], "custom-input-label text-gray-400 absolute top-4 left-5 transition-all truncate"]),
          for: _ctx.uid
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
          _withDirectives(_createElementVNode("span", _hoisted_4, "*", 512), [
            [_vShow, _ctx.required && _ctx.showRequiredIndication && !_ctx.disabled]
          ])
        ], 10, _hoisted_3)),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["min-h-4 flex text-xs text-left w-full ml-1", { 'text-red-600': _ctx.hint?.type === 'error', 'text-gray-500': _ctx.hint?.type === 'hint' }])
    }, _toDisplayString(_ctx.hint?.text || ''), 3), [
      [_vShow, _ctx.hint.text]
    ])
  ]))
}