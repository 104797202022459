import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex mx-6 space-x-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "text-right mr-6 w-80" }
const _hoisted_4 = { class: "w-20" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-end gap-x-1"
}
const _hoisted_6 = ["disabled", "onClick"]
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = ["disabled", "onClick"]
const _hoisted_9 = { class: "sr-only" }

import { ref, computed, watch, nextTick } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Directory, SettingEvent, SettingEventType, TableHeader, UserRole, IconSource } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';
import AddButton from '@/components/ui/AddButton.vue';
import DirectoriesMoreInfoTooltip from '@/views/dashboard/settings/directories/DirectoriesMoreInfoTooltip.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import useFilterableTable from '@/composables/useFilterableTable';
import Spinner from '@/components/ui/Spinner.vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import LogOutIcon from '@/components/icons/LogOutIcon.vue';
import InfoIcon from '@/components/icons/InfoIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DirectoriesTable',
  setup(__props) {

store.dispatch(ActionType.GetDirectories);
store.dispatch(ActionType.GetActions);
store.dispatch(ActionType.GetSettingEvents);

const editingDirectory = ref<null | Directory>(null);
const editDirectoryInitialState = ref<{
    event: null | SettingEvent;
    form: null;
    confirmed: boolean;
}>({
    event: null,
    form: null,
    confirmed: false,
});

const headers = computed<TableHeader[]>(() => [
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.active'),
        value: 'active',
        align: 'left',
        sortable: true,
        searchable: false,
    },
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.name'),
        value: 'name',
        align: 'left',
        sortable: true,
        searchable: true,
    },
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.mainNumber'),
        value: 'main_number',
        align: 'left',
        sortable: true,
        searchable: true,
    },
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.moreInfo'),
        value: 'search_topics',
        align: 'left',
        sortable: false,
        searchable: true,
        searchComparison: (item: Directory, search) => {
            const searchableSearchTopics = item.search_topics.filter((currentSearchTopic) => currentSearchTopic.searchable);
            const querables = searchableSearchTopics.flatMap((currentSearchTopic) => currentSearchTopic.querables);
            return querables.join(',').toLowerCase().trim().includes(search.toLowerCase().trim());
        },
    },
    {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
        searchable: false,
    },
]);
const items = computed(() => store.state.directories);
const { search, filteredItems } = useFilterableTable(items, headers);

const canCreateDirectores = computed(() => store.state.userRole === UserRole.solutionAdmin);
const canManageDirectory = computed(() => store.getters.loggedInUserCanManageDirectory);

const editDirectoryStepConfig = computed(() => [
    { directory: editingDirectory.value },
    {},
    {
        rejectable: false,
        submitActionType: ActionType.PutForm,
        editButtonLabel: i18n.global.t('global.forms.steps.confirmation.backButtonLabel'),
    },
    {},
]);
const createDirectorySettingEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === SettingEventType.directoryCreate));
const editDirectorySettingEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === SettingEventType.directoryData));

function onFormModalClosing(isFinalStep: boolean) {
    if (isFinalStep) {
        store.dispatch(ActionType.GetDirectories, { options: { ignoreCache: true } });
    }
}

async function onEditDirectoryClicked(directory: Directory, open: CallableFunction) {
    editingDirectory.value = directory;

    await nextTick();

    open();
}

watch(
    editDirectorySettingEvent,
    (newValue: SettingEvent | undefined) => {
        if (newValue) {
            editDirectoryInitialState.value.event = newValue;
        }
    },
    { immediate: true, deep: true }
);

const loadingDirectoryId = ref<string | null>(null);

async function onSwitchDirectory(directory: Directory) {
    try {
        if (loadingDirectoryId.value !== null) {
            return;
        }

        loadingDirectoryId.value = directory.id;

        await store.dispatch(ActionType.ActivateDirectory, { form: { directoryId: directory.id } });

        window.location.reload();
    } catch (err) {
        loadingDirectoryId.value = null;
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(MainViewCard, { class: "space-y-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.directories.content.table.title')), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(SearchInput, {
            value: _unref(search),
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : null)),
            label: _ctx.$t('views.settings.directories.content.table.searchFieldLabel')
          }, null, 8, ["value", "label"])
        ]),
        (createDirectorySettingEvent.value && canCreateDirectores.value)
          ? (_openBlock(), _createBlock(SettingsFormModal, {
              key: 0,
              "initial-state": {
                    event: createDirectorySettingEvent.value,
                    form: null,
                    confirmed: false,
                },
              onClosing: onFormModalClosing
            }, {
              default: _withCtx(({ open }) => [
                _createVNode(AddButton, {
                  "text-class": "font-medium",
                  onClick: open
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.settings.directories.content.createDirectoryButtonLabel')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["initial-state"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(CustomTable, {
        expandable: "",
        items: _unref(filteredItems),
        headers: headers.value
      }, {
        header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("th", { class: "w-20" }, null, -1)
        ])),
        active: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          _createElementVNode("td", {
            class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex rounded-full h-5 w-5 bg-opacity-20", item.active ? 'bg-green-200' : 'bg-red-400'])
            }, [
              _createVNode(_component_Icon, {
                class: "m-auto",
                src: item.active ? _unref(IconSource).Active : _unref(IconSource).Inactive
              }, null, 8, ["src"])
            ], 2)
          ], 6)
        ]),
        search_topics: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          (_openBlock(), _createElementBlock("td", {
            key: header.value,
            class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, [
            _createVNode(DirectoriesMoreInfoTooltip, { directory: item }, null, 8, ["directory"])
          ], 6))
        ]),
        actions: _withCtx(({ item }) => [
          _createElementVNode("td", _hoisted_4, [
            (canManageDirectory.value || !item.active)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(SettingsFormModal, {
                    "initial-state": editDirectoryInitialState.value,
                    "step-config": editDirectoryStepConfig.value,
                    onClosing: onFormModalClosing
                  }, {
                    default: _withCtx(({ open }) => [
                      _createVNode(_unref(TooltipProvider), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Tooltip), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("button", {
                                    type: "button",
                                    disabled: loadingDirectoryId.value !== null,
                                    class: "flex items-center justify-center h-8 w-8 rounded-full text-gray-800 hover:text-gray-900 hover:bg-gray-100 disabled:bg-transparent transition-colors",
                                    onClick: ($event: any) => (onEditDirectoryClicked(item, open))
                                  }, [
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('views.settings.directories.content.buttons.viewCompanyDetails')), 1),
                                    _createVNode(InfoIcon, {
                                      class: "h-5 w-5",
                                      "aria-hidden": "true"
                                    })
                                  ], 8, _hoisted_6)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TooltipContent), null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('views.settings.directories.content.buttons.viewCompanyDetails')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["initial-state", "step-config"]),
                  (!item.active)
                    ? (_openBlock(), _createBlock(_unref(TooltipProvider), { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Tooltip), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("button", {
                                    type: "button",
                                    disabled: loadingDirectoryId.value !== null,
                                    class: "flex items-center justify-center h-8 w-8 rounded-full text-gray-800 hover:text-gray-900 hover:bg-gray-100 disabled:bg-transparent transition-colors",
                                    onClick: ($event: any) => (onSwitchDirectory(item))
                                  }, [
                                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('views.settings.directories.content.buttons.switchCompany')), 1),
                                    (loadingDirectoryId.value === item.id)
                                      ? (_openBlock(), _createBlock(Spinner, {
                                          key: 0,
                                          class: "h-5 w-5"
                                        }))
                                      : (_openBlock(), _createBlock(LogOutIcon, {
                                          key: 1,
                                          class: "h-5 w-5",
                                          "aria-hidden": "true"
                                        }))
                                  ], 8, _hoisted_8)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TooltipContent), null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('views.settings.directories.content.buttons.switchCompany')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["items", "headers"])
    ]),
    _: 1
  }))
}
}

})