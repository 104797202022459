<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.settings.users.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <UsersTable></UsersTable>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import UsersTable from '@/views/dashboard/settings/users/UsersTable.vue';

export default defineComponent({
    components: { DashboardContentLayout, UsersTable },
});
</script>
