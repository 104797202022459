import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChart = _resolveComponent("GenericChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GenericChart, {
      "print-subtitle": _ctx.reportTitle,
      options: _ctx.data.options || _ctx.columnChartOptions
    }, null, 8, ["print-subtitle", "options"])
  ]))
}