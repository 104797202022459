<template>
    <router-link :to="'/assignments'" style="width: 26px" class="grid relative">
        <Icon class="cursor-pointer m-auto w-full" :src="IconSource.TaskList"></Icon>
        <div v-if="pendingAssignments" class="absolute -top-0.5 -right-0.5 h-3 w-3 bg-red-400 rounded-full border-gray-700 border"></div>
    </router-link>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { store } from '@/plugins/store';

export default defineComponent({
    setup() {
        const meta = computed(() => store.state.meta);
        const pendingAssignments = computed(() => Boolean(meta.value && (meta.value.assignments.due || meta.value.assignments.overdue)));

        return { meta, pendingAssignments };
    },
});
</script>
