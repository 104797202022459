import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-92 flex" }
const _hoisted_2 = { class: "pt-8 border-t border-t-gray-200 space-y-6" }
const _hoisted_3 = { class: "flex items-center gap-x-5" }
const _hoisted_4 = { class: "flex gap-x-2 gap-y-1 items-center" }
const _hoisted_5 = { class: "divide-y divide-y-gray-100" }
const _hoisted_6 = ["onClick"]

import { ref, computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import ExpansionPanel from '@/components/ui/ExpansionPanel.vue';
import { DataQualityAssessmentFarmSummary, DataQualityAssessmentMethod, DataQualityCategory, IconSource, DataQualityAssessmentFarmLocationMethodAggregate, MultiStepFormState, Form } from '@/types';
import Icon from '@/components/icons/Icon.vue';
import { store } from '@/plugins/store';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ActionType } from '@/plugins/store/actions';
import DataQualityStatusBarTooltip from '@/components/common/dataQuality/DataQualityStatusBarTooltip.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';

interface PopulatedDataQualityAssessmentMethod extends DataQualityAssessmentFarmLocationMethodAggregate {
    _method: DataQualityAssessmentMethod | undefined;
}

interface PopulatedDataQualityAssessmentFarmSummary extends DataQualityAssessmentFarmSummary {
    _category: DataQualityCategory | undefined;
    _items: PopulatedDataQualityAssessmentMethod[];
}

const FORM_ID = 43; // DQA


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

store.dispatch(ActionType.GetDataQualityCategories);
store.dispatch(ActionType.GetDataQualityAssessmentMethods);
useRefetchDataOnDashboardStateChanged(ActionType.GetDataQualityAssessment, { watchSites: true });

const searchQuery = ref('');

const farmAssessmentSummaries = computed<PopulatedDataQualityAssessmentFarmSummary[]>(() => {
    if (!store.state.dataQualityAssessment || !store.state.dataQualityCategories || !store.state.dataQualityAssessmentMethods) {
        return [];
    }

    return (
        store.state.dataQualityAssessment.farm_summaries?.map((f) => ({
            ...f,
            _category: store.state.dataQualityCategories!.find((c) => c.id === f.quality_assessment_id),
            _items:
                f.method_aggregate
                    ?.map((m) => {
                        const method = store.state.dataQualityAssessmentMethods!.find((dm) => dm.id === m.assessment_method_id);

                        return {
                            ...m,
                            _method: method,
                        };
                    })
                    .filter((m) => m._method) || [],
        })) || []
    );
});
const assesmentDate = computed(() => (store.state.dataQualityAssessment ? store.state.dataQualityAssessment.created_at_utc : ''));

const filteredFarmAssessmentSummaries = computed(() => {
    if (!searchQuery.value) {
        return farmAssessmentSummaries.value;
    }

    return farmAssessmentSummaries.value.filter((f) => f.farm.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
});

const actionModalInitialState = ref<MultiStepFormState>({ form: null, confirmed: false, event: null });
const actionModalStepConfig = ref([{}, { deleteButton: false, editable: false }, { disableRefetch: true, submitActionType: ActionType.GetForm }, {}]);
const actionModalHeader = ref<{ icon_url: string; text: string } | null>(null);
const isLoadingActionForm = ref(false);

async function openForm(methodId: DataQualityAssessmentFarmLocationMethodAggregate['id'], open: CallableFunction) {
    try {
        if (isLoadingActionForm.value) {
            return;
        }

        isLoadingActionForm.value = true;

        const form: Form = await store.dispatch(ActionType.GetForm, {
            form: { form_id: FORM_ID, resource_id: methodId },
        });

        actionModalInitialState.value = { ...actionModalInitialState.value, form };
        actionModalHeader.value = { icon_url: IconSource.DataQualityIcon as string, text: form.sections?.[0]?.name || '' };

        open();
    } catch {
        //
    } finally {
        isLoadingActionForm.value = false;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.dataQuality.header.heading'),
    subheading: assesmentDate.value ? `${_ctx.$t('views.dataQuality.texts.lastCalculated')}: ${_ctx.$d(new Date(assesmentDate.value), 'long')}` : ''
  }, {
    "header-top-right": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(SearchInput, {
          value: searchQuery.value,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
          class: "my-auto",
          label: _ctx.$t('views.dataQuality.tables.searchFieldLabel'),
          "background-color": 'bg-white',
          onOnEscape: _cache[1] || (_cache[1] = ($event: any) => (searchQuery.value = ''))
        }, null, 8, ["value", "label"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(ActionModal, {
        "initial-step-index": 1,
        "initial-state": actionModalInitialState.value,
        "step-config": actionModalStepConfig.value,
        header: actionModalHeader.value
      }, {
        default: _withCtx(({ open }) => [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredFarmAssessmentSummaries.value, (summary) => {
              return (_openBlock(), _createBlock(ExpansionPanel, {
                key: summary.id
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(DataQualityStatusBarTooltip, {
                      "quality-assessment-id": summary.quality_assessment_id
                    }, null, 8, ["quality-assessment-id"]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(Icon, {
                        src: _unref(IconSource).FarmsPrimary,
                        class: "h-7 w-7 flex-shrink-0",
                        "aria-hidden": "true"
                      }, null, 8, ["src"]),
                      _createElementVNode("span", null, _toDisplayString(summary.farm.name), 1)
                    ])
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(summary._items, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `method-${summary.id}-${item.id}`,
                        class: "relative hover:bg-gray-100 transition-colors flex items-center"
                      }, [
                        _createVNode(DataQualityStatusBarTooltip, {
                          "quality-assessment-id": item.quality_assessment_id,
                          class: "absolute left-6"
                        }, null, 8, ["quality-assessment-id"]),
                        _createElementVNode("button", {
                          class: "flex pl-32 outline-none flex-grow items-center justify-between space-x-2 pr-6 py-6",
                          onClick: ($event: any) => (openForm(item.id, open))
                        }, [
                          _createElementVNode("div", null, _toDisplayString(item._method?.name), 1),
                          _createVNode(Icon, {
                            src: _unref(IconSource).Right,
                            class: "h-3.5 w-3.5 flex-shrink-0",
                            "aria-hidden": "true"
                          }, null, 8, ["src"])
                        ], 8, _hoisted_6)
                      ]))
                    }), 128))
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["initial-state", "step-config", "header"])
    ]),
    _: 1
  }, 8, ["heading", "subheading"]))
}
}

})