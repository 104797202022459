<template>
    <div>
        <div class="assignment-tabs default-tabs">
            <Tabs teleport="#assignment-tabs-target" active-tab-class="--active">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-4">
                        <Tab :name="$t('views.assignments.tabTitles.upcoming')" :default="true">
                            <Tasks :completed-tasks="false" class="mt-8"></Tasks>
                        </Tab>
                    </div>
                    <div class="flex pr-4">
                        <Tab :name="$t('views.assignments.tabTitles.completed')">
                            <Tasks completed-tasks class="mt-8"></Tasks>
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.assignments.tabTitles.templates')">
                            <Templates class="mt-8"></Templates>
                        </Tab>
                    </div>
                </nav>
            </Tabs>

            <div id="assignment-tabs-target" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import Templates from '@/views/dashboard/assignments/templates/Index.vue';
import Tasks from '@/views/dashboard/assignments/tasks/Index.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

export default defineComponent({
    components: { Tab, Tabs, Templates, Tasks },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetAssignments, { watchSites: true, watchFarmsInsteadOfSites: false });

        store.dispatch(ActionType.GetAssignmentTypes);
        store.dispatch(ActionType.GetMiscEvents);
        store.dispatch(ActionType.GetAssignmentTemplates);
    },
});
</script>
