<script setup lang="ts">
import { Search } from 'lucide-vue-next';
import { computed } from 'vue';
import { Input } from '@/components/ui/input';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps<{
    modelValue: string;
}>();

const emit = defineEmits<{
    'update:modelValue': [value: string];
}>();

const computedModelValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

function clear() {
    computedModelValue.value = '';
}
</script>

<template>
    <div class="relative">
        <Search class="pointer-events-none absolute h-4 w-4 left-3 top-1/2 transform -translate-y-1/2 text-gray-500" aria-hidden="true" />
        <Input v-model="computedModelValue" class="w-full pl-9" v-bind="$attrs" @keydown.esc.stop="clear" />
    </div>
</template>
