<template>
    <div class="relative overflow-hidden">
        <textarea
            :id="uid"
            ref="input-field"
            v-model="computedValue"
            v-focus="focusOnRender"
            :class="[filledClass, borderClass, inputClass, paddingClass, focusedClass]"
            :disabled="disabled"
            class="custom-input block w-full box-border outline-none rounded"
            :readonly="readonly"
            :tabIndex="tabIndex"
            rows="3"
            @keydown.enter="$emit('submit')"
            @input="$emit('input')"
            @focus="onFocus"
        />
        <label :class="[labelClass]" :for="uid" class="custom-input-label text-gray-400 absolute top-4 left-5 transition-all truncate">
            <span>{{ label }}</span>
            <span v-show="required && showRequiredIndication && !disabled" class="text-black">*</span>
        </label>
        <span v-show="hint.text" class="min-h-4 flex text-xs text-left w-full ml-1" :class="{ 'text-red-600': hint?.type === 'error', 'text-gray-500': hint?.type === 'hint' }">{{ hint?.text || '' }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Focus from '@makeabledk/vue-ui/directives/focus/src/Focus';
import { validatableField } from '@/mixins/validatableField';

export default defineComponent({
    directives: { Focus },
    mixins: [validatableField],
    emits: ['update:value', 'submit', 'input', 'focus'],
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
        showRequiredIndication: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
        errorState: {
            type: Boolean,
            default: false,
        },
        tabIndex: {
            type: String,
            default: null,
        },
        focusHighlight: {
            type: Boolean,
            default: false,
        },
        focusOnRender: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uid: `textarea-${Math.random().toString(36).substring(6)}`,
        };
    },
    computed: {
        paddingClass(): string {
            return this.$props.label ? 'pt-6 pb-1 pl-5' : 'py-3.5';
        },
        labelClass(): string {
            return this.disabled ? 'opacity-60' : '';
        },
        inputClass(): string {
            return this.disabled ? 'bg-white text-gray-400' : 'bg-gray-100';
        },
        borderClass(): string {
            if (this.disabled) {
                return 'border-gray-100 border-l border-t border-r border-b-2';
            }
            return this.validInput && !this.errorState ? 'border-green-200 border-b-2' : 'border-red-300 border-b-2';
        },
        filledClass(): string {
            return this.value !== null && `${this.value}`.length > 0 ? 'filled' : '';
        },
        focusedClass(): string {
            return this.focusHighlight ? 'focused' : '';
        },
        computedValue: {
            get(): string | null {
                return this.value;
            },
            set(newValue: string) {
                this.$emit('update:value', newValue);
            },
        },
    },
    methods: {
        onFocus() {
            this.$emit('focus');
        },
        focusInput(): void {
            (this.$refs['input-field'] as HTMLInputElement).focus();
        },
    },
});
</script>

<style scoped>
.custom-input.filled + .custom-input-label,
.custom-input:focus + .custom-input-label {
    top: 0.4rem !important;
    font-size: 0.8125rem !important;
}

body.--keyboard-navigation .custom-input.focused,
body.--keyboard-navigation .custom-input:focus {
    border-color: #63b3ed;
    border-right: 4px solid #63b3ed !important;
}
</style>
