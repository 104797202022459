<template>
    <MainViewCard class="pt-2">
        <div class="flex mt-2 mb-4">
            <div class="flex ml-6 space-x-2 flex-1">
                <Icon class="h-6.5 my-auto" :src="IconSource.FarmsPrimary"></Icon>
                <span class="text-lg my-auto pt-2 font-medium">{{ $t('views.settings.locations.farms.tableHeader') }}</span>
            </div>
            <span class="flex flex-1 justify-end">
                <span class="text-right mr-6 flex">
                    <SettingsFormModal v-if="createSettingsEvent && canManageDirectory" :initial-state="createInitialState" @closing="onModalClosing">
                        <template #default="{ open }">
                            <AddButton text-class="font-medium" @click="open">
                                {{ $t('views.settings.locations.farms.newFarmButtonLabel') }}
                            </AddButton>
                        </template>
                    </SettingsFormModal>
                </span>
            </span>
        </div>
        <CustomTable :headers="headers" :items="filteredDirectoryFarms">
            <template #header_actions>
                <th class="w-8"></th>
            </template>
            <template #actions="{ item }">
                <td class="w-8 my-auto">
                    <span class="flex justify-end">
                        <SettingsFormModal
                            v-if="editSettingsEvent && canManageDirectory"
                            :initial-state="editInitialState"
                            :step-config="[
                                {},
                                {},
                                {
                                    rejectable: false,
                                    submitActionType: putFormActionType,
                                },
                                {},
                            ]"
                            @closing="onModalClosing"
                        >
                            <template #default="{ open }">
                                <button type="button" class="p-2 -mr-2" @click="onEditFarmClicked(item, open)">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </template>
                        </SettingsFormModal>
                    </span>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Farm, TableHeader, SettingEvent, SettingEventType, DirectoryFarm } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import AddButton from '@/components/ui/AddButton.vue';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';
import useGetItemProperty from '@/components/common/composables/useGetItemProperty';
import useLocationSettingEvents from '@/views/dashboard/settings/locations/useLocationSettingEvents';

export default defineComponent({
    components: { MainViewCard, CustomTable, AddButton, SettingsFormModal },
    props: {
        canManageDirectory: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: '',
        },
    },
    setup() {
        return useLocationSettingEvents(
            { event: null as null | SettingEvent, form: null, confirmed: false },
            { event: null as null | SettingEvent, form: null, farm: null as null | DirectoryFarm, confirmed: false },
            SettingEventType.farmCreate,
            SettingEventType.farmEdit,
            ActionType.GetDirectoryFarms
        );
    },
    computed: {
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.settings.locations.farms.headers.name'),
                    value: 'name',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.settings.locations.farms.headers.id'),
                    value: 'chr_number',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.settings.locations.farms.headers.street'),
                    value: 'street',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.settings.locations.farms.headers.pigCapacity'),
                    value: 'pig_capacity',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                },
            ];
        },
        directoryFarms(): DirectoryFarm[] {
            return store.state.directoryFarms;
        },
        filteredDirectoryFarms(): DirectoryFarm[] {
            const searchableHeaders = this.headers.filter((header) => header.searchable);
            return this.directoryFarms.filter((currentDirectoryFarm) =>
                Boolean(
                    searchableHeaders.find((currentSearchableHeader) =>
                        currentSearchableHeader.searchComparison
                            ? currentSearchableHeader.searchComparison(currentDirectoryFarm, this.$props.search)
                            : `${this.getItemProperty(currentSearchableHeader, currentDirectoryFarm)}`.toLowerCase().trim().includes(this.search.toLowerCase().trim())
                    )
                )
            );
        },
    },
    methods: {
        onEditFarmClicked(farm: DirectoryFarm, open: CallableFunction) {
            this.editInitialState.farm = farm;
            open();
        },
        getItemProperty: useGetItemProperty,
    },
});
</script>
