<template>
    <Listbox v-model="listboxValue">
        <div v-click-outside="onClickOutside" class="relative">
            <ListboxButton
                class="overflow-hidden relative w-full shadow-none"
                :disabled="disabled"
                @click="onListboxButtonClicked"
                @focus="buttonFocused = true"
                @blur="buttonFocused = false"
                @keydown.enter="onListboxButtonClicked"
                @keydown.space="onListboxButtonClicked"
            >
                <CustomInput
                    ref="input-field"
                    v-model:value="inputDisplayValue"
                    :show-required-indication="showRequiredIndication"
                    :active="expandListbox"
                    :required="required"
                    :prepend="prepend"
                    :focus-highlight="buttonFocused"
                    :rules="rules"
                    input-type="text"
                    :label="label"
                    :details="details"
                    :disabled="disabled"
                    :error-state="validInput === false"
                    tab-index="-1"
                    readonly
                    cursor="cursor-pointer"
                ></CustomInput>
                <span v-show="!disabled" class="absolute -mt-2 right-5 top-1/2"><ExpandIcon :expand="expandListbox" color="black"></ExpandIcon></span>
            </ListboxButton>
            <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-100 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <div v-show="expandListbox" :class="[dropdownHeightClass]" class="absolute -mt-4 top-full left-0 w-full bg-white border z-30 rounded overflow-y-auto">
                    <div v-if="showSelectAll" class="flex justify-end py-2 px-3">
                        <button class="text-sky-500 hover:text-sky-700 text-sm transition-colors underline" style="text-underline-offset: 2px" @click.prevent.stop="toggleSelectAll">
                            {{ isAllSelected ? $t('global.uiElements.multiSelect.deselectAllButtonLabel') : $t('global.uiElements.multiSelect.selectAllButtonLabel') }}
                        </button>
                    </div>
                    <ListboxOptions static class="outline-none" @keydown.esc.stop="expandListbox = false">
                        <div v-for="item in filteredItems" :key="item[itemValue]">
                            <ListboxOption v-slot="{ active }" :value="item" :disabled="item.disabled">
                                <button
                                    type="button"
                                    :class="[
                                        {
                                            'bg-primary-300 bg-opacity-40': isSelected(item),
                                            'hover:bg-gray-100': !isSelected(item) && !item.disabled,
                                            'bg-gray-100': active,
                                            'text-gray-400': item.disabled,
                                        },
                                        item.disabled ? 'cursor-default' : 'cursor-pointer',
                                    ]"
                                    class="p-3 h-full text-left w-full flex"
                                    :disabled="item.disabled"
                                >
                                    <span class="flex-1">
                                        {{ item[itemText] }}
                                    </span>
                                    <span class="my-auto justify-self-end" :class="{ 'pointer-events-none': item.disabled }">
                                        <CustomCheckbox :checked="isSelected(item)" :disabled="item.disabled" :class="{ 'opacity-40': item.disabled }" @update:checked="listboxValue = item"></CustomCheckbox>
                                    </span>
                                </button>
                            </ListboxOption>
                            <div v-if="groups && item[groupKey] && item[groupKey].length">
                                <ListboxOption v-for="child in item[groupKey]" :key="groupItemValue ? child[groupItemValue] : child[itemValue]" v-slot="{ active }" :value="child" :disabled="child.disabled">
                                    <button
                                        type="button"
                                        :class="[
                                            {
                                                'bg-primary-300 bg-opacity-40': isSelected(child, true),
                                                'hover:bg-gray-100': !isSelected(child, true) && !child.disabled,
                                                'bg-gray-100': active,
                                                'text-gray-400': child.disabled,
                                            },
                                            child.disabled ? 'cursor-default' : 'cursor-pointer',
                                        ]"
                                        class="p-3 pl-10 h-full text-left w-full flex"
                                        :disabled="child.disabled"
                                    >
                                        <span class="flex-1">
                                            {{ child[groupItemText || itemText] }}
                                        </span>
                                        <span class="my-auto justify-self-end" :class="{ 'pointer-events-none': child.disabled }">
                                            <CustomCheckbox
                                                :checked="isSelected(child, true)"
                                                :disabled="child.disabled"
                                                :class="{ 'opacity-40': child.disabled }"
                                                @update:checked="listboxValue = child"
                                            ></CustomCheckbox>
                                        </span>
                                    </button>
                                </ListboxOption>
                            </div>
                        </div>
                    </ListboxOptions>
                </div>
            </transition>
        </div>
    </Listbox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import ClickOutside from '@makeabledk/vue-ui/directives/click-outside';
import ExpandIcon from '@/components/icons/ExpandIcon.vue';
import { validatableField } from '@/mixins/validatableField';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';

export default defineComponent({
    directives: {
        ClickOutside,
    },
    components: {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        ExpandIcon,
        CustomInput,
        CustomCheckbox,
    },
    mixins: [validatableField],
    emits: ['update:value', 'input'],
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            default: [],
        },
        items: {
            type: Array,
            default: [],
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        dropdownHeightClass: {
            type: String,
            default: 'max-h-64',
        },
        prepend: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showRequiredIndication: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        showSelectAll: {
            type: Boolean,
            default: false,
        },
        groups: {
            type: Boolean,
            default: false,
        },
        groupKey: {
            type: String,
            default: '',
        },
        groupItemValue: {
            type: String,
            default: '',
        },
        groupItemText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            listboxValue: null,
            expandListbox: false,
            buttonFocused: false,
        };
    },
    computed: {
        textColorClass(): string {
            return this.disabled ? 'text-gray-400' : 'text-dark-gray-600';
        },
        cursorClass(): string {
            return this.disabled ? 'cursor-default' : 'cursor-pointer';
        },
        validationClass(): string {
            if (!this.$props.required) {
                return '';
            }
            return this.validInput ? 'border-green-200' : 'border-red-300';
        },
        filteredItems() {
            return this.$props.items.filter((currentItem) => currentItem !== null);
        },
        computedValue: {
            get(): any[] {
                return this.value === null ? [] : this.value;
            },
            set(newValue: any[]) {
                this.$emit('update:value', newValue);
            },
        },
        inputDisplayValue(): string {
            if (!this.computedValue.length) {
                return this.$t('global.uiElements.multiSelect.noSelections');
            }
            if (this.computedValue.length === 1) {
                return this.computedValue[0][this.itemText];
            }
            return this.$t('global.uiElements.multiSelect.selected').replace('$NUMBER', `${this.computedValue.length}`);
        },
        isAllSelected() {
            if (!Array.isArray(this.computedValue)) {
                return false;
            }

            return this.filteredItems.length && this.computedValue.length === this.filteredItems.filter((currentItem: any) => !currentItem.disabled).length;
        },
    },
    watch: {
        listboxValue(newValue: any) {
            if (newValue) {
                const indexOfNew = this.computedValue.findIndex((currentValue) => {
                    if (newValue[this.$props.groupItemValue]) {
                        return currentValue[this.$props.groupItemValue] === newValue[this.$props.groupItemValue];
                    }

                    return currentValue[this.$props.itemValue] === newValue[this.$props.itemValue];
                });
                if (indexOfNew >= 0) {
                    if (this.computedValue.length > 1 || this.$props.allowEmpty) {
                        this.computedValue = this.computedValue.filter((currentValue) => {
                            if (newValue[this.$props.groupItemValue]) {
                                return currentValue[this.$props.groupItemValue] !== newValue[this.$props.groupItemValue];
                            }

                            return currentValue[this.$props.itemValue] !== newValue[this.$props.itemValue];
                        });
                    }
                } else {
                    this.computedValue = [...this.computedValue, newValue];
                }
                this.listboxValue = null;
            }
        },
        computedValue() {
            this.$emit('input');
        },
    },
    created() {
        if (this.value === null) {
            this.computedValue = [];
        }
    },
    methods: {
        onClickOutside() {
            this.expandListbox = false;
        },
        onListboxButtonClicked() {
            this.expandListbox = !this.expandListbox;
        },
        async toggleSelectAll() {
            if (this.isAllSelected) {
                this.computedValue = [];
                return;
            }

            this.computedValue = [];

            await this.$nextTick();

            this.computedValue = this.filteredItems.filter((currentItem: any) => !currentItem.disabled);
        },
        isSelected(item: any, isGroupItem?: boolean) {
            return this.computedValue.some(
                (currentValue) => currentValue[isGroupItem ? this.$props.groupItemValue : this.$props.itemValue] === item[isGroupItem ? this.$props.groupItemValue : this.$props.itemValue]
            );
        },
    },
});
</script>
