import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-2" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "justify-center flex flex-col" }
const _hoisted_4 = { class: "flex justify-center my-4" }
const _hoisted_5 = { class: "space-y-4" }
const _hoisted_6 = { class: "border-collapse mx-auto w-full" }
const _hoisted_7 = { class: "px-6 my-2 rounded text-dark-gray-800 text-opacity-50" }
const _hoisted_8 = { class: "my-auto py-2 pl-2 pr-1 bg-feed-600 bg-opacity-60 rounded-l-lg" }
const _hoisted_9 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_10 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_11 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_12 = { class: "my-auto py-2 pr-2 bg-feed-600 bg-opacity-60 rounded-r-lg" }
const _hoisted_13 = { class: "flex justify-end my-4" }
const _hoisted_14 = { class: "w-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_SimpleInput = _resolveComponent("SimpleInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.compoundMixProperties.header')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AutoComplete, {
          search: _ctx.autoCompleteSearch,
          "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autoCompleteSearch) = $event)),
          class: "w-72",
          items: _ctx.registerItems,
          "item-text": "material_text",
          "item-value": "id",
          label: _ctx.$t('views.feed.mixes.newMixModal.compoundMixProperties.autoCompleteFieldLabel'),
          onItemClicked: _ctx.onRegisterItemClicked
        }, null, 8, ["search", "items", "label", "onItemClicked"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("table", _hoisted_6, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_7, [
              _createElementVNode("th", null, _toDisplayString(_ctx.feedUnitHeaderText), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.crude_protein')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.phosphorus')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.price')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.surcharge')), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_8, [
                _createVNode(_component_SimpleInput, {
                  value: _ctx.computedValue.wgt_avg_fu,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedValue.wgt_avg_fu) = $event)),
                  class: _normalizeClass(["w-full", { 'text-gray-400': _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.fu === 'number' }]),
                  type: "number",
                  step: "any",
                  readonly: _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.fu === 'number'
                }, null, 8, ["value", "class", "readonly"])
              ]),
              _createElementVNode("td", _hoisted_9, [
                _createVNode(_component_SimpleInput, {
                  value: _ctx.computedValue.wgt_avg_crude_protein,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValue.wgt_avg_crude_protein) = $event)),
                  class: _normalizeClass(["w-full", { 'text-gray-400': _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.crude_protein === 'number' }]),
                  type: "number",
                  step: "any",
                  readonly: _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.crude_protein === 'number'
                }, null, 8, ["value", "class", "readonly"])
              ]),
              _createElementVNode("td", _hoisted_10, [
                _createVNode(_component_SimpleInput, {
                  value: _ctx.computedValue.wgt_avg_phosphorus,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedValue.wgt_avg_phosphorus) = $event)),
                  class: _normalizeClass(["w-full", { 'text-gray-400': _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.phosphorus === 'number' }]),
                  type: "number",
                  step: "any",
                  readonly: _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.phosphorus === 'number'
                }, null, 8, ["value", "class", "readonly"])
              ]),
              _createElementVNode("td", _hoisted_11, [
                _createVNode(_component_SimpleInput, {
                  value: _ctx.computedValue.prices[0].price,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedValue.prices[0].price) = $event)),
                  class: _normalizeClass(["w-full", { 'text-gray-400': _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.price === 'number' }]),
                  type: "number",
                  step: "any",
                  readonly: _ctx.chosenRegisterItem && typeof _ctx.chosenRegisterItem.price === 'number'
                }, null, 8, ["value", "class", "readonly"])
              ]),
              _createElementVNode("td", _hoisted_12, [
                _createVNode(_component_SimpleInput, {
                  value: _ctx.computedValue.surcharge,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedValue.surcharge) = $event)),
                  class: "w-full",
                  type: "number",
                  step: "any"
                }, null, 8, ["value"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_CustomButton, {
              disabled: _ctx.disableButtons,
              disable: !_ctx.validForm,
              onClick: _ctx.onSubmitClicked
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.compoundMixProperties.confirmButtonLabel')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "disable", "onClick"])
          ])
        ])
      ])
    ])
  ]))
}