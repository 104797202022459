<script setup lang="ts">
import { computed } from 'vue';
import { Select as UISelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { ChartPeriod } from '@/types';

interface Props {
    modelValue: ChartPeriod;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void;
}>();

const computedModelValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<template>
    <UISelect v-model="computedModelValue">
        <SelectTrigger class="rounded-full min-w-28 font-medium bg-primary-800 h-auto text-base border-none hover:bg-primary-700 transition-colors text-white w-auto px-4 py-2">
            <SelectValue />
        </SelectTrigger>
        <SelectContent align="end" :body-lock="false">
            <SelectGroup>
                <SelectItem :value="ChartPeriod.THREE_MONTHS">{{ $t('views.monitoring.charts.periods.threeMonths') }}</SelectItem>
                <SelectItem :value="ChartPeriod.SIX_MONTHS">{{ $t('views.monitoring.charts.periods.sixMonths') }}</SelectItem>
                <SelectItem :value="ChartPeriod.NINE_MONTHS">{{ $t('views.monitoring.charts.periods.nineMonths') }}</SelectItem>
                <SelectItem :value="ChartPeriod.TWELVE_MONTHS">{{ $t('views.monitoring.charts.periods.twelveMonths') }}</SelectItem>
            </SelectGroup>
        </SelectContent>
    </UISelect>
</template>
