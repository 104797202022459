import { createStore, Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex';
import { Form } from '@makeabledk/vue-ui/support/http';
import { State, state } from './state';
import { MutationType, Mutations, mutations } from './mutations';
import { Actions, actions } from './actions';
import { Getters, getters } from './getters';

export const store = createStore<State>({
    state,
    mutations,
    actions,
    getters,
}) as Store;

export function useStore() {
    return store as Store;
}

export type Store = Omit<VuexStore<State>, 'getters' | 'commit' | 'dispatch'> & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: MutationType, payload: P, options?: CommitOptions): ReturnType<Mutations[K]>;
} & {
    dispatch<K extends keyof Actions>(key: K, payload?: Parameters<Actions[K]>[1] | Form<Parameters<Actions[K]>[1]>, options?: DispatchOptions): ReturnType<Actions[K]>;
} & {
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>;
    };
};
