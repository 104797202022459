<template>
    <GenericMultiStepModal :header-icon="IconSource.Economy" :is-final-step="false" @closing="onModalClose">
        <template #default="scope">
            <slot v-bind="scope" />
        </template>
        <template #component="scope">
            <component :is="currentStep.component" :config="currentStepConfig" :text="currentStepConfig.text" @submit="next" @close="scope.close" @skip="goToStep(currentStepIndex + 2)"></component>
        </template>
    </GenericMultiStepModal>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import GenericMultiStepModal from '@/components/common/GenericMultiStepModal.vue';
import EditGlobalPriceForm from '@/components/common/economy/globalPrices/EditGlobalPriceForm.vue';
import GenericSubmissionStatus from '@/components/common/GenericSubmissionStatus.vue';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { GenericMultiStepModal, MultiStepFormHeaderIcon, CloseModalButton },
    props: {
        stepConfig: {
            type: Array,
            default: [{}, {}, {}],
        },
    },
    setup(props) {
        const multiSteps = useMultiSteps([
            {
                component: EditGlobalPriceForm,
            },
            {
                component: GenericSubmissionStatus,
            },
            {
                component: GenericSubmissionStatus,
            },
        ]).setInitial(0);

        const onModalClose = () => {
            multiSteps.reset();
        };

        const currentStepConfig = computed(() => props.stepConfig[multiSteps.currentStepIndex.value]);

        return {
            ...multiSteps,
            onModalClose,
            currentStepConfig,
        };
    },
});
</script>
