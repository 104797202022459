<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="!max-w-6xl !w-full m-auto border-0" mask-class="grid" @closing="onClose" @opening="onOpen">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="pt-6 mx-auto">
                <div class="flex items-center space-x-3 pl-6 pr-12">
                    <Icon :src="IconSource.Treatment" class="w-12 h-12"></Icon>
                    <div>
                        <div class="font-bold text-lg">{{ $t('views.monitoring.main.tables.treatmentsTable.header') }}</div>
                        <div class="text-gray-600">{{ sectionName }} ({{ barnName }})</div>
                    </div>
                </div>
                <CustomTable :headers="headers" :items="treatments" resize-on-mount :resize-delay="300" class="mt-4 opacity-0 transition-opacity" :class="{ 'opacity-100': visible }">
                    <template #day="{ item, getHeaderClass, getWidth, header }">
                        <td
                            class="flex-1"
                            :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                            :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                        >
                            {{ item.day }}/{{ item.total_days }}
                        </td>
                    </template>
                    <template #dosis_pr_animal="{ item, getHeaderClass, getWidth, header }">
                        <td
                            class="flex-1"
                            :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                            :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                        >
                            {{ item.dosis_pr_animal }} {{ item.dosis_unit ? item.dosis_unit : '' }}
                        </td>
                    </template>
                </CustomTable>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { Treatment, IconSource } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import Icon from '@/components/icons/Icon.vue';
import { i18n } from '@/plugins/internationalization/i18n';

export default defineComponent({
    components: { Modal, CloseModalButton, CustomTable, Icon },
    props: {
        treatments: {
            type: Array as () => Treatment[],
            default: () => [],
        },
        sectionName: {
            type: String,
            default: '',
        },
        barnName: {
            type: String as () => string | null | undefined,
            default: '',
        },
    },
    setup() {
        const headers = ref<any[]>([
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.treatmentDate'), value: 'treatment_date', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.barn'), value: 'barn_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.section'), value: 'section_name', align: 'left', sortable: true },
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.pen'), value: 'pen', align: 'center', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.animalCount'), value: 'animal_count', align: 'center', sortable: true, searchable: true },
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.dosisPerAnimal'), value: 'dosis_pr_animal', align: 'center', sortable: true, searchable: true },
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.diagnosis'), value: 'diagnosis', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.monitoring.main.tables.treatmentsTable.headings.treatmentDay'), value: 'day', align: 'center', sortable: true, searchable: true },
        ]);

        const visible = ref(false);

        function onClose() {
            visible.value = false;
        }

        function onOpen() {
            // Compensate for the table resizing after the modal transition
            setTimeout(() => {
                visible.value = true;
            }, 300);
        }

        return {
            headers,
            IconSource,
            onClose,
            onOpen,
            visible,
        };
    },
});
</script>
