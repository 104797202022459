import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-gray-100 py-6 px-8 space-y-4 rounded-xl" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = {
  key: 1,
  class: "space-y-4"
}
const _hoisted_4 = { class: "text-gray-500" }
const _hoisted_5 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "m-auto border-0",
    "mask-class": "grid",
    onClosing: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closing')))
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", { scope: scope })
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.state.event)
          ? (_openBlock(), _createBlock(_component_MultiStepFormHeaderIcon, {
              key: 0,
              "icon-source": _ctx.state.event.icon_url
            }, null, 8, ["icon-source"]))
          : _createCommentVNode("", true),
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('global.forms.steps.forceSubmission.forceSubmissionHeader')), 1),
        (_ctx.computedState.errors.hasErrors())
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.problemElements, (problemElement) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: problemElement.element?.id
                }, [
                  _createElementVNode("div", null, _toDisplayString(`${problemElement.element?.label || 'N/A'}: ${_ctx.formatValue(problemElement.element)}`), 1),
                  _createElementVNode("div", _hoisted_4, _toDisplayString(problemElement.errorMessage), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CustomButton, {
            disabled: _ctx.disableButtons,
            "color-preset": "secondary",
            onClick: ($event: any) => (_ctx.onEditClicked(scope.close))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.forceSubmission.editButtonLabel')), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          (_ctx.submissionCanBeForced)
            ? (_openBlock(), _createBlock(_component_CustomButton, {
                key: 0,
                disabled: _ctx.disableButtons,
                onClick: ($event: any) => (_ctx.onSubmitClicked(scope.close))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.forceSubmission.forceSubmissionConfirmButtonLabel')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 3
  }))
}