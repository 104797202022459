<template>
    <button type="button" class="flex rounded-md bg-gray-200 relative h-full px-4" @click="isWeaners = !isWeaners">
        <div
            :style="`width: ${isWeaners ? pigTypePickerOptions.weanersWidth + 24 : pigTypePickerOptions.slaughterPigsWidth + 24}px; left: ${
                isWeaners ? `calc(100% - ${pigTypePickerOptions.weanersWidth + 28}px)` : '4px'
            }`"
            class="absolute z-10 top-1 bottom-1 bg-primary-700 rounded-md transition-all duration-300"
        ></div>
        <div ref="slaughters" :class="{ 'text-white': !isWeaners }" class="relative z-20 my-auto mr-4 whitespace-nowrap transition-all duration-300">{{ $t('views.pigTypePicker.slaughterPigs') }}</div>
        <div ref="weaners" :class="{ 'text-white': isWeaners }" class="relative z-20 my-auto ml-4 whitespace-nowrap transition-all duration-300">{{ $t('views.pigTypePicker.weaners') }}</div>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { store } from '@/plugins/store';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import { PigType } from '@/types';
import { MutationType } from '@/plugins/store/mutations';

export default defineComponent({
    components: { CustomCheckbox },
    data() {
        return {
            isMounted: false,
        };
    },
    computed: {
        isWeaners: {
            get(): boolean {
                return store.state.pigType === PigType.Weaners;
            },
            set(newValue: boolean) {
                store.commit(MutationType.SetPigType, newValue ? PigType.Weaners : PigType.SlaughterPigs);
            },
        },
        pigTypePickerOptions: {
            get() {
                return store.state.pigTypePickerOptions;
            },
            set(newValue: { weanersWidth: number; slaughterPigsWidth: number }) {
                store.commit(MutationType.SetPigTypePickerOptions, newValue);
            },
        },
        dashboardLoaded() {
            return store.state.dashboardLoaded;
        },
    },
    watch: {
        dashboardLoaded: {
            immediate: true,
            handler() {
                this.initWidths();
            },
        },
        isMounted: {
            handler() {
                this.initWidths();
            },
        },
    },
    mounted() {
        this.isMounted = true;
    },
    methods: {
        initWidths() {
            // We have to wait until dashboardLoaded or we may initialize with the width of another language
            if (this.dashboardLoaded && this.isMounted) {
                this.pigTypePickerOptions = {
                    weanersWidth: (this.$refs.weaners as HTMLDivElement).offsetWidth,
                    slaughterPigsWidth: (this.$refs.slaughters as HTMLDivElement).offsetWidth,
                };
            }
        },
    },
});
</script>
