<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="w-[680px] max-w-full m-auto border-0" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>
        <template #header="scope">
            <CloseModalButton @close="scope.close" />
        </template>
        <template #body="scope">
            <div class="bg-white relative rounded-lg grid py-6 space-y-3 px-10 m-auto">
                <div v-if="item">
                    <div v-if="item.video_title" class="text-xl font-semibold mb-6">
                        {{ item.video_title }}
                    </div>
                    <div class="relative rounded-lg overflow-hidden aspect-video">
                        <Skeleton v-if="!hasVideoLoaded" class="absolute inset-0" />
                        <iframe
                            :src="item.video_source_url"
                            loading="lazy"
                            :title="item.video_title ?? item.text"
                            allow="encrypted-media; fullscreen;"
                            class="absolute w-full h-full inset-0 border-none p-0 m-0 overflow-hidden transition-opacity duration-300"
                            :class="{ 'opacity-0': !hasVideoLoaded, 'opacity-100': hasVideoLoaded }"
                            @load="hasVideoLoaded = true"
                        />
                    </div>
                    <div v-if="item.reference_url" class="flex justify-end mt-2">
                        <a
                            :href="item.reference_url"
                            class="flex text-sm font-medium items-center gap-x-1.5 py-1.5 px-2 rounded-md hover:bg-gray-100 transition-colors duration-200"
                            target="_blank"
                            @click="scope.close"
                        >
                            {{ $t('global.modals.helpVideo.openInNewWindowButtonLabel') }}
                            <Icon :src="IconSource.LinkIcon" class="flex-shrink-0 size-4" />
                        </a>
                    </div>
                    <p v-if="item.video_description" class="text-gray-500 mt-4">{{ item.video_description }}</p>
                    <div v-if="item.references?.length" class="mt-5 space-y-3 pb-4">
                        <div class="text-lg font-semibold text-primary-800">{{ $t('global.modals.helpVideo.otherRelevantVideosHeader') }}</div>
                        <ul class="list-disc pl-5 space-y-1.5">
                            <li v-for="(reference, referenceIndex) in item.references" :key="`reference-${referenceIndex}`">
                                <a :href="reference.url" target="_blank" class="hover:text-primary-700 transition-colors duration-200 underline underline-offset-2">{{ reference.text }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { HelpWidgetVideoItem, IconSource } from '@/types';
import { Skeleton } from '@/components/ui/skeleton';

interface Props {
    item?: HelpWidgetVideoItem;
}

defineProps<Props>();

const hasVideoLoaded = ref(false);
</script>
