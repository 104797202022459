<template>
    <div class="flex flex-col items-center w-full" :class="{ 'opacity-60': disabled }">
        <div class="flex items-center justify-between w-full space-x-2 text-[0.8125rem] mb-2">
            <label :for="id" class="text-gray-400">{{ label }}</label>
            <div class="font-medium">
                {{ value }}
                <span v-if="unit">{{ unit }}</span>
            </div>
        </div>
        <input
            :id="id"
            :value="value"
            class="transparent h-2 w-full rounded-lg cursor-pointer appearance-none border-transparent accent-primary-800 bg-gray-100"
            type="range"
            :disabled="disabled"
            :readonly="readonly"
            :min="min"
            :max="max"
            :step="step"
            @input="onInput"
        />
        <span v-show="hint.text" class="min-h-4 flex text-xs text-left w-full ml-1" :class="{ 'text-red-600': hint?.type === 'error', 'text-gray-500': hint?.type === 'hint' }">{{ hint?.text || '' }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getUniqueId } from '@/util/uniqueId';
import { validatableField } from '@/mixins/validatableField';

export default defineComponent({
    mixins: [validatableField],
    props: {
        value: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 1,
        },
        unit: {
            type: String,
            default: '',
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
    },
    emits: ['update:value'],
    data() {
        return {
            id: getUniqueId(),
        };
    },
    methods: {
        onInput(event: Event) {
            const target = event.target as HTMLInputElement;

            this.$emit('update:value', Number(target.value));
        },
    },
});
</script>
