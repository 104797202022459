import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full min-h-60 space-y-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "flex space-x-4" }
const _hoisted_4 = { class: "mt-4 grid gap-y-4 h-full" }
const _hoisted_5 = { class: "flex space-x-4 self-end" }
const _hoisted_6 = { class: "flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_EditUserPermissionsForm = _resolveComponent("EditUserPermissionsForm")!
  const _component_SettingsModal = _resolveComponent("SettingsModal")!
  const _component_DeleteButton = _resolveComponent("DeleteButton")!
  const _component_DeleteUserConfirmationModal = _resolveComponent("DeleteUserConfirmationModal")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.users.content.modals.edit.steps.form.title')), 1),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createVNode(_component_CustomInput, {
          value: _ctx.computedForm.email,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedForm.email) = $event)),
          label: _ctx.$t('views.settings.users.content.modals.edit.steps.form.emailFieldLabel'),
          "input-type": "email",
          rules: [{ validate: _ctx.validateEmail, message: '' }]
        }, null, 8, ["value", "label", "rules"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.computedForm.name,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedForm.name) = $event)),
          label: _ctx.$t('views.settings.users.content.modals.edit.steps.form.nameFieldLabel'),
          "input-type": "text",
          rules: [{ validate: _ctx.validateName, message: '' }]
        }, null, 8, ["value", "label", "rules"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.computedForm.phoneNumber,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedForm.phoneNumber) = $event)),
          label: _ctx.$t('views.settings.users.content.modals.edit.steps.form.phoneNumberFieldLabel'),
          "input-type": "text",
          rules: [{ validate: _ctx.validatePhoneNumber, message: '' }]
        }, null, 8, ["value", "label", "rules"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SettingsModal, null, {
          default: _withCtx(({ open }) => [
            _createVNode(_component_AddButton, { onClick: open }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.edit.steps.form.permissionsButtonLabel')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          form: _withCtx(({ close }) => [
            _createVNode(_component_EditUserPermissionsForm, {
              "directory-user": _ctx.computedForm.directoryUser,
              onCancel: close,
              onSubmit: close
            }, null, 8, ["directory-user", "onCancel", "onSubmit"])
          ]),
          _: 1
        }),
        _createElementVNode("div", null, [
          (_ctx.showDeleteUserButton)
            ? (_openBlock(), _createBlock(_component_DeleteUserConfirmationModal, {
                key: 0,
                "directory-user": _ctx.computedForm.directoryUser,
                onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_DeleteButton, {
                    onClick: scope.open
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.delete.deleteModalButtonLabel')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["directory-user"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_CustomButton, {
              loading: _ctx.disableButtons,
              disabled: !_ctx.validForm,
              onClick: _ctx.onNextButtonClicked
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.edit.steps.form.nextButtonLabel')), 1)
              ]),
              _: 1
            }, 8, ["loading", "disabled", "onClick"])
          ])
        ])
      ])
    ])
  ]))
}