import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "w-20" }
const _hoisted_4 = { class: "w-20" }
const _hoisted_5 = { class: "flex justify-end my-4 space-x-4" }
const _hoisted_6 = { class: "flex border-2 border-opacity-10 rounded-lg space-x-4 px-4 box-content" }
const _hoisted_7 = { class: "my-auto py-2 space-x-2" }
const _hoisted_8 = { for: "surcharge" }
const _hoisted_9 = { class: "my-auto font-medium py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_HomeMixRawMaterialsTable = _resolveComponent("HomeMixRawMaterialsTable")!
  const _component_SimpleInput = _resolveComponent("SimpleInput")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": `m-auto border-0 w-256 xl:w-288 max-w-6xl transition-width duration-500`,
    "mask-class": "grid"
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MultiStepFormHeaderIcon, {
          "icon-source": _ctx.IconSource.Food,
          "is-final-step": false
        }, null, 8, ["icon-source"]),
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.feed.mixes.editMixModal.mixRawMaterials.header').replace('$MIX_NAME', _ctx.mix.name)), 1),
        _createElementVNode("div", null, [
          _createVNode(_component_HomeMixRawMaterialsTable, { value: _ctx.mix }, {
            sum_name: _withCtx(({ sumHeader }) => [
              (_openBlock(), _createElementBlock("td", {
                key: sumHeader,
                style: {},
                class: "flex flex-1 px-2 my-auto word-break"
              }, _toDisplayString(_ctx.$t('views.feed.mixes.homeMixRawMaterialsTable.total')), 1))
            ]),
            price: _withCtx(({ header, item }) => [
              (_openBlock(), _createElementBlock("td", {
                key: header.value,
                class: "flex flex-1 px-2 my-auto word-break"
              }, _toDisplayString(_ctx.$n(_ctx.getPrice(item.material)?.price || 0, 'price')), 1))
            ]),
            sum_components: _withCtx(({ sumHeader }) => [
              (_openBlock(), _createElementBlock("td", {
                key: sumHeader,
                class: "flex flex-1 px-2 my-auto word-break"
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$n(_ctx.mixComponentsSum)), 1)
              ]))
            ]),
            sum_price: _withCtx(({ sumHeader }) => [
              (_openBlock(), _createElementBlock("td", {
                key: sumHeader,
                class: "flex flex-1 px-2 my-auto word-break"
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$n(_ctx.getPrice(_ctx.mix)?.price || 0, 'price')), 1)
              ]))
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('views.feed.mixes.editMixModal.mixRawMaterials.surcharge')) + ":", 1),
              _createVNode(_component_SimpleInput, {
                id: "surcharge",
                value: _ctx.$n(_ctx.mix.surcharge || 0, 'price'),
                disabled: "",
                class: "w-16",
                type: "number",
                name: "surcharge",
                step: "any"
              }, null, 8, ["value"])
            ]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(`${_ctx.$t('views.feed.mixes.editMixModal.mixRawMaterials.totalPrice')}: ${_ctx.$n((_ctx.getPrice(_ctx.mix).price || 0) + (_ctx.mix.surcharge || 0), 'price')}`), 1)
          ])
        ])
      ])
    ]),
    _: 3
  }))
}