<template>
    <GenericMultiStepModal :header-icon="header?.iconUrl || form?.event?.icon_url" :is-final-step="isFinalStep" modal-class="w-128" @closing="onModalClose">
        <template #default="scope">
            <slot v-bind="scope" />
        </template>
        <template #component="scope">
            <component
                :is="currentStep.component"
                v-model:state="form"
                :config="currentStepConfig"
                @submit="next"
                @skip="(stepsToSkip = 2) => goToStep(currentStepIndex + stepsToSkip)"
                @back="previous"
                @close="scope.close"
                @submission="$emit('submission')"
            ></component>
        </template>
    </GenericMultiStepModal>
</template>

<script lang="ts">
import { defineComponent, Ref, computed, watch, PropType } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import GenericMultiStepModal from '@/components/common/GenericMultiStepModal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { MultiStepFormState } from '@/types';
import { ActionType } from '@/plugins/store/actions';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import useResettableForm from '@/components/common/composables/useResettableForm';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import FormLoader from '@/components/common/dynamicFormModal/FormLoader.vue';
import DynamicForm from '@/components/common/multiStepFormSteps/DynamicForm.vue';
import SubmissionStatus from '@/components/common/dynamicFormModal/SubmissionStatus.vue';
import ConfirmationForm from '@/components/common/multiStepFormSteps/ConfirmationForm.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { GenericMultiStepModal, CustomButton, MultiStepFormHeaderIcon, CloseModalButton },
    emits: ['closing', 'submission'],
    props: {
        stepConfig: {
            type: Array as PropType<Record<any, any>[]>,
            default: [
                {},
                {},
                {
                    rejectable: false,
                    submitActionType: ActionType.PutForm,
                },
                {},
            ],
        },
        initialState: {
            type: Object as PropType<MultiStepFormState>,
            default: {
                location: null,
                form: null,
                confirmed: false,
                farm: null,
                record_id: null,
                notification: null,
                event: null,
            },
        },
        initialStepIndex: {
            type: Number as PropType<number>,
            default: 0,
        },
        header: {
            type: Object as PropType<{ iconUrl: string }>,
            default: null,
        },
        submissionText: {
            type: String as PropType<string>,
            default: '',
        },
        refetchActions: {
            type: Array as PropType<ActionType | ActionType[]>,
            default: () => [],
        },
    },
    setup(props, context) {
        const resettableForm = useResettableForm().setInitial(props.initialState);
        const form = resettableForm.getInstance() as Ref<Form<MultiStepFormState>>;

        watch(
            () => props.initialState,
            (newValue) => {
                if (newValue) {
                    resettableForm.setInitial(props.initialState);
                }
            },
            { deep: true }
        );

        let formEdited = false;

        watch(
            () => form.value.form,
            (newValue, oldValue) => {
                formEdited = Boolean(newValue && oldValue);
            },
            { deep: true }
        );

        const multiSteps = useMultiSteps([
            {
                component: FormLoader,
            },
            {
                component: DynamicForm,
            },
            {
                component: ConfirmationForm,
            },
            {
                component: SubmissionStatus,
            },
        ]);

        watch(
            () => props.initialStepIndex,
            (newValue) => {
                multiSteps.setInitial(newValue);
            },
            { immediate: true }
        );

        const currentStepConfig = computed(() => {
            const defaultConfig = {
                disableRefetch: true,
                refetchActions: props.refetchActions,
                submissionText: props.submissionText,
            };
            const stepConfig = props.stepConfig[multiSteps.currentStepIndex.value];

            return stepConfig ? { ...defaultConfig, ...stepConfig } : defaultConfig;
        });

        const onModalClose = (next: (closeModal: boolean) => boolean) => {
            if (!multiSteps.isFinalStep.value && formEdited) {
                if (!window.confirm(i18n.global.t('global.forms.confirmCloseModalMessage'))) {
                    return next(false);
                }
            }

            context.emit('closing', multiSteps.isFinalStep.value);

            multiSteps.reset();
            resettableForm.reset();

            return true;
        };

        return { form, ...multiSteps, onModalClose, currentStepConfig };
    },
});
</script>
