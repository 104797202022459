<template>
    <div class="space-y-4">
        <h2 class="max-w-120 text-xl font-semibold text-center">{{ $t('views.feed.mixes.editMixModal.header').replace('$MIX_NAME', mix.name) }}</h2>
        <div>
            <table class="w-120 table-headers-medium">
                <thead>
                    <tr class="px-6 flex mt-2 rounded text-dark-gray-800 text-opacity-50">
                        <th class="flex flex-1">
                            <span class="mx-auto">{{ $t('views.feed.mixes.editMixModal.table.headers.price') }}</span>
                        </th>
                        <th class="flex flex-1">
                            <span class="mx-auto">{{ $t('views.feed.mixes.editMixModal.table.headers.validFrom') }}</span>
                        </th>
                        <th class="flex w-12"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="mix.type.id === 2" class="rounded flex py-4 px-6 my-4 bg-feed-600 bg-opacity-60 border-2 border-opacity-10 rounded-lg">
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">
                                <SimpleInput v-model:value="priceToAdd.price" class="w-20" type="number" :placeholder="$t('views.feed.mixes.editMixModal.table.newPricePlaceholder')"></SimpleInput>
                            </span>
                        </td>
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto w-32">
                                <FeedDatePicker v-model:value="priceToAdd.valid_from" :placeholder="$t('views.feed.mixes.editMixModal.table.newValidFromPlaceholder')" :disabled="false"></FeedDatePicker>
                            </span>
                        </td>
                        <td class="flex w-12 my-auto">
                            <button
                                type="button"
                                :class="{ 'opacity-25 cursor-default': !validPriceToAdd, 'bg-primary-800 text-white rounded font-medium': validPriceToAdd }"
                                class="mx-auto px-2 py-1"
                                @click="onAddPriceClicked"
                            >
                                {{ $t('views.feed.mixes.editMixModal.table.addPriceButtonLabel') }}
                            </button>
                        </td>
                    </tr>
                    <tr v-for="(price, index) of prices" :key="index" class="rounded flex py-4 px-6 my-4 odd:bg-dark-gray-100 border-2 border-opacity-10 rounded-lg">
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">{{ $n(price.price, 'price') }}</span>
                        </td>
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">{{ $d(price.valid_from, 'short') }}</span>
                        </td>
                        <td class="flex w-12 my-auto">
                            <button v-if="mix.type.id === 2 && mix.prices && mix.prices.length > 1" type="button" class="mx-auto" @click="onDeletePriceClicked(price)">
                                <Icon class="w-6" :src="IconSource.Delete"></Icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex space-x-4">
            <DeleteMixConfirmationModal :mix="computedValue" @submit="$emit('submit')">
                <template #default="scope">
                    <DeleteButton @click="scope.open">
                        {{ $t('views.feed.mixes.deleteMixModal.deleteButtonLabel') }}
                    </DeleteButton>
                </template>
            </DeleteMixConfirmationModal>
            <div>
                <MixFarmsModal :mix="mix">
                    <template #default="scope">
                        <AddButton @click="scope.open">
                            {{ $t('views.feed.mixes.editMixModal.mixFarms.mixFarmsButtonLabel') }}
                        </AddButton>
                    </template>
                </MixFarmsModal>
            </div>
            <div v-if="showMixMaterials">
                <MixRawMaterialsModal :mix="mix">
                    <template #default="scope">
                        <AddButton @click="scope.open">
                            {{ $t('views.feed.mixes.editMixModal.mixRawMaterials.mixRawMaterialsButtonLabel') }}
                        </AddButton>
                    </template>
                </MixRawMaterialsModal>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import { Mix, Price, MixTypeId } from '@/types';
import DeleteMixConfirmationModal from '@/views/dashboard/feed/feedMixes/editMixModal/DeleteMixConfirmationModal.vue';
import MixFarmsModal from '@/views/dashboard/feed/feedMixes/editMixModal/MixFarmsModal.vue';
import MixRawMaterialsModal from '@/views/dashboard/feed/feedMixes/editMixModal/MixRawMaterialsModal.vue';
import FeedDatePicker from '@/components/ui/FeedDatePicker.vue';
import AddButton from '@/components/ui/AddButton.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import SimpleInput from '@/components/ui/SimpleInput.vue';

export default defineComponent({
    components: { CustomButton, DeleteMixConfirmationModal, MixFarmsModal, MixRawMaterialsModal, FeedDatePicker, AddButton, DeleteButton, SimpleInput },
    emits: ['submit', 'update:mix'],
    props: {
        mix: {
            type: Object as () => Form<Mix> & Mix,
            required: true,
        },
    },
    data() {
        return {
            priceToAdd: {
                price: '' as number | string,
                valid_from: '',
            },
        };
    },
    computed: {
        showMixMaterials(): boolean {
            return this.$props.mix.type.id !== MixTypeId.COMPOUND_MIX;
        },
        computedValue: {
            get(): Form<Mix> & Mix {
                return this.mix;
            },
            set(newValue: any) {
                this.$emit('update:mix', newValue);
            },
        },
        farms(): { id: number; name: string }[] {
            return store.state.farms.map((farm) => ({ name: farm.name, id: farm.farm_id }));
        },
        validPriceToAdd(): boolean {
            return Boolean(typeof this.priceToAdd.price === 'number' && typeof this.priceToAdd.valid_from === 'string' && this.priceToAdd.valid_from.length);
        },
        prices(): Price[] {
            return [...this.mix.prices].sort((a, b) => Date.parse(a.valid_from) - Date.parse(b.valid_from));
        },
    },
    methods: {
        async onAddPriceClicked() {
            if (!this.validPriceToAdd) {
                return;
            }

            const form = new Form({ mix: this.mix, price: this.priceToAdd as Price });
            await store.dispatch(ActionType.CreateMixPrice, { form: form as any });

            if (!form.errors.hasErrors()) {
                this.priceToAdd.price = '';
                this.priceToAdd.valid_from = '';
                store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
            }
        },
        async onDeletePriceClicked(price: Price) {
            await store.dispatch(ActionType.DeleteMixPrice, { form: { mix: this.mix, price } });
            store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
        },
    },
});
</script>
