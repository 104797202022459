import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-white relative rounded-lg grid py-6 space-y-3 px-10 m-auto" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-xl font-semibold mb-6"
}
const _hoisted_4 = { class: "relative rounded-lg overflow-hidden aspect-video" }
const _hoisted_5 = ["src", "title"]
const _hoisted_6 = {
  key: 1,
  class: "flex justify-end mt-2"
}
const _hoisted_7 = ["href", "onClick"]
const _hoisted_8 = {
  key: 2,
  class: "text-gray-500 mt-4"
}
const _hoisted_9 = {
  key: 3,
  class: "mt-5 space-y-3 pb-4"
}
const _hoisted_10 = { class: "text-lg font-semibold text-primary-800" }
const _hoisted_11 = { class: "list-disc pl-5 space-y-1.5" }
const _hoisted_12 = ["href"]

import { ref } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { HelpWidgetVideoItem, IconSource } from '@/types';
import { Skeleton } from '@/components/ui/skeleton';

interface Props {
    item?: HelpWidgetVideoItem;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpVideoModal',
  props: {
    item: {}
  },
  setup(__props: any) {



const hasVideoLoaded = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_unref(Modal), {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "w-[680px] max-w-full m-auto border-0",
    "mask-class": "grid"
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.item)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.item.video_title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.item.video_title), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (!hasVideoLoaded.value)
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      class: "absolute inset-0"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("iframe", {
                  src: _ctx.item.video_source_url,
                  loading: "lazy",
                  title: _ctx.item.video_title ?? _ctx.item.text,
                  allow: "encrypted-media; fullscreen;",
                  class: _normalizeClass(["absolute w-full h-full inset-0 border-none p-0 m-0 overflow-hidden transition-opacity duration-300", { 'opacity-0': !hasVideoLoaded.value, 'opacity-100': hasVideoLoaded.value }]),
                  onLoad: _cache[0] || (_cache[0] = ($event: any) => (hasVideoLoaded.value = true))
                }, null, 42, _hoisted_5)
              ]),
              (_ctx.item.reference_url)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("a", {
                      href: _ctx.item.reference_url,
                      class: "flex text-sm font-medium items-center gap-x-1.5 py-1.5 px-2 rounded-md hover:bg-gray-100 transition-colors duration-200",
                      target: "_blank",
                      onClick: scope.close
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.modals.helpVideo.openInNewWindowButtonLabel')) + " ", 1),
                      _createVNode(_component_Icon, {
                        src: _unref(IconSource).LinkIcon,
                        class: "flex-shrink-0 size-4"
                      }, null, 8, ["src"])
                    ], 8, _hoisted_7)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.item.video_description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.item.video_description), 1))
                : _createCommentVNode("", true),
              (_ctx.item.references?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('global.modals.helpVideo.otherRelevantVideosHeader')), 1),
                    _createElementVNode("ul", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.references, (reference, referenceIndex) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: `reference-${referenceIndex}`
                        }, [
                          _createElementVNode("a", {
                            href: reference.url,
                            target: "_blank",
                            class: "hover:text-primary-700 transition-colors duration-200 underline underline-offset-2"
                          }, _toDisplayString(reference.text), 9, _hoisted_12)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}
}

})