<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.monitoring.log.header.heading')" :has-period-picker="true">
        <MainViewCard>
            <div class="space-y-4 pt-4">
                <div class="flex justify-end mx-6 space-x-6">
                    <button type="button" class="bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-2 px-3 flex" @click="refresh">
                        <span class="m-auto pr-2">
                            <Icon :src="IconSource.RefreshActivities"></Icon>
                        </span>
                        <span class="font-medium">{{ $t('views.monitoring.log.fetchLogsButtonLabel') }}</span>
                    </button>
                    <AlarmsTableCalendarModal :alarms="items"></AlarmsTableCalendarModal>
                    <FilterModal
                        v-model:selected-ids="selectedAlarmTypes"
                        :header="$t('views.monitoring.log.filterModal.header')"
                        :sub-header="$t('views.monitoring.log.filterModal.subheader')"
                        :types="alarmTypes"
                    >
                        <template #default="{ scope }">
                            <button type="button" class="bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-2 px-3 flex" @click="scope.open">
                                <span class="m-auto pr-2">
                                    <Icon :src="IconSource.Filter"></Icon>
                                </span>
                                <span class="font-medium">{{ $t('views.monitoring.log.filterButtonLabel') }}</span>
                                <span v-if="alarmTypes.length && selectedAlarmTypes.length !== alarmTypes.length" class="bg-primary-800 rounded-full text-white font-medium leading-none px-2 py-1 ml-2">{{
                                    selectedAlarmTypes.length
                                }}</span>
                            </button>
                        </template>
                    </FilterModal>
                </div>
                <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader">
                    <template #default="{ open }">
                        <CustomTable :items="items" :headers="headers">
                            <template #header_icon>
                                <th class="w-10"></th>
                            </template>
                            <template #icon="{ item }">
                                <td class="w-10 my-auto">
                                    <Icon class="h-10 w-10" :src="item.icon"></Icon>
                                </td>
                            </template>
                            <template #severity_level="{ item, getHeaderClass, getWidth, header }">
                                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                                    <AlertBadge v-if="item.severity_level === 3" type="error">
                                        {{ $t('views.monitoring.log.tables.alarmStatus.alarm') }}
                                    </AlertBadge>
                                    <AlertBadge v-else-if="item.severity_level === 2" type="warning">
                                        {{ $t('views.monitoring.log.tables.alarmStatus.attention') }}
                                    </AlertBadge>
                                    <AlertBadge v-else type="success">
                                        {{ $t('views.monitoring.log.tables.alarmStatus.OK') }}
                                    </AlertBadge>
                                </td>
                            </template>
                            <template #header_actions>
                                <th class="w-8"></th>
                            </template>
                            <template #actions="{ item }">
                                <td class="w-8 my-auto">
                                    <span class="flex justify-end">
                                        <button type="button" class="p-2 -mr-2" @click="onAlarmClicked(item, open)">
                                            <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                        </button>
                                    </span>
                                </td>
                            </template>
                        </CustomTable>
                    </template>
                </ActionModal>
            </div>
        </MainViewCard>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import { IconSource, RecordForm, Alarm } from '@/types';
import MainViewCard from '@/components/common/MainViewCard.vue';
import AlarmsTableCalendarModal from './AlarmsTableCalendarModal.vue';
import FilterModal from '@/components/common/FilterModal.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import AlertBadge from '@/components/ui/AlertBadge.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { i18n } from '@/plugins/internationalization/i18n';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, AlarmsTableCalendarModal, FilterModal, CustomTable, AlertBadge, ActionModal },
    setup() {
        store.dispatch(ActionType.GetAlarmTypes);
        useRefetchDataOnDashboardStateChanged(ActionType.GetAlarms, { watchSites: true, watchFarmsInsteadOfSites: false, watchFormSubmissions: true });

        const alarmTypes = computed(() => store.state.alarmTypes || []);
        const selectedAlarmTypes = ref<number[]>([]);

        watch(
            alarmTypes,
            (newValue) => {
                selectedAlarmTypes.value = newValue.map((alarmType) => alarmType.id);
            },
            { immediate: true }
        );

        function refresh() {
            store.dispatch(ActionType.GetAlarms, { options: { ignoreCache: true } });
        }

        const headers = computed(() => [
            { text: '', value: 'icon', align: 'left', sortable: false, searchable: false },
            { text: i18n.global.t('views.monitoring.log.tables.headings.type'), value: 'type', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.monitoring.log.tables.headings.date'), value: 'date', align: 'left', sortable: true, searchable: true, type: 'datetime' },
            { text: i18n.global.t('views.monitoring.log.tables.headings.severity'), value: 'severity_level', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.monitoring.log.tables.headings.reason'), value: 'cause', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.monitoring.log.tables.headings.location'), value: 'location', align: 'left', sortable: true },
            { text: i18n.global.t('views.monitoring.log.tables.headings.section'), value: 'section_name', align: 'left', sortable: true },
            { text: '', value: 'actions', align: 'right', sortable: false, searchable: false },
        ]);

        function getDisplayLocation(alarm: Alarm) {
            let location = '';

            if (alarm.farm_name && alarm.farm_name !== '-') {
                location += alarm.farm_name;
            }

            if (alarm.barn_name && alarm.barn_name !== '-') {
                location += `/ ${alarm.barn_name}`;
            }

            return location;
        }

        const items = computed(() => {
            const alarms = store.state.alarmResponse?.data || [];
            const filteredAlarms = alarms.filter((a) => selectedAlarmTypes.value.includes(a.type_id));

            return filteredAlarms.map((a) => {
                const alarmType = alarmTypes.value.find((t) => t.id === a.type_id);

                return {
                    ...a,
                    icon: alarmType?.icon_id || '',
                    type: alarmType?.name || '',
                    cause: a.cause?.name || '',
                    location: getDisplayLocation(a),
                };
            });
        });

        const actionModalInitialState = ref<any>({});
        const actionModalStepConfig = ref<any>([{}, { deleteButton: true, editable: true }, { disableRefetch: true, submitActionType: ActionType.PutAlarmForm }, {}]);
        const actionModalHeader = ref<{ icon_url: IconSource | string; text: string }>({ icon_url: '', text: '' });
        const isLoadingActionForm = ref(false);

        const onAlarmClicked = async (alarm: Alarm, open: CallableFunction) => {
            try {
                if (isLoadingActionForm.value) return;

                isLoadingActionForm.value = true;

                const alarmForm: RecordForm = await store.dispatch(ActionType.GetAlarmForm, { form: { alarm_id: alarm.id } });

                if (alarmForm) {
                    actionModalStepConfig.value[1] = { deleteButton: alarmForm.deletable, editable: alarmForm.editable };
                    actionModalInitialState.value = { alarm_id: alarm.id, location: null, form: alarmForm.form, confirmed: false, farm: null, event: null, record_id: null, notification: null };
                    actionModalHeader.value = { icon_url: IconSource.AlarmIcon, text: alarmForm.form.sections?.[0]?.name || '' };

                    open();
                }
            } finally {
                isLoadingActionForm.value = false;
            }
        };

        return {
            refresh,
            IconSource,
            headers,
            items,
            selectedAlarmTypes,
            alarmTypes,
            actionModalInitialState,
            actionModalStepConfig,
            actionModalHeader,
            onAlarmClicked,
        };
    },
});
</script>
