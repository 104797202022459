import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { HTMLAttributes, computed } from 'vue';
import { SelectSeparator, SelectSeparatorProps } from 'radix-vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectSeparator',
  props: {
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectSeparator), _mergeProps(delegatedProps.value, {
    class: _unref(cn)('-mx-1 my-1 h-px bg-muted', props.class)
  }), null, 16, ["class"]))
}
}

})