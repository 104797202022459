import { keepPreviousData, useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { ChartPeriod } from '@/types';

const DEFAULT_OPTIONS = {
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 5, // 5 minutes
};

export const queryKeys = {
    monitorOverviewCharts: () => ['charts', 'monitoring', 'monitorOverview'],
    useMonitorOverviewChartsEntry: ({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }) => [
        ...queryKeys.monitorOverviewCharts(),
        { sectionId, hourly, period },
    ],
    monitorWaterConsumptionCharts: () => ['charts', 'monitoring', 'monitorWaterConsumption'],
    monitorWaterConsumptionChart: ({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }) => [
        ...queryKeys.monitorWaterConsumptionCharts(),
        { sectionId, hourly, period },
    ],
    feedingSystemRuntimeCharts: () => ['charts', 'monitoring', 'feedingSystemRuntime'],
    feedingSystemRuntimeChart: ({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }) => [
        ...queryKeys.feedingSystemRuntimeCharts(),
        { sectionId, hourly, period },
    ],
    weightCharts: () => ['charts', 'monitoring', 'weight'],
    weightChart: ({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }) => [...queryKeys.weightCharts(), { sectionId, hourly, period }],
};

export function useMonitorOverviewCharts({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.useMonitorOverviewChartsEntry({ sectionId, hourly, period }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorOverviewCharts, {
                form: {
                    location_id: sectionId.value,
                    hourly: hourly.value,
                    period: period.value,
                },
            }),
        placeholderData: keepPreviousData,
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

export function useMonitorWaterConsumptionChart({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.monitorWaterConsumptionChart({ sectionId, hourly, period }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorChartData, {
                form: {
                    url: 'charts/monitor/water-consumption',
                    location_id: sectionId.value,
                    hourly: hourly.value,
                    period: period.value,
                },
            }),
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

export function useMonitorFeedingSystemRuntimeChart({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.feedingSystemRuntimeChart({ sectionId, hourly, period }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorChartData, {
                form: {
                    url: 'charts/monitor/feeding-sys-runtime',
                    location_id: sectionId.value,
                    hourly: hourly.value,
                    period: period.value,
                },
            }),
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

export function useMonitorWeightChart({ sectionId, hourly, period }: { sectionId: Ref<number>; hourly: Ref<boolean>; period: Ref<ChartPeriod> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.weightChart({ sectionId, hourly, period }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorChartData, {
                form: {
                    url: 'charts/monitor/weight-measurements',
                    location_id: sectionId.value,
                    hourly: hourly.value,
                    period: period.value,
                },
            }),
        ...DEFAULT_OPTIONS,
        ...options,
    });
}
