import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "flex mx-6 space-x-6 pt-4" }
const _hoisted_3 = { class: "flex-1 font-medium" }
const _hoisted_4 = { class: "text-lg" }
const _hoisted_5 = { class: "opacity-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_CreateOrEditPriceChargeModal = _resolveComponent("CreateOrEditPriceChargeModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('views.economy.status.table.header')), 1),
        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('views.economy.status.table.subheader')), 1)
      ]),
      _createVNode(_component_CreateOrEditPriceChargeModal, { "step-config": _ctx.priceChargeStepConfig }, {
        default: _withCtx(({ open }) => [
          _createVNode(_component_AddButton, {
            "text-class": "font-medium",
            onClick: open
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.economy.status.modals.createOrEditPriceChargeModal.buttonLabel')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["step-config"])
    ]),
    _createVNode(_component_CustomTable, {
      headers: _ctx.headers,
      items: _ctx.items
    }, null, 8, ["headers", "items"])
  ]))
}