import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChartWithApi = _resolveComponent("GenericChartWithApi")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_GenericTableWithApi = _resolveComponent("GenericTableWithApi")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.feed.consumption.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MainViewCard, { class: "pt-4 pb-2 px-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_GenericChartWithApi, {
              "chart-name": _ctx.chart.name,
              height: _ctx.chart.height,
              "chart-type": _ctx.chart.type
            }, null, 8, ["chart-name", "height", "chart-type"])
          ]),
          _: 1
        }),
        _createVNode(_component_MainViewCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_GenericTableWithApi, { source: _ctx.feedConsumptionTableSource }, null, 8, ["source"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}