<template>
    <div>
        <div v-if="chartOptions?.series?.length || chartOptions?.type === ChartType.Dashboard">
            <slot></slot>
        </div>
        <div v-else class="min-h-20 flex justify-center">
            <h4 class="text-lg font-medium my-auto">{{ $t('layouts.dashboard.charts.noChartData') }}</h4>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ComputedRef, unref, computed } from 'vue';
import { ChartType } from '@/types';

export default defineComponent({
    props: {
        options: {
            /* global Highcharts */
            type: Object as () => ComputedRef<Highcharts.Options & { type?: ChartType }> | (Highcharts.Options & { type?: ChartType }),
            default: null,
        },
    },
    setup(props) {
        const chartOptions = computed(() => unref(props.options));

        return {
            chartOptions,
            ChartType,
        };
    },
});
</script>
