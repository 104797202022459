import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 py-6 px-8 rounded-xl" }
const _hoisted_2 = {
  key: 0,
  class: "space-y-6"
}
const _hoisted_3 = { class: "text-xl font-semibold text-center" }
const _hoisted_4 = { class: "flex space-x-4" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "my-auto justify-self-end" }
const _hoisted_7 = { class: "my-auto font-medium justify-self-start" }
const _hoisted_8 = {
  key: 1,
  class: "w-80 space-y-6 flex-col"
}
const _hoisted_9 = { class: "text-xl font-semibold text-center" }
const _hoisted_10 = { class: "flex justify-center mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": " m-auto border-0",
    "mask-class": "grid",
    onOpening: _ctx.onOpening,
    onClosing: _ctx.onClose
  }, {
    toggle: _withCtx((scope) => [
      _createVNode(_component_CustomButton, {
        class: "px-6",
        onClick: scope.open
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.reports.reportsPage.eControlModal.modalButtonLabel')), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.pdf)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.header), 1),
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eControlActions, (eControlAction) => {
                      return (_openBlock(), _createElementBlock("button", {
                        key: eControlAction.text,
                        type: "button",
                        class: "bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-4 px-3 flex w-40 justify-center space-x-2",
                        onClick: eControlAction.onClick
                      }, [
                        _createElementVNode("span", _hoisted_6, [
                          _createVNode(_component_Icon, {
                            src: eControlAction.icon
                          }, null, 8, ["src"])
                        ]),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(eControlAction.text), 1)
                      ], 8, _hoisted_5))
                    }), 128))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.header), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_Spinner, {
                      class: "w-16 h-16",
                      active: true
                    })
                  ])
                ]))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["onOpening", "onClosing"]))
}