<template>
    <Icon :src="iconSource" :style="expand ? 'transform: rotate(180deg);' : ''" style="transition: transform 0.2s"></Icon>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IconSource } from '@/types';

export default defineComponent({
    props: {
        expand: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'white',
            validator(value: string) {
                return ['white', 'black', 'primary'].indexOf(value) !== -1;
            },
        },
    },
    computed: {
        iconSource() {
            switch (this.$props.color) {
                case 'primary': {
                    return IconSource.DropdownPrimary;
                }
                case 'black': {
                    return IconSource.DropdownDark;
                }
                default: {
                    return IconSource.DropdownLight;
                }
            }
        },
    },
});
</script>
