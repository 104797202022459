<template>
    <Modal close-esc-event="keydown" :close-button="false" :modal-class="`m-auto border-0 max-w-6xl transition-width duration-500`" mask-class="grid" @closing="onModalClose">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128">
                <MultiStepFormHeaderIcon :icon-source="IconSource.Food" :is-final-step="false"></MultiStepFormHeaderIcon>
                <h2 class="text-xl font-semibold text-center">{{ $t('views.feed.mixes.editMixModal.mixFarms.header') }}</h2>
                <div>
                    <CustomMultiSelect
                        v-model:value="selectedFarms"
                        :prepend="$t('views.feed.mixes.editMixModal.mixFarms.farmsFieldLabel')"
                        label=""
                        :items="farms"
                        item-text="name"
                        item-value="farm_id"
                        :show-required-indication="false"
                    ></CustomMultiSelect>
                </div>
                <div class="flex space-x-4">
                    <CustomButton :disabled="disableButtons" color-preset="secondary" @click="scope.close">
                        {{ $t('views.feed.mixes.editMixModal.mixFarms.cancelButtonLabel') }}
                    </CustomButton>
                    <CustomButton :disabled="disableButtons" @click="onSubmitClicked(scope.close)">
                        {{ $t('views.feed.mixes.editMixModal.mixFarms.submitButtonLabel') }}
                    </CustomButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Mix, Farm } from '@/types';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, CustomButton, CustomMultiSelect, CloseModalButton },
    emits: ['submit'],
    props: {
        mix: {
            type: Object as () => Mix,
            required: true,
        },
    },
    data() {
        return {
            selectedFarms: [] as Farm[],
            disableButtons: false,
        };
    },
    computed: {
        farms(): Farm[] {
            return store.state.farms;
        },
    },
    created() {
        this.selectedFarms = this.farms.filter((currentFarm) => Boolean(this.$props.mix.farms.find((currentMixFarm) => currentFarm.farm_id === currentMixFarm.id)));
    },
    methods: {
        async onSubmitClicked(close: CallableFunction) {
            this.disableButtons = true;
            const farmsToAdd = this.selectedFarms
                .filter((currentSelectedFarm) => !this.$props.mix.farms.find((currentMixFarm) => currentMixFarm.id === currentSelectedFarm.farm_id))
                .map((currentFarmToAdd) => ({ id: currentFarmToAdd.farm_id, name: currentFarmToAdd.name }));
            const farmsToDelete = this.$props.mix.farms.filter((currentMixFarm) => !this.selectedFarms.find((currentSelectedFarm) => currentSelectedFarm.farm_id === currentMixFarm.id));

            const createRequests = farmsToAdd.map((currentFarmToAdd) => store.dispatch(ActionType.CreateMixFarm, { form: { farm: currentFarmToAdd, mix: this.$props.mix } }));
            const deleteRequests = farmsToDelete.map((currentFarmToDelete) => store.dispatch(ActionType.DeleteMixFarm, { form: { farm: currentFarmToDelete, mix: this.$props.mix } }));

            await Promise.all([...createRequests, ...deleteRequests]);
            store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
            close();
        },
        onModalClose() {
            this.selectedFarms = this.farms.filter((currentFarm) => Boolean(this.$props.mix.farms.find((currentMixFarm) => currentFarm.farm_id === currentMixFarm.id)));
        },
    },
});
</script>
