import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-8" }
const _hoisted_2 = { class: "select-date-tabs" }
const _hoisted_3 = { class: "grid grid-cols-5 mb-4 w-full gap-2" }
const _hoisted_4 = { class: "space-y-8 w-full" }
const _hoisted_5 = { class: "flex flex-col xl:flex-row space-y-8 xl:space-x-6 xl:space-y-0" }
const _hoisted_6 = { class: "flex justify-end" }
const _hoisted_7 = { class: "grid grid-cols-2 space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PigsStatusGraph = _resolveComponent("PigsStatusGraph")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_ReportsEntriesTable = _resolveComponent("ReportsEntriesTable")!
  const _component_ReportsDeparturesTable = _resolveComponent("ReportsDeparturesTable")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_EControlModal = _resolveComponent("EControlModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Tabs, {
        teleport: "#date-tabs-target",
        "active-tab-class": "--active"
      }, {
        default: _withCtx(() => [
          _createElementVNode("nav", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.reports, (report, index) => {
              return (_openBlock(), _createBlock(_component_Tab, {
                key: report.name,
                slug: `${index + 1}`,
                name: report.name,
                default: index === 0,
                onClick: ($event: any) => (_ctx.onTabClicked(report))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_MainViewCard, { class: "pt-4 pb-2 px-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PigsStatusGraph, {
                          "report-title": report.name,
                          data: report.graph
                        }, null, 8, ["report-title", "data"])
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_ReportsEntriesTable, {
                        "empty-rows": _ctx.emptyEntryTableRows,
                        data: report.entriesTable,
                        records: report.records,
                        onFetchRecords: _ctx.fetchRecords
                      }, null, 8, ["empty-rows", "data", "records", "onFetchRecords"]),
                      _createVNode(_component_ReportsDeparturesTable, {
                        data: report.departuresTable,
                        records: report.records,
                        onFetchRecords: _ctx.fetchRecords
                      }, null, 8, ["data", "records", "onFetchRecords"])
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["slug", "name", "default", "onClick"]))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _cache[0] || (_cache[0] = _createElementVNode("div", {
        id: "date-tabs-target",
        class: "flex justify-center w-full"
      }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_CustomButton, {
          class: "max-w-32 justify-self-end",
          "color-preset": "secondary",
          onClick: _ctx.onResetClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('views.reports.resetButtonLabel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_EControlModal, { state: _ctx.state }, null, 8, ["state"])
      ])
    ])
  ]))
}