import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-6 px-6 flex h-16.5" }
const _hoisted_2 = { class: "flex space-x-4" }
const _hoisted_3 = { class: "text-2xl" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "bg-white h-full w-full relative rounded-lg grid pt-4 pb-2 px-4 my-auto overflow-x-auto max-w-screen grid" }
const _hoisted_6 = {
  ref: "chartElement",
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    size: "screen",
    "modal-class": `m-auto border-0 transition-width duration-500 w-280 h-160 generic-chart-modal`,
    "mask-class": "grid",
    onOpen: _ctx.onOpening
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.chartTitle), 1),
            _createVNode(_component_AddButton, {
              class: "text-base py-1.5 my-auto",
              "icon-source": _ctx.IconSource.Print,
              onClick: _ctx.printChart
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('layouts.dashboard.charts.printChartButtonLabel')), 1)
              ]),
              _: 1
            }, 8, ["icon-source", "onClick"])
          ]),
          _createElementVNode("button", {
            class: "ml-auto mb-auto",
            onClick: scope.close
          }, [
            _createVNode(_component_Icon, {
              src: _ctx.IconSource.Close
            }, null, 8, ["src"])
          ], 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, null, 512)
        ])
      ])
    ]),
    _: 3
  }, 8, ["onOpen"]))
}