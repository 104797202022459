import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mx-6 space-x-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "text-right mr-6 w-80" }
const _hoisted_4 = { class: "w-6 my-auto" }
const _hoisted_5 = { class: "flex justify-end" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_NewMixModal = _resolveComponent("NewMixModal")!
  const _component_LocationsTooltip = _resolveComponent("LocationsTooltip")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_EditMixModal = _resolveComponent("EditMixModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, { class: "space-y-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.feed.mixes.table.header')), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_SearchInput, {
            value: _ctx.search,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            label: _ctx.$t('views.feed.mixes.table.searchFieldLabel')
          }, null, 8, ["value", "label"])
        ]),
        _createVNode(_component_NewMixModal, null, {
          default: _withCtx(({ open }) => [
            _createVNode(_component_AddButton, {
              "text-class": "font-medium",
              onClick: open
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.feed.mixes.table.addButtonLabel')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_CustomTable, {
        items: _ctx.filteredItems,
        headers: _ctx.headers
      }, {
        header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("th", { class: "w-6" }, null, -1)
        ])),
        prices: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          (_openBlock(), _createElementBlock("td", {
            key: header.value,
            class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.getStyledPriceForMix(item)), 1)
          ], 6))
        ]),
        farms: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          (_openBlock(), _createElementBlock("td", {
            key: header.value,
            class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, [
            _createVNode(_component_LocationsTooltip, {
              locations: item.farms,
              "number-of-locations-replace-text": "$NUMBER_OF_FARMS",
              "number-of-locations-text": _ctx.$t('views.feed.mixes.table.farmsTooltip.tableText'),
              "tooltip-header": _ctx.$t('views.feed.mixes.table.farmsTooltip.tooltipHeader')
            }, null, 8, ["locations", "number-of-locations-text", "tooltip-header"])
          ], 6))
        ]),
        actions: _withCtx(({ item }) => [
          _createElementVNode("td", _hoisted_4, [
            _createVNode(_component_EditMixModal, { mix: item }, {
              default: _withCtx(({ open }) => [
                _createElementVNode("span", _hoisted_5, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "p-2 -mr-2",
                    onClick: open
                  }, [
                    _createVNode(_component_Icon, {
                      class: "h-3 w-3 cursor-pointer",
                      src: _ctx.IconSource.Right
                    }, null, 8, ["src"])
                  ], 8, _hoisted_6)
                ])
              ]),
              _: 2
            }, 1032, ["mix"])
          ])
        ]),
        _: 1
      }, 8, ["items", "headers"])
    ]),
    _: 1
  }))
}