import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { HTMLAttributes, computed } from 'vue';
import { SelectScrollUpButton, SelectScrollUpButtonProps, useForwardProps } from 'radix-vue';
import { ChevronUp } from 'lucide-vue-next';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectScrollUpButton',
  props: {
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectScrollUpButton), _mergeProps(_unref(forwardedProps), {
    class: _unref(cn)('flex cursor-default items-center justify-center py-1', props.class)
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_unref(ChevronUp), { class: "h-4 w-4" })
      ])
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})