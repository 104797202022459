<template>
    <div class="space-y-4">
        <MultiStepFormHeaderIcon :is-final-step="false" :icon-source="IconSource.CreateBatch"></MultiStepFormHeaderIcon>
        <h2 class="text-2xl font-semibold text-center">{{ batch.name }}</h2>
        <div class="mt-4">
            <div class="border border-gray-300 bg-gray-100 bg-opacity-50 rounded-2xl divide-gray-300 divide-y">
                <div class="py-2 px-4">
                    <div class="font-bold">{{ $t('views.pigs.batches.batchDetailsModal.startDate') }}</div>
                    <div>{{ $d(batch.start_date, 'short') }}</div>
                </div>
                <div class="py-2 px-4">
                    <div class="font-bold">{{ $t('views.pigs.batches.batchDetailsModal.endDate') }}</div>
                    <div>{{ batch.end_date === null ? $t('views.pigs.batches.batchDetailsModal.missingEndDateMessage') : $d(batch.end_date, 'short') }}</div>
                </div>
                <div class="py-2 px-4">
                    <div class="font-bold">{{ $t('views.pigs.batches.batchDetailsModal.supplierName') }}</div>
                    <div v-if="batch.supplier_numbers && batch.supplier_numbers.length">
                        <div v-for="supplier_number of batch.supplier_numbers" :key="supplier_number">{{ supplier_number }}</div>
                    </div>
                    <div v-else>
                        {{ $t('views.pigs.batches.batchDetailsModal.missingSupplierNameMessage') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="flex space-x-4 mt-4">
            <CustomButton color-preset="secondary" @click="$emit('cancel')">
                {{ $t('views.pigs.batches.batchDetailsModal.cancelButtonLabel') }}
            </CustomButton>
            <ActionModal class="w-full" :initial-step-index="1" :initial-state="updateBatchModalInitialState" :step-config="updateBatchModalStepConfig" @closing="onUpdateBatchModalClose">
                <template #default="{ open }">
                    <CustomButton :disabled="disableButtons" @click="onUpdateBatchButtonClicked(open)">
                        {{ $t('views.pigs.batches.batchDetailsModal.confirmButtonLabel') }}
                    </CustomButton>
                </template>
            </ActionModal>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { Action, BatchEvent, BatchEventType, MultiStepFormState, Form, DirectoryBatch } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';

export default defineComponent({
    components: { ActionModal, CustomButton, MultiStepFormHeaderIcon },
    emits: ['cancel', 'submit'],
    props: {
        batch: {
            type: Object as () => DirectoryBatch,
            required: true,
        },
    },
    data() {
        return {
            updateBatchModalInitialState: undefined as undefined | MultiStepFormState,
            updateBatchModalStepConfig: [{}, {}, { submitActionType: ActionType.PutForm }, {}],
            disableButtons: false,
        };
    },
    computed: {
        updateBatchEvent(): BatchEvent | undefined {
            return store.state.batchEvents.find((currentBatchEvent) => currentBatchEvent.type === BatchEventType.BatchUpdate);
        },
        updateBatchAction(): Action | undefined {
            return store.state.actions.find((currentAction) => currentAction.id === this.updateBatchEvent?.action_id);
        },
    },
    created() {
        store.dispatch(ActionType.GetActions);
        store.dispatch(ActionType.GetBatchEvents);
    },
    methods: {
        async onUpdateBatchButtonClicked(open: CallableFunction) {
            if (!this.updateBatchEvent || !this.updateBatchAction) {
                return;
            }
            this.disableButtons = true;
            const form: Form = await store.dispatch(ActionType.GetForm, { form: { form_id: this.updateBatchAction.form_id, farm_id: this.$props.batch.farm.id, batch_id: this.$props.batch.id } });
            this.disableButtons = false;
            this.updateBatchModalInitialState = { location: this.$props.batch, form, confirmed: false, farm: null, event: this.updateBatchEvent, record_id: null, notification: null };
            open();
        },
        async onUpdateBatchModalClose(closedOnFinalStep: boolean) {
            if (closedOnFinalStep) {
                await store.dispatch(ActionType.GetBatches, { options: { ignoreCache: true } });
                this.$emit('submit');
            }
        },
    },
});
</script>
