import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-6 h-full min-h-60" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "grid gap-y-4 h-full" }
const _hoisted_4 = { class: "grid grid-cols-2 py-4 px-6 bg-gray-100 rounded-lg" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "w-40 self-end mr-2" }
const _hoisted_7 = { class: "ml-2 self-end flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationElement = _resolveComponent("ConfirmationElement")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.users.content.modals.edit.steps.confirmation.title')), 1),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ConfirmationElement, {
            class: "p-2",
            label: _ctx.$t('views.settings.users.content.modals.edit.steps.confirmation.emailFieldLabel')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.computedForm.email), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_ConfirmationElement, {
            class: "p-2",
            label: _ctx.$t('views.settings.users.content.modals.edit.steps.confirmation.nameFieldLabel')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.computedForm.name), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_ConfirmationElement, {
            class: "p-2",
            label: _ctx.$t('views.settings.users.content.modals.edit.steps.confirmation.phoneNumberFieldLabel')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.computedForm.phoneNumber), 1)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_CustomButton, {
              disabled: _ctx.disableButtons,
              "color-preset": "secondary",
              onClick: _ctx.onBackClicked
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.edit.steps.confirmation.backButtonLabel')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_CustomButton, {
              disabled: _ctx.disableButtons,
              onClick: _ctx.onSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.edit.steps.confirmation.confirmButtonLabel')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ])
  ]))
}