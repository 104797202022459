<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="w-128 m-auto border-0" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-white relative rounded-lg grid pb-6 pt-10 gap-y-16 px-10 max-w-lg m-auto">
                <slot v-bind="scope" name="form"></slot>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, CloseModalButton },
});
</script>
