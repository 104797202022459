import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "ml-1 flex space-x-2" }
const _hoisted_3 = { class: "text-lg my-auto pt-2 font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EconomyStatusGlobalPrices = _resolveComponent("EconomyStatusGlobalPrices")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_EconomyStatusTable = _resolveComponent("EconomyStatusTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.canManageSolution)
      ? (_openBlock(), _createBlock(_component_EconomyStatusGlobalPrices, {
          key: 0,
          entries: _ctx.statusEntries
        }, null, 8, ["entries"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.farm,
        class: "space-y-4"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Icon, {
            class: "h-6.5 my-auto",
            src: _ctx.IconSource.FarmsPrimary
          }, null, 8, ["src"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(item.farm), 1)
        ]),
        _createVNode(_component_MainViewCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_EconomyStatusTable, {
              "action-modal-initial-state": _ctx.actionModalInitialState,
              headers: _ctx.headers,
              items: item.items
            }, null, 8, ["action-modal-initial-state", "headers", "items"])
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}