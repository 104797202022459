<template>
    <div>
        <div class="health-overview-tabs default-tabs">
            <Tabs teleport="#health-overview-tabs-target" active-tab-class="--active">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-4">
                        <Tab :name="$t('views.health.overview.tabs.ongoing')" :default="true">
                            <TreatmentsTable :items="ongoingTreatments" class="mt-4"></TreatmentsTable>
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.health.overview.tabs.completed')">
<TreatmentsTable :items="completedTreatments" class="mt-4" completed></TreatmentsTable> </Tab>
                    </div>
                </nav>
            </Tabs>

            <div id="health-overview-tabs-target" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import TreatmentsTable from '@/views/dashboard/health/healthOverview/TreatmentsTable.vue';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { Treatment } from '@/types';

export default defineComponent({
    components: { Tab, Tabs, TreatmentsTable },
    setup() {
        useRefetchDataOnDashboardStateChanged([ActionType.GetTreatments, ActionType.GetTreatmentHistory], { watchSites: true, watchFormSubmissions: true });

        function getLocationDisplay(treatment: Treatment) {
            const locationMap = [treatment.farm_name];

            if (treatment.barn_name) {
                locationMap.push(treatment.barn_name);
            }

            return locationMap.join(' / ');
        }

        function formatTreatmentItem(treatment: Treatment) {
            return {
                ...treatment,
                _location: getLocationDisplay(treatment),
                previous_treatments:
                    treatment.previous_treatments?.map((i) => ({
                        ...i,
                        _location: getLocationDisplay(i),
                    })) || [],
            };
        }

        const ongoingTreatments = computed(() => store.state.treatments.map((t) => formatTreatmentItem(t)));
        const completedTreatments = computed(() => store.state.treatmentHistory?.items?.map((i) => formatTreatmentItem(i)) || []);

        return { ongoingTreatments, completedTreatments };
    },
});
</script>
