import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "w-full flex space-x-2 content-center" }
const _hoisted_3 = { class: "grid content-center h-7 min-w-4 justify-center my-auto" }
const _hoisted_4 = { class: "font-semibold text-lg hidden lg:block my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_EventButton = _resolveComponent("EventButton")!

  return (_openBlock(), _createBlock(_component_EventButton, { events: _ctx.events }, {
    button: _withCtx(({ expandDropdown, toggleExpandDropdown }) => [
      _createElementVNode("button", {
        class: "expand-button bg-white rounded-xl py-2 text-primary-800 hover:bg-gray-100 transition-colors cursor-pointer",
        onClick: toggleExpandDropdown
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_Icon, {
              class: _normalizeClass([{ 'transform rotate-90': expandDropdown }, "transition-transform my-auto"]),
              style: {"width":"17px","height":"17px"},
              src: _ctx.IconSource.AddAction
            }, null, 8, ["class", "src"])
          ]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('layouts.dashboard.topBar.eventButton.label')), 1)
        ])
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["events"]))
}