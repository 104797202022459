<template>
    <div class="flex space-x-4">
        <AddGlobalPriceModal :step-config="addGlobalPriceStepConfig">
            <template #default="scope">
                <AddButton text-class="font-medium" @click="scope.open">
                    {{ $t('views.economy.deaths.modals.addGlobalPrice.buttonLabel') }}
                </AddButton>
            </template>
        </AddGlobalPriceModal>
        <EditGlobalPriceModal :step-config="editGlobalPriceStepConfig">
            <template #default="scope">
                <AddButton text-class="font-medium" @click="scope.open">
                    {{ $t('views.economy.deaths.modals.editGlobalPrice.buttonLabel') }}
                </AddButton>
            </template>
        </EditGlobalPriceModal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import AddGlobalPriceModal from '@/components/common/economy/globalPrices/AddGlobalPriceModal.vue';
import EditGlobalPriceModal from '@/components/common/economy/globalPrices/EditGlobalPriceModal.vue';
import AddButton from '@/components/ui/AddButton.vue';
import { ActionType } from '@/plugins/store/actions';
import { EconomyDeadPayment } from '@/types';

export default defineComponent({
    components: { AddGlobalPriceModal, EditGlobalPriceModal, AddButton },
    props: {
        deaths: {
            type: Array as () => EconomyDeadPayment[],
            default: [],
        },
    },
    computed: {
        addGlobalPriceStepConfig() {
            return [
                {
                    text: this.$t('views.economy.deaths.modals.addGlobalPrice.header'),
                    numberFields: [
                        { value: 'less_than30', label: this.$t('views.economy.deaths.modals.addGlobalPrice.lessThanThirtyFieldLabel'), colSpan: 'col-span-1' },
                        { value: 'greater_than30', label: this.$t('views.economy.deaths.modals.addGlobalPrice.greaterThanThirtyFieldLabel'), colSpan: 'col-span-1' },
                    ],
                    transformPeriod: (period: string) => ({ valid_from: format(new Date(period), 'yyyy-MM-dd') }),
                    submitActionType: ActionType.CreateGlobalDeadPaymentPrice,
                    fetchActionType: ActionType.GetEconomyDeadPayments,
                    datePicker: {
                        max: new Date().toISOString(),
                        disable: this.$props.deaths.map((currentDeathPayment) => new Date(currentDeathPayment.valid_from)),
                    },
                    confirmButtonLabel: this.$t('views.economy.deaths.modals.addGlobalPrice.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.deaths.modals.addGlobalPrice.periodFieldLabel'),
                },
                { text: this.$t('views.economy.deaths.modals.addGlobalPrice.successMessage') },
            ];
        },
        editGlobalPriceStepConfig() {
            return [
                {
                    text: this.$t('views.economy.deaths.modals.editGlobalPrice.header'),
                    numberFields: [
                        { value: 'less_than30', label: this.$t('views.economy.deaths.modals.editGlobalPrice.lessThanThirtyFieldLabel'), colSpan: 'col-span-1' },
                        { value: 'greater_than30', label: this.$t('views.economy.deaths.modals.editGlobalPrice.greaterThanThirtyFieldLabel'), colSpan: 'col-span-1' },
                    ],
                    items: this.$props.deaths,
                    fetchActionType: ActionType.GetEconomyDeadPayments,
                    confirmButtonLabel: this.$t('views.economy.deaths.modals.editGlobalPrice.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.deaths.modals.editGlobalPrice.periodFieldLabel'),
                    updateActionType: ActionType.EditGlobalDeadPayment,
                    deleteButtonLabel: this.$t('views.economy.deaths.modals.deleteGlobalPriceConfirmationModal.deleteButtonLabel'),
                    deleteModal: {
                        header: this.$t('views.economy.deaths.modals.deleteGlobalPriceConfirmationModal.header'),
                        message: this.$t('views.economy.deaths.modals.deleteGlobalPriceConfirmationModal.message'),
                        cancelButtonLabel: this.$t('views.economy.deaths.modals.deleteGlobalPriceConfirmationModal.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('views.economy.deaths.modals.deleteGlobalPriceConfirmationModal.confirmButtonLabel'),
                        deleteActionType: ActionType.DeleteGlobalDeadPayment,
                        fetchActionType: ActionType.GetEconomyDeadPayments,
                    },
                },
                { text: this.$t('views.economy.deaths.modals.editGlobalPrice.successMessage') },
                { text: this.$t('views.economy.deaths.modals.deleteGlobalPriceConfirmationModal.successMessage') },
            ];
        },
    },
});
</script>
