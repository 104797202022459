import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "absolute rounded-lg inset-0 flex justify-center items-center bg-gray-300",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["custom-button", [
            {
                'custom-button--disabled': _ctx.disabled,
                'custom-button--loading': _ctx.loading,
                'custom-button--primary': _ctx.colorPreset === 'primary',
                'custom-button--secondary': _ctx.colorPreset === 'secondary',
                'custom-button--warning': _ctx.colorPreset === 'warning',
                'custom-button--white': _ctx.colorPreset === 'white',
            },
        ]]),
    disabled: _ctx.disabled || _ctx.loading,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Spinner, { class: "h-6 w-6 mx-auto" })
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}