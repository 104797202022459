import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center w-full px-12 py-4" }
const _hoisted_2 = { class: "text-2xl py-4 font-semibold" }
const _hoisted_3 = { class: "my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_StandardDeviationBarChart = _resolveComponent("StandardDeviationBarChart")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      "close-esc-event": "keydown",
      "close-button": false,
      "modal-class": "w-full bg-white m-auto rounded-md border-0",
      "mask-class": "grid",
      onClosing: _ctx.onClose
    }, {
      toggle: _withCtx((scope) => [
        _createVNode(_component_CustomInput, {
          disabled: _ctx.disabled || _ctx.recentlyClosed,
          label: _ctx.label,
          rules: [],
          details: _ctx.details,
          "input-type": "text",
          value: _ctx.inputValue,
          cursor: "cursor-pointer",
          onFocus: ($event: any) => (_ctx.onInputFocus(scope.open)),
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input')))
        }, null, 8, ["disabled", "label", "details", "value", "onFocus"])
      ]),
      header: _withCtx((scope) => [
        _createVNode(_component_CloseModalButton, {
          onClose: scope.close
        }, null, 8, ["onClose"])
      ]),
      body: _withCtx((scope) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.label), 1),
          _createElementVNode("div", null, [
            _createVNode(_component_StandardDeviationBarChart, {
              median: _ctx.average,
              "y-axis-multiplier": _ctx.amount,
              spread: _ctx.spread,
              label: _ctx.label,
              "max-quartile-multiplier": _ctx.max
            }, null, 8, ["median", "y-axis-multiplier", "spread", "label", "max-quartile-multiplier"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Slider, {
              value: _ctx.computedValue,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedValue) = $event)),
              "slider-values": _ctx.sliderValues,
              label: `+/- ${_ctx.$n(_ctx.computedValue)}kg`
            }, null, 8, ["value", "slider-values", "label"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_CustomButton, {
              onClick: scope.close
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.dynamicForm.saveButtonLabel')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onClosing"])
  ]))
}