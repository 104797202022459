import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "text-gray-400 text-sm" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-x-4 gap-y-4" }
const _hoisted_3 = ["disabled", "value", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("label", {
          key: item[_ctx.itemValue],
          class: _normalizeClass([`${_ctx.wrapperCheckedClass(item)}`, "cursor-pointer flex-1 p-3 rounded-md border"])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
            disabled: _ctx.disabled,
            value: _ctx.returnObject ? item : item[_ctx.itemValue],
            type: "radio",
            name: _ctx.radioButtonGroup,
            class: "h-4 w-4 cursor-pointer"
          }, null, 8, _hoisted_3), [
            [_vModelRadio, _ctx.computedValue]
          ]),
          _createElementVNode("span", {
            class: _normalizeClass([[_ctx.textColorClass], "pl-2 cursor-pointer"])
          }, _toDisplayString(item[_ctx.itemText]), 3)
        ], 2))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["h-4 flex text-xs text-left w-full ml-1", { 'text-red-600': _ctx.details?.type === 'error', 'text-gray-500': _ctx.details?.type === 'hint' }])
    }, _toDisplayString(_ctx.details?.text || ''), 3), [
      [_vShow, _ctx.details]
    ])
  ]))
}