<template>
    <div class="mx-auto">
        <PreloadSpinner :show="!preloaderState.minTimePassed || !preloaderState.dashboardLoaded"></PreloadSpinner>
        <InvalidScreenSizeWarning v-show="windowWidth < 1024"></InvalidScreenSizeWarning>
        <DashboardLayout>
            <router-view></router-view>
        </DashboardLayout>
        <LoadingPageOverlay />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useKeyboardNavigationClass from '@makeabledk/vue-ui/composables/keyboard-navigation-class';
import DashboardLayout from '@/components/layouts/dashboardLayout/DashboardLayout.vue';
import PreloadSpinner from '@/components/common/PreloadSpinner.vue';
import InvalidScreenSizeWarning from '@/components/common/InvalidScreenSizeWarning.vue';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { store } from '@/plugins/store';
import useWindowWidthListener from '@/components/common/composables/useWindowWidthListener';
import LoadingPageOverlay from '@/components/common/LoadingPageOverlay.vue';

export default defineComponent({
    components: { DashboardLayout, PreloadSpinner, InvalidScreenSizeWarning, LoadingPageOverlay },
    setup() {
        return useWindowWidthListener();
    },
    data() {
        return {
            preloaderState: {
                minTimePassed: Object.hasOwnProperty.call(window, 'webpackHotUpdate'),
                dashboardLoaded: Object.hasOwnProperty.call(window, 'webpackHotUpdate'),
            },
        };
    },
    computed: {
        dashboardLoaded(): boolean {
            return store.state.dashboardLoaded;
        },
    },
    watch: {
        dashboardLoaded() {
            this.preloaderState.dashboardLoaded = true;
        },
    },
    created() {
        useKeyboardNavigationClass();
    },
    mounted() {
        setTimeout(() => {
            this.preloaderState.minTimePassed = true;
        }, 2000);
    },
    methods: {
        async waitForApiClient() {
            while (!ApiClient.apiState.userProfileLanguage || !ApiClient.apiState.authToken) await new Promise((resolve) => setTimeout(resolve, 100));
        },
    },
});
</script>

<style>
body button:focus {
    outline: none !important;
}

body.--keyboard-navigation button:focus {
    box-shadow: 0 0 0 3px rgb(66 153 225 / 50%);
    border-color: #63b3ed;
}
</style>
