import get from 'lodash-es/get';
import { Ref, computed, ref, unref } from 'vue';
import { Barn, Batch, DirectoryBarn, DirectoryFarm, Farm, Section } from '@/types';

export default function useFilterableLocations(search: Ref<string>, farms: Ref<Farm[]>, searchableProperties: { farms: string[]; locations?: string[]; sections?: string[] }) {
    const filteredFarms = computed(() => {
        const result: Farm[] = [];
        if (search.value.length === 0) {
            return farms.value;
        }

        unref(farms).forEach((currentFarm) => {
            const farmMatchesSearch = Boolean(
                searchableProperties.farms.find((currentSearchableProperty) => `${get(currentFarm, currentSearchableProperty)}`.toLowerCase().trim().includes(search.value.toLowerCase().trim()))
            );

            if (farmMatchesSearch) {
                result.push(currentFarm);
            } else if (currentFarm.locations && searchableProperties.locations) {
                const locationsMatchingSearch: (Barn | Batch)[] = [];
                currentFarm.locations.forEach((currentLocation) => {
                    const locationMatchesSearch = Boolean(
                        searchableProperties.locations!.find((currentSearchableProperty) => `${get(currentLocation, currentSearchableProperty)}`.toLowerCase().trim().includes(search.value.toLowerCase().trim()))
                    );

                    if (locationMatchesSearch) {
                        locationsMatchingSearch.push(currentLocation);
                    } else if ((currentLocation as Barn).sections) {
                        const sectionsMatchingSearch = (currentLocation as Barn).sections?.filter((currentSection) =>
                            Boolean(
                                searchableProperties.sections!.find((currentSearchableProperty) =>
                                    `${get(currentSection, currentSearchableProperty)}`.toLowerCase().trim().includes(search.value.toLowerCase().trim())
                                )
                            )
                        );

                        if (sectionsMatchingSearch?.length) {
                            locationsMatchingSearch.push({ ...currentLocation, sections: sectionsMatchingSearch });
                        }
                    }
                });

                if (locationsMatchingSearch.length) {
                    result.push({ ...currentFarm, locations: locationsMatchingSearch });
                }
            }
        });
        return result;
    });

    return { filteredFarms, search };
}
