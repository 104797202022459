<template>
    <GenericMultiStepModal :header-icon="IconSource.ButtonUserManagement" :is-final-step="isFinalStep" modal-class="w-128" @closing="onModalClose">
        <template #default="scope">
            <slot v-bind="scope" />
        </template>
        <template #component="scope">
            <component :is="currentStep.component" v-model:form="form" @submit="next" @back="previous" @close="scope.close"></component>
        </template>
    </GenericMultiStepModal>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import isEqual from 'lodash-es/isEqual';
import GenericMultiStepModal from '@/components/common/GenericMultiStepModal.vue';
import { DirectoryUser } from '@/types';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import useResettableForm from '@/components/common/composables/useResettableForm';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import EditUserForm from '@/views/dashboard/settings/users/editUserModal/EditUserForm.vue';
import EditUserConfirmationForm from '@/views/dashboard/settings/users/editUserModal/EditUserConfirmationForm.vue';
import EditUserSubmissionStatusForm from '@/views/dashboard/settings/users/editUserModal/EditUserSubmissionStatusForm.vue';

export default defineComponent({
    components: { GenericMultiStepModal, MultiStepFormHeaderIcon, CloseModalButton },
    props: {
        initialState: {
            type: Object as () => DirectoryUser,
        },
    },
    setup(props) {
        const multiSteps = useMultiSteps([{ component: EditUserForm }, { component: EditUserConfirmationForm }, { component: EditUserSubmissionStatusForm }]);
        const resettableForm = useResettableForm().setInitial({
            name: `${(props.initialState as DirectoryUser).first_name} ${(props.initialState as DirectoryUser).last_name}`,
            email: (props.initialState as DirectoryUser).email,
            phoneNumber: '',
            directoryUser: props.initialState as DirectoryUser,
        });
        const form = resettableForm.getInstance();

        watch(
            () => props.initialState,
            (initialState) => {
                resettableForm.setInitial({
                    name: `${(initialState as DirectoryUser).first_name} ${(initialState as DirectoryUser).last_name}`,
                    email: (initialState as DirectoryUser).email,
                    phoneNumber: '',
                    directoryUser: initialState as DirectoryUser,
                });
            }
        );

        const onModalClose = (next: (closeModal: boolean) => boolean) => {
            if (multiSteps.currentStepIndex.value === 0 && !isEqual(resettableForm.getInitial(), form.value.data())) {
                if (!window.confirm(i18n.global.t('global.forms.confirmCloseModalMessage'))) {
                    return next(false);
                }
            }
            multiSteps.reset();
            resettableForm.reset();
            return true;
        };

        return { onModalClose, ...multiSteps, form };
    },
});
</script>
