import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "z-[1020] sticky top-24 w-full overflow-hidden"
}
const _hoisted_2 = { class: "flex items-center justify-center bg-warning-100 py-2 pl-4 pr-12 relative gap-x-1" }
const _hoisted_3 = { class: "sr-only" }

import { computed, watch, ref } from 'vue';
import { useLocalStorage, StorageSerializers } from '@vueuse/core';
import { addDays } from 'date-fns';
import { X } from 'lucide-vue-next';
import { useQueryClient } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';
import { store } from '@/plugins/store';
import { IconSource, NotificationStatusType } from '@/types';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import { queryKeys } from '@/plugins/store/actions/queries/notifications';

const STORAGE_KEY = 'settings:notifications-bar-hidden';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsBar',
  setup(__props) {

const queryClient = useQueryClient();
const router = useRouter();

const isVisible = ref(false);
const unhandledNotificationsCount = computed(() => store.state.meta?.notifications?.unhandled || 0);
const isDashboardLoaded = computed(() => store.state.dashboardLoaded);

const storedIsHidden = useLocalStorage<{ hidden: boolean; expires: number } | null>(STORAGE_KEY, null, { serializer: StorageSerializers.object });

function closeNotificationsBar() {
    isVisible.value = false;

    const expires = addDays(new Date(), 1).getTime(); // 1 day

    storedIsHidden.value = { hidden: true, expires };
}

function isNotificationsBarVisible() {
    if (storedIsHidden.value?.expires && storedIsHidden.value.expires <= Date.now()) {
        storedIsHidden.value = null;

        return false;
    }

    return storedIsHidden.value?.hidden !== true;
}

async function handleNavigation() {
    try {
        await queryClient.invalidateQueries({ queryKey: queryKeys.notifications });

        router.push({ name: 'NotificationsIndex', query: { statuses: NotificationStatusType.REQUIRES_ACTION } });
    } catch (err) {
        //
    }
}

watch(
    [() => isDashboardLoaded.value, () => unhandledNotificationsCount.value],
    ([newIsDashboardLoaded, newUnhandledNotificationsCount]) => {
        if (newIsDashboardLoaded && newUnhandledNotificationsCount > 0 && isNotificationsBarVisible()) {
            isVisible.value = true;
        } else {
            isVisible.value = false;
        }
    },
    { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(ExpandCollapseTransition, null, {
    default: _withCtx(() => [
      (isVisible.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Icon, {
                src: _unref(IconSource).AttentionIcon,
                class: "w-6 h-6 mr-1.5",
                "aria-hidden": "true"
              }, null, 8, ["src"]),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('layouts.dashboard.notificationsBar.notificationText').replace('%VALUE%', unhandledNotificationsCount.value.toString())), 1),
              _createElementVNode("button", {
                class: "font-semibold underline underline-offset-4 hover:opacity-80 transition-opacity",
                onClick: _withModifiers(handleNavigation, ["prevent"])
              }, _toDisplayString(_ctx.$t('layouts.dashboard.notificationsBar.buttons.reactNow')), 1),
              _createElementVNode("button", {
                class: "flex items-center justify-center absolute right-4 h-8 w-8 rounded-full hover:bg-warning-200 transition-colors",
                onClick: closeNotificationsBar
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('layouts.dashboard.notificationsBar.buttons.close')), 1),
                _createVNode(_unref(X), {
                  class: "w-6 h-6",
                  "aria-hidden": "true"
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})