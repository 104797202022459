import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex mx-auto space-x-4" }
const _hoisted_5 = { class: "underline" }
const _hoisted_6 = { class: "py-6 px-8" }
const _hoisted_7 = { class: "text-center pb-4" }
const _hoisted_8 = { class: "font-semibold text-xl" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "relative inset-0 absolute pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatPickrWrapper = _resolveComponent("FlatPickrWrapper")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropDown, {
      expand: _ctx.expandPicker,
      "onUpdate:expand": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.expandPicker) = $event)),
      "dropdown-class": "w-full -mt-4 flex flex-col",
      "dropdown-content-style": 'overflow-y: visible !important'
    }, {
      "dropdown-content": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.showCalendar)
            ? (_openBlock(), _createBlock(_component_FlatPickrWrapper, {
                key: 0,
                value: _ctx.computedValue.start,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue.start) = $event)),
                show: true,
                disabled: false,
                config: _ctx.flatpickrConfig,
                mode: "single",
                inline: ""
              }, null, 8, ["value", "config"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Modal, {
            "close-esc-event": "keydown",
            "close-button": false,
            "modal-class": "w-128 m-auto border-0",
            "mask-class": "grid"
          }, {
            toggle: _withCtx((scope) => [
              _createElementVNode("button", {
                class: "bg-primary-500 flex w-full space-x-4 rounded-b-md py-3 px-4",
                type: "button",
                onClick: scope.open
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Icon, {
                    class: "h-4 my-auto",
                    src: _ctx.IconSource.RefreshActivities
                  }, null, 8, ["src"]),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.openRecurrenceButtonLabel')), 1)
                ])
              ], 8, _hoisted_3)
            ]),
            header: _withCtx((scope) => [
              _createVNode(_component_CloseModalButton, {
                onClose: scope.close
              }, null, 8, ["onClose"])
            ]),
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.header')), 1)
                ]),
                _createVNode(_component_CustomSelect, {
                  value: _ctx.computedValue.type,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedValue.type) = $event)),
                  items: _ctx.frequencySelectItems,
                  required: "",
                  "item-value": "id",
                  "allow-empty": false,
                  "dropdown-height-class": "max-h-84",
                  "item-text": "name",
                  rules: [],
                  label: _ctx.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.typeFieldLabel'),
                  "show-required-indication": false,
                  "show-hint": false
                }, null, 8, ["value", "items", "label"]),
                _createVNode(_component_ExpandCollapseTransition, null, {
                  default: _withCtx(() => [
                    (_ctx.computedValue?.type === 'weekly' || _ctx.computedValue?.type === 'daily')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_CustomSelect, {
                            value: _ctx.intervalSelectValue,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.intervalSelectValue) = $event)),
                            items: _ctx.intervalSelectItems,
                            required: "",
                            "item-value": "id",
                            "allow-empty": false,
                            "dropdown-height-class": "max-h-84",
                            "item-text": "name",
                            rules: [],
                            label: _ctx.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervalFieldLabel'),
                            "show-required-indication": false,
                            "show-hint": false
                          }, null, 8, ["value", "items", "label"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ExpandCollapseTransition, null, {
                  default: _withCtx(() => [
                    (_ctx.computedValue?.type === 'weekly')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_CustomMultiSelect, {
                            value: _ctx.daysOfWeekMultiSelectValue,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.daysOfWeekMultiSelectValue) = $event)),
                            items: _ctx.daysOfWeekMultiSelectItems,
                            label: _ctx.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeekFieldLabel'),
                            "item-text": "name",
                            "item-value": "id",
                            "allow-empty": false,
                            "show-required-indication": false
                          }, null, 8, ["value", "items", "label"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ExpandCollapseTransition, null, {
                  default: _withCtx(() => [
                    (_ctx.computedValue?.type === 'weekly' || _ctx.computedValue?.type === 'daily')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_DatePicker, {
                            value: _ctx.computedValue.end,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedValue.end) = $event)),
                            min: _ctx.computedValue.start,
                            max: _ctx.max,
                            label: _ctx.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.endDateFieldlabel')
                          }, null, 8, ["value", "min", "max", "label"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_CustomInput, {
          ref: "input-field",
          value: _ctx.inputValue,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inputValue) = $event)),
          required: _ctx.required,
          "show-required-indication": _ctx.required,
          rules: [],
          "input-type": "text",
          label: _ctx.label,
          details: _ctx.details,
          disabled: _ctx.disabled,
          readonly: "",
          "add-input-class": _ctx.inputValueFontSize,
          cursor: "cursor-pointer",
          onSubmit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('submit'))),
          onFocus: _cache[7] || (_cache[7] = ($event: any) => (_ctx.expandPicker = true)),
          onKeydown: _withKeys(_withModifiers(_ctx.onEscape, ["stop"]), ["esc"])
        }, null, 8, ["value", "required", "show-required-indication", "label", "details", "disabled", "add-input-class", "onKeydown"]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("span", {
            class: _normalizeClass([[{ 'cursor-default opacity-40': _ctx.disabled }, _ctx.details ? '-mt-3.5' : '-mt-2'], "absolute h-full top-0 right-5 grid content-center"]),
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$refs['input-field'].focusInput()))
          }, [
            _createVNode(_component_Icon, {
              class: "m-auto",
              src: _ctx.IconSource.DatePicker
            }, null, 8, ["src"])
          ], 2)
        ])
      ]),
      _: 1
    }, 8, ["expand"])
  ]))
}