import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "m-auto pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    disabled: _ctx.disabled,
    class: _normalizeClass([[_ctx.buttonColors, _ctx.cursor], "rounded-lg py-2 px-3 flex transition-colors"])
  }, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_Icon, {
        src: _ctx.disabled ? _ctx.IconSource.DeleteWhite : _ctx.IconSource.DeleteRed
      }, null, 8, ["src"])
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.textClass)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 10, _hoisted_1))
}