import resolveConfig from 'tailwindcss/resolveConfig';
import { TailwindValuesColor } from 'tailwindcss/tailwind-config';
// @ts-ignore-next-line
import * as tailwindConfig from '@/../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

type extendColors = {
    primary: {
        300: string;
        500: string;
        700: string;
        800: string;
    };
    pigs: string;
    deaths: string;
    feed: {
        400: string;
        600: string;
    };
    slaughters: string;
    status: string;
    transfer: string;
    batch: string;
    'dark-gray': {
        100: string;
        200: string;
        600: string;
        800: string;
    };
    health: string;
};

export default fullConfig.theme.colors as TailwindValuesColor & extendColors;
