<template>
    <div class="space-y-2">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.feed.mixes.newMixModal.compoundMixProperties.header') }}</h2>
        <div class="justify-center flex flex-col">
            <div class="flex justify-center my-4">
                <AutoComplete
                    v-model:search="autoCompleteSearch"
                    class="w-72"
                    :items="registerItems"
                    item-text="material_text"
                    item-value="id"
                    :label="$t('views.feed.mixes.newMixModal.compoundMixProperties.autoCompleteFieldLabel')"
                    @item-clicked="onRegisterItemClicked"
                ></AutoComplete>
            </div>
            <div class="space-y-4">
                <table class="border-collapse mx-auto w-full">
                    <thead>
                        <tr class="px-6 my-2 rounded text-dark-gray-800 text-opacity-50">
                            <th>{{ feedUnitHeaderText }}</th>
                            <th>{{ $t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.crude_protein') }}</th>
                            <th>{{ $t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.phosphorus') }}</th>
                            <th>{{ $t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.price') }}</th>
                            <th>{{ $t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.surcharge') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="my-auto py-2 pl-2 pr-1 bg-feed-600 bg-opacity-60 rounded-l-lg">
                                <SimpleInput
                                    v-model:value="computedValue.wgt_avg_fu"
                                    class="w-full"
                                    type="number"
                                    step="any"
                                    :class="{ 'text-gray-400': chosenRegisterItem && typeof chosenRegisterItem.fu === 'number' }"
                                    :readonly="chosenRegisterItem && typeof chosenRegisterItem.fu === 'number'"
                                ></SimpleInput>
                            </td>
                            <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                <SimpleInput
                                    v-model:value="computedValue.wgt_avg_crude_protein"
                                    class="w-full"
                                    type="number"
                                    step="any"
                                    :class="{ 'text-gray-400': chosenRegisterItem && typeof chosenRegisterItem.crude_protein === 'number' }"
                                    :readonly="chosenRegisterItem && typeof chosenRegisterItem.crude_protein === 'number'"
                                ></SimpleInput>
                            </td>
                            <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                <SimpleInput
                                    v-model:value="computedValue.wgt_avg_phosphorus"
                                    class="w-full"
                                    type="number"
                                    step="any"
                                    :class="{ 'text-gray-400': chosenRegisterItem && typeof chosenRegisterItem.phosphorus === 'number' }"
                                    :readonly="chosenRegisterItem && typeof chosenRegisterItem.phosphorus === 'number'"
                                ></SimpleInput>
                            </td>
                            <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                <SimpleInput
                                    v-model:value="computedValue.prices[0].price"
                                    class="w-full"
                                    type="number"
                                    step="any"
                                    :class="{ 'text-gray-400': chosenRegisterItem && typeof chosenRegisterItem.price === 'number' }"
                                    :readonly="chosenRegisterItem && typeof chosenRegisterItem.price === 'number'"
                                ></SimpleInput>
                            </td>
                            <td class="my-auto py-2 pr-2 bg-feed-600 bg-opacity-60 rounded-r-lg">
                                <SimpleInput v-model:value="computedValue.surcharge" class="w-full" type="number" step="any"></SimpleInput>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex justify-end my-4">
                    <div class="w-60">
                        <CustomButton :disabled="disableButtons" :disable="!validForm" @click="onSubmitClicked">
                            {{ $t('views.feed.mixes.newMixModal.compoundMixProperties.confirmButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import set from 'lodash-es/set';
import AutoComplete from '@/components/ui/AutoComplete.vue';
import { store } from '@/plugins/store';
import { FeedUnitType, MixForm, MixRegisterItem } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import SimpleInput from '@/components/ui/SimpleInput.vue';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { AutoComplete, CustomButton, SimpleInput },
    props: {
        value: {
            type: Object as () => Form<MixForm> & MixForm,
            required: true,
        },
    },
    data() {
        return {
            chosenRegisterItem: null as null | MixRegisterItem,
            autoCompleteSearch: '',
            disableButtons: false,
        };
    },
    computed: {
        computedValue: {
            get(): Form<MixForm> & MixForm {
                return this.value;
            },
            set(newValue: any) {
                this.$emit('update:value', newValue);
            },
        },
        feedUnitHeaderText() {
            return store.state.user?.energy_unit === FeedUnitType.Mj
                ? this.$t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.mj')
                : this.$t('views.feed.mixes.newMixModal.compoundMixProperties.table.headers.fu');
        },
        registerItems(): MixRegisterItem[] {
            return store.state.mixRegisterItems;
        },
        validForm(): boolean {
            return (
                [
                    {
                        formKey: 'wgt_avg_fu',
                        requiredType: 'number',
                    },
                    {
                        formKey: 'wgt_avg_crude_protein',
                        requiredType: 'number',
                    },
                    {
                        formKey: 'surcharge',
                        requiredType: 'number',
                    },
                    /* eslint-disable valid-typeof */
                ].every((formAttribute) => typeof this.computedValue[formAttribute.formKey] === formAttribute.requiredType) &&
                this.computedValue.prices &&
                this.computedValue.prices.length === 1 &&
                typeof this.computedValue.prices[0].price === 'number'
            );
        },
    },
    methods: {
        async onSubmitClicked() {
            if (!this.validForm) {
                return;
            }
            this.disableButtons = true;
            this.setValidFrom();
            await store.dispatch(ActionType.CreateFeedMix, { form: this.computedValue });
            store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
            store.dispatch(ActionType.GetMixRegisterItems, { options: { ignoreCache: true } });
            if (!this.computedValue.errors.hasErrors()) {
                this.$emit('submit');
            }
        },
        /* CompoundMixes always get their creation date as their initial TimeStamp */
        setValidFrom() {
            Object.assign(this.computedValue.prices[0], { valid_from: new Date().toISOString() });
        },
        onRegisterItemClicked(registerItem: MixRegisterItem) {
            this.chosenRegisterItem = registerItem;
            (
                [
                    { registerKey: 'fu', formKey: 'wgt_avg_fu' },
                    { registerKey: 'crude_protein', formKey: 'wgt_avg_crude_protein' },
                    { registerKey: 'phosphorus', formKey: 'wgt_avg_phosphorus' },
                    { registerKey: 'price', formKey: 'prices[0].price' },
                ] as { registerKey: keyof MixRegisterItem; formKey: string }[]
            ).forEach((key) => {
                const registerValue = registerItem[key.registerKey];
                if (registerValue) {
                    set(this.computedValue, key.formKey, registerValue);
                }
            });
            this.autoCompleteSearch = registerItem.material_text;
            this.computedValue.register = registerItem;
        },
    },
});
</script>
