import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white relative pt-4 shadow-md rounded-lg px-2 w-full max-w-full"
}
const _hoisted_2 = {
  key: 1,
  class: "absolute top-4 right-4 z-10"
}
const _hoisted_3 = {
  key: 1,
  class: "bg-white relative shadow-md rounded-lg w-full max-w-full overflow-hidden"
}
const _hoisted_4 = {
  key: 2,
  class: "w-full max-w-full space-y-1 mt-6 mb-2"
}
const _hoisted_5 = { class: "font-bold text-lg leading-tight" }
const _hoisted_6 = {
  key: 0,
  class: "text-gray-500 leading-tight"
}
const _hoisted_7 = {
  key: 1,
  class: "text-sm text-gray-500"
}
const _hoisted_8 = {
  key: 0,
  class: "absolute inset-0 z-30 flex items-center justify-center bg-white/70"
}

import * as Highcharts from 'highcharts';
import { ref, watch } from 'vue';
import { DashboardItem, DashboardBurgerMenuOption, IconSource } from '@/types';
import ChartMultiSelect from '@/components/ui/ChartMultiSelect.vue';
import EmptyChartState from '@/components/charts/EmptyChartState.vue';
import GenericChart from '@/components/charts/GenericChart.vue';
import GenericTable from '@/components/common/GenericTable.vue';
import Spinner from '@/components/ui/Spinner.vue';
import HelpWidgetButton from '@/components/common/helpWidget/HelpWidgetButton.vue';

interface Props {
    item: DashboardItem;
    loading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericDashboardElement',
  props: {
    item: {},
    loading: { type: Boolean, default: false }
  },
  emits: ["update:chart", "update:item", "update:options"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const selectedOptions = ref(props.item.burger_menu?.options.filter((option) => option.is_applied) || []);

watch(
    () => props.item.burger_menu,
    (value) => {
        selectedOptions.value = value?.options.filter((option) => option.is_applied) || [];
    }
);

function onOptionsMenuClosing() {
    let hasChanged = (props.item.burger_menu?.options.filter((option) => option.is_applied) || []).length !== selectedOptions.value.length;

    for (const option of selectedOptions.value) {
        const propOption = props.item.burger_menu?.options.filter((o) => o.is_applied).find((o) => o.query_parameter === option.query_parameter);

        if (!propOption) {
            hasChanged = true;
        }
    }

    emit('update:options', {
        options: selectedOptions.value,
        hasChanged,
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-stretch relative", { 'col-span-2': _ctx.item.width === 2 }])
  }, [
    (_ctx.item.type === 'chart' && _ctx.item.chart_options)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.item.help_widget_item)
            ? (_openBlock(), _createBlock(HelpWidgetButton, {
                key: 0,
                item: _ctx.item.help_widget_item,
                "icon-size-class": "size-5",
                class: _normalizeClass(["absolute top-[18px] z-10", {
                    'right-[94px]': _ctx.item.display_magnifier && _ctx.item.burger_menu?.is_menu_applied === true,
                    'right-[56px]': (!_ctx.item.display_magnifier && _ctx.item.burger_menu?.is_menu_applied === true) || (_ctx.item.display_magnifier && !_ctx.item.burger_menu?.is_menu_applied),
                    'right-4': !_ctx.item.display_magnifier && !_ctx.item.burger_menu?.is_menu_applied,
                }])
              }, null, 8, ["item", "class"]))
            : _createCommentVNode("", true),
          (_ctx.item.burger_menu?.is_menu_applied && _ctx.item.burger_menu?.options?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(ChartMultiSelect, {
                  value: selectedOptions.value,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((selectedOptions).value = $event)),
                  items: _ctx.item.burger_menu.options,
                  disabled: _ctx.loading,
                  "item-text": "name",
                  "item-value": "query_parameter",
                  onClosing: onOptionsMenuClosing
                }, null, 8, ["value", "items", "disabled"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(EmptyChartState, {
            options: _ctx.item.chart_options,
            class: "w-full grid"
          }, {
            default: _withCtx(() => [
              _createVNode(GenericChart, {
                "destroy-on-update": "",
                options: _ctx.item.chart_options,
                "has-options": _ctx.item.burger_menu?.is_menu_applied === true,
                expandable: _ctx.item.display_magnifier,
                onReady: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:chart', $event))),
                onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:chart', $event)))
              }, null, 8, ["options", "has-options", "expandable"])
            ]),
            _: 1
          }, 8, ["options"])
        ]))
      : (_ctx.item.type === 'table' && _ctx.item.table)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(GenericTable, {
              "table-data": _ctx.item.table,
              "help-link": _ctx.item.help_widget_item,
              narrow: ""
            }, null, 8, ["table-data", "help-link"])
          ]))
        : (_ctx.item.type === 'text' && _ctx.item.text_content)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.text_content.title), 1),
              (_ctx.item.text_content.subtitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.item.text_content.subtitle), 1))
                : _createCommentVNode("", true),
              (_ctx.item.text_content.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.item.text_content.description), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade-fast",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(Spinner, { class: "h-8 w-8" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}
}

})