<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.settings.cookies.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <MainViewCard class="p-4">
            <div ref="script"></div>
        </MainViewCard>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard },
    mounted() {
        if (process.env.VUE_APP_COOKIE_BOT_DOMAIN_ID) {
            const script = document.createElement('script');
            script.setAttribute('src', `https://consent.cookiebot.com/${process.env.VUE_APP_COOKIE_BOT_DOMAIN_ID}/cd.js`);
            (this.$refs.script as HTMLDivElement).appendChild(script);
        }
    },
});
</script>
