<template>
    <div class="space-y-8">
        <MainViewCard v-for="directoryFarm of filteredDirectoryFarms" :key="directoryFarm.id" class="pt-2">
            <div class="flex mt-2 mb-4">
                <div class="flex ml-6 space-x-2 flex-1">
                    <Icon class="h-6.5 my-auto" :src="IconSource.FarmsPrimary"></Icon>
                    <span class="text-lg my-auto pt-2 font-medium">{{ directoryFarm.name }}</span>
                </div>
                <span class="flex flex-1 justify-end mr-6">
                    <SettingsFormModal
                        v-if="createSettingsEvent && canManageDirectory"
                        :initial-state="createInitialState"
                        :step-config="[
                            {},
                            {},
                            {
                                rejectable: false,
                                submitActionType: putFormActionType,
                            },
                            {},
                        ]"
                        @closing="onModalClosing"
                    >
                        <template #default="{ open }">
                            <AddButton text-class="font-medium" @click="onCreateBarnClicked(directoryFarm, open)">
                                {{ $t('views.settings.locations.barns.newBarnButtonLabel') }}
                            </AddButton>
                        </template>
                    </SettingsFormModal>
                </span>
            </div>
            <CustomTable :headers="headers" :items="directoryFarm.barns">
                <template #header_actions>
                    <th class="w-8"></th>
                </template>
                <template #actions="{ item }">
                    <td class="w-8 my-auto">
                        <span class="flex justify-end">
                            <SettingsFormModal
                                v-if="editSettingsEvent && canManageDirectory"
                                :event="editSettingsEvent"
                                :initial-state="editInitialState"
                                :step-config="[
                                    {},
                                    {},
                                    {
                                        rejectable: false,
                                        submitActionType: putFormActionType,
                                    },
                                    {},
                                ]"
                                @closing="onModalClosing"
                            >
                                <template #default="{ open }">
                                    <button type="button" class="p-2 -mr-2" @click="onEditBarnClicked(directoryFarm, item, open)">
                                        <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                    </button>
                                </template>
                            </SettingsFormModal>
                        </span>
                    </td>
                </template>
            </CustomTable>
        </MainViewCard>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Barn, TableHeader, SettingEvent, SettingEventType, DirectoryFarm, DirectoryBarn } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import AddButton from '@/components/ui/AddButton.vue';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';
import useGetItemProperty from '@/components/common/composables/useGetItemProperty';
import useLocationSettingEvents from '@/views/dashboard/settings/locations/useLocationSettingEvents';

export default defineComponent({
    components: { MainViewCard, CustomTable, AddButton, SettingsFormModal },
    props: {
        canManageDirectory: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: '',
        },
    },
    setup() {
        return useLocationSettingEvents(
            { event: null as null | SettingEvent, form: null, farm: null as null | DirectoryFarm, confirmed: false },
            { event: null as null | SettingEvent, form: null, farm: null as null | DirectoryFarm, location: null as null | Barn, confirmed: false },
            SettingEventType.barnCreate,
            SettingEventType.barnEdit,
            ActionType.GetDirectoryBarns
        );
    },
    computed: {
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.settings.locations.barns.headers.name'),
                    value: 'name',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.settings.locations.barns.headers.pigCapacity'),
                    value: 'pig_capacity',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.settings.locations.barns.headers.animalGroup'),
                    value: 'animal_group',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                },
            ];
        },
        directoryFarms(): DirectoryFarm[] {
            return store.getters.getDirectoryFarms(true, false) as DirectoryFarm[];
        },
        filteredDirectoryFarms(): DirectoryFarm[] {
            const searchableHeaders = this.headers.filter((header) => header.searchable);
            const filteredDirectoryFarms: DirectoryFarm[] = [];
            if (this.$props.search.length === 0) {
                return this.directoryFarms;
            }

            this.directoryFarms.forEach((currentDirectoryFarm) => {
                if (currentDirectoryFarm.name.toLowerCase().trim().includes(this.$props.search.toLowerCase().trim())) {
                    filteredDirectoryFarms.push(currentDirectoryFarm);
                } else if (currentDirectoryFarm.barns) {
                    const barnsMatchingSearch = currentDirectoryFarm.barns.filter((currentDirectoryBarn) =>
                        Boolean(
                            searchableHeaders.find((currentSearchableHeader) =>
                                currentSearchableHeader.searchComparison
                                    ? currentSearchableHeader.searchComparison(currentDirectoryBarn, this.$props.search)
                                    : `${this.getItemProperty(currentSearchableHeader, currentDirectoryBarn)}`.toLowerCase().trim().includes(this.search.toLowerCase().trim())
                            )
                        )
                    );

                    if (barnsMatchingSearch.length) {
                        filteredDirectoryFarms.push({ ...currentDirectoryFarm, barns: barnsMatchingSearch });
                    }
                }
            });

            return filteredDirectoryFarms;
        },
    },
    methods: {
        onCreateBarnClicked(farm: DirectoryFarm, open: CallableFunction) {
            this.createInitialState.farm = farm;
            open();
        },
        onEditBarnClicked(farm: DirectoryFarm, barn: Barn, open: CallableFunction) {
            this.editInitialState.farm = farm;
            this.editInitialState.location = barn;
            open();
        },
        getItemProperty: useGetItemProperty,
    },
});
</script>
