import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center" }
const _hoisted_2 = { class: "border-white border absolute bottom-0 right-0 bg-green-600 p-1 rounded-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.iconContainerClass, "relative"])
    }, [
      _createVNode(_component_Icon, {
        class: _normalizeClass(_ctx.iconClass),
        src: _ctx.iconSource
      }, null, 8, ["class", "src"]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Icon, {
              class: "m-auto w-3 h-3",
              src: _ctx.IconSource.Check
            }, null, 8, ["src"])
          ], 512), [
            [_vShow, _ctx.isFinalStep]
          ])
        ]),
        _: 1
      })
    ], 2)
  ]))
}