import * as nprogress from 'nprogress';
import { ActionAugments } from '@/plugins/store/actions';
import { MutationType } from '@/plugins/store/mutations';
import ApiClient from './ApiClient';
import { RequestOptions } from '@/types';

type AxiosMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';
export default class ApiRequest {
    static ongoingRequests: string[] = [];

    static ongoingRequestsWithLoadingIndicator: number = 0;

    private throttleKey: undefined | string | null;

    private store: undefined | ActionAugments;

    private options: undefined | RequestOptions;

    private storeAccessors: undefined | { currentState?: any; mutationType: MutationType; stateKey?: string };

    constructor(options?: RequestOptions) {
        this.options = options;
    }

    connect(store: ActionAugments, storeAccessors: { currentState?: any; mutationType: MutationType; stateKey?: string }) {
        this.store = store;
        this.storeAccessors = storeAccessors;
        return this;
    }

    throttle(key: string) {
        this.throttleKey = key;
        return this;
    }

    async send(method: AxiosMethod, url: string, payload?: any) {
        await this.useThrottle(method);
        let response = method === 'get' ? this.useCached() : null;
        try {
            if (!response) {
                this.useLoadingIndicator();
                response = await ApiClient.send(method, url, this.options || {}, payload);
                if (this.options?.cacheResponse !== false) {
                    this.cacheReponse(response);
                }
                this.clearLoadingIndicator();
            }
        } catch (error) {
            this.clearLoadingIndicator();
            throw error;
        } finally {
            this.clearThrottle();
        }
        return response;
    }

    private clearThrottle() {
        if (this.throttleKey) {
            ApiRequest.ongoingRequests.splice(ApiRequest.ongoingRequests.indexOf(this.throttleKey), 1);
        }
    }

    /* If several requests are made with the same throttleKey, subsequent requests will halt until the 1st resolves */
    private async useThrottle(method: AxiosMethod): Promise<boolean> {
        if (method !== 'get' || !this.throttleKey) {
            return true;
        }

        while (ApiRequest.ongoingRequests.includes(this.throttleKey)) await new Promise((resolve) => setTimeout(resolve, 100));
        ApiRequest.ongoingRequests.push(this.throttleKey);
        return true;
    }

    private useCached() {
        if (this.options?.ignoreCache || !this.storeAccessors?.stateKey) {
            return null;
        }

        return !ApiRequest.isEmpty((this.store as any).state[this.storeAccessors?.stateKey]) ? (this.store as any).state[this.storeAccessors?.stateKey] : null;
    }

    private static isEmpty(state: any) {
        return state === null || state?.length === 0;
    }

    private cacheReponse(response: any) {
        if (this.store && this.storeAccessors?.mutationType) {
            this.store.commit(this.storeAccessors?.mutationType, response);
        }
    }

    private useLoadingIndicator() {
        if (!this.options?.skipLoadingIndicator) {
            ApiRequest.ongoingRequestsWithLoadingIndicator++;
            if (ApiRequest.ongoingRequestsWithLoadingIndicator === 1) {
                nprogress.start();
            }
        }
    }

    private clearLoadingIndicator() {
        if (!this.options?.skipLoadingIndicator) {
            ApiRequest.ongoingRequestsWithLoadingIndicator--;
            if (ApiRequest.ongoingRequestsWithLoadingIndicator === 0) {
                nprogress.done();
            }
        }
    }
}
