export type ValidatableRule = {
    validate: (value: any) => boolean;
    message: string;
};

export const validatableField = {
    emits: ['update:validForm'],
    props: {
        rules: {
            type: Array as () => ValidatableRule[],
            default: [],
        },
    },
    data() {
        return {
            validInput: true,
            errorMessage: '',
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue: any) {
                const firstInvalidRule = ((this as any).$props.rules as ValidatableRule[]).find((currentRule) => !currentRule.validate(newValue));
                if (firstInvalidRule) {
                    (this as any).validInput = false;
                    (this as any).errorMessage = firstInvalidRule.message;
                } else {
                    (this as any).validInput = true;
                    (this as any).errorMessage = '';
                }
            },
        },
    },
    computed: {
        hint(): any {
            return (this as any).details || { type: 'hint', text: (this as any).errorMessage };
        },
    },
};
