<template>
    <div class="inline-flex items-center gap-2 rounded-full px-2.5 py-1" :class="colorClass">
        {{ text }}
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Notification, NotificationStatusType } from '@/types';
import { i18n } from '@/plugins/internationalization/i18n';

interface Props {
    notification?: Notification;
    type?: NotificationStatusType;
}

const props = defineProps<Props>();

const type = computed<NotificationStatusType | null>(() => {
    if (!props.notification) {
        return null;
    }

    if (props.notification.requires_action) {
        return NotificationStatusType.REQUIRES_ACTION;
    }

    if (props.notification.approved) {
        return NotificationStatusType.APPROVED;
    }

    if (props.notification.rejected) {
        return NotificationStatusType.REJECTED;
    }

    if (props.notification.read) {
        return NotificationStatusType.READ;
    }

    return NotificationStatusType.UNREAD;
});
const text = computed(() => {
    const statusType = props.type || type.value;

    switch (statusType) {
        case NotificationStatusType.REQUIRES_ACTION:
            return i18n.global.t('views.notifications.statusOptions.requiresAction');
        case NotificationStatusType.APPROVED:
            return i18n.global.t('views.notifications.statusOptions.approved');
        case NotificationStatusType.REJECTED:
            return i18n.global.t('views.notifications.statusOptions.rejected');
        case NotificationStatusType.READ:
            return i18n.global.t('views.notifications.statusOptions.read');
        default:
            return i18n.global.t('views.notifications.statusOptions.unread');
    }
});
const colorClass = computed(() => {
    const statusType = props.type || type.value;

    switch (statusType) {
        case NotificationStatusType.REQUIRES_ACTION:
            return 'bg-warning-100';
        case NotificationStatusType.APPROVED:
            return 'bg-success-100';
        case NotificationStatusType.UNREAD:
            return 'bg-sky-100';
        case NotificationStatusType.REJECTED:
            return 'bg-error-100';
        default:
            return 'bg-gray-100';
    }
});
</script>
