import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-center outline-none" }
const _hoisted_3 = { class: "h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.mixProperties.header')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomInput, {
        value: _ctx.computedValue.name,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue.name) = $event)),
        required: "",
        rules: [],
        "error-state": !_ctx.computedValue.name || _ctx.computedValue.name.length === 0,
        "show-required-indication": "",
        label: _ctx.$t('views.feed.mixes.newMixModal.mixProperties.nameFieldLabel'),
        "focus-on-render": ""
      }, null, 8, ["value", "error-state", "label"]),
      _createVNode(_component_CustomSelect, {
        value: _ctx.computedValue.type,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedValue.type) = $event)),
        prepend: _ctx.$t('views.feed.mixes.newMixModal.mixProperties.selectFieldLabel'),
        label: "",
        "return-object": "",
        items: _ctx.mixTypes,
        "item-text": "name",
        "item-value": "id",
        "show-required-indication": false,
        "allow-empty": false
      }, null, 8, ["value", "prepend", "items"]),
      _createVNode(_component_CustomMultiSelect, {
        value: _ctx.computedValue.farms,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValue.farms) = $event)),
        prepend: _ctx.$t('views.feed.mixes.newMixModal.mixProperties.farmsFieldLabel'),
        label: "",
        items: _ctx.farms,
        "item-text": "name",
        "item-value": "id",
        "show-required-indication": false
      }, null, 8, ["value", "prepend", "items"]),
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        disable: !Boolean(_ctx.computedValue.type && _ctx.computedValue.name.length),
        onClick: _ctx.onSubmitButtonClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.feed.mixes.newMixModal.mixProperties.confirmButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "disable", "onClick"])
    ])
  ]))
}