import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 13,
  class: "mb-2"
}
const _hoisted_2 = { key: 18 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomTextArea = _resolveComponent("CustomTextArea")!
  const _component_DistributionChartModal = _resolveComponent("DistributionChartModal")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!
  const _component_RadioButtonGroup = _resolveComponent("RadioButtonGroup")!
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_CustomRangeInput = _resolveComponent("CustomRangeInput")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_TimePicker = _resolveComponent("TimePicker")!
  const _component_DateTimePicker = _resolveComponent("DateTimePicker")!
  const _component_DateTimeRecurrencePicker = _resolveComponent("DateTimeRecurrencePicker")!
  const _component_DynamicFormImages = _resolveComponent("DynamicFormImages")!
  const _component_DynamicFormButton = _resolveComponent("DynamicFormButton")!
  const _component_DynamicFormLink = _resolveComponent("DynamicFormLink")!
  const _component_DynamicFormQualityDescriptor = _resolveComponent("DynamicFormQualityDescriptor")!
  const _component_GenericChart = _resolveComponent("GenericChart")!

  return (_ctx.element)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.colSpan)
      }, [
        (_ctx.element.type === 'textbox' && _ctx.computedElement.type_settings.input_type === 'string')
          ? (_openBlock(), _createBlock(_component_CustomInput, {
              key: 0,
              ref: "dynamic-element",
              value: _ctx.computedElement.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedElement.value) = $event)),
              label: _ctx.computedElement.label,
              disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
              "input-type": "text",
              rules: _ctx.rules,
              details: _ctx.preconditionHint || _ctx.details,
              "show-required-indication": _ctx.computedElement.required,
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input')))
            }, null, 8, ["value", "label", "disabled", "rules", "details", "show-required-indication"]))
          : (_ctx.element.type === 'textbox' && (_ctx.computedElement.type_settings.input_type === 'int' || _ctx.computedElement.type_settings.input_type === 'double'))
            ? (_openBlock(), _createBlock(_component_CustomInput, {
                key: 1,
                ref: "dynamic-element",
                value: _ctx.computedElement.value,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                label: _ctx.computedElement.label,
                step: _ctx.computedElement.type_settings.input_type === 'int' ? '1' : '0.1',
                disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                "input-type": "number",
                rules: _ctx.rules,
                details: _ctx.preconditionHint || _ctx.details,
                "show-required-indication": _ctx.computedElement.required,
                onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('input')))
              }, null, 8, ["value", "label", "step", "disabled", "rules", "details", "show-required-indication"]))
            : (_ctx.element.type === 'textarea' && _ctx.computedElement.type_settings.input_type === 'string')
              ? (_openBlock(), _createBlock(_component_CustomTextArea, {
                  key: 2,
                  ref: "dynamic-element",
                  value: _ctx.computedElement.value,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                  label: _ctx.computedElement.label,
                  disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                  rules: _ctx.rules,
                  details: _ctx.preconditionHint || _ctx.details,
                  "show-required-indication": _ctx.computedElement.required,
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('input')))
                }, null, 8, ["value", "label", "disabled", "rules", "details", "show-required-indication"]))
              : (_ctx.element.type === 'chartspread' && (_ctx.computedElement.type_settings.input_type === 'int' || _ctx.computedElement.type_settings.input_type === 'double'))
                ? (_openBlock(), _createBlock(_component_DistributionChartModal, {
                    key: 3,
                    ref: "dynamic-element",
                    value: _ctx.computedElement.value,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                    label: _ctx.computedElement.label,
                    amount: _ctx.preconditionValues[0],
                    average: _ctx.preconditionValues[1],
                    min: 0.05,
                    max: 0.3,
                    default: 0.1,
                    disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                    details: _ctx.preconditionHint || _ctx.details,
                    onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('input')))
                  }, null, 8, ["value", "label", "amount", "average", "disabled", "details"]))
                : (_ctx.computedElement.type_settings.input_type === 'keyvaluepair' && _ctx.computedElement.type === 'dropdown')
                  ? (_openBlock(), _createBlock(_component_CustomSelect, {
                      key: 4,
                      ref: "dynamic-element",
                      value: _ctx.computedElement.value,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                      items: _ctx.computedElement.type_settings.values,
                      "item-text": "value",
                      disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                      "return-object": "",
                      rules: _ctx.rules,
                      "item-value": "id",
                      required: "",
                      "allow-empty": !_ctx.computedElement.required,
                      label: _ctx.computedElement.label,
                      details: _ctx.preconditionHint || _ctx.details,
                      "show-required-indication": _ctx.computedElement.required,
                      onInput: _ctx.onSelectInput
                    }, null, 8, ["value", "items", "disabled", "rules", "allow-empty", "label", "details", "show-required-indication", "onInput"]))
                  : (_ctx.computedElement.type_settings.input_type === 'keyvaluepairlist' && _ctx.computedElement.type === 'multiselect')
                    ? (_openBlock(), _createBlock(_component_CustomMultiSelect, {
                        key: 5,
                        ref: "dynamic-element",
                        value: _ctx.computedElement.value,
                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                        items: _ctx.computedElement.type_settings.values,
                        "item-text": "value",
                        disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                        rules: _ctx.rules,
                        "item-value": "id",
                        required: "",
                        label: _ctx.computedElement.label,
                        "show-required-indication": _ctx.computedElement.required,
                        details: _ctx.preconditionHint || _ctx.details,
                        onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('input')))
                      }, null, 8, ["value", "items", "disabled", "rules", "label", "show-required-indication", "details"]))
                    : (_ctx.computedElement.type_settings.input_type === 'keyvaluepair' && _ctx.computedElement.type === 'radiobutton')
                      ? (_openBlock(), _createBlock(_component_RadioButtonGroup, {
                          key: 6,
                          ref: "dynamic-element",
                          value: _ctx.computedElement.value,
                          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                          items: _ctx.computedElement.type_settings.values,
                          "item-text": "value",
                          disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                          "return-object": "",
                          rules: _ctx.rules,
                          "item-value": "id",
                          label: _ctx.computedElement.label,
                          "radio-button-group": `${_ctx.sectionId}_${_ctx.computedElement.id}`,
                          details: _ctx.preconditionHint || _ctx.details,
                          onInput: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('input')))
                        }, null, 8, ["value", "items", "disabled", "rules", "label", "radio-button-group", "details"]))
                      : (_ctx.computedElement.type_settings.input_type === 'bool' && _ctx.computedElement.type === 'checkbox')
                        ? (_openBlock(), _createBlock(_component_FormCheckbox, {
                            key: 7,
                            value: _ctx.computedElement.value,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                            disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                            label: _ctx.computedElement.label
                          }, null, 8, ["value", "disabled", "label"]))
                        : (_ctx.computedElement && _ctx.computedElement.type === 'slider')
                          ? (_openBlock(), _createBlock(_component_CustomRangeInput, {
                              key: 8,
                              value: _ctx.computedElement.value,
                              "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                              disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                              label: _ctx.computedElement.label || '',
                              min: _ctx.element.type_settings.minimum_value || 0,
                              max: _ctx.element.type_settings.maximum_value || 100,
                              step: _ctx.element.type_settings.stepsize || 1,
                              unit: _ctx.element.type_settings.unit || '',
                              class: "my-3"
                            }, null, 8, ["value", "disabled", "label", "min", "max", "step", "unit"]))
                          : (_ctx.element.type_settings.input_type === 'datetime' && _ctx.computedElement.type === 'datepicker')
                            ? (_openBlock(), _createBlock(_component_DatePicker, {
                                key: 9,
                                ref: "dynamic-element",
                                value: _ctx.computedElement.value,
                                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                                required: _ctx.computedElement.required,
                                min: _ctx.element.type_settings.minimum_value,
                                max: _ctx.element.type_settings.maximum_value,
                                disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                                label: _ctx.computedElement.label,
                                details: _ctx.preconditionHint || _ctx.details
                              }, null, 8, ["value", "required", "min", "max", "disabled", "label", "details"]))
                            : (_ctx.element.type_settings.input_type === 'datetime' && _ctx.computedElement.type === 'clockpicker')
                              ? (_openBlock(), _createBlock(_component_TimePicker, {
                                  key: 10,
                                  ref: "dynamic-element",
                                  value: _ctx.computedElement.value,
                                  "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                                  required: _ctx.computedElement.required,
                                  disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                                  label: _ctx.computedElement.label,
                                  details: _ctx.preconditionHint || _ctx.details
                                }, null, 8, ["value", "required", "disabled", "label", "details"]))
                              : (_ctx.element.type_settings.input_type === 'datetime' && _ctx.computedElement.type === 'datetimepicker')
                                ? (_openBlock(), _createBlock(_component_DateTimePicker, {
                                    key: 11,
                                    ref: "dynamic-element",
                                    value: _ctx.computedElement.value,
                                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                                    required: _ctx.computedElement.required,
                                    min: _ctx.element.type_settings.minimum_value,
                                    max: _ctx.element.type_settings.maximum_value,
                                    disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                                    label: _ctx.computedElement.label,
                                    details: _ctx.preconditionHint || _ctx.details
                                  }, null, 8, ["value", "required", "min", "max", "disabled", "label", "details"]))
                                : (_ctx.element.type_settings.input_type === 'datetime_with_recurrence' && _ctx.computedElement.type === 'datepicker_with_recurrence')
                                  ? (_openBlock(), _createBlock(_component_DateTimeRecurrencePicker, {
                                      key: 12,
                                      ref: "dynamic-element",
                                      value: _ctx.computedElement.value,
                                      "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.computedElement.value) = $event)),
                                      required: _ctx.computedElement.required,
                                      min: _ctx.element.type_settings.minimum_value,
                                      max: _ctx.element.type_settings.maximum_value,
                                      disabled: _ctx.computedElement.disabled || _ctx.preconditionDisabled,
                                      label: _ctx.computedElement.label,
                                      details: _ctx.preconditionHint || _ctx.details
                                    }, null, 8, ["value", "required", "min", "max", "disabled", "label", "details"]))
                                  : (_ctx.element.type === 'button' && _ctx.element.type_settings.action === 'image-upload')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                        (_ctx.computedElement && _ctx.element.type_settings.values && _ctx.element.type_settings.values.length)
                                          ? (_openBlock(), _createBlock(_component_DynamicFormImages, {
                                              key: 0,
                                              element: _ctx.computedElement,
                                              "onUpdate:element": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.computedElement) = $event)),
                                              "assignment-id": _ctx.assignmentId
                                            }, null, 8, ["element", "assignment-id"]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : (_ctx.element.type === 'button')
                                      ? (_openBlock(), _createBlock(_component_DynamicFormButton, {
                                          key: 14,
                                          ref: "dynamic-element",
                                          element: _ctx.element,
                                          disabled: _ctx.computedElement.disabled,
                                          onCloneSection: _cache[20] || (_cache[20] = (cloneFormSectionOptions) => _ctx.$emit('clone-section', cloneFormSectionOptions)),
                                          onBackendRetrieval: _cache[21] || (_cache[21] = (buttonElement) => _ctx.$emit('backend-retrieval', buttonElement))
                                        }, null, 8, ["element", "disabled"]))
                                      : (_ctx.element.type === 'linkbutton')
                                        ? (_openBlock(), _createBlock(_component_DynamicFormLink, {
                                            key: 15,
                                            element: _ctx.element,
                                            disabled: _ctx.computedElement.disabled
                                          }, null, 8, ["element", "disabled"]))
                                        : (_ctx.element.type === 'qualitydescriptor')
                                          ? (_openBlock(), _createBlock(_component_DynamicFormQualityDescriptor, {
                                              key: 16,
                                              element: _ctx.element
                                            }, null, 8, ["element"]))
                                          : (_ctx.element.type === 'highchart')
                                            ? (_openBlock(), _createBlock(_component_GenericChart, {
                                                key: 17,
                                                options: _ctx.computedElement?.value?.options,
                                                class: "my-3"
                                              }, null, 8, ["options"]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString('Label:' + _ctx.element.label), 1))
      ], 2))
    : _createCommentVNode("", true)
}