<template>
    <form class="space-y-4 flex flex-col justify-center">
        <MultiStepFormHeaderIcon :is-final-step="false" :icon-source="IconSource.ButtonUserManagement"></MultiStepFormHeaderIcon>
        <div>
            <h2 class="text-2xl font-semibold text-center">
                {{ $t('views.settings.users.content.modals.permissions.header').replace('$USER_NAME', `${directoryUser.first_name} ${directoryUser.last_name}`) }}
            </h2>
        </div>
        <div class="min-w-80 mx-auto">
            <div class="flex flex-col">
                <div>
                    <CustomSelect
                        v-model:value="chosenRole"
                        :items="directoryRoles"
                        item-text="name"
                        return-object
                        :rules="[]"
                        item-value="id"
                        :allow-empty="false"
                        :label="$t('views.settings.users.content.modals.permissions.directoryRoleFieldLabel')"
                        :disabled="isLoggedInUser"
                    ></CustomSelect>
                </div>

                <div class="border-2 border-opacity-10 rounded-lg px-4 py-2">
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="px-2"></th>
                                <th v-for="directoryPermission of directoryPermissions" :key="directoryPermission.id" class="px-2">
                                    <span class="mx-auto">{{ directoryPermission.name }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="choosablePermission of choosablePermissions" :key="choosablePermission.id">
                                <td class="font-medium whitespace-nowrap">{{ choosablePermission.name }}</td>
                                <td v-for="(permission, index) of choosablePermission.permissions" :key="permission.id">
                                    <div class="flex justify-center">
                                        <CustomCheckbox :checked="permission.selected" @update:checked="(newValue) => onChoosablePermissionClicked(newValue, choosablePermission, index)"></CustomCheckbox>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="flex gap-x-4 mt-4">
                <CustomButton color-preset="secondary" @click="$emit('cancel')">
                    {{ $t('views.settings.users.content.modals.permissions.cancelButtonLabel') }}
                </CustomButton>
                <CustomButton :disabled="disableButtons" @click="onConfirmButtonClicked">
                    {{ $t('views.settings.users.content.modals.permissions.confirmButtonLabel') }}
                </CustomButton>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { Form } from '@makeabledk/vue-ui/support/http';
import { DirectoryFarm, DirectoryRole, DirectoryPermission, DirectoryUser } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import AddButton from '@/components/ui/AddButton.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';

type ChoosablePermission = {
    name: string;
    id: number;
    permissions: (DirectoryPermission & {
        selected: boolean;
    })[];
};

export default defineComponent({
    components: { CustomButton, AddButton, CustomCheckbox, CustomSelect, MultiStepFormHeaderIcon },
    emits: ['cancel', 'submit'],
    props: {
        directoryUser: {
            type: Object as () => DirectoryUser,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            chosenRole: null as null | DirectoryRole,
            choosablePermissions: [] as ChoosablePermission[],
            disableButtons: false,
        };
    },
    computed: {
        directoryFarms() {
            return store.state.directoryFarms;
        },
        directoryRoles() {
            return store.state.directoryRoles;
        },
        directoryPermissions() {
            return store.state.directoryPermissions;
        },
        isLoggedInUser(): boolean {
            return this.$props.directoryUser.id === store.state.user?.id;
        },
    },
    watch: {
        directoryUser: {
            deep: true,
            handler(newValue: DirectoryUser) {
                const directoryRole = this.directoryRoles.find((c) => c.key === newValue.settings.role.key);
                if (directoryRole) {
                    this.chosenRole = directoryRole;
                }

                this.choosablePermissions = this.directoryFarms.map((f) => ({
                    ...f,
                    permissions: this.directoryPermissions.map((permission) => ({
                        ...permission,
                        selected: Boolean(newValue.settings.farms.find((currentUserPermissionFarm) => currentUserPermissionFarm.id === f.id && currentUserPermissionFarm.permission.id === permission.id)),
                    })),
                }));
            },
        },
        directoryRoles: {
            immediate: true,
            deep: true,
            handler(newValue: DirectoryRole[]) {
                const directoryRole = newValue.find((currentDirectoryRole) => currentDirectoryRole.key === this.$props.directoryUser.settings.role.key);
                if (directoryRole) {
                    this.chosenRole = directoryRole;
                }
            },
        },
        directoryFarms: {
            immediate: true,
            deep: true,
            handler(newValue: DirectoryFarm[]) {
                this.choosablePermissions = newValue.map((directoryFarm) => ({
                    ...directoryFarm,
                    permissions: this.directoryPermissions.map((permission) => ({
                        ...permission,
                        selected: Boolean(
                            this.$props.directoryUser.settings.farms.find(
                                (currentUserPermissionFarm) => currentUserPermissionFarm.id === directoryFarm.id && currentUserPermissionFarm.permission.id === permission.id
                            )
                        ),
                    })),
                }));
            },
        },
    },
    created() {
        store.dispatch(ActionType.GetDirectoryFarms);
        store.dispatch(ActionType.GetDirectoryRoles);
        store.dispatch(ActionType.GetDirectoryPermissions);
    },
    methods: {
        async onConfirmButtonClicked() {
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            const permissions = this.choosablePermissions
                .map((currentChoosablePermission) => {
                    const checkedPermission = currentChoosablePermission.permissions.find((permission) => permission.selected);
                    if (checkedPermission) {
                        return {
                            name: currentChoosablePermission.name,
                            id: currentChoosablePermission.id,
                            permission: {
                                id: checkedPermission.id,
                                key: checkedPermission.key,
                                name: checkedPermission.name,
                            },
                        };
                    }
                    return null;
                })
                .filter((currentPermission) => currentPermission);
            const form = new Form({ role: this.chosenRole, farms: permissions });
            await store.dispatch(ActionType.PutDirectoryUserSettings, { directoryUser: this.$props.directoryUser, form: form as any });

            if (!form.errors.hasErrors()) {
                store.dispatch(ActionType.GetDirectoryUsers, { options: { ignoreCache: true } });
                if (this.isLoggedInUser) {
                    window.location.reload();
                }
                this.toast.success(this.$t('views.settings.users.content.modals.permissions.permissionsChangedSnackbarMessage'));
                this.$emit('submit');
            }
        },
        onChoosablePermissionClicked(newValue: boolean, choosablePermission: ChoosablePermission, index: number) {
            if (newValue) {
                choosablePermission.permissions.forEach((currentPermission) => {
                    currentPermission.selected = false;
                });
            }
            choosablePermission.permissions[index].selected = newValue;
        },
    },
});
</script>
