import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-8" }
const _hoisted_2 = { class: "flex mt-2 mb-4" }
const _hoisted_3 = { class: "flex ml-6 space-x-2 flex-1" }
const _hoisted_4 = { class: "text-lg my-auto pt-2 font-medium" }
const _hoisted_5 = { class: "flex flex-1 justify-end mr-6" }
const _hoisted_6 = { class: "w-8 my-auto" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_SettingsFormModal = _resolveComponent("SettingsFormModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDirectoryFarms, (directoryFarm) => {
      return (_openBlock(), _createBlock(_component_MainViewCard, {
        key: directoryFarm.id,
        class: "pt-2"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Icon, {
                class: "h-6.5 my-auto",
                src: _ctx.IconSource.FarmsPrimary
              }, null, 8, ["src"]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(directoryFarm.name), 1)
            ]),
            _createElementVNode("span", _hoisted_5, [
              (_ctx.createSettingsEvent && _ctx.canManageDirectory)
                ? (_openBlock(), _createBlock(_component_SettingsFormModal, {
                    key: 0,
                    "initial-state": _ctx.createInitialState,
                    "step-config": [
                            {},
                            {},
                            {
                                rejectable: false,
                                submitActionType: _ctx.putFormActionType,
                            },
                            {},
                        ],
                    onClosing: _ctx.onModalClosing
                  }, {
                    default: _withCtx(({ open }) => [
                      _createVNode(_component_AddButton, {
                        "text-class": "font-medium",
                        onClick: ($event: any) => (_ctx.onCreateBarnClicked(directoryFarm, open))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.locations.barns.newBarnButtonLabel')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1032, ["initial-state", "step-config", "onClosing"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_CustomTable, {
            headers: _ctx.headers,
            items: directoryFarm.barns
          }, {
            header_actions: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("th", { class: "w-8" }, null, -1)
            ])),
            actions: _withCtx(({ item }) => [
              _createElementVNode("td", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, [
                  (_ctx.editSettingsEvent && _ctx.canManageDirectory)
                    ? (_openBlock(), _createBlock(_component_SettingsFormModal, {
                        key: 0,
                        event: _ctx.editSettingsEvent,
                        "initial-state": _ctx.editInitialState,
                        "step-config": [
                                    {},
                                    {},
                                    {
                                        rejectable: false,
                                        submitActionType: _ctx.putFormActionType,
                                    },
                                    {},
                                ],
                        onClosing: _ctx.onModalClosing
                      }, {
                        default: _withCtx(({ open }) => [
                          _createElementVNode("button", {
                            type: "button",
                            class: "p-2 -mr-2",
                            onClick: ($event: any) => (_ctx.onEditBarnClicked(directoryFarm, item, open))
                          }, [
                            _createVNode(_component_Icon, {
                              class: "h-3 w-3",
                              src: _ctx.IconSource.Right
                            }, null, 8, ["src"])
                          ], 8, _hoisted_8)
                        ]),
                        _: 2
                      }, 1032, ["event", "initial-state", "step-config", "onClosing"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["headers", "items"])
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}