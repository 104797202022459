import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full space-y-4" }
const _hoisted_2 = { class: "font-semibold text-xl text-center" }
const _hoisted_3 = { class: "w-full flex justify-center h-88" }
const _hoisted_4 = { class: "flex justify-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateSelector = _resolveComponent("DateSelector")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.state.isBatchReport ? _ctx.$t('views.reports.selectDatesPage.batchHeader') : _ctx.$t('views.reports.selectDatesPage.farmsHeader')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DateSelector, { state: _ctx.computedState }, null, 8, ["state"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CustomButton, {
        class: "max-w-32",
        "color-preset": "secondary",
        onClick: _ctx.onResetClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.reports.resetButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        class: _normalizeClass(["max-w-32", { 'cursor-not-allowed': _ctx.disableNext }]),
        onClick: _ctx.onNextClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.reports.nextButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "class", "onClick"])
    ])
  ]))
}