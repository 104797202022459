<script setup lang="ts">
import { HTMLAttributes, computed } from 'vue';
import { SelectGroup, SelectGroupProps } from 'radix-vue';
import { cn } from '@/lib/utils';

const props = defineProps<SelectGroupProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>

<template>
    <SelectGroup :class="cn('w-full', props.class)" v-bind="delegatedProps">
        <slot />
    </SelectGroup>
</template>
