import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white relative rounded-lg grid pb-6 pt-10 gap-y-16 px-10 max-w-lg m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "w-128 m-auto border-0",
    "mask-class": "grid"
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "form", _normalizeProps(_guardReactiveProps(scope)))
      ])
    ]),
    _: 3
  }))
}