<template>
    <div class="space-y-4">
        <div v-for="listing of listings" :key="listing.title" class="space-y-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ listing.title }}</h4>
            <MainViewCard>
                <div class="space-y-4">
                    <div class="flex mx-6 space-x-6 pt-4 min-h-14">
                        <div class="font-medium flex-1">
                            <h4 class="text-lg">{{ listing.price_listing.title }}</h4>
                            <h5 class="opacity-60">{{ listing.price_listing.sub_title }}</h5>
                        </div>
                    </div>
                    <div class="overflow-x-auto">
                        <CustomTable :headers="listing.price_listing.headers" :items="listing.price_listing.data"></CustomTable>
                    </div>
                </div>
            </MainViewCard>
            <MainViewCard>
                <div class="space-y-4">
                    <div class="flex mx-6 space-x-6 pt-4 min-h-14">
                        <div class="font-medium flex-1">
                            <h4 class="text-lg">{{ listing.regulations.title }}</h4>
                            <h5 class="opacity-60">{{ listing.regulations.sub_title }}</h5>
                        </div>
                    </div>
                    <div class="overflow-x-auto">
                        <CustomTable :headers="listing.regulations.headers" :items="listing.regulations.data"></CustomTable>
                    </div>
                </div>
            </MainViewCard>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TableHeader } from '@/types';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { MainViewCard, CustomTable },
    computed: {
        listings() {
            return store.state.economyListings;
        },
    },
    created() {
        store.dispatch(ActionType.GetEconomyListings, { options: { ignoreCache: true } });
    },
});
</script>
