import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-8"
}
const _hoisted_2 = { class: "feed-consumption-data-tabs default-tabs" }
const _hoisted_3 = { class: "space-x-8 flex border-b border-black border-opacity-10 mb-4" }
const _hoisted_4 = { class: "flex pr-4" }
const _hoisted_5 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChart = _resolveComponent("GenericChart")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_GenericTable = _resolveComponent("GenericTable")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.feed.consumptionData.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": true
  }, {
    default: _withCtx(() => [
      (_ctx.feedConsumptionData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_MainViewCard, { class: "pt-4 pb-2 px-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_GenericChart, {
                  options: _ctx.feedConsumptionData.chart.options
                }, null, 8, ["options"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Tabs, {
                teleport: "#feed-consumption-data-tabs-target",
                "active-tab-class": "--active"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("nav", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_Tab, {
                        name: _ctx.$t('views.feed.consumptionData.content.tabs.feedUsage.title'),
                        default: true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MainViewCard, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_GenericTable, {
                                "table-data": _ctx.feedConsumptionData.feed_usage
                              }, null, 8, ["table-data"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["name"])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_Tab, {
                        name: _ctx.$t('views.feed.consumptionData.content.tabs.rawMaterialsUsage.title')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MainViewCard, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_GenericTable, {
                                "table-data": _ctx.feedConsumptionData.ingredient_usage
                              }, null, 8, ["table-data"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["name"])
                    ])
                  ])
                ]),
                _: 1
              }),
              _cache[0] || (_cache[0] = _createElementVNode("div", { id: "feed-consumption-data-tabs-target" }, null, -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["heading"]))
}