<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="m-auto border-0" mask-class="grid" @closing="onModalClose">
        <template #toggle="scope">
            <slot v-bind="scope"></slot>
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="bg-gray-100 py-6 px-8 rounded-xl space-y-4">
                <Icon class="mx-auto w-12 h-12" :src="notification.icon_url"></Icon>
                <p>{{ notification.message }}</p>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { useQueryClient } from '@tanstack/vue-query';
import { Notification } from '@/types';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { queryKeys } from '@/plugins/store/actions/queries/notifications';

interface Props {
    notification: Notification;
}

const props = defineProps<Props>();

const queryClient = useQueryClient();

async function onModalClose() {
    try {
        if (props.notification.read) {
            return;
        }

        await store.dispatch(ActionType.EditNotification, { form: { notification: props.notification, update: [{ value: true, path: '/read', op: 'replace' }] } });
        await Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.notifications }), store.dispatch(ActionType.GetMeta, { options: { ignoreCache: true, skipLoadingIndicator: true } })]);
    } catch (err) {
        //
    }
}
</script>
