import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericMultiStepModal = _resolveComponent("GenericMultiStepModal")!

  return (_openBlock(), _createBlock(_component_GenericMultiStepModal, {
    "header-icon": _ctx.IconSource.Economy,
    "is-final-step": false,
    onClosing: _ctx.onModalClose
  }, {
    default: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    component: _withCtx((scope) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentStep.component), {
        config: _ctx.currentStepConfig,
        text: _ctx.currentStepConfig.text,
        onSubmit: _ctx.next,
        onClose: scope.close
      }, null, 40, ["config", "text", "onSubmit", "onClose"]))
    ]),
    _: 3
  }, 8, ["header-icon", "onClosing"]))
}