<template>
    <div class="h-full pb-6">
        <h2 class="text-xl font-semibold text-center">{{ message }}</h2>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MultiStepFormState, SettingEvent, SettingEventType } from '@/types';

export default defineComponent({
    emits: ['close'],
    props: {
        state: {
            type: Object as () => MultiStepFormState,
            required: true,
        },
    },
    computed: {
        message(): string {
            switch ((this.$props.state.event as SettingEvent).type) {
                case SettingEventType.directoryCreate: {
                    return this.$t('global.forms.steps.submission.directoryCreateSuccess');
                }
                case SettingEventType.directoryData: {
                    return this.$t('global.forms.steps.submission.directoryEditSuccess');
                }
                case SettingEventType.farmCreate: {
                    return this.$t('global.forms.steps.submission.farmCreateSuccess');
                }
                case SettingEventType.barnCreate: {
                    return this.$t('global.forms.steps.submission.barnCreateSuccess');
                }
                case SettingEventType.farmEdit: {
                    return this.$t('global.forms.steps.submission.farmEditSuccess');
                }
                case SettingEventType.barnEdit: {
                    return this.$t('global.forms.steps.submission.barnEditSuccess');
                }
                default: {
                    return this.$t('global.forms.steps.submission.success');
                }
            }
        },
    },
    mounted() {
        setTimeout(() => this.$emit('close'), 1700);
    },
});
</script>
