<template>
    <button type="button" :disabled="disabled" :class="[buttonColors, cursor]" class="rounded-lg py-2 px-3 flex transition-colors">
        <span class="m-auto pr-2">
            <Icon :src="disabled ? IconSource.DeleteWhite : IconSource.DeleteRed"></Icon>
        </span>
        <span :class="textClass">
            <slot></slot>
        </span>
    </button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        textClass: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const buttonColors = computed(() => (props.disabled ? 'bg-gray-300 text-white' : 'bg-red-400 text-red-400 bg-opacity-25 hover:bg-opacity-35'));
        const cursor = computed(() => (props.disabled ? 'cursor-default' : 'cursor-pointer'));

        return {
            buttonColors,
            cursor,
        };
    },
});
</script>
