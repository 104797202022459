import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx } from "vue"

import { HelpWidgetRouteItem } from '@/types/HelpWidget';
import Icon from '@/components/icons/Icon.vue';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import Spinner from '@/components/ui/Spinner.vue';

interface Props {
    item: HelpWidgetRouteItem;
    loading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpWidgetRouteItem',
  props: {
    item: {},
    loading: { type: Boolean }
  },
  emits: ["select-route"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function onClick() {
    emit('select-route', props.item);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DropdownMenuItem), {
    class: "gap-x-2.5 text-base",
    onClick: onClick
  }, {
    default: _withCtx(() => [
      (_ctx.item.icon_id !== null && _ctx.item.icon_id !== undefined)
        ? (_openBlock(), _createBlock(Icon, {
            key: 0,
            src: _ctx.item.icon_id,
            class: "flex-shrink-0 size-5"
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.item.text) + " ", 1),
      _createVNode(Spinner, {
        class: _normalizeClass(["flex-shrink-0 size-5 pointer-events-none ml-auto", _ctx.loading ? 'visible' : 'invisible'])
      }, null, 8, ["class"])
    ]),
    _: 1
  }))
}
}

})