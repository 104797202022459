import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-4 flex flex-col justify-center" }
const _hoisted_2 = { class: "text-2xl font-semibold text-center" }
const _hoisted_3 = { class: "min-w-80 mx-auto" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "border-2 border-opacity-10 rounded-lg px-4 py-2" }
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = { class: "mx-auto" }
const _hoisted_8 = { class: "font-medium whitespace-nowrap" }
const _hoisted_9 = { class: "flex justify-center" }
const _hoisted_10 = { class: "flex gap-x-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createVNode(_component_MultiStepFormHeaderIcon, {
      "is-final-step": false,
      "icon-source": _ctx.IconSource.ButtonUserManagement
    }, null, 8, ["icon-source"]),
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.users.content.modals.permissions.header').replace('$USER_NAME', `${_ctx.directoryUser.first_name} ${_ctx.directoryUser.last_name}`)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createVNode(_component_CustomSelect, {
            value: _ctx.chosenRole,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosenRole) = $event)),
            items: _ctx.directoryRoles,
            "item-text": "name",
            "return-object": "",
            rules: [],
            "item-value": "id",
            "allow-empty": false,
            label: _ctx.$t('views.settings.users.content.modals.permissions.directoryRoleFieldLabel'),
            disabled: _ctx.isLoggedInUser
          }, null, 8, ["value", "items", "label", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _cache[2] || (_cache[2] = _createElementVNode("th", { class: "px-2" }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.directoryPermissions, (directoryPermission) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: directoryPermission.id,
                    class: "px-2"
                  }, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(directoryPermission.name), 1)
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choosablePermissions, (choosablePermission) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: choosablePermission.id
                }, [
                  _createElementVNode("td", _hoisted_8, _toDisplayString(choosablePermission.name), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(choosablePermission.permissions, (permission, index) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: permission.id
                    }, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_CustomCheckbox, {
                          checked: permission.selected,
                          "onUpdate:checked": (newValue) => _ctx.onChoosablePermissionClicked(newValue, choosablePermission, index)
                        }, null, 8, ["checked", "onUpdate:checked"])
                      ])
                    ]))
                  }), 128))
                ]))
              }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_CustomButton, {
          "color-preset": "secondary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.permissions.cancelButtonLabel')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_CustomButton, {
          disabled: _ctx.disableButtons,
          onClick: _ctx.onConfirmButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.permissions.confirmButtonLabel')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ])
  ]))
}