import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-x-3 gap-y-2 w-full" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "text-left font-semibold text-3xl pb-0 whitespace-nowrap" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm text-gray-500"
}
const _hoisted_5 = { class: "flex space-x-4" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PigTypePicker = _resolveComponent("PigTypePicker")!
  const _component_PeriodPicker = _resolveComponent("PeriodPicker")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.heading), 1),
      (_ctx.subheading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.subheading), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.hasPigTypePicker)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_PigTypePicker)
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasPeriodPicker)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_PeriodPicker)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "top-right")
    ])
  ]))
}