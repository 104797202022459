import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "space-y-4 pt-4" }
const _hoisted_2 = { class: "flex justify-end mx-6 space-x-6" }
const _hoisted_3 = { class: "m-auto pr-2" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "m-auto pr-2" }
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = {
  key: 0,
  class: "bg-primary-800 rounded-full text-white font-medium leading-none px-2 py-1 ml-2"
}
const _hoisted_9 = { class: "w-10 my-auto" }
const _hoisted_10 = { class: "w-8 my-auto" }
const _hoisted_11 = { class: "flex justify-end" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AlarmsTableCalendarModal = _resolveComponent("AlarmsTableCalendarModal")!
  const _component_FilterModal = _resolveComponent("FilterModal")!
  const _component_AlertBadge = _resolveComponent("AlertBadge")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.monitoring.log.header.heading'),
    "has-period-picker": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MainViewCard, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                type: "button",
                class: "bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-2 px-3 flex",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refresh && _ctx.refresh(...args)))
              }, [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_Icon, {
                    src: _ctx.IconSource.RefreshActivities
                  }, null, 8, ["src"])
                ]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('views.monitoring.log.fetchLogsButtonLabel')), 1)
              ]),
              _createVNode(_component_AlarmsTableCalendarModal, { alarms: _ctx.items }, null, 8, ["alarms"]),
              _createVNode(_component_FilterModal, {
                "selected-ids": _ctx.selectedAlarmTypes,
                "onUpdate:selectedIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedAlarmTypes) = $event)),
                header: _ctx.$t('views.monitoring.log.filterModal.header'),
                "sub-header": _ctx.$t('views.monitoring.log.filterModal.subheader'),
                types: _ctx.alarmTypes
              }, {
                default: _withCtx(({ scope }) => [
                  _createElementVNode("button", {
                    type: "button",
                    class: "bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-2 px-3 flex",
                    onClick: scope.open
                  }, [
                    _createElementVNode("span", _hoisted_6, [
                      _createVNode(_component_Icon, {
                        src: _ctx.IconSource.Filter
                      }, null, 8, ["src"])
                    ]),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('views.monitoring.log.filterButtonLabel')), 1),
                    (_ctx.alarmTypes.length && _ctx.selectedAlarmTypes.length !== _ctx.alarmTypes.length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.selectedAlarmTypes.length), 1))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_5)
                ]),
                _: 1
              }, 8, ["selected-ids", "header", "sub-header", "types"])
            ]),
            _createVNode(_component_ActionModal, {
              "initial-step-index": 1,
              "initial-state": _ctx.actionModalInitialState,
              "step-config": _ctx.actionModalStepConfig,
              header: _ctx.actionModalHeader
            }, {
              default: _withCtx(({ open }) => [
                _createVNode(_component_CustomTable, {
                  items: _ctx.items,
                  headers: _ctx.headers
                }, {
                  header_icon: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("th", { class: "w-10" }, null, -1)
                  ])),
                  icon: _withCtx(({ item }) => [
                    _createElementVNode("td", _hoisted_9, [
                      _createVNode(_component_Icon, {
                        class: "h-10 w-10",
                        src: item.icon
                      }, null, 8, ["src"])
                    ])
                  ]),
                  severity_level: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                    _createElementVNode("td", {
                      class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
                      style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                    }, [
                      (item.severity_level === 3)
                        ? (_openBlock(), _createBlock(_component_AlertBadge, {
                            key: 0,
                            type: "error"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.log.tables.alarmStatus.alarm')), 1)
                            ]),
                            _: 1
                          }))
                        : (item.severity_level === 2)
                          ? (_openBlock(), _createBlock(_component_AlertBadge, {
                              key: 1,
                              type: "warning"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.log.tables.alarmStatus.attention')), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_AlertBadge, {
                              key: 2,
                              type: "success"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.log.tables.alarmStatus.OK')), 1)
                              ]),
                              _: 1
                            }))
                    ], 6)
                  ]),
                  header_actions: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("th", { class: "w-8" }, null, -1)
                  ])),
                  actions: _withCtx(({ item }) => [
                    _createElementVNode("td", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "p-2 -mr-2",
                          onClick: ($event: any) => (_ctx.onAlarmClicked(item, open))
                        }, [
                          _createVNode(_component_Icon, {
                            class: "h-3 w-3 cursor-pointer",
                            src: _ctx.IconSource.Right
                          }, null, 8, ["src"])
                        ], 8, _hoisted_12)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["items", "headers"])
              ]),
              _: 1
            }, 8, ["initial-state", "step-config", "header"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["heading"]))
}