<template>
    <div class="w-full grid">
        <GenericChart :print-subtitle="reportTitle" :options="data.options || columnChartOptions"></GenericChart>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GenericChart from '@/components/charts/GenericChart.vue';
import useColumnChartOptions from '@/components/charts/composables/useColumnChartOptions';
import colors from '@/plugins/tailwind';

export default defineComponent({
    components: { GenericChart },
    props: {
        data: {
            type: Object as () => { title: string; series: { name: string; data: any[] }[]; height: number; options: any },
            required: true,
        },
        reportTitle: {
            type: String,
            default: '',
        },
    },
    computed: {
        columnChartOptions(): any {
            Object.assign(this.data.series[0], {
                type: 'line',
                stickyTracking: false,
            });
            return useColumnChartOptions({
                title: { text: this.data.title },
                series: this.data.series,
                chart: { height: this.data.height },
                yAxis: {
                    allowDecimals: false,
                },
                xAxis: {
                    min: null,
                    max: null,
                },
                colors: ['#2f73a4', colors.pigs, colors.deaths, colors.slaughters, colors.primary[800], colors.feed[400], '#e8b24a'],
            });
        },
    },
});
</script>
