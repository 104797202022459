<template>
    <DashboardContentLayout class="space-y-4" :heading="$t('views.economy.status.header.heading')" :has-period-picker="true" :has-pig-type-picker="false">
        <EconomyActivitiesTables></EconomyActivitiesTables>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import EconomyActivitiesTables from '@/views/dashboard/economy/economyStatus/EconomyStatusTables.vue';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, EconomyActivitiesTables },
});
</script>
