<template>
    <div class="space-y-4">
        <h2 class="max-w-120 text-xl font-semibold text-center">{{ $t('views.feed.rawMaterials.editRawMaterialModal.header').replace('$RAW_MATERIAL_NAME', rawMaterial.name) }}</h2>
        <div>
            <table class="w-120 table-headers-medium">
                <thead>
                    <tr class="px-6 flex mt-2 rounded text-dark-gray-800 text-opacity-50">
                        <th class="flex flex-1">
                            <span class="mx-auto">{{ $t('views.feed.rawMaterials.editRawMaterialModal.table.headers.price') }}</span>
                        </th>
                        <th class="flex flex-1">
                            <span class="mx-auto">{{ $t('views.feed.rawMaterials.editRawMaterialModal.table.headers.validFrom') }}</span>
                        </th>
                        <th class="flex w-12"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="rounded flex py-4 px-6 my-4 bg-feed-600 bg-opacity-60 border-2 border-opacity-10 rounded-lg">
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">
                                <SimpleInput
                                    v-model:value="priceToAdd.price"
                                    :placeholder="$t('views.feed.rawMaterials.editRawMaterialModal.table.newPricePlaceholder')"
                                    class="w-24"
                                    type="number"
                                    step="any"
                                ></SimpleInput>
                            </span>
                        </td>
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto w-32">
                                <FeedDatePicker
                                    v-model:value="priceToAdd.valid_from"
                                    :placeholder="$t('views.feed.rawMaterials.editRawMaterialModal.table.newValidFromPlaceholder')"
                                    :disabled="false"
                                ></FeedDatePicker>
                            </span>
                        </td>
                        <td class="flex w-12 my-auto">
                            <button
                                type="button"
                                :class="{ 'opacity-25 cursor-default': !validPriceToAdd, 'bg-primary-800 text-white rounded font-medium': validPriceToAdd }"
                                class="mx-auto px-4 py-1"
                                @click="onAddPriceClicked"
                            >
                                {{ $t('views.feed.rawMaterials.editRawMaterialModal.table.addPriceButtonLabel') }}
                            </button>
                        </td>
                    </tr>
                    <tr v-for="(price, index) of prices" :key="index" class="rounded flex py-4 px-6 my-4 odd:bg-dark-gray-100 border-2 border-opacity-10 rounded-lg">
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">{{ $n(price.price, 'price') }}</span>
                        </td>
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">{{ $d(price.valid_from, 'short') }}</span>
                        </td>
                        <td class="flex w-12 my-auto">
                            <button v-if="prices.length > 1" type="button" class="mx-auto" @click="onDeletePriceClicked(price)">
                                <Icon class="w-6" :src="IconSource.Delete"></Icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex space-x-4">
            <DeleteRawMaterialConfirmationModal :raw-material="rawMaterial" @submit="$emit('submit')">
                <template #default="scope">
                    <DeleteButton @click="scope.open">
                        {{ $t('views.feed.rawMaterials.deleteRawMaterialModal.deleteButtonLabel') }}
                    </DeleteButton>
                </template>
            </DeleteRawMaterialConfirmationModal>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import { Price, RawMaterial, RawMaterialRegisterItem, TableHeader } from '@/types';
import AutoComplete from '@/components/ui/AutoComplete.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import FeedDatePicker from '@/components/ui/FeedDatePicker.vue';
import DeleteRawMaterialConfirmationModal from '@/views/dashboard/feed/feedRawMaterials/editRawMaterialModal/DeleteRawMaterialConfirmationModal.vue';
import SimpleInput from '@/components/ui/SimpleInput.vue';

export default defineComponent({
    components: { CustomButton, AutoComplete, DatePicker, FeedDatePicker, SimpleInput, DeleteRawMaterialConfirmationModal, DeleteButton },
    emits: ['close', 'submit'],
    props: {
        rawMaterial: {
            type: Object as () => Form<RawMaterial> & RawMaterial,
            required: true,
        },
    },
    data() {
        return {
            priceToAdd: {
                price: '' as number | string,
                valid_from: '',
            },
        };
    },
    computed: {
        validPriceToAdd(): boolean {
            return Boolean(typeof this.priceToAdd.price === 'number' && typeof this.priceToAdd.valid_from === 'string' && this.priceToAdd.valid_from.length);
        },
        prices(): Price[] {
            return [...this.rawMaterial.prices].sort((a, b) => Date.parse(a.valid_from) - Date.parse(b.valid_from));
        },
    },
    methods: {
        async onAddPriceClicked() {
            if (!this.validPriceToAdd) {
                return;
            }
            await store.dispatch(ActionType.CreateRawMaterialPrice, { form: { rawMaterial: this.rawMaterial, price: this.priceToAdd as Price } });
            this.priceToAdd.price = '';
            this.priceToAdd.valid_from = '';
            store.dispatch(ActionType.GetRawMaterials, { options: { ignoreCache: true } });
            store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
        },
        async onDeletePriceClicked(price: Price) {
            if (this.rawMaterial.prices.length > 1) {
                await store.dispatch(ActionType.DeleteRawMaterialPrice, { form: { rawMaterial: this.rawMaterial, price } });
                store.dispatch(ActionType.GetRawMaterials, { options: { ignoreCache: true } });
                store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
            }
        },
    },
});
</script>
