import { watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { store } from '@/plugins/store';
import { queryKeys } from '@/plugins/store/actions/queries/analysis/dashboards';

const queryKeysToClear = [queryKeys.getDashboardRoot];

export function useClearCachesOnFormSubmission() {
    const queryClient = useQueryClient();

    watch(
        () => store.state.formRecentlySubmitted,
        (newValue) => {
            if (newValue) {
                for (const queryKey of queryKeysToClear) {
                    queryClient.invalidateQueries({ queryKey });
                }
            }
        }
    );
}
