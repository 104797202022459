import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = {
  key: 1,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.currentStep < 2)
            ? (_openBlock(), _createBlock(_component_MainViewCard, {
                key: 0,
                class: _normalizeClass([_ctx.currentWidth, "px-4 py-4"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_Transition, {
                    name: "fade",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
                        state: _ctx.state,
                        onNext: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next(0))),
                        onSkip: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next(1))),
                        onReset: _ctx.onReset
                      }, null, 40, ["state", "onReset"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
                  state: _ctx.state,
                  onNext: _cache[2] || (_cache[2] = ($event: any) => (_ctx.next(0))),
                  onSkip: _cache[3] || (_cache[3] = ($event: any) => (_ctx.next(1))),
                  onReset: _ctx.onReset
                }, null, 40, ["state", "onReset"]))
              ]))
        ]),
        _: 1
      })
    ])
  ]))
}