<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.health.instructions.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-8">
            <InstructionsTabs></InstructionsTabs>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import InstructionsTabs from '@/views/dashboard/health/instructions/InstructionsTabs.vue';

export default defineComponent({
    components: { DashboardContentLayout, InstructionsTabs },
});
</script>
