export type BatchEvent = {
    id: number;
    name: string;
    action_id: number;
    icon_url: string;
    type: 'create' | 'close' | 'update';
    is_listed: boolean;
};

export enum BatchEventType {
    BatchCreate = 'create',
    BatchClose = 'close',
    BatchUpdate = 'update',
}
