import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white pt-6 rounded-xl" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ActivityTable = _resolveComponent("ActivityTable")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "w-full m-auto border-0",
    "mask-class": "grid"
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ActivityTable, {
          title: _ctx.title,
          "selectable-record-type-ids": _ctx.recordTypeIds,
          "use-records": _ctx.records,
          "fetch-own-records": false,
          onFetchRecords: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('fetch-records')))
        }, {
          "top-right": _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              onClick: scope.close
            }, [
              _createVNode(_component_Icon, {
                src: _ctx.IconSource.Close
              }, null, 8, ["src"])
            ], 8, _hoisted_2)
          ]),
          _: 2
        }, 1032, ["title", "selectable-record-type-ids", "use-records"])
      ])
    ]),
    _: 3
  }))
}