<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.debug.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-8">
            <MainViewCard class="pt-4 pb-2 px-2">
                <GenericChartWithApi :fetch-action-type="chart.fetchActionType" :chart-name="chart.name" :height="chart.height" :chart-type="chart.type"></GenericChartWithApi>
            </MainViewCard>
            <ActionModal v-if="playgroundModalInitialState.form" :header="{ icon_url: '', text: 'Playground' }" :initial-step-index="1" :initial-state="playgroundModalInitialState">
                <template #default="{ open }">
                    <CustomButton class="w-40" @click="open"> Playground form </CustomButton>
                </template>
            </ActionModal>
            <GenericDashboard
                :data="dashboardData"
                :loading="dashboardLoading"
                :fetch-params="{ initialRequest: false }"
                :fetch-action-type="ActionType.GetDebugDashboardOverview"
                :state-mutation-type="MutationType.SetDebugDashboardOverview"
            />
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { Chart, ChartType } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { MutationType } from '@/plugins/store/mutations';

useRefetchDataOnDashboardStateChanged(ActionType.GetDebugDashboardOverview, { watchSites: true });

const chart = ref({
    name: Chart.Debug,
    type: ChartType.ColumnChart,
    height: 400,
    fetchActionType: ActionType.GetDebugChart,
});
const playgroundModalInitialState = ref<any>({
    location: null,
    form: null,
    confirmed: false,
    farm: null,
    record_id: null,
    notification: null,
    event: null,
});

async function getForm() {
    const form = await store.dispatch(ActionType.GetForm, { form: { form_id: 999 } });

    playgroundModalInitialState.value.form = form;
}

const dashboardData = computed(() => store.state.debugDashboardOverview);
const dashboardLoading = computed(() => store.state.debugDashboardOverviewLoading);

onMounted(() => {
    getForm();
});
</script>
