import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue';
import { RadioGroupIndicator, RadioGroupItem, type RadioGroupItemProps, useForwardProps } from 'radix-vue';
import { Circle } from 'lucide-vue-next';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioGroupItem',
  props: {
    id: {},
    value: {},
    disabled: { type: Boolean },
    required: { type: Boolean },
    name: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RadioGroupItem), _mergeProps(_unref(forwardedProps), {
    class: 
            _unref(cn)(
                'aspect-square h-5 w-5 rounded-full border border-primary-800 text-primary-800 ring-offset-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                props.class
            )
        
  }), {
    default: _withCtx(() => [
      _createVNode(_unref(RadioGroupIndicator), { class: "flex items-center justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_unref(Circle), { class: "h-2.5 w-2.5 fill-current text-current" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["class"]))
}
}

})