import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid h-full" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([[_ctx.cursorClass], "flex flex-1 p-3 rounded-md border bg-green-200 border-green-700 border-opacity-50 bg-opacity-20"]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClicked && _ctx.onClicked(...args)), ["prevent"]))
      }, [
        _createElementVNode("label", _hoisted_2, [
          _createVNode(_component_CustomCheckbox, {
            checked: _ctx.computedValue,
            "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
            disabled: _ctx.disabled,
            class: "my-auto"
          }, null, 8, ["checked", "disabled"]),
          _createElementVNode("span", {
            class: _normalizeClass([_ctx.textColorClass, _ctx.cursorClass, _ctx.labelClasses])
          }, _toDisplayString(_ctx.label), 3)
        ])
      ], 2)
    ]),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["h-4 flex text-xs text-left w-full ml-1", { 'text-red-600': _ctx.details?.type === 'error', 'text-gray-500': _ctx.details?.type === 'hint' }])
    }, _toDisplayString(_ctx.details?.text || ''), 3), [
      [_vShow, _ctx.details]
    ])
  ], 2))
}