<template>
    <DropdownMenuItem class="gap-x-2.5 text-base" @click="onClick">
        <Icon v-if="item.icon_id !== null && item.icon_id !== undefined" :src="item.icon_id" class="flex-shrink-0 size-5" />
        {{ item.text }}
        <Spinner class="flex-shrink-0 size-5 pointer-events-none ml-auto" :class="loading ? 'visible' : 'invisible'" />
    </DropdownMenuItem>
</template>

<script lang="ts" setup>
import { HelpWidgetRouteItem } from '@/types/HelpWidget';
import Icon from '@/components/icons/Icon.vue';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import Spinner from '@/components/ui/Spinner.vue';

interface Props {
    item: HelpWidgetRouteItem;
    loading?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    (e: 'select-route', item: HelpWidgetRouteItem): void;
}>();

function onClick() {
    emit('select-route', props.item);
}
</script>
