import useDefaultChartOptions from '@/components/charts/composables/useDefaultChartOptions';

export default function useStackedColumnChartOptions(chartInstanceOptions: any) {
    return useDefaultChartOptions(chartInstanceOptions, {
        chart: { type: 'column' },
        xAxis: {
            stackLabels: {
                enabled: true,
            },
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
        },
    });
}
