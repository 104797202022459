import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "space-y-6 min-h-40" }
const _hoisted_2 = {
  key: 0,
  class: "text-xl font-semibold text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.config.headerText || _ctx.state.event?.name)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.config.headerText || _ctx.state.event?.name || ''), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Spinner, {
      class: "w-16 h-16 mx-auto",
      active: ""
    })
  ]))
}