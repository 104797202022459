import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative form-month-picker" }
const _hoisted_2 = { class: "relative overflow-hidden cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FlatpickrWrapper = _resolveComponent("FlatpickrWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FlatpickrWrapper, {
      value: _ctx.computedValue,
      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedValue) = $event)),
      show: _ctx.expandPicker,
      "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.expandPicker) = $event)),
      "disable-months": _ctx.disableMonths,
      "wrapper-class": 'flatpickr-component-month-wrapper',
      config: _ctx.flatpickrConfig,
      "auto-width": "",
      mode: "single"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CustomInput, {
            ref: "input-field",
            value: _ctx.inputValue,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            rules: [{ validate: (value) => Boolean(value), message: '' }],
            required: "",
            "input-type": "text",
            label: _ctx.label,
            readonly: "",
            cursor: "cursor-pointer",
            onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit'))),
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.expandPicker = true)),
            onKeydown: _withKeys(_withModifiers(_ctx.onEscape, ["stop"]), ["esc"])
          }, null, 8, ["value", "rules", "label", "onKeydown"]),
          _createElementVNode("span", {
            class: "absolute h-full top-0 right-5 grid content-center -mt-2",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$refs['input-field'].focusInput()))
          }, [
            _createVNode(_component_Icon, {
              class: "m-auto",
              src: _ctx.IconSource.DatePicker
            }, null, 8, ["src"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["value", "show", "disable-months", "config"])
  ]))
}