<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.slaughters.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <MainViewCard>
            <GenericTableWithApi :source="slaughterTableSource"></GenericTableWithApi>
        </MainViewCard>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import GenericTableWithApi from '@/components/common/GenericTableWithApi.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { Table } from '@/types';

export default defineComponent({
    components: { DashboardContentLayout, GenericTableWithApi, MainViewCard },
    data() {
        return {
            slaughterTableSource: Table.SlaughterSettlements,
        };
    },
});
</script>
