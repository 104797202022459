/* eslint-disable */
export enum HelpItemType {
    Url = 0,
    Route = 1,
    Video = 2,
}
/* eslint-enable */

export interface HelpWidgetBaseItem {
    type: HelpItemType;
    is_visible: boolean;
    icon_id: number;
    text: string;
}

export interface HelpWidgetUrlItem extends HelpWidgetBaseItem {
    type: HelpItemType.Url;
    url: string;
}

export interface HelpWidgetRouteItem extends HelpWidgetBaseItem {
    type: HelpItemType.Route;
    route: string;
}

export interface HelpWidgetVideoItem extends HelpWidgetBaseItem {
    type: HelpItemType.Video;
    video_title: string;
    video_source_url: string;
    reference_url: string | null;
    video_description: string | null;
    references_subtitle: string | null;
    references: HelpWidgetReference[];
}

export interface HelpWidgetReference {
    text: string;
    url: string;
}

export type HelpWidgetItem = HelpWidgetUrlItem | HelpWidgetRouteItem | HelpWidgetVideoItem;

export interface HelpWidgetData {
    items: HelpWidgetItem[];
}
