<template>
    <div>
        <label class="text-gray-400 text-sm">
            {{ label }}
        </label>
        <div class="grid grid-cols-2 gap-x-4 gap-y-4">
            <label v-for="item of items" :key="item[itemValue]" :class="`${wrapperCheckedClass(item)}`" class="cursor-pointer flex-1 p-3 rounded-md border">
                <input v-model="computedValue" :disabled="disabled" :value="returnObject ? item : item[itemValue]" type="radio" :name="radioButtonGroup" class="h-4 w-4 cursor-pointer" />
                <span :class="[textColorClass]" class="pl-2 cursor-pointer">{{ item[itemText] }}</span>
            </label>
        </div>
        <span v-show="details" class="h-4 flex text-xs text-left w-full ml-1" :class="{ 'text-red-600': details?.type === 'error', 'text-gray-500': details?.type === 'hint' }">{{ details?.text || '' }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { validatableField } from '@/mixins/validatableField';

export default defineComponent({
    mixins: [validatableField],
    emits: ['update:value', 'input'],
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        radioButtonGroup: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
    },
    computed: {
        textColorClass(): string {
            return this.disabled ? 'text-gray-400' : 'text-dark-gray-600';
        },
        computedValue: {
            get(): any {
                return this.value;
            },
            set(newValue: any) {
                this.$emit('update:value', newValue);
            },
        },
    },
    watch: {
        computedValue() {
            this.$emit('input');
        },
    },
    methods: {
        isChecked(item: any): boolean {
            if (this.returnObject) {
                return JSON.stringify(item) === JSON.stringify(this.computedValue);
            }
            return this.computedValue === item[this.itemValue];
        },
        wrapperCheckedClass(item: any): string {
            return this.isChecked(item) ? 'bg-green-200 border-green-700 border-opacity-50 bg-opacity-20' : 'bg-gray-100 border-gray-300';
        },
    },
});
</script>

<style scoped>
input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    border-radius: 100%;
    height: 1em;
    width: 1em;
    color: #4299e1;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    margin-bottom: 2px;
}

input[type='radio']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: #91c391;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
