import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex mx-6 space-x-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "text-right mr-6 w-80" }
const _hoisted_4 = { class: "w-6 my-auto" }
const _hoisted_5 = { class: "flex justify-end" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_CreateUserForm = _resolveComponent("CreateUserForm")!
  const _component_SettingsModal = _resolveComponent("SettingsModal")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_EditUserModal = _resolveComponent("EditUserModal")!
  const _component_EditUserPermissionsForm = _resolveComponent("EditUserPermissionsForm")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, { class: "space-y-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.users.content.table.title')), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_SearchInput, {
            value: _ctx.search,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            label: _ctx.$t('views.settings.users.content.table.searchFieldLabel')
          }, null, 8, ["value", "label"])
        ]),
        (_ctx.canManageDirectory && _ctx.showInviteUsersButton)
          ? (_openBlock(), _createBlock(_component_SettingsModal, { key: 0 }, {
              default: _withCtx(({ open }) => [
                _createVNode(_component_AddButton, {
                  "text-class": "font-medium",
                  onClick: open
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.create.createModalButtonLabel')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              form: _withCtx(({ close }) => [
                _createVNode(_component_CreateUserForm, {
                  onCancel: close,
                  onSubmit: close
                }, null, 8, ["onCancel", "onSubmit"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_CustomTable, {
        headers: _ctx.headers,
        items: _ctx.filteredItems
      }, {
        header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("th", { class: "w-6" }, null, -1)
        ])),
        name: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          _createElementVNode("td", {
            class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, _toDisplayString(`${item.first_name} ${item.last_name}`), 7)
        ]),
        actions: _withCtx(({ item }) => [
          _createElementVNode("td", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              (_ctx.canEditUsers)
                ? (_openBlock(), _createBlock(_component_EditUserModal, {
                    key: 0,
                    "initial-state": item
                  }, {
                    default: _withCtx(({ open }) => [
                      _createElementVNode("button", {
                        type: "button",
                        class: "p-2 -mr-2",
                        onClick: open
                      }, [
                        _createVNode(_component_Icon, {
                          class: "h-3 w-3",
                          src: _ctx.IconSource.Right
                        }, null, 8, ["src"])
                      ], 8, _hoisted_6)
                    ]),
                    _: 2
                  }, 1032, ["initial-state"]))
                : (_ctx.canManageDirectory)
                  ? (_openBlock(), _createBlock(_component_SettingsModal, { key: 1 }, {
                      default: _withCtx(({ open }) => [
                        _createElementVNode("button", {
                          type: "button",
                          class: "p-2 -mr-2",
                          onClick: open
                        }, [
                          _createVNode(_component_Icon, {
                            class: "h-3 w-3",
                            src: _ctx.IconSource.Right
                          }, null, 8, ["src"])
                        ], 8, _hoisted_7)
                      ]),
                      form: _withCtx(({ close }) => [
                        _createVNode(_component_EditUserPermissionsForm, {
                          "directory-user": item,
                          onCancel: close,
                          onSubmit: close
                        }, null, 8, ["directory-user", "onCancel", "onSubmit"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}