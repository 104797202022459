export type MixType = {
    id: number;
    text: string;
};

export enum MixTypeId {
    HOME_MIX = 1,
    COMPOUND_MIX = 2,
    COMPONENT_MIX = 3,
}
