<template>
    <component :is="component" v-model:value="computedValue" @submit="$emit('submit')"></component>
</template>

<script lang="ts">
import { defineComponent, Component } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import { MixForm, MixTypeId } from '@/types';
import CompoundMix from '@/views/dashboard/feed/feedMixes/newMixModal/CompoundMix.vue';
import HomeOrComponentMix from '@/views/dashboard/feed/feedMixes/newMixModal/HomeOrComponentMix.vue';

/* This component is essentially a wrapper for CompoundMix and HomeMixRawMaterials to ensure they get the same position in the multi-step modal */
export default defineComponent({
    emits: ['update:value', 'submit'],
    props: {
        value: {
            type: Object as () => Form<MixForm> & MixForm,
            required: true,
        },
    },
    computed: {
        computedValue: {
            get(): Form<MixForm> & MixForm {
                return this.value;
            },
            set(newValue: any) {
                this.$emit('update:value', newValue);
            },
        },
        component(): Component {
            return this.$props.value.type.id === MixTypeId.COMPOUND_MIX ? CompoundMix : HomeOrComponentMix;
        },
    },
});
</script>
