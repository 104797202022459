import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "h-full min-h-60 space-y-5" }
const _hoisted_2 = { class: "space-y-4" }
const _hoisted_3 = { class: "text-xl font-semibold text-center" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500 text-center"
}
const _hoisted_5 = {
  key: 0,
  class: "grid h-full"
}
const _hoisted_6 = {
  key: 0,
  class: "flex gap-x-2 mb-4"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "grid grid-cols-2 gap-y-1 gap-x-2"
}
const _hoisted_10 = {
  key: 0,
  class: "text-lg font-semibold mt-2 mb-4 text-center col-span-2"
}
const _hoisted_11 = {
  key: 0,
  class: "mt-2 mb-4"
}
const _hoisted_12 = { class: "text-lg font-semibold mb-3 w-full break-all" }
const _hoisted_13 = ["aria-checked", "aria-labelledby", "disabled", "onKeydown", "onClick"]
const _hoisted_14 = ["id"]
const _hoisted_15 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_16 = {
  key: 2,
  class: "flex space-x-4 justify-end mt-4"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 1,
  class: "flex-grow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DeleteButton = _resolveComponent("DeleteButton")!
  const _component_DynamicFormElement = _resolveComponent("DynamicFormElement")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DeleteFormConfirmationModal = _resolveComponent("DeleteFormConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.computedState?.form?.title || _ctx.header?.text || _ctx.computedState.event?.name || ''), 1),
      (_ctx.computedState?.form?.sub_title)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.computedState.form.sub_title), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_ctx.computedState.form)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedState.form.sections, (formSection, sectionIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: formSection.id,
                class: _normalizeClass([{ 'mt-4': formSection.name }, "first:mt-0"])
              }, [
                (_ctx.collapsibleSections[formSection.id])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", null, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "bg-primary-700 text-primary-800 bg-opacity-20 min-h-[40px] hover:bg-opacity-30 transition-colors duration-300 rounded-lg py-2 px-3 flex",
                          onClick: ($event: any) => (_ctx.onToggleExpandSectionButtonClicked(formSection))
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass(["m-auto", { 'pr-2': formSection.name }])
                          }, [
                            _createVNode(_component_Icon, {
                              src: formSection.collapsed ? _ctx.IconSource.AddAction : _ctx.IconSource.Minus
                            }, null, 8, ["src"])
                          ], 2),
                          _createElementVNode("span", null, _toDisplayString(formSection.name), 1)
                        ], 8, _hoisted_7)
                      ]),
                      (_ctx.dynamicSectionOptions[formSection.id])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_DeleteButton, {
                              onClick: ($event: any) => (_ctx.onDeleteSection(formSection))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.dynamicSectionOptions[formSection.id].deleteSectionButtonLabel), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ExpandCollapseTransition, null, {
                  default: _withCtx(() => [
                    (!formSection.collapsed)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          (sectionIndex > 0 && formSection.name)
                            ? (_openBlock(), _createElementBlock("h3", _hoisted_10, _toDisplayString(formSection.name), 1))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formSection.children, (formElement, elementIndex) => {
                            return (_openBlock(), _createBlock(_component_DynamicFormElement, {
                              ref_for: true,
                              ref: `${formSection.id}_${formElement.id}`,
                              key: formElement.id,
                              element: formSection.children[elementIndex],
                              "onUpdate:element": ($event: any) => ((formSection.children[elementIndex]) = $event),
                              "precondition-disabled": _ctx.getPreconditionDisabled(formElement, formSection),
                              "precondition-data": _ctx.getPreconditionData(formElement, formSection),
                              rules: _ctx.getRules(formElement),
                              details: _ctx.inputDetails[sectionIndex][elementIndex],
                              "section-id": formSection.id,
                              "assignment-id": _ctx.computedState.assignment_id,
                              onInput: _ctx.validateForm,
                              onCloneSection: _ctx.onCloneSection,
                              onBackendRetrieval: (triggeredElement) => _ctx.onBackendRetrieval(triggeredElement, formSection)
                            }, null, 8, ["element", "onUpdate:element", "precondition-disabled", "precondition-data", "rules", "details", "section-id", "assignment-id", "onInput", "onCloneSection", "onBackendRetrieval"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ], 2))
            }), 128)),
            (_ctx.subassignments)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t('global.forms.steps.dynamicForm.sectionHeadings.subAssignments')) + " (" + _toDisplayString(_ctx.subassignments.length) + ")", 1),
                  _createVNode(_TransitionGroup, {
                    tag: "div",
                    "enter-active-class": "transition-all duration-300 ease-in",
                    "leave-active-class": "transition-all duration-300 ease-in",
                    "enter-class": "transform -translate-y-4 opacity-0",
                    "leave-to-class": "transform translate-y-4 opacity-0"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subassignments, (subassignment) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: subassignment.id
                        }, [
                          _createElementVNode("button", {
                            type: "button",
                            class: _normalizeClass(["flex w-full items-center justify-between bg-white hover:bg-gray-100 transition-colors border border-gray-200 rounded-xl py-3.5 px-4 space-x-4 flex mt-2 text-left", { 'opacity-70 cursor-not-allowed hover:bg-white': _ctx.config.editable === false }]),
                            "aria-checked": _ctx.completedSubassignments.includes(subassignment.id) ? 'true' : 'false',
                            "aria-labelledby": `subassignment-label-${subassignment.id}`,
                            disabled: _ctx.config.editable === false,
                            onKeydown: [
                              _withKeys(_withModifiers(($event: any) => (_ctx.completeSubassignment(subassignment.id)), ["prevent"]), ["space"]),
                              _withKeys(_withModifiers(($event: any) => (_ctx.completeSubassignment(subassignment.id)), ["prevent"]), ["enter"])
                            ],
                            onClick: ($event: any) => (_ctx.completeSubassignment(subassignment.id))
                          }, [
                            _createElementVNode("div", {
                              id: `subassignment-label-${subassignment.id}`
                            }, _toDisplayString(subassignment.description), 9, _hoisted_14),
                            _createElementVNode("div", {
                              class: _normalizeClass(["flex items-center flex-shrink-0 h-8 w-8 rounded-full", _ctx.completedSubassignments.includes(subassignment.id) ? 'bg-green-200 bg-opacity-20' : 'border border-gray-400 bg-white'])
                            }, [
                              _createVNode(_component_Icon, {
                                class: _normalizeClass(["m-auto h-4 opacity-0 transition-opacity", { 'opacity-100': _ctx.completedSubassignments.includes(subassignment.id) }]),
                                src: _ctx.IconSource.Active
                              }, null, 8, ["src", "class"])
                            ], 2)
                          ], 42, _hoisted_13)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.computedAssignment && _ctx.computedAssignment.is_completed === false && _ctx.config.editable !== false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode(_component_CustomButton, {
                    loading: _ctx.isCompletingAssignment,
                    disabled: _ctx.disableButtons,
                    onClick: _ctx.completeAssignment
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.dynamicForm.completeButtonLabel')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading", "disabled", "onClick"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.config.deleteButton || _ctx.config.editable !== false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_CustomButton, {
                      disabled: _ctx.disableButtons || _ctx.isCompletingAssignment,
                      "color-preset": "white",
                      class: "px-5",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.dynamicForm.cancelButtonLabel')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  (_ctx.config.deleteButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createVNode(_component_DeleteFormConfirmationModal, {
                          state: _ctx.state,
                          "onUpdate:state": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state) = $event)),
                          type: _ctx.formType,
                          header: _ctx.header,
                          onDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('skip')))
                        }, {
                          default: _withCtx((scope) => [
                            _createVNode(_component_CustomButton, {
                              disabled: _ctx.disableButtons || _ctx.isCompletingAssignment,
                              "color-preset": "warning",
                              class: "px-5 whitespace-nowrap",
                              onClick: scope.open
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString((_ctx.computedState.form.buttons && _ctx.computedState.form.buttons.delete) || _ctx.deleteButtonText), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]),
                          _: 1
                        }, 8, ["state", "type", "header"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.config.editable !== false)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createVNode(_component_CustomButton, {
                          loading: _ctx.disableButtons,
                          disabled: !_ctx.validForm || _ctx.isCompletingAssignment,
                          "color-preset": _ctx.computedAssignment && _ctx.computedAssignment.is_completed === false ? 'secondary' : 'primary',
                          class: "px-5 max-w-full",
                          onClick: _ctx.onNextButtonClicked
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString((_ctx.computedState.form.buttons && _ctx.computedState.form.buttons.next) || _ctx.$t('global.forms.steps.dynamicForm.nextButtonLabel')), 1)
                          ]),
                          _: 1
                        }, 8, ["loading", "disabled", "color-preset", "onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}