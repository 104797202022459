<template>
    <div class="space-y-4">
        <div style="grid-template-columns: repeat(auto-fit, minmax(280px, 1fr))" class="grid gap-8">
            <EditableKpiType v-for="kpiType of kpiTypes" :key="kpiType.id" :selected="kpiType.selected" :kpi-type="kpiType" @click="kpiTypeClicked(kpiType)"></EditableKpiType>
        </div>
        <div class="w-40">
            <CustomButton :disabled="disableButtons" @click="updateKpiPreferences">
                {{ $t('views.settings.kpis.content.editButtonLabel') }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { Form } from '@makeabledk/vue-ui/support/http';
import cloneDeep from 'lodash-es/cloneDeep';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { KpiType, KpiPreference } from '@/types';
import EditableKpiType from '@/views/dashboard/settings/kpis/EditableKpiType.vue';
import CustomButton from '@/components/ui/CustomButton.vue';

export default defineComponent({
    components: { EditableKpiType, CustomButton },
    data() {
        return {
            MAX_NUMBER_OF_KPIS: 4,
            MIN_NUMBER_OF_KPIS: 4,
            chosenKpiPreferences: [] as KpiPreference[],
            toast: useToast(),
            disableButtons: false,
        };
    },
    computed: {
        kpiTypes(): KpiType[] {
            return cloneDeep(store.state.kpiTypes).map((kpiType) => {
                const selected = this.chosenKpiPreferences.find((currentKpiPreference) => currentKpiPreference.type_id === kpiType.id);
                return { ...kpiType, selected: Boolean(selected) };
            });
        },
        kpiPreferences() {
            return store.state.kpiPreferences;
        },
    },
    watch: {
        kpiPreferences(newValue: KpiPreference[]) {
            this.chosenKpiPreferences = newValue;
        },
    },
    created() {
        store.dispatch(ActionType.GetKpiTypes);
        store.dispatch(ActionType.GetKpiPreferences);
        this.chosenKpiPreferences = this.kpiPreferences;
    },
    methods: {
        kpiTypeClicked(kpiType: KpiType) {
            if (kpiType.selected) {
                this.chosenKpiPreferences = this.chosenKpiPreferences.filter((kpiPreference) => kpiPreference.type_id !== kpiType.id);
            } else {
                this.chosenKpiPreferences = [...this.chosenKpiPreferences, { type_id: kpiType.id, index: this.chosenKpiPreferences.length - 1 }];
            }
        },
        async updateKpiPreferences() {
            if (this.chosenKpiPreferences.length > this.MAX_NUMBER_OF_KPIS) {
                this.toast.warning(this.$t('views.settings.kpis.content.maxAllowedKpisWarning').replace('$NUMBER', `${this.MAX_NUMBER_OF_KPIS}`));
                return;
            }
            if (this.chosenKpiPreferences.length < this.MIN_NUMBER_OF_KPIS) {
                this.toast.warning(this.$t('views.settings.kpis.content.minAllowedKpisWarning').replace('$NUMBER', `${this.MIN_NUMBER_OF_KPIS}`));
                return;
            }
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;

            const form = new Form({
                kpis: this.chosenKpiPreferences.map((preference, index) => ({ type_id: preference.type_id, index })),
            }) as Form<{ kpis: KpiPreference[] }> & { kpis: KpiPreference[] };
            await store.dispatch(ActionType.PutKpiPreferences, { form: form as any });

            if (form.successful) {
                store.dispatch(ActionType.GetKpiPreferences, { options: { ignoreCache: true } });
                store.dispatch(ActionType.GetKpis, { options: { ignoreCache: true } });
                this.toast.success(this.$t('views.settings.kpis.content.updatedSettingsSuccess'));
            }
            this.disableButtons = false;
        },
    },
});
</script>
