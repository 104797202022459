import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative form-date-picker" }
const _hoisted_2 = { class: "relative overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FlatPickrWrapper = _resolveComponent("FlatPickrWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FlatPickrWrapper, {
      value: _ctx.computedValue,
      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedValue) = $event)),
      show: _ctx.expandPicker,
      "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.expandPicker) = $event)),
      disabled: _ctx.disabled,
      config: _ctx.flatpickrConfig,
      "auto-width": "",
      mode: "single"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CustomInput, {
            ref: "input-field",
            value: _ctx.inputValue,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            required: _ctx.required,
            "show-required-indication": _ctx.required,
            rules: [],
            "input-type": "text",
            label: _ctx.label,
            details: _ctx.details,
            disabled: _ctx.disabled,
            readonly: "",
            cursor: "cursor-pointer",
            onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit'))),
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.expandPicker = true)),
            onKeydown: _withKeys(_withModifiers(_ctx.onEscape, ["stop"]), ["esc"])
          }, null, 8, ["value", "required", "show-required-indication", "label", "details", "disabled", "onKeydown"]),
          (!_ctx.required)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([[{ 'cursor-default opacity-40': _ctx.disabled }, _ctx.details ? '-mt-3.5' : '-mt-2'], "absolute h-full top-0 right-14 grid content-center"]),
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClearClicked && _ctx.onClearClicked(...args)))
              }, [
                _createVNode(_component_Icon, {
                  class: "m-auto",
                  src: _ctx.IconSource.Close
                }, null, 8, ["src"])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass([[{ 'cursor-default opacity-40': _ctx.disabled }, _ctx.details ? '-mt-3.5' : '-mt-2'], "absolute h-full top-0 right-5 grid content-center"]),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$refs['input-field'].focusInput()))
          }, [
            _createVNode(_component_Icon, {
              class: "m-auto",
              src: _ctx.IconSource.DatePicker
            }, null, 8, ["src"])
          ], 2)
        ])
      ]),
      _: 1
    }, 8, ["value", "show", "disabled", "config"])
  ]))
}