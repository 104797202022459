<template>
    <div class="h-full pb-6">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.feed.mixes.deleteMixModal.deleteMixSuccessMessage') }}</h2>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';

export default defineComponent({
    emits: ['close'],
    mounted() {
        setTimeout(() => this.$emit('close'), 2000);
    },
    /* If we refetch immediately after delete, the EditMixModal will be unmounted as the RawMaterial no longer exists */
    beforeUnmount() {
        store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
    },
});
</script>
