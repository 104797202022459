import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "h-full min-h-60 w-128 space-y-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-y-1 gap-x-2" }
const _hoisted_4 = { class: "col-span-2" }
const _hoisted_5 = { class: "flex space-x-4 self-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_DeleteButton = _resolveComponent("DeleteButton")!
  const _component_DeletePriceChargeConfirmationModal = _resolveComponent("DeletePriceChargeConfirmationModal")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomSelect, {
        value: _ctx.selectedItem,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
        "item-text": "period",
        "return-object": "",
        "item-value": "id",
        "allow-empty": false,
        label: _ctx.config.periodFieldLabel,
        items: _ctx.config.items,
        class: "col-span-2",
        required: "",
        rules: [{ validate: (value) => Boolean(value), message: '' }]
      }, null, 8, ["value", "label", "items", "rules"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.numberFields, (numberField) => {
        return (_openBlock(), _createBlock(_component_CustomInput, {
          key: numberField.value,
          value: _ctx.form[numberField.value],
          "onUpdate:value": ($event: any) => ((_ctx.form[numberField.value]) = $event),
          class: _normalizeClass([numberField.colSpan]),
          label: numberField.label,
          "input-type": "number",
          required: "",
          rules: [{ validate: (value) => typeof value === 'number', message: '' }],
          disabled: !_ctx.selectedItem
        }, null, 8, ["value", "onUpdate:value", "class", "label", "rules", "disabled"]))
      }), 128)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DeletePriceChargeConfirmationModal, {
          config: _ctx.config.deleteModal,
          item: _ctx.selectedItem,
          onSubmit: _ctx.onPriceDeleted
        }, {
          default: _withCtx(({ open }) => [
            _createVNode(_component_DeleteButton, {
              disabled: !_ctx.selectedItem || !_ctx.selectedItem[_ctx.config.chargeKey],
              onClick: ($event: any) => (_ctx.onDeletePriceChargeClicked(open))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.config.deleteButtonLabel), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"])
          ]),
          _: 1
        }, 8, ["config", "item", "onSubmit"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        disable: !_ctx.validForm,
        onClick: _ctx.onSubmitClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.config.confirmButtonLabel), 1)
        ]),
        _: 1
      }, 8, ["disabled", "disable", "onClick"])
    ])
  ]))
}