<template>
    <Modal close-esc-event="keydown" size="5xl" :close-button="false" modal-class="m-auto w-full border-0" mask-class="grid">
        <template #toggle="scope">
            <button type="button" class="bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-2 px-3 flex" @click="scope.open">
                <span class="m-auto pr-2">
                    <Icon :src="IconSource.DatePicker"></Icon>
                </span>
                <span class="font-medium">{{ $t('views.monitoring.log.calendarModal.calendarButtonLabel') }}</span>
            </button>
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="bg-white divide-x flex rounded-xl w-full h-120">
                <div class="py-8 px-4 flex-1">
                    <FullCalendarWrapper v-model:dateString="date" :events="calendarAlarms"></FullCalendarWrapper>
                </div>
                <div class="py-4 flex-1">
                    <AlarmsTableCalendarList :alarms="filteredAlarms"></AlarmsTableCalendarList>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Alarm } from '@/types';
import FullCalendarWrapper from '@/components/ui/FullCalendarWrapper.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import AlarmsTableCalendarList from './AlarmsTableCalendarList.vue';

export default defineComponent({
    components: { Modal, FullCalendarWrapper, CloseModalButton, AlarmsTableCalendarList },
    props: {
        alarms: {
            type: Array as () => Alarm[],
            default: () => [],
        },
    },
    emits: ['update:selectedIds'],
    data() {
        return {
            date: '',
        };
    },
    computed: {
        calendarAlarms(): any[] {
            const colors: Record<number, string> = {
                1: '#00ce4d',
                2: '#ea9206',
                3: '#f5492b',
            };

            return Object.values(
                this.$props.alarms.reduce((accum, current) => {
                    const currentDate = new Date(Date.parse(current.date));
                    accum[`${current.severity_level}${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDate()}`] = {
                        start: new Date(Date.parse(current.date)),
                        allDay: true,
                        color: colors[current.severity_level] || colors[1],
                    };
                    return accum;
                }, {} as { [key: string]: { start: Date; allDay: boolean; color: string } })
            );
        },
        filteredAlarms(): Alarm[] {
            const selectedDate = new Date(Date.parse(this.date));
            return this.$props.alarms.filter((event) => {
                const date = new Date(Date.parse(event.date));
                return selectedDate.getFullYear() === date.getFullYear() && selectedDate.getMonth() === date.getMonth() && selectedDate.getDate() === date.getDate();
            });
        },
    },
});
</script>
