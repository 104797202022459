<template>
    <div class="space-y-4">
        <div class="flex mx-6 space-x-6">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t(title) }}</h4>
            <button type="button" class="bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3 flex" @click="fetchRecords">
                <span class="m-auto pr-2">
                    <Icon :src="IconSource.RefreshActivities"></Icon>
                </span>
                <span class="font-medium">{{ $t('views.activityTable.fetchActivitiesButtonLabel') }}</span>
            </button>
            <TableCalendarModal :events="records" :categories="recordTypes"></TableCalendarModal>
            <FilterModal v-model:selected-ids="selectedRecordTypeIds" :header="$t('views.activityTable.filterModal.header')" :sub-header="$t('views.activityTable.filterModal.subheader')" :types="recordTypes">
                <template #default="{ scope }">
                    <button type="button" class="flex items-center bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3" @click="scope.open">
                        <span class="m-auto pr-2">
                            <Icon :src="IconSource.Filter"></Icon>
                        </span>
                        <span class="font-medium">{{ $t('views.activityTable.filterButtonLabel') }}</span>
                        <span v-if="recordTypes.length && selectedRecordTypeIds.length !== recordTypes.length" class="bg-primary-800 rounded-full text-white font-medium leading-none px-2 py-1 ml-2">
                            {{ selectedRecordTypeIds.length }}
                        </span>
                    </button>
                </template>
            </FilterModal>
            <slot name="top-right" />
        </div>
        <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader" @closing="onActionModalClosing">
            <template #default="{ open }">
                <CustomTable :items="records" :headers="headers" clickable-items @item-clicked="(item) => onRecordClicked(item, open)">
                    <template #header_icon_url>
                        <th class="w-16"></th>
                    </template>

                    <template #header_actions>
                        <th class="w-6"></th>
                    </template>

                    <template #icon_url="{ item }">
                        <td class="w-16 my-auto">
                            <Icon class="w-10 h-10" :src="item.icon_url"></Icon>
                        </td>
                    </template>

                    <template #date="{ item, getHeaderClass, getWidth, header }">
                        <td class="flex-1" :class="`${getHeaderClass(header, true)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                            {{ $d(item.date, 'short') }}
                        </td>
                    </template>

                    <template #actions>
                        <td class="w-6 my-auto">
                            <span class="flex justify-end">
                                <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                            </span>
                        </td>
                    </template>
                </CustomTable>
            </template>
        </ActionModal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import FilterModal from '@/components/common/FilterModal.vue';
import TableCalendarModal from '@/components/common/TableCalendarModal.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Record, RecordType, RecordForm, MultiStepFormState, Event, TableHeader } from '@/types';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';

export default defineComponent({
    components: { CustomTable, FilterModal, TableCalendarModal, ActionModal },
    emits: ['fetch-records'],
    props: {
        selectableRecordTypeIds: {
            type: Array as () => number[],
            default: [],
        },
        title: {
            type: String,
            default: 'views.activityTable.title',
        },
        fetchOwnRecords: {
            type: Boolean,
            default: true,
        },
        useRecords: {
            type: Array as () => Record[],
            default: null,
        },
    },
    data() {
        return {
            selectedRecordTypeIds: [] as Number[],
            actionModalInitialState: undefined as undefined | MultiStepFormState,
            actionModalHeader: null as null | { icon_url: string; text: string },
            actionModalStepConfig: [{}, { deleteButton: true, editable: true }, { submitActionType: ActionType.PutRecordForm }, {}],
        };
    },
    computed: {
        headers(): TableHeader[] {
            return [
                {
                    text: '',
                    value: 'icon_url',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                },
                {
                    text: this.$t('views.activityTable.table.headers.type'),
                    value: 'name',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.activityTable.table.headers.date'),
                    value: 'date',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.activityTable.table.headers.amount'),
                    value: 'amount',
                    align: 'left',
                    sortable: false,
                    searchable: true,
                },
                {
                    text: this.$t('views.activityTable.table.headers.description'),
                    value: 'description',
                    align: 'left',
                    sortable: false,
                    searchable: true,
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'right',
                    sortable: false,
                    searchable: false,
                },
            ];
        },
        recordTypes(): RecordType[] {
            return store.state.recordTypes.filter((recordType) => this.$props.selectableRecordTypeIds.includes(recordType.id));
        },
        records(): Record[] {
            return (this.$props.useRecords || (store.getters.getTableRecords(this.selectableRecordTypeIds) as Record[])).filter((record) => this.selectedRecordTypeIds.includes(record.type_id));
        },
    },
    watch: {
        selectableRecordTypeIds: {
            immediate: true,
            handler(newValue) {
                this.selectedRecordTypeIds = [...newValue];
            },
        },
    },
    created() {
        store.dispatch(ActionType.GetRecordTypes);
    },
    methods: {
        async onRecordClicked(record: Record, open: CallableFunction) {
            const recordForm: RecordForm = await store.dispatch(ActionType.GetRecordForm, { form: { record_id: record.id } });
            this.actionModalInitialState = { location: null, form: recordForm.form, confirmed: false, farm: null, event: null, record_id: record.id, notification: null };
            this.actionModalStepConfig[1].deleteButton = recordForm.deletable;
            this.actionModalStepConfig[1].editable = recordForm.editable;
            this.actionModalHeader = { icon_url: record.icon_url || '', text: record.name || '' };
            open();
        },
        fetchRecords() {
            if (this.$props.fetchOwnRecords) {
                store.dispatch(ActionType.GetRecords, { options: { ignoreCache: true } });
            } else {
                this.$emit('fetch-records');
            }
        },
        onActionModalClosing(isFinalStep: boolean) {
            if (isFinalStep && !this.$props.fetchOwnRecords) {
                this.$emit('fetch-records');
            }
        },
    },
});
</script>
