<template>
    <EventButton dropdown-class="w-46" :events="batchEvents">
        <template #button="{ toggleExpandDropdown }">
            <button class="expand-button bg-primary-300 rounded-xl py-2 text-primary-800 hover:bg-opacity-90 transition-colors cursor-pointer" @click="toggleExpandDropdown">
                <span class="w-full flex space-x-2 content-center">
                    <span style="margin-top: 2px" class="font-semibold text-lg hidden lg:block">
                        {{ $t('layouts.dashboard.topBar.batchButton.label') }}
                    </span>
                </span>
            </button>
        </template>
    </EventButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Event, BatchEvent } from '@/types';
import EventButton from '@/components/layouts/dashboardLayout/topBar/events/EventButton.vue';

export default defineComponent({
    components: { DropDown, EventButton },
    computed: {
        batchEvents(): BatchEvent[] {
            return store.state.batchEvents.filter((currentBatchAction) => currentBatchAction.is_listed);
        },
    },
    created() {
        store.dispatch(ActionType.GetBatchEvents);
        store.dispatch(ActionType.GetActions);
    },
});
</script>

<style scoped>
.expand-button {
    padding-left: 1rem;
    padding-right: 1rem;
}
@media (min-width: 1120px) {
    .expand-button {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
</style>
