import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "text-right mb-3" }
const _hoisted_3 = ["min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentIndex) = $event)),
      style: _normalizeStyle(`background: linear-gradient(90deg, #FFB9C8 ${_ctx.progress}%, rgb(214, 214, 214) ${_ctx.progress}%);`),
      min: _ctx.min,
      max: _ctx.max,
      type: "range",
      class: "slider"
    }, null, 12, _hoisted_3), [
      [_vModelText, _ctx.currentIndex]
    ])
  ]))
}