<template>
    <div v-if="initials.length" class="rounded-full w-9 h-9 grid content-center justify-center border-solid border-2 text-sm border-light-blue-500 text-white">
        {{ initials }}
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { User } from '@/types';

export default defineComponent({
    props: {
        user: {
            type: Object as () => null | User,
            default: null,
        },
    },
    computed: {
        // Capitalized version of the first and last name
        initials(): string {
            return `${this.user?.first_name[0] || ''}${this.user?.last_name[0] || ''}`;
        },
    },
});
</script>
