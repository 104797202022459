import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg bg-gray-50 overflow-hidden shadow-md" }
const _hoisted_2 = ["aria-controls", "aria-expanded"]
const _hoisted_3 = { class: "text-lg flex-grow font-semibold mr-2" }
const _hoisted_4 = ["id"]

import { computed, ref } from 'vue';
import { useElementSize } from '@vueuse/core';
import { getUniqueId } from '@/util/uniqueId';
import Icon from '@/components/icons/Icon.vue';
import { IconSource } from '@/types';

interface Props {
    show?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpansionPanel',
  props: {
    show: { type: Boolean, default: undefined }
  },
  emits: ["update:show"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const internalShow = ref(false);
const show = computed(() => props.show ?? internalShow.value);
const contents = ref<HTMLElement>();

const { height: targetHeight } = useElementSize(contents, undefined, {
    box: 'border-box',
});

const height = computed(() => (show.value ? targetHeight.value : 0));
const id = computed(() => `expansion-panel-${getUniqueId()}`);

function toggle() {
    if (props.show === undefined) {
        internalShow.value = !internalShow.value;
    } else {
        emit('update:show', !props.show);
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      "aria-controls": id.value,
      "aria-expanded": show.value,
      class: _normalizeClass(["relative z-10 flex bg-white hover:bg-gray-50 duration-300 filter drop-shadow rounded-t-lg w-full cursor-pointer justify-between items-center py-6 px-6", { 'rounded-b-lg': !show.value }]),
      style: {"transition-property":"background-color, border-radius","transition-timing-function":"cubic-bezier(0.4, 0, 0.2, 1)"},
      onClick: toggle
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _createVNode(Icon, {
        src: _unref(IconSource).Right,
        class: _normalizeClass(["h-3.5 w-3.5 flex-shrink-0 transform transition-transform duration-300 rotate-90", { 'rotate-[-90deg]': show.value }]),
        "aria-hidden": "true"
      }, null, 8, ["src", "class"])
    ], 10, _hoisted_2),
    _createElementVNode("div", {
      id: id.value,
      tabindex: "-1",
      style: _normalizeStyle({
                height: `${height.value}px`,
            }),
      class: "overflow-hidden transition-height duration-300 will-change-height"
    }, [
      _createElementVNode("div", {
        ref_key: "contents",
        ref: contents
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 512)
    ], 12, _hoisted_4)
  ]))
}
}

})