import { ValidatableRule } from '@/mixins/validatableField';
import { i18n } from '@/plugins/internationalization/i18n';
import { FormElement } from '@/types';

/* For objects with price arrays (current RawMaterial and Mix), returns the Price-object with the latest valid_from */
export default function dynamicFormRules(formElement: FormElement) {
    let rules: ValidatableRule[] = [];

    if (!formElement.required) {
        return rules;
    }

    switch (formElement.type_settings.input_type) {
        case 'string': {
            rules = [
                {
                    validate: (value: string | null) => formElement.type_settings.minimum_value === null || (typeof value === 'string' && value.length >= formElement.type_settings.minimum_value),
                    message: i18n.global.t('global.forms.steps.dynamicForm.hints.strings.minLength').replace('$VALUE', formElement.type_settings.minimum_value),
                },
                {
                    validate: (value: string | null) => value === null || formElement.type_settings.maximum_value === null || value.length <= formElement.type_settings.maximum_value,
                    message: i18n.global.t('global.forms.steps.dynamicForm.hints.strings.maxLength').replace('$VALUE', formElement.type_settings.minimum_value),
                },
            ];
            break;
        }
        case 'int': {
            rules = [
                {
                    validate: (value: string | number | null) => typeof value === 'number' && Number.isInteger(value),
                    message: i18n.global.t('global.forms.steps.dynamicForm.hints.integers.isInteger'),
                },
                {
                    validate: (value: number) => value >= (typeof formElement.type_settings.minimum_value === 'number' ? formElement.type_settings.minimum_value : Number.MIN_SAFE_INTEGER),
                    message: i18n.global.t('global.forms.steps.dynamicForm.hints.integers.min').replace('$VALUE', formElement.type_settings.minimum_value),
                },
                {
                    validate: (value: number) => value <= (typeof formElement.type_settings.maximum_value === 'number' ? formElement.type_settings.maximum_value : Number.MAX_SAFE_INTEGER),
                    message: i18n.global.t('global.forms.steps.dynamicForm.hints.integers.max').replace('$VALUE', formElement.type_settings.maximum_value),
                },
            ];
            break;
        }
        case 'double': {
            // chartspread uses maximum and minimum values differently from all other number fields
            if (formElement.type === 'chartspread') {
                rules = [
                    {
                        validate: (value: string | number | null) => typeof value === 'number',
                        message: i18n.global.t('global.forms.steps.dynamicForm.hints.doubles.isNumber'),
                    },
                ];
            } else {
                rules = [
                    {
                        validate: (value: string | number | null) => typeof value === 'number',
                        message: i18n.global.t('global.forms.steps.dynamicForm.hints.doubles.isNumber'),
                    },
                    {
                        validate: (value: number) => value >= (typeof formElement.type_settings.minimum_value === 'number' ? formElement.type_settings.minimum_value : Number.MIN_SAFE_INTEGER),
                        message: i18n.global.t('global.forms.steps.dynamicForm.hints.doubles.min').replace('$VALUE', formElement.type_settings.minimum_value),
                    },
                    {
                        validate: (value: number) => value <= (typeof formElement.type_settings.maximum_value === 'number' ? formElement.type_settings.maximum_value : Number.MAX_SAFE_INTEGER),
                        message: i18n.global.t('global.forms.steps.dynamicForm.hints.doubles.max').replace('$VALUE', formElement.type_settings.maximum_value),
                    },
                ];
            }
            break;
        }
        case 'keyvaluepair': {
            rules = [
                {
                    validate: (value: any) => value !== null && value !== '',
                    message: i18n.global.t('global.forms.steps.dynamicForm.hints.keyValuePairs.notEmpty'),
                },
            ];
            break;
        }
        default: {
            rules = [];
        }
    }

    return rules;
}
