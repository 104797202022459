import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]

import { HelpWidgetUrlItem } from '@/types/HelpWidget';
import Icon from '@/components/icons/Icon.vue';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';

interface Props {
    item: HelpWidgetUrlItem;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpWidgetUrlItem',
  props: {
    item: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DropdownMenuItem), {
    "as-child": "",
    class: "gap-x-2.5 text-base"
  }, {
    default: _withCtx(() => [
      _createElementVNode("a", {
        href: _ctx.item.url,
        target: "_blank"
      }, [
        (_ctx.item.icon_id !== null && _ctx.item.icon_id !== undefined)
          ? (_openBlock(), _createBlock(Icon, {
              key: 0,
              src: _ctx.item.icon_id,
              class: "flex-shrink-0 size-5"
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.item.text), 1)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}
}

})