<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.economy.budget.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <div class="space-y-6">
            <MainViewCard>
                <EconomyBudgetTable></EconomyBudgetTable>
            </MainViewCard>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import EconomyBudgetTable from '@/views/dashboard/economy/economyBudget/EconomyBudgetTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';

export default defineComponent({
    components: { DashboardContentLayout, EconomyBudgetTable, MainViewCard },
});
</script>

<style scoped></style>
