<template>
    <div>
        <div v-if="user" class="location-type-tabs default-tabs">
            <Tabs teleport="#location-type-tabs-target" active-tab-class="--active">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-8">
                        <Tab :name="$t('views.settings.locations.tabs.farms')" :default="true">
                            <Farms :can-manage-directory="canManageDirectory" :search="search" class="mt-8"></Farms>
                        </Tab>
                    </div>
                    <div class="flex pr-8">
                        <Tab :name="$t('views.settings.locations.tabs.barns')">
                            <Barns :can-manage-directory="canManageDirectory" :search="search" class="mt-8"></Barns>
                        </Tab>
                    </div>
                    <div v-if="enableSectionsModule" class="flex">
                        <Tab :name="$t('views.settings.locations.tabs.sections')">
                            <Sections :can-manage-directory="canManageDirectory" :search="search" class="mt-8"></Sections>
                        </Tab>
                    </div>
                </nav>
            </Tabs>

            <div id="location-type-tabs-target" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import Farms from '@/views/dashboard/settings/locations/Farms.vue';
import Barns from '@/views/dashboard/settings/locations/Barns.vue';
import Sections from '@/views/dashboard/settings/locations/Sections.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Module } from '@/types';

export default defineComponent({
    components: { DashboardContentLayout, SearchInput, Tab, Tabs, Farms, Barns, Sections },
    props: {
        search: {
            type: String,
            default: '',
        },
    },
    computed: {
        canManageDirectory(): boolean {
            return store.getters.loggedInUserCanManageDirectory;
        },
        user() {
            return store.state.user;
        },
        enableSectionsModule(): boolean {
            return store.getters.hasModuleEnabled(Module.Sections);
        },
    },
    created() {
        store.dispatch(ActionType.GetDirectoryFarms);
        store.dispatch(ActionType.GetDirectoryBarns);
        store.dispatch(ActionType.GetDirectorySections);
        store.dispatch(ActionType.GetActions);
        store.dispatch(ActionType.GetSettingEvents);
    },
});
</script>
