<template>
    <div class="w-full relative overflow-hidden text-dark-gray-800 text-opacity-40 font-light">
        <input
            :id="uid"
            v-model="inputValue"
            :class="backgroundColor"
            class="cursor-text font-light w-full py-2 pl-10 pr-1 rounded-xl outline-none"
            type="text"
            @focus="$emit('focus')"
            @keydown.enter.prevent="$emit('submit')"
            @blur="$emit('blur')"
            @keyup.esc="onEscape"
            @keydown.tab="$emit('onTab')"
        />
        <label :for="uid" class="flex absolute top-2 left-2 h-6">
            <Icon class="my-auto pr-2 cursor-text" :src="IconSource.Search"></Icon>
            <span v-show="!inputValue" class="pl-0.5 cursor-text">{{ label }}</span>
        </label>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
        preventEscPropagation: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: 'bg-dark-gray-200',
        },
    },
    emits: ['update:value', 'focus', 'blur', 'submit', 'onEscape', 'onTab'],
    data() {
        return {
            uid: `input-${Math.random().toString(36).substring(6)}`,
            inputValue: this.$props.value,
        };
    },
    computed: {
        computedValue: {
            get(): string {
                return this.value;
            },
            set(newValue: string) {
                this.$emit('update:value', newValue);
            },
        },
    },
    watch: {
        inputValue(newValue: string) {
            if (this.computedValue !== newValue) {
                this.computedValue = newValue;
            }
        },
        value(newValue: string) {
            if (this.inputValue !== newValue) {
                this.inputValue = newValue;
            }
        },
    },
    methods: {
        onEscape(event: Event) {
            if (this.$props.preventEscPropagation) {
                event.stopPropagation();
            }
            this.$emit('onEscape');
        },
    },
});
</script>
