<template>
    <div>
        <div ref="chart"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import NormalDistribution from 'normal-distribution';
import * as Highcharts from 'highcharts';
import round from 'lodash-es/round';
import colors from '@/plugins/tailwind';

export default defineComponent({
    props: {
        median: {
            type: Number,
            default: 20,
        },
        spread: {
            type: Number,
            default: 2,
        },
        maxQuartileMultiplier: {
            type: Number,
            default: 0.2,
        },
        yAxisMultiplier: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            chart: null as any,
        };
    },
    computed: {
        options(): any {
            const axis = this.getAxis();
            const values = this.getValues(axis);
            const weight = this.$t('layouts.dashboard.charts.distribution.weight');
            const distribution = this.$t('layouts.dashboard.charts.distribution.distribution');
            const yAxisMultiplier = this.yAxisMultiplier || 1;

            return {
                chart: {
                    type: 'column',
                },

                legend: {
                    enabled: false,
                },

                credits: {
                    enabled: false,
                },

                title: {
                    text: '',
                },

                colors: [colors.pigs, colors.deaths, colors.feed[400], colors.primary[800], colors.batch],
                xAxis: {
                    categories: axis,
                    gridLineWidth: 1,
                    title: {
                        text: '',
                    },
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: '',
                    },
                    labels: {
                        formatter(): number {
                            return round((this as any).value * yAxisMultiplier);
                        },
                    },
                },
                plotOptions: {
                    series: {
                        groupPadding: 0,
                        pointPadding: 0.05,
                        borderWidth: 0,
                    },
                },
                tooltip: {
                    formatter(): string {
                        return `<span class="text-xs">${weight}: ${(this as any).point.category}</span><br><span class="text-sm">${distribution}: ${Math.round((this as any).point.y * 100)} %</span>`;
                    },
                },
                series: [
                    {
                        name: '',
                        data: values,
                    },
                ],
            };
        },
    },
    watch: {
        spread(newValue: number) {
            this.chart.update(this.options);
        },
    },
    mounted() {
        this.chart = Highcharts.chart(this.$refs.chart as HTMLElement, this.options);
    },
    beforeUnmount() {
        if (this.chart) {
            (this.chart as any).destroy();
        }
    },
    methods: {
        getValues(axis: number[]): number[] {
            const normDist = new NormalDistribution(this.median, this.spread);

            let values = axis.map((axisValue) => normDist.pdf(axisValue));
            if (values.length === 1 && !values[0]) {
                values = [1];
            }
            return values;
        },
        getAxis(): number[] {
            const numberOfValues = 20;
            const maxQuartile = this.median * this.maxQuartileMultiplier;
            let values = new Array(numberOfValues);
            values = Array.from(values).map((value, index) => round(this.median - maxQuartile + index * (maxQuartile / (numberOfValues / 2)), 1));
            values.shift();

            return values;
        },
    },
});
</script>
