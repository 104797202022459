import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center space-x-3" }
const _hoisted_2 = { class: "flex items-center space-x-3" }
const _hoisted_3 = { class: "font-bold text-lg" }
const _hoisted_4 = { class: "text-sm text-gray-500" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "pt-6 space-y-6 border-t border-t-gray-200"
}
const _hoisted_7 = { class: "flex justify-between items-center gap-x-3" }
const _hoisted_8 = { class: "text-lg font-medium" }
const _hoisted_9 = { class: "flex items-center justify-end gap-x-4" }
const _hoisted_10 = {
  key: 0,
  class: "text-center font-medium pt-6 pb-2 border-t border-t-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ChartPeriodSelect = _resolveComponent("ChartPeriodSelect")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_WaterChart = _resolveComponent("WaterChart")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["space-y-4 px-4 py-4", { 'expanded-barn': _ctx.isExpanded }]),
        style: {"scroll-margin-top":"120px"}
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_Icon, {
                class: "h-6",
                src: _ctx.IconSource.Location
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.barn.headers.barn_name), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.barn.rows?.length || 0) + " " + _toDisplayString(_ctx.barn.rows?.length === 1 ? _ctx.$t('views.monitoring.main.headings.section') : _ctx.$t('views.monitoring.main.headings.sectionPlural')), 1)
            ])
          ]),
          (_ctx.barn.rows && _ctx.barn.rows.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: "text-sky-500 hover:text-sky-700 transition-colors p-2 -mr-2",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleExpandBarn(_ctx.barn.headers.barn_id)), ["prevent"]))
                }, _toDisplayString(_ctx.isExpanded ? _ctx.$t('views.monitoring.main.buttons.hideSections') : _ctx.$t('views.monitoring.main.buttons.viewSections')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ExpandCollapseTransition, null, {
          default: _withCtx(() => [
            (_ctx.isExpanded && _ctx.barn.rows && _ctx.barn.rows.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.isHourly ? _ctx.$t('views.monitoring.charts.titles.waterConsumptionLiterPerHour') : _ctx.$t('views.monitoring.charts.titles.waterConsumptionLiterPerDay')), 1),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_ChartPeriodSelect, {
                        modelValue: _ctx.period,
                        "onUpdate:modelValue": [
                          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.period) = $event)),
                          _ctx.triggerDelayedLoading
                        ],
                        disabled: _ctx.isLoading || _ctx.isDelayedLoading
                      }, null, 8, ["modelValue", "disabled", "onUpdate:modelValue"]),
                      _createVNode(_component_SwitchButton, {
                        modelValue: _ctx.isHourly,
                        "onUpdate:modelValue": [
                          _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isHourly) = $event)),
                          _ctx.triggerDelayedLoading
                        ],
                        "true-text": _ctx.$t('views.monitoring.main.charts.hourlyLabel'),
                        "false-text": _ctx.$t('views.monitoring.main.charts.dailyLabel'),
                        disabled: _ctx.isLoading || _ctx.isDelayedLoading
                      }, null, 8, ["modelValue", "true-text", "false-text", "disabled", "onUpdate:modelValue"])
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.barn.rows, (row) => {
                    return (_openBlock(), _createBlock(_component_WaterChart, {
                      key: row.section_id,
                      row: row
                    }, null, 8, ["row"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (!(_ctx.barn.rows && _ctx.barn.rows.length))
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('views.monitoring.main.tables.noSectionsText')), 1))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }))
}