import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { HTMLAttributes, computed } from 'vue';
import { SelectItem, SelectItemProps, SelectItemText, useForwardProps } from 'radix-vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectItem',
  props: {
    value: {},
    disabled: { type: Boolean },
    textValue: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectItem), _mergeProps(_unref(forwardedProps), {
    class: 
            _unref(cn)(
                'relative flex w-full cursor-pointer select-none items-center py-2 px-3 text-base outline-none truncate data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                'hover:bg-gray-100 data-[state=checked]:!bg-primary-300 data-[state=checked]:!bg-opacity-40',
                'data-[highlighted]:bg-gray-100',
                props.class
            )
        
  }), {
    default: _withCtx(() => [
      _createVNode(_unref(SelectItemText), null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})