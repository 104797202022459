<template>
    <DashboardContentLayout class="space-y-8" :heading="$t(heading)" :has-period-picker="true" :has-pig-type-picker="true">
        <MainViewCard>
            <ActivityTable class="pt-4" :selectable-record-type-ids="filteredRecordTypeIds"></ActivityTable>
        </MainViewCard>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import ActivityTable from '@/components/common/activityTable/ActivityTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { ActivityTableRecordType, Module } from '@/types';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { DashboardContentLayout, ActivityTable, MainViewCard },
    props: {
        recordTypeIds: {
            type: Array as () => ActivityTableRecordType[],
            default: [],
        },
        heading: {
            type: String,
            default: '',
        },
    },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetRecords, { watchSites: true, watchFarmsInsteadOfSites: false, watchFormSubmissions: true });
    },
    computed: {
        filteredRecordTypeIds() {
            const excludedIds: number[] = [];

            if (!store.getters.hasModuleEnabled(Module.Medicine)) {
                excludedIds.push(ActivityTableRecordType.Treatment);
            }

            return this.recordTypeIds.filter((id) => excludedIds.includes(id) === false);
        },
    },
});
</script>
