<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@/lib/utils';

const props = defineProps<{
    class?: HTMLAttributes['class'];
}>();
</script>

<template>
    <tr :class="cn('group border-b transition-colors hover:bg-gray-50 data-[state=selected]:bg-primary-300/30', props.class)">
        <slot />
    </tr>
</template>
