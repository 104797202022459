import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActivityTable = _resolveComponent("ActivityTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t(_ctx.heading),
    "has-period-picker": true,
    "has-pig-type-picker": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MainViewCard, null, {
        default: _withCtx(() => [
          _createVNode(_component_ActivityTable, {
            class: "pt-4",
            "selectable-record-type-ids": _ctx.filteredRecordTypeIds
          }, null, 8, ["selectable-record-type-ids"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["heading"]))
}