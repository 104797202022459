import { vModelCheckbox as _vModelCheckbox, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = ["id", "disabled", "tabindex"]
const _hoisted_3 = {
  viewBox: "0 0 21 21",
  "aria-hidden": "true"
}
const _hoisted_4 = {
  key: 0,
  points: "5 10.75 8.5 14.25 16 6"
}
const _hoisted_5 = {
  key: 1,
  x1: "5",
  y1: "10.75",
  x2: "16",
  y2: "10.75"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass([[{ hoverable: _ctx.hoverable, tabbable: _ctx.tabbable, 'cursor-pointer': !_ctx.disabled, indeterminate: _ctx.indeterminate }], "checkbox bounce flex"]),
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
    }, [
      _withDirectives(_createElementVNode("input", {
        id: _ctx.uid + '_input',
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedChecked) = $event)),
        class: _normalizeClass([_ctx.cursorClass]),
        disabled: _ctx.disabled,
        tabindex: _ctx.tabbable ? undefined : '-1',
        type: "checkbox",
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.computedChecked = !_ctx.computedChecked), ["enter"]))
      }, null, 42, _hoisted_2), [
        [_vModelCheckbox, _ctx.computedChecked]
      ]),
      (_openBlock(), _createElementBlock("svg", _hoisted_3, [
        (!_ctx.indeterminate)
          ? (_openBlock(), _createElementBlock("polyline", _hoisted_4))
          : (_openBlock(), _createElementBlock("line", _hoisted_5))
      ])),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([_ctx.labelClass, "ml-3 -mt-1"])
          }, _toDisplayString(_ctx.label), 3))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}