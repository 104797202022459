import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative raw-material-date-picker" }
const _hoisted_2 = ["disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatPickrWrapper = _resolveComponent("FlatPickrWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FlatPickrWrapper, {
      value: _ctx.computedValue,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValue) = $event)),
      show: _ctx.expandPicker,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expandPicker) = $event)),
      config: _ctx.flatpickrConfig,
      "auto-width": "",
      mode: "single"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          disabled: _ctx.disabled,
          readonly: "",
          type: "string",
          placeholder: _ctx.placeholder,
          class: "py-1 w-full cursor-text text-center outline-none bg-white rounded",
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandPicker = true))
        }, null, 40, _hoisted_2), [
          [_vModelText, _ctx.inputValue]
        ])
      ]),
      _: 1
    }, 8, ["value", "show", "config"])
  ]))
}