<template>
    <div class="h-full">
        <h2 class="text-xl font-semibold text-center">{{ text }}</h2>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    emits: ['close', 'submission'],
    data() {
        return {
            timeout: setTimeout(() => {
                this.$emit('close');
            }, 2000),
        };
    },
    mounted() {
        this.$emit('submission');
    },
    beforeUnmount() {
        clearTimeout(this.timeout);
    },
});
</script>
