<template>
    <div class="h-full pb-6">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.settings.users.content.modals.edit.steps.submission.success') }}</h2>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import { EditUserForm } from '@/types';

export default defineComponent({
    emits: ['close'],
    props: {
        form: {
            type: Object as () => Form<EditUserForm> & EditUserForm,
            required: true,
        },
    },
    mounted() {
        setTimeout(() => this.$emit('close'), 2000);
    },
});
</script>
