import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvalidScreenSizeWarning = _resolveComponent("InvalidScreenSizeWarning")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_InvalidScreenSizeWarning, null, null, 512), [
      [_vShow, _ctx.windowWidth < 1024]
    ]),
    _createVNode(_component_AuthLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}