<template>
    <transition-group
        v-if="group"
        enter-active-class="collapse-expand-enter-active"
        leave-active-class="collapse-expand-leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave">
        <slot />
    </transition-group>
    <transition
        v-else
        enter-active-class="collapse-expand-enter-active"
        leave-active-class="collapse-expand-leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot />
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        group: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        beforeEnter(element: any) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = '0px';
                }

                element.style.display = null;
            });
        },
        enter(element: any) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = `${element.scrollHeight}px`;
                });
            });
        },
        afterEnter(element: any) {
            element.style.height = null;
        },
        beforeLeave(element: any) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = `${element.offsetHeight}px`;
                }
            });
        },
        leave(element: any) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = '0px';
                });
            });
        },
        afterLeave(element: any) {
            element.style.height = null;
        },
    },
});
</script>

<style>
.collapse-expand-enter-active,
.collapse-expand-leave-active {
    overflow: hidden;
    transition: height 0.2s linear;
}
</style>
