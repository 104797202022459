import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex mx-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "text-right w-80" }
const _hoisted_4 = { class: "w-8 my-auto" }
const _hoisted_5 = { class: "flex justify-end" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_CreateAssignmentModal = _resolveComponent("CreateAssignmentModal")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, { class: "pt-2 space-y-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.assignments.tabs.assignments.tables.assignments.header')), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_SearchInput, {
            value: _ctx.search,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            label: _ctx.$t('views.assignments.tabs.assignments.tables.assignments.searchFieldLabel'),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.search = ''), ["prevent"]), ["esc"]))
          }, null, 8, ["value", "label"])
        ]),
        _createVNode(_component_CreateAssignmentModal, { onClosing: _ctx.onCreateTaskClose }, {
          default: _withCtx(({ open }) => [
            (!_ctx.completedTasks)
              ? (_openBlock(), _createBlock(_component_AddButton, {
                  key: 0,
                  "text-class": "font-medium",
                  class: "ml-6",
                  onClick: open
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.assignments.tabs.assignments.tables.assignments.addButtonLabel')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onClosing"])
      ]),
      _createVNode(_component_ActionModal, {
        "initial-step-index": 1,
        "initial-state": _ctx.actionModalInitialState,
        "step-config": _ctx.actionModalStepConfig,
        header: _ctx.actionModalHeader,
        assignment: _ctx.assignmentToEdit,
        onSubmission: _ctx.onEditSubmission
      }, {
        default: _withCtx(({ open }) => [
          _createVNode(_component_CustomTable, {
            headers: _ctx.headers,
            items: _ctx.filteredItems
          }, {
            header_actions: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("th", { class: "w-8" }, null, -1)
            ])),
            actions: _withCtx(({ item }) => [
              _createElementVNode("td", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: ($event: any) => (_ctx.onAssignmentClicked(item, open)),
                    class: "p-2 -mr-2"
                  }, [
                    _createVNode(_component_Icon, {
                      class: "h-3 w-3 cursor-pointer",
                      src: _ctx.IconSource.Right
                    }, null, 8, ["src"])
                  ], 8, _hoisted_6)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["headers", "items"])
        ]),
        _: 1
      }, 8, ["initial-state", "step-config", "header", "assignment", "onSubmission"])
    ]),
    _: 1
  }))
}