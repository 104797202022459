import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed z-[2000] inset-0 flex items-center justify-center h-full bg-background/50 backdrop-blur-sm"
}
const _hoisted_2 = { class: "flex items-center gap-3 p-4" }
const _hoisted_3 = { class: "text-xl font-medium" }

import { useLoadingPageOverlayState } from '@/composables/useLoadingPageOverlayState';
import Spinner from '@/components/ui/Spinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingPageOverlay',
  setup(__props) {

const { isLoading, text } = useLoadingPageOverlayState();

return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(text)), 1),
          _createVNode(Spinner, {
            class: "size-6",
            "aria-hidden": "true"
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})