<template>
    <GenericMultiStepModal :header-icon="IconSource.Food" :is-final-step="false" @closing="onModalClose">
        <template #default="scope">
            <slot v-bind="scope" />
        </template>
        <template #component="scope">
            <component :is="currentStep.component" v-model:rawMaterial="form" @submit="next" @close="scope.close"></component>
        </template>
    </GenericMultiStepModal>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import RawMaterialProperties from '@/views/dashboard/feed/feedRawMaterials/editRawMaterialModal/RawMaterialProperties.vue';
import DeleteSuccess from '@/views/dashboard/feed/feedRawMaterials/editRawMaterialModal/DeleteSuccess.vue';
import { RawMaterial } from '@/types';
import useResettableForm from '@/components/common/composables/useResettableForm';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import GenericMultiStepModal from '@/components/common/GenericMultiStepModal.vue';

export default defineComponent({
    components: { GenericMultiStepModal, MultiStepFormHeaderIcon, CloseModalButton },
    props: {
        rawMaterial: {
            type: Object as () => RawMaterial,
            required: true,
        },
    },
    setup(props) {
        const resettableForm = useResettableForm().setInitial(props.rawMaterial);
        const form = resettableForm.getInstance();

        watch(
            () => props.rawMaterial,
            (newValue) => {
                if (newValue) {
                    resettableForm.setInitial(props.rawMaterial);
                }
            }
        );

        const multiSteps = useMultiSteps([
            {
                component: RawMaterialProperties,
            },
            {
                component: DeleteSuccess,
            },
        ]).setInitial(0);

        const onModalClose = () => {
            multiSteps.reset();
            resettableForm.reset();
        };

        return {
            ...multiSteps,
            onModalClose,
            form,
        };
    },
});
</script>
