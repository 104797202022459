<template>
    <div class="flex flex-col space-y-8">
        <div class="select-date-tabs">
            <Tabs teleport="#date-tabs-target" active-tab-class="--active">
                <nav class="grid grid-cols-5 mb-4 w-full gap-2">
                    <Tab v-for="(report, index) of state.reports" :key="report.name" :slug="`${index + 1}`" :name="report.name" :default="index === 0" @click="onTabClicked(report)">
                        <div class="space-y-8 w-full">
                            <MainViewCard class="pt-4 pb-2 px-2">
                                <PigsStatusGraph :report-title="report.name" :data="report.graph"></PigsStatusGraph>
                            </MainViewCard>
                            <div class="flex flex-col xl:flex-row space-y-8 xl:space-x-6 xl:space-y-0">
                                <ReportsEntriesTable :empty-rows="emptyEntryTableRows" :data="report.entriesTable" :records="report.records" @fetch-records="fetchRecords"></ReportsEntriesTable>
                                <ReportsDeparturesTable :data="report.departuresTable" :records="report.records" @fetch-records="fetchRecords"></ReportsDeparturesTable>
                            </div>
                        </div>
                    </Tab>
                </nav>
            </Tabs>

            <div id="date-tabs-target" class="flex justify-center w-full" />
        </div>
        <div class="flex justify-end">
            <div class="grid grid-cols-2 space-x-4">
                <CustomButton class="max-w-32 justify-self-end" color-preset="secondary" @click="onResetClicked">
                    {{ $t('views.reports.resetButtonLabel') }}
                </CustomButton>
                <EControlModal :state="state"></EControlModal>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import CustomButton from '@/components/ui/CustomButton.vue';
import EControlModal from '@/views/dashboard/reports/reportsPage/EControlModal.vue';
import PigsStatusGraph from '@/views/dashboard/reports/reportsPage/PigsStatusGraph.vue';
import ReportsEntriesTable from '@/views/dashboard/reports/reportsPage/ReportsEntriesTable.vue';
import ReportsDeparturesTable from '@/views/dashboard/reports/reportsPage/ReportsDeparturesTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { ReportViewState, Report, RecordResponse } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { CustomButton, EControlModal, PigsStatusGraph, MainViewCard, ReportsEntriesTable, ReportsDeparturesTable, Tab, Tabs },
    emits: ['reset'],
    props: {
        state: {
            type: Object as () => ReportViewState,
            required: true,
        },
    },
    data() {
        return {
            activeReport: this.$props.state.reports[0],
        };
    },
    computed: {
        emptyEntryTableRows() {
            return Math.max(this.activeReport.departuresTable.items.length - this.activeReport.entriesTable.items.length, 0);
        },
    },
    created() {
        store.dispatch(ActionType.GetRecordTypes).then(this.fetchRecords);
    },
    methods: {
        onTabClicked(report: Report) {
            this.activeReport = report;
            this.fetchRecords();
        },
        onResetClicked() {
            this.$emit('reset');
        },
        async fetchRecords() {
            const recordResponse: RecordResponse = await store.dispatch(ActionType.GetReportRecords, {
                options: { ignoreCache: true },
                form: {
                    siteIds: this.activeReport.site_ids,
                    period: `from=${this.activeReport.from}&to=${this.activeReport.to}`,
                },
            });

            this.activeReport.records = recordResponse.data.map((record) => {
                const recordType = store.state.recordTypes.find((currentRecordType) => currentRecordType.id === record.type_id);
                return { ...record, icon_url: recordType?.icon_url, name: recordType?.name };
            });
        },
    },
});
</script>

<style scoped>
.select-date-tabs nav a {
    border-width: 1px;
    border-radius: 0.5rem;
    @apply border-opacity-20;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    font-weight: 600;
    background-color: white;
    text-align: center;
}

.select-date-tabs nav a.--active {
    @apply bg-primary-800;
    @apply border-primary-700;
    color: white;
}
</style>
