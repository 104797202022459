<template>
    <div class="space-y-6 min-h-40">
        <h2 class="text-xl font-semibold text-center">{{ state.event?.name || '' }}</h2>
        <Spinner class="w-16 h-16 mx-auto" :active="true"></Spinner>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import Spinner from '@/components/ui/Spinner.vue';
import { Form as FormType, Action, MultiStepFormState, DirectoryFarm, DirectoryBarn, Directory, Section } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { Spinner },
    emits: ['update:state', 'submit'],
    props: {
        state: {
            type: Object as () => Form<MultiStepFormState> & MultiStepFormState,
            required: true,
        },
        config: {
            type: Object as () => { directory?: Directory },
            required: true,
        },
    },
    computed: {
        computedState: {
            get(): Form<MultiStepFormState> {
                return this.$props.state;
            },
            set(newValue: Form<MultiStepFormState>) {
                this.$emit('update:state', newValue);
            },
        },
        activeAction(): Action | undefined {
            return store.state.actions.find((currentAction) => currentAction.id === this.$props.state.event?.action_id);
        },
    },
    watch: {
        activeAction: {
            immediate: true,
            deep: true,
            handler(newValue: Action | undefined) {
                if (newValue) {
                    store
                        .dispatch(ActionType.GetForm, {
                            form: {
                                form_id: newValue.form_id,
                                farm_id: (this.state.farm as DirectoryFarm | undefined)?.id,
                                barn_id: (this.state.location as DirectoryBarn | undefined)?.id,
                                section_id: this.state.section?.id,
                                directory_id: this.config.directory?.id,
                            },
                        })
                        .then((form: FormType) => {
                            Object.assign(this.computedState, { form });
                            this.$emit('submit');
                        });
                }
            },
        },
    },
});
</script>
