import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataQualityStatusBar = _resolveComponent("DataQualityStatusBar")!
  const _component_TooltipTrigger = _resolveComponent("TooltipTrigger")!
  const _component_TooltipContent = _resolveComponent("TooltipContent")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_TooltipProvider = _resolveComponent("TooltipProvider")!

  return (_ctx.qualityAssessmentCategory)
    ? (_openBlock(), _createBlock(_component_TooltipProvider, {
        key: 0,
        "delay-duration": 200
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, null, {
            default: _withCtx(() => [
              _createVNode(_component_TooltipTrigger, { "as-child": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DataQualityStatusBar, _mergeProps({ "quality-assessment-category": _ctx.qualityAssessmentCategory }, _ctx.$attrs), null, 16, ["quality-assessment-category"])
                ]),
                _: 1
              }),
              _createVNode(_component_TooltipContent, { "collision-padding": 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.qualityAssessmentCategory?.name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}