import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["tabindex", "onClick", "onKeydown"]
const _hoisted_2 = {
  class: "py-2 font-medium",
  role: "menuitem"
}
const _hoisted_3 = {
  style: {"grid-template-columns":"2em auto"},
  class: "grid gap-2"
}
const _hoisted_4 = { class: "grid content-center" }
const _hoisted_5 = { class: "h-full text-gray-800 inline-flex items-center" }
const _hoisted_6 = {
  style: {"grid-template-columns":"auto 1.5em"},
  class: "w-full grid"
}
const _hoisted_7 = {
  style: {"grid-template-columns":"2em auto"},
  class: "grid gap-x-4"
}
const _hoisted_8 = { class: "grid content-center" }
const _hoisted_9 = { class: "grid grid-cols-1 pt-1" }
const _hoisted_10 = { class: "text-white h-5 text-sm overflow-hidden" }
const _hoisted_11 = { class: "text-gray-300 text-opacity-60 text-xs overflow-hidden" }
const _hoisted_12 = { class: "content-center justify-end grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_UserInitialsIcon = _resolveComponent("UserInitialsIcon")!
  const _component_ExpandIcon = _resolveComponent("ExpandIcon")!
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createBlock(_component_DropDown, {
    expand: _ctx.expandDropdown,
    "onUpdate:expand": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.expandDropdown) = $event)),
    "dropdown-class": "mt-1 w-60"
  }, {
    "dropdown-content": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settings, (setting, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: setting.name,
          tabindex: index,
          class: "text-left w-full px-6 py-2 hover:bg-gray-100 first:rounded-t-md last:rounded-b-md cursor-pointer",
          onClick: ($event: any) => (setting.onSettingClicked()),
          onKeydown: [
            _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.expandDropdown = false), ["esc"])),
            _withKeys(($event: any) => (setting.onSettingClicked()), ["enter"])
          ]
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Icon, {
                  src: setting.icon
                }, null, 8, ["src"])
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(setting.name), 1)
            ])
          ])
        ], 40, _hoisted_1))
      }), 128))
    ]),
    default: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "h-full w-full grid cursor-pointer text-left",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandDropdown = !_ctx.expandDropdown))
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_UserInitialsIcon, { user: _ctx.user }, null, 8, ["user"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('layouts.dashboard.topBar.settings.header')), 1),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.user?.email || ''), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ExpandIcon, {
              color: "white",
              expand: _ctx.expandDropdown
            }, null, 8, ["expand"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["expand"]))
}