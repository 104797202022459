export type SensorLocationHistoryEntry = {
    id: number;
    sensor_id: number;
    sensor_type_id: SensorType;
    location_id: number;
    from: string;
};

/* eslint-disable */
export enum SensorType {
    WATER = 1,
    FEEDING = 2,
    WEIGHT = 3,
}
/* eslint-enable */

export type SensorLocationHistory = SensorLocationHistoryEntry[];
