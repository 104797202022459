<template>
    <MainViewCard>
        <div class="px-6 py-6">
            <h4 class="text-lg font-medium my-auto flex-1">
                {{ $t('views.pigs.batches.table.header').replace('$START_DATE', dateStrings[0]).replace('$END_DATE', dateStrings[1]) }}
            </h4>
        </div>
        <CustomTable :headers="headers" :items="items">
            <template #header_actions>
                <th class="w-6"></th>
            </template>

            <template #is_active="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <div class="flex rounded-full h-5 w-5 bg-opacity-20" :class="item.is_active ? 'bg-green-200' : 'bg-red-400'">
                        <Icon class="m-auto" :src="item.is_active ? IconSource.Active : IconSource.Inactive"></Icon>
                    </div>
                </td>
            </template>

            <template #start_date="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    {{ $d(item.start_date, 'short') }}
                </td>
            </template>
            <template #end_date="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    {{ item.end_date === null ? '-' : $d(item.end_date, 'short') }}
                </td>
            </template>

            <template #actions="{ item }">
                <td class="w-6 my-auto">
                    <PigsBatchesModal :batch="item">
                        <template #default="{ open }">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="open">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </template>
                    </PigsBatchesModal>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { DirectoryBatch, TableHeader } from '@/types';
import { store } from '@/plugins/store';
import PigsBatchesModal from '@/views/dashboard/pigs/pigsBatches/PigsBatchesModal.vue';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

export default defineComponent({
    components: { CustomTable, MainViewCard, PigsBatchesModal },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetBatches, { watchSites: false, watchFarmsInsteadOfSites: false, watchFormSubmissions: false });
    },
    computed: {
        dateStrings() {
            const datePickerStringConstituents = store.state.periodPickerDateString.split(' - ');
            switch (datePickerStringConstituents.length) {
                case 1: {
                    return [this.$d(Date.parse(datePickerStringConstituents[0]), 'short'), this.$d(Date.parse(datePickerStringConstituents[0]), 'short')];
                }
                case 2: {
                    return [this.$d(Date.parse(datePickerStringConstituents[0]), 'short'), this.$d(Date.parse(datePickerStringConstituents[1]), 'short')];
                }
                default: {
                    return ['', ''];
                }
            }
        },
        items(): (DirectoryBatch & { farm_name: string; barn_name: string })[] {
            return store.state.batches.map((currentBatch) => ({ ...currentBatch, farm_name: currentBatch.farm.name, barn_name: currentBatch.barn.name }));
        },
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.pigs.batches.table.headings.active'),
                    value: 'is_active',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.pigs.batches.table.headings.name'),
                    value: 'name',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.pigs.batches.table.headings.startDate'),
                    value: 'start_date',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.pigs.batches.table.headings.endDate'),
                    value: 'end_date',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.pigs.batches.table.headings.farm'),
                    value: 'farm_name',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.pigs.batches.table.headings.barn'),
                    value: 'barn_name',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'left',
                    sortable: false,
                },
            ];
        },
    },
});
</script>
