<template>
    <div class="relative border border-gray-400 rounded-xl h-60 w-100 bg-white">
        <div class="absolute -top-10 left-40 p-3.5 h-20 w-20 mx-auto bg-primary-800 rounded-full">
            <Icon class="w-full h-full" :src="IconSource.Login"></Icon>
        </div>

        <div class="flex h-full w-full px-4">
            <form class="pt-20 pb-4 w-full flex flex-col" @submit.prevent="onSubmit">
                <div>
                    <CustomInput
                        v-model:value="form.email"
                        hide-auth-state
                        :show-required-indication="false"
                        :label="$t('views.auth.forgotPassword.emailFieldLabel')"
                        input-type="email"
                        @submit="onSubmit"
                    ></CustomInput>
                </div>

                <div class="mt-auto w-full">
                    <CustomButton :disabled="disableButtons" @click="onSubmit">
                        {{ $t('views.auth.forgotPassword.submitButtonLabel') }}
                    </CustomButton>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Solution } from '@/types';

export default defineComponent({
    components: { CustomInput, CustomCheckbox, CustomButton },
    data() {
        return {
            form: new Form({
                email: '',
            }),
            disableButtons: false,
        };
    },
    methods: {
        onSubmit() {
            this.disableButtons = true;
            alert('Not yet implemented');
            this.disableButtons = false;
        },
    },
});
</script>
