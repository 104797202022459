import { ref } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import cloneDeep from 'lodash-es/cloneDeep';

export default function useResettableForm() {
    const form = ref(new Form({}));
    let initialState: any = null;

    const resettableFormApi = {
        getInitial() {
            return initialState;
        },
        setInitial(data: any) {
            form.value.setData(data);
            initialState = cloneDeep(data);
            return this;
        },
        fillFormAndInitial(data: any) {
            form.value.fill(data);
            Object.assign(initialState, cloneDeep(data));
        },
        reset() {
            form.value.setData(initialState);
            form.value.errors.set([]);
        },
        getInstance() {
            return form;
        },
    };

    return resettableFormApi;
}
