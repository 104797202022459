<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.economy.listings.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <EconomyListingsTables></EconomyListingsTables>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import EconomyListingsTables from '@/views/dashboard/economy/economyListings/EconomyListingsTables.vue';

export default defineComponent({
    components: { DashboardContentLayout, EconomyListingsTables },
});
</script>

<style scoped></style>
