<template>
    <MainViewCard class="space-y-4 overflow-hidden">
        <div class="flex mx-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ completed ? $t('views.health.overview.tables.completedHeader') : $t('views.health.overview.tables.ongoingHeader') }}</h4>
            <span class="text-right w-80">
                <SearchInput v-model:value="search" :label="$t('views.health.overview.tables.searchFieldLabel')"></SearchInput>
            </span>
        </div>
        <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader">
            <template #default="{ open }">
                <div class="overflow-y-auto">
                    <CustomTable :headers="tableHeaders" :items="filteredItems" :expanded-items="expandedRows" expand-items-key="previous_treatments" @sort="expandedRows = []">
                        <template #header_expand>
                            <th class="w-8"></th>
                        </template>
                        <template #header_actions>
                            <th class="w-6"></th>
                        </template>
                        <template #description="{ item, getHeaderClass, getWidth, header }">
                            <td
                                class="flex-1"
                                :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                                :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                            >
                                {{ truncate(item.description || '', { length: 30 }) }}
                            </td>
                        </template>
                        <template #day="{ item, getHeaderClass, getWidth, header }">
                            <td
                                class="flex-1"
                                :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                                :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                            >
                                {{ item.day }}/{{ item.total_days }}
                            </td>
                        </template>
                        <template #day-expand="{ item, getHeaderClass, getWidth, header }">
                            <td
                                class="flex-1"
                                :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                                :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                            >
                                {{ item.day }}/{{ item.total_days }}
                            </td>
                        </template>
                        <template #dosis_pr_animal="{ item, getHeaderClass, getWidth, header, getItemProperty }">
                            <td
                                class="flex-1"
                                :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                                :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                            >
                                {{ getItemProperty(header, item) }} {{ item.dosis_unit ? item.dosis_unit : '' }}
                            </td>
                        </template>
                        <template #dosis_pr_animal-expand="{ item, getHeaderClass, getWidth, header, getItemProperty }">
                            <td
                                class="flex-1"
                                :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                                :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                            >
                                {{ getItemProperty(header, item) }} {{ item.dosis_unit ? item.dosis_unit : '' }}
                            </td>
                        </template>
                        <template #expand="{ item, index, expanded }">
                            <td class="flex-shrink-0 ml-auto w-5 my-auto">
                                <span v-if="item.previous_treatments && item.previous_treatments.length" class="flex justify-end">
                                    <button type="button" class="flex items-center justify-center w-5 h-5" @click="expandRow(index)">
                                        <Icon :class="{ 'rotate-90': !expanded, 'rotate-[-90deg]': expanded }" class="h-3 w-3 cursor-pointer transform transition-transform" :src="IconSource.Right"></Icon>
                                    </button>
                                </span>
                            </td>
                        </template>
                        <template #expand-expand>
                            <td class="flex-shrink-0 ml-auto w-8 my-auto"></td>
                        </template>
                        <template #description-expand="{ item, getHeaderClass, getWidth, header }">
                            <td
                                class="flex-1"
                                :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                                :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                            >
                                {{ truncate(item.description || '', { length: 30 }) }}
                            </td>
                        </template>
                        <template #actions="{ item }">
                            <td class="flex-shrink-0 w-6 my-auto">
                                <span class="flex justify-end">
                                    <button type="button" class="flex items-center justify-center w-5 h-5" @click="onEditClicked(item, open)">
                                        <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                    </button>
                                </span>
                            </td>
                        </template>
                        <template #actions-expand="{ item }">
                            <td class="flex-shrink-0 w-6 my-auto">
                                <span class="flex justify-end">
                                    <button type="button" class="flex items-center justify-center w-5 h-5" @click="onEditClicked(item, open)">
                                        <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                    </button>
                                </span>
                            </td>
                        </template>
                    </CustomTable>
                </div>
            </template>
        </ActionModal>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue';
import { truncate } from 'lodash-es';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { Treatment, Form, RecordForm, MultiStepFormState, IconSource, TableHeader, Module } from '@/types';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import Icon from '@/components/icons/Icon.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, CustomTable, ActionModal, Icon, SearchInput },
    props: {
        items: {
            type: Array as () => Treatment[],
            required: true,
        },
        completed: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const enableSectionsModule = computed<boolean>(() => store.getters.hasModuleEnabled(Module.Sections));
        const tableHeaders = computed<TableHeader[]>(() => [
            { text: '', value: 'expand', align: 'left', sortable: false, searchable: false },
            { text: i18n.global.t('views.health.overview.tables.headings.location'), value: '_location', align: 'left', sortable: true, searchable: true, cellClass: 'font-medium text-primary-800' },
            ...(enableSectionsModule.value
                ? ([{ text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.section'), value: 'section_name', align: 'left', sortable: true, searchable: true }] as TableHeader[])
                : []),
            { text: i18n.global.t('views.health.overview.tables.headings.pen'), value: 'pen', align: 'center', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.remark'), value: 'description', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.diagnosis'), value: 'diagnosis', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.drugName'), value: 'drug_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.administrationMethod'), value: 'administration_method', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.treatmentDay'), value: 'day', align: 'center', sortable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.retentionDate'), value: 'retention_date', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.health.overview.tables.headings.animalCount'), value: 'animal_count', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.weight'), value: 'weight', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.health.overview.tables.headings.dosisPerAnimal'), value: 'dosis_pr_animal', align: 'left', sortable: true, searchable: true, type: 'number' },
            { text: i18n.global.t('views.health.overview.tables.headings.treatmentDate'), value: 'treatment_date', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: '', value: 'actions', align: 'right', sortable: false, searchable: false },
        ]);
        const expandedRows = ref<number[]>([]);
        const items = computed(() => props.items);
        const { search, filteredItems } = useFilterableTable(items, tableHeaders);

        const expandRow = (index: number) => {
            if (expandedRows.value.includes(index)) {
                expandedRows.value = expandedRows.value.filter((rowIndex) => rowIndex !== index);
            } else {
                expandedRows.value.push(index);
            }
        };

        watch(
            () => props.items,
            () => {
                expandedRows.value = [];
            }
        );

        const actionModalInitialState = ref<undefined | MultiStepFormState>(undefined);
        const actionModalHeader = ref<null | { icon_url: string; text: string }>(null);
        const actionModalStepConfig = ref<any[]>([
            {},
            { deleteButton: false, editable: !props.completed },
            { disableRefetch: true, submitActionType: props.completed ? ActionType.GetRecordForm : ActionType.PutForm },
            {},
        ]);

        const isLoadingForm = ref(false);

        const onEditClicked = async (item: any, open: CallableFunction) => {
            try {
                if (isLoadingForm.value) return;

                isLoadingForm.value = true;

                if (item.done) {
                    const recordForm: RecordForm = await store.dispatch(ActionType.GetRecordForm, { form: { record_id: item.form.record_id } });

                    if (recordForm) {
                        actionModalStepConfig.value = [{}, { deleteButton: recordForm.deletable, editable: recordForm.editable }, { disableRefetch: true, submitActionType: ActionType.GetRecordForm }];
                        actionModalInitialState.value = { location: null, form: recordForm.form, confirmed: false, farm: null, event: null, record_id: recordForm.record_id, notification: null };
                        actionModalHeader.value = { icon_url: IconSource.Treatment as any, text: recordForm.form.sections?.[0]?.name || i18n.global.t('views.health.overview.modals.viewTreatment.header') };

                        open();
                    }
                } else {
                    const form: Form = await store.dispatch(ActionType.GetForm, { form: item.form });

                    if (form) {
                        // Temporary fix for detemining whether the user has write permissions on the farm with the assignment
                        let farm = store.state.farms?.find((currentFarm) => currentFarm.farm_id === item.location_id);

                        if (!farm) {
                            const barn = store.state.barns?.find((currentBarn) => currentBarn.barn_id === item.location_id);
                            if (barn) {
                                farm = store.state.farms?.find((currentFarm) => currentFarm.farm_id === barn.farm_id);
                            }
                        }

                        if (!farm) {
                            const section = store.state.sections?.find((currentSection) => currentSection.id === item.location_id);
                            if (section) {
                                const barn = store.state.barns?.find((currentBarn) => currentBarn.barn_id === section.barn_id);
                                if (barn) {
                                    farm = store.state.farms?.find((currentFarm) => currentFarm.farm_id === barn.farm_id);
                                }
                            }
                        }

                        actionModalStepConfig.value = [
                            {},
                            { deleteButton: farm?.permission.key === 'ReadWrite', editable: farm?.permission.key === 'ReadWrite' },
                            { disableRefetch: true, submitActionType: ActionType.PutForm },
                        ];
                        actionModalInitialState.value = { location: null, form, confirmed: false, farm: null, event: null, notification: null };
                        actionModalHeader.value = { icon_url: IconSource.Treatment as any, text: form.sections?.[0]?.name || i18n.global.t('views.health.overview.modals.editTreatment.header') };

                        open();
                    }
                }
            } finally {
                isLoadingForm.value = false;
            }
        };

        return {
            onEditClicked,
            actionModalInitialState,
            actionModalHeader,
            actionModalStepConfig,
            tableHeaders,
            expandRow,
            expandedRows,
            search,
            filteredItems,
            truncate,
        };
    },
});
</script>
