<template>
    <div class="tooltip relative inline-block">
        <div class="z-10" :class="[{ dotted: enableTooltip }]">{{ text }}</div>
        <div v-if="enableTooltip" class="tooltip-content z-20 invisible absolute bg-gray-800 text-white rounded py-2 px-3 divide-white divide-y top-0 right-0">
            <div class="font-semibold text-lg">{{ tooltipHeader }}</div>
            <div class="text-sm">
                <ul>
                    <li v-for="location of locations" :key="location.id">
                        {{ location.name }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        locations: {
            type: Array as () => { id: number; name: string }[],
            default: [],
        },
        tooltipHeader: {
            type: String,
            default: '',
        },
        numberOfLocationsText: {
            type: String,
            default: '',
        },
        numberOfLocationsReplaceText: {
            type: String,
            default: '',
        },
    },
    computed: {
        text(): string {
            if (this.$props.locations.length === 0) {
                return '-';
            }
            if (this.$props.locations.length === 1) {
                return this.$props.locations[0].name;
            }
            return `${this.$props.numberOfLocationsText.replace(this.$props.numberOfLocationsReplaceText, `${this.$props.locations.length}`)}`;
        },
        enableTooltip(): boolean {
            return this.locations.length > 1;
        },
    },
});
</script>

<style scoped>
.tooltip:hover .tooltip-content {
    visibility: visible !important;
}
.dotted {
    text-decoration-style: dotted;
    text-decoration-line: underline;
}
</style>
