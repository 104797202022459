import { ref } from 'vue';

const isLoading = ref(false);
const text = ref('');

export function useLoadingPageOverlayState() {
    function open(loadingText: string) {
        isLoading.value = true;
        text.value = loadingText;
    }

    function close() {
        isLoading.value = false;
        text.value = '';
    }

    return { isLoading, text, open, close };
}
