<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.pigs.deaths.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-8">
            <MainViewCard class="py-4 px-2">
                <GenericChartWithApi :chart-name="dailyDeadChart.name" :height="dailyDeadChart.height" :chart-type="dailyDeadChart.type" :chart-instance-options="dailyDeadChart.options"></GenericChartWithApi>
            </MainViewCard>
            <MainViewCard>
                <GenericTableWithApi :source="pigsDeathsTableSource"></GenericTableWithApi>
            </MainViewCard>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import GenericTableWithApi from '@/components/common/GenericTableWithApi.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import { Table, Chart, ChartType } from '@/types';
import colors from '@/plugins/tailwind';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, GenericTableWithApi, GenericChartWithApi },
    data() {
        return {
            pigsDeathsTableSource: Table.PigsDeaths,
            dailyDeadChart: {
                name: Chart.DailyDead,
                type: ChartType.ColumnChart,
                height: 300,
                options: {
                    yAxis: {
                        allowDecimals: false,
                    },
                    colors: [colors.deaths, colors.primary[800], colors.pigs, colors.feed[400], '#2f73a4', '#e8b24a'],
                },
            },
        };
    },
});
</script>
