import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "flex flex-col h-full min-h-44" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!
  const _component_CustomCombobox = _resolveComponent("CustomCombobox")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.health.instructions.modals.createInstruction.header')), 1),
    _createElementVNode("form", _hoisted_3, [
      _createVNode(_component_CustomMultiSelect, {
        value: _ctx.computedState.farms,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedState.farms) = $event)),
        class: "self-start w-full",
        items: _ctx.farms,
        "return-object": "",
        required: "",
        "item-value": "farm_id",
        "item-text": "name",
        "dropdown-height-class": "max-h-84",
        rules: [{ validate: (value) => value && value.length > 0, message: '' }],
        label: _ctx.$t('views.health.instructions.modals.createInstruction.fields.farms'),
        disabled: false,
        "show-required-indication": "",
        "show-hint": false
      }, null, 8, ["value", "items", "rules", "label"]),
      _createVNode(_component_CustomCombobox, {
        value: _ctx.computedState.resource,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedState.resource) = $event)),
        items: _ctx.drugs,
        "item-value": "id",
        "item-text": "name",
        label: _ctx.$t('views.health.instructions.modals.createInstruction.fields.drug'),
        rules: [{ validate: (value) => value !== null, message: '' }],
        "show-required-indication": "",
        required: ""
      }, null, 8, ["value", "items", "label", "rules"]),
      _createVNode(_component_CustomButton, {
        loading: _ctx.isLoading,
        disabled: !_ctx.validForm,
        onClick: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.health.instructions.modals.createInstruction.confirmButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["loading", "disabled", "onClick"])
    ])
  ]))
}