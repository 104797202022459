<template>
    <div>
        <TopBar></TopBar>
        <NotificationsBar />
        <SideBar>
            <slot></slot>
            <template #footer>
                <Footer></Footer>
            </template>
        </SideBar>
        <HelpWidget />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TopBar from '@/components/layouts/dashboardLayout/topBar/TopBar.vue';
import SideBar from '@/components/layouts/dashboardLayout/sideBar/Index.vue';
import Footer from '@/components/layouts/dashboardLayout/footer/Footer.vue';
import HelpWidget from '@/components/layouts/dashboardLayout/helpWidget/HelpWidget.vue';
import NotificationsBar from '@/components/layouts/dashboardLayout/notificationsBar/NotificationsBar.vue';

export default defineComponent({
    components: { TopBar, SideBar, Footer, HelpWidget, NotificationsBar },
});
</script>
