<template>
    <div class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <div class="flex-1 font-medium">
                <h4 class="text-lg">{{ $t('views.economy.status.table.header') }}</h4>
                <h5 class="opacity-60">{{ $t('views.economy.status.table.subheader') }}</h5>
            </div>
            <CreateOrEditPriceChargeModal :step-config="priceChargeStepConfig">
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="open">
                        {{ $t('views.economy.status.modals.createOrEditPriceChargeModal.buttonLabel') }}
                    </AddButton>
                </template>
            </CreateOrEditPriceChargeModal>
        </div>
        <CustomTable :headers="headers" :items="items"></CustomTable>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EconomyStatusEntry, MultiStepFormState, TableHeader } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import AddButton from '@/components/ui/AddButton.vue';
import CreateOrEditPriceChargeModal from '@/components/common/economy/priceCharges/CreateOrEditPriceChargeModal.vue';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { CustomTable, CreateOrEditPriceChargeModal, AddButton },
    props: {
        actionModalInitialState: {
            type: Object as () => MultiStepFormState,
            required: true,
        },
        headers: {
            type: Array as () => TableHeader[],
            default: [],
        },
        items: {
            type: Array as () => EconomyStatusEntry[],
            default: [],
        },
    },
    computed: {
        priceChargeStepConfig() {
            return [
                {
                    text: this.$t('views.economy.status.modals.createOrEditPriceChargeModal.header').replace('$LOCATION_NAME', this.$props.items[0].site.name),
                    numberFields: [
                        { value: 'piglet_dkk', label: this.$t('views.economy.status.modals.createOrEditPriceChargeModal.pigletFieldLabel'), colSpan: 'col-span-1' },
                        { value: 'piglet_dkk_pr_kg', label: this.$t('views.economy.status.modals.createOrEditPriceChargeModal.pigletPerKgFieldLabel'), colSpan: 'col-span-1' },
                        { value: 'slpig_dkk', label: this.$t('views.economy.status.modals.createOrEditPriceChargeModal.slaughterPigsFieldLabel'), colSpan: 'col-span-1' },
                        { value: 'slpig_dkk_pr_kg', label: this.$t('views.economy.status.modals.createOrEditPriceChargeModal.slaughterPigsPerKgFieldLabel'), colSpan: 'col-span-1' },
                    ],
                    chargeKey: 'charge',
                    fetchActionType: ActionType.GetEconomyStatusEntries,
                    createActionType: ActionType.CreateStatusEntryCharge,
                    updateActionType: ActionType.EditStatusEntryCharge,
                    confirmButtonLabel: this.$t('views.economy.status.modals.createOrEditPriceChargeModal.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.status.modals.addGlobalPrice.periodFieldLabel'),
                    deleteButtonLabel: this.$t('views.economy.status.modals.deletePriceChargeModal.buttonLabel'),
                    items: this.$props.items,
                    deleteModal: {
                        header: this.$t('views.economy.status.modals.deletePriceChargeModal.header'),
                        message: this.$t('views.economy.status.modals.deletePriceChargeModal.message'),
                        cancelButtonLabel: this.$t('views.economy.status.modals.deletePriceChargeModal.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('views.economy.status.modals.deletePriceChargeModal.confirmButtonLabel'),
                        chargeKey: 'charge',
                        deleteActionType: ActionType.DeleteStatusEntryCharge,
                        fetchActionType: ActionType.GetEconomyStatusEntries,
                    },
                },
                { text: this.$t('views.economy.status.modals.createOrEditPriceChargeModal.successMessage') },
                { text: this.$t('views.economy.status.modals.deletePriceChargeModal.successMessage') },
            ];
        },
    },
});
</script>
