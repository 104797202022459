<template>
    <div class="w-full">
        <div style="z-index: 9999" class="fixed w-full h-full left-0 top-0 grid bg-gray-100 justify-center content-center">
            <div class="text-center grid">
                <span class="text-lg font-semibold">
                    {{ $t('layouts.dashboard.warnings.screenSize.warningHeader') }}
                </span>
                <span class="text-lg font-semibold my-4">
                    {{ $t('layouts.dashboard.warnings.screenSize.warningSubheader') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped></style>
