<template>
    <div class="relative z-50">
        <button class="absolute right-6 top-6 cursor-pointer" @click="$emit('close')">
            <Icon :src="IconSource.Close"></Icon>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['close'],
});
</script>
