import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PigsBatchesTable = _resolveComponent("PigsBatchesTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.pigs.batches.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MainViewCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_PigsBatchesTable)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}