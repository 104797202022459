import { PluginOptions, POSITION, TYPE } from 'vue-toastification';

export default {
    toastDefaults: {
        [TYPE.ERROR]: {
            timeout: 10000,
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
        },
        [TYPE.WARNING]: {
            timeout: 5000,
        },
    },
    position: POSITION.BOTTOM_CENTER,
    timeout: 3000,
} as PluginOptions;
