<template>
    <div>
        <Modal close-esc-event="keydown" :close-button="false" modal-class="w-full bg-white m-auto rounded-md border-0" mask-class="grid" @closing="onClose">
            <template #toggle="scope">
                <CustomInput
                    :disabled="disabled || recentlyClosed"
                    :label="label"
                    :rules="[]"
                    :details="details"
                    input-type="text"
                    :value="inputValue"
                    cursor="cursor-pointer"
                    @focus="onInputFocus(scope.open)"
                    @input="$emit('input')"
                ></CustomInput>
            </template>

            <template #header="scope">
                <CloseModalButton @close="scope.close"></CloseModalButton>
            </template>

            <template #body="scope">
                <div class="text-center w-full px-12 py-4">
                    <h1 class="text-2xl py-4 font-semibold">{{ label }}</h1>
                    <div>
                        <StandardDeviationBarChart :median="average" :y-axis-multiplier="amount" :spread="spread" :label="label" :max-quartile-multiplier="max"></StandardDeviationBarChart>
                    </div>
                    <div class="my-4">
                        <Slider v-model:value="computedValue" :slider-values="sliderValues" :label="`+/- ${$n(computedValue)}kg`"></Slider>
                    </div>
                    <div>
                        <CustomButton @click="scope.close">
                            {{ $t('global.forms.steps.dynamicForm.saveButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Modal } from '@makeabledk/vue-ui/components/modal';
import round from 'lodash-es/round';
import StandardDeviationBarChart from '@/components/charts/StandardDeviationBarChart.vue';
import Slider from '@/components/ui/Slider.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, StandardDeviationBarChart, Slider, CustomInput, CustomButton, CloseModalButton },
    emits: ['update:value', 'input'],
    props: {
        value: {
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: 0.05,
        },
        max: {
            type: Number,
            default: 0.3,
        },
        default: {
            type: Number,
            default: 0.1,
        },
        average: {
            type: Number,
            default: 0,
        },
        amount: {
            type: Number,
            default: 0,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
    },
    data() {
        return {
            recentlyClosed: false,
        };
    },
    computed: {
        /* Each step of the slider doesn't have the same value increase */
        sliderValues(): number[] {
            const result: number[] = [];
            for (let i = 0; i < 11; i++) {
                if (i < 6) {
                    result.push(round(this.$props.average * this.$props.min + i * ((this.$props.average * this.$props.default - this.$props.average * this.$props.min) / 5), 1));
                } else {
                    result.push(round(this.$props.average * this.$props.default + (i - 5) * ((this.$props.average * this.$props.max - this.$props.average * this.$props.default) / 5), 1));
                }
            }
            return result;
        },
        computedValue: {
            get(): number | null {
                return this.$props.value;
            },
            set(newValue: number) {
                this.$emit('update:value', newValue);
                this.$emit('input');
            },
        },
        inputValue(): string {
            return `${this.$n(this.average || 0)} ± ${this.$n(this.computedValue || 0)}kg`;
        },
        spread() {
            return Number(this.computedValue) / 1.96;
        },
    },
    watch: {
        disabled(newValue: boolean) {
            if (!newValue && !this.value && this.average) {
                this.computedValue = Math.ceil(this.average * 0.1);
            }
        },
        average(newValue: number) {
            if (typeof newValue === 'number') {
                this.computedValue = Number((this.default * newValue).toFixed(1));
            }
        },
    },
    methods: {
        onClose() {
            this.recentlyClosed = true;
            setTimeout(() => {
                this.recentlyClosed = false;
            }, 500);
        },
        onInputFocus(open: CallableFunction) {
            if (!this.recentlyClosed) {
                open();
            }
        },
    },
});
</script>
