<template>
    <div class="w-full space-y-4">
        <h2 class="font-semibold text-xl text-center">{{ state.isBatchReport ? $t('views.reports.selectDatesPage.batchHeader') : $t('views.reports.selectDatesPage.farmsHeader') }}</h2>

        <div class="w-full flex justify-center h-88">
            <DateSelector :state="computedState"></DateSelector>
        </div>

        <div class="flex justify-center space-x-4">
            <CustomButton class="max-w-32" color-preset="secondary" @click="onResetClicked">
                {{ $t('views.reports.resetButtonLabel') }}
            </CustomButton>
            <CustomButton :disabled="disableButtons" class="max-w-32" :class="{ 'cursor-not-allowed': disableNext }" @click="onNextClicked">
                {{ $t('views.reports.nextButtonLabel') }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import DateSelector from '@/views/dashboard/reports/selectDates/DateSelector.vue';
import { ReportViewState } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { MainViewCard, CustomButton, DateSelector },
    emits: ['next', 'update:state', 'reset'],
    props: {
        state: {
            type: Object as () => ReportViewState,
            required: true,
        },
    },
    data() {
        return { disableButtons: false };
    },
    computed: {
        computedState: {
            get(): ReportViewState {
                return this.$props.state;
            },
            set(newValue: ReportViewState) {
                this.$emit('update:state', newValue);
            },
        },
        disableNext(): boolean {
            return this.computedState.dates.length < 2 || this.computedState.dates.length > 5;
        },
    },
    methods: {
        async onNextClicked() {
            if (this.disableNext || this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            this.computedState.reports = await store.dispatch(ActionType.GetReportData, { form: { site_ids: this.computedState.siteIds, dates: this.computedState.dates } });
            this.$emit('next');
        },
        onResetClicked() {
            this.$emit('reset');
        },
    },
});
</script>
