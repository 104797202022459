import { customRef, nextTick, watch, unref, type Ref, type MaybeRefOrGetter } from 'vue';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import type { RouteParamValueRaw, Router } from 'vue-router';
import { tryOnScopeDispose } from '@vueuse/core';

const _queue = new WeakMap<Router, Map<string, any>>();

function toValue<T>(value: MaybeRefOrGetter<T>): T {
    if (typeof value === 'function') {
        return (value as () => T)();
    }

    return unref(value);
}

type RouteQueryValueRaw = RouteParamValueRaw | string[];

interface ReactiveRouteOptionsWithTransform<T, K> {
    mode?: 'replace' | 'push' | Ref<'replace' | 'push'>;
    route?: ReturnType<typeof useRoute>;
    router?: ReturnType<typeof useRouter>;
    // eslint-disable-next-line no-unused-vars
    transform?: (val: T | undefined) => K;
}

export function useRouteQuery<T extends RouteQueryValueRaw = RouteQueryValueRaw, K = T>(name: string, defaultValue?: MaybeRefOrGetter<T>, options: ReactiveRouteOptionsWithTransform<T, K> = {}): Ref<K> {
    const { mode = 'replace', route = useRoute(), router = useRouter(), transform = (value) => value as K } = options;

    if (!_queue.has(router)) _queue.set(router, new Map());

    const _queriesQueue = _queue.get(router)!;

    let query = route.query[name] as any;

    tryOnScopeDispose(() => {
        query = undefined;
    });

    let _trigger: () => void;

    const proxy = customRef<any>((track, trigger) => {
        _trigger = trigger;

        return {
            get() {
                track();

                return transform(query !== undefined ? query : toValue(defaultValue));
            },
            set(v) {
                if (query === v) {
                    return;
                }

                const defaultVal = toValue(defaultValue);

                query = v === defaultVal || v === null ? undefined : v;

                _queriesQueue.set(name, v === defaultVal || v === null ? undefined : v);

                trigger();

                nextTick(() => {
                    if (_queriesQueue.size === 0) {
                        return;
                    }

                    const newQueries = Object.fromEntries(_queriesQueue.entries());

                    _queriesQueue.clear();

                    const { params, query: currentQuery, hash } = route;

                    router[toValue(mode)]({
                        params,
                        query: { ...currentQuery, ...newQueries },
                        hash,
                    });
                });
            },
        };
    });

    const unwatch = watch(
        () => route.query[name],
        (v) => {
            if (query === v) {
                return;
            }

            query = v;

            _trigger();
        },
        { flush: 'sync' }
    );

    onBeforeRouteLeave(() => {
        /*
            Navigation happens before the component is unmounted,
            so we need to unwatch here to make sure route queries doesn't update as undefined
        */
        unwatch();
    });

    return proxy as Ref<K>;
}
