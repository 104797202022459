import { type VariantProps, cva } from 'class-variance-authority';

export { default as Badge } from './Badge.vue';

export const badgeVariants = cva('inline-flex items-center rounded-full border px-2.5 py-0.5 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2', {
    variants: {
        variant: {
            default: 'border-transparent bg-slate-900 text-white hover:bg-slate-800',
            secondary: 'border-transparent bg-slate-100 text-slate-900 hover:bg-slate-200',
            destructive: 'border-transparent bg-red-500 text-white hover:bg-red-600',
            info: 'border-transparent bg-blue-50 text-blue-900 hover:bg-blue-100',
            outline: 'text-slate-950',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export type BadgeVariants = VariantProps<typeof badgeVariants>;
