<template>
    <div class="max-w-128 w-full h-full">
        <h3 class="text-center pb-2 font-semibold">{{ batchTabIsActive ? $t('views.reports.selectSitesPage.selectBatchesConditions') : $t('views.reports.selectSitesPage.selectFarmsConditions') }}</h3>
        <div class="border-2 h-full overflow-y-auto border-opacity-10 rounded-lg py-2 max-h-80">
            <table class="w-full">
                <thead></thead>
                <tbody>
                    <tr v-for="site of selectableSites" :key="site.site_ids" class="hover:bg-gray-100 cursor-pointer" @click="onSiteClicked(site)">
                        <td class="pl-4 font-medium">{{ site.name }}</td>
                        <td class="w-6.5 pr-4">
                            <div class="flex justify-center">
                                <CustomCheckbox :checked="computedState.siteIds.includes(site.site_ids)" @update:checked="onSiteClicked(site)"></CustomCheckbox>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import { ReportBatch, ReportViewState, ReportFarm } from '@/types';

export default defineComponent({
    components: { CustomCheckbox },
    props: {
        state: {
            type: Object as () => ReportViewState,
            required: true,
        },
        selectableSites: {
            type: Array as () => (ReportFarm | ReportBatch)[],
            default: [],
        },
        batchTabIsActive: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        computedState: {
            get(): ReportViewState {
                return this.$props.state;
            },
            set(newValue: ReportViewState) {
                this.$emit('update:state', newValue);
            },
        },
    },
    methods: {
        onSiteClicked(site: ReportFarm | ReportBatch) {
            const siteIds = site.site_ids;
            const indexOfSiteId = this.computedState.siteIds.indexOf(siteIds);
            if (indexOfSiteId >= 0) {
                this.computedState.siteIds.splice(indexOfSiteId, 1);
            } else {
                this.computedState.siteIds.push(siteIds);
            }
        },
    },
});
</script>
