<template>
    <transition name="slide-fade">
        <div v-if="show" style="z-index: 1060" class="fixed w-full h-full left-0 top-0 grid bg-white">
            <div v-if="lottieAnimation" class="m-auto">
                <Vue3Lottie :animation-link="lottieAnimation" :width="300" />
            </div>
            <div v-else class="m-auto">
                <Icon class="w-36 mx-auto" :src="SPINNING_ICON_SOURCE"></Icon>
                <Icon :src="STATIC_ICON_SOURCE"></Icon>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            SPINNING_ICON_SOURCE: process.env.VUE_APP_SOLUTION_PATH ? `/solutions/${process.env.VUE_APP_SOLUTION_PATH}/assets/images/preload-spinner.gif` : null,
            STATIC_ICON_SOURCE: process.env.VUE_APP_SOLUTION_PATH ? `/solutions/${process.env.VUE_APP_SOLUTION_PATH}/assets/images/preload-static.png` : null,
            lottieAnimation: process.env.VUE_APP_PRELOAD_SPINNER_LOTTIE_LINK,
        };
    },
    watch: {
        show() {
            window.scrollTo(0, 0);
        },
    },
});
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.1s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(-40px);
    opacity: 0;
}
</style>
