<template>
    <div class="space-y-8">
        <TreatmentsTable :completed-tasks="completedTasks"></TreatmentsTable>
        <AssignmentsTable :completed-tasks="completedTasks"></AssignmentsTable>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AssignmentsTable from '@/views/dashboard/assignments/tasks/AssignmentsTable.vue';
import TreatmentsTable from '@/views/dashboard/assignments/tasks/TreatmentsTable.vue';

export default defineComponent({
    components: { AssignmentsTable, TreatmentsTable },
    props: {
        completedTasks: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
