<template>
    <div ref="calendar"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Calendar, CalendarOptions, LocaleInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import danishLocale from '@fullcalendar/core/locales/da';
import englishLocale from '@fullcalendar/core/locales/en-gb';
import swedishLocale from '@fullcalendar/core/locales/sv';
import spanishLocale from '@fullcalendar/core/locales/es';
import portuguese from '@fullcalendar/core/locales/pt';
import { store } from '@/plugins/store';
import { IconSource } from '@/types';

export default defineComponent({
    props: {
        dateString: {
            type: String,
            default: '',
        },
        events: {
            type: Array,
            default: [],
        },
    },
    emits: ['update:dateString'],
    data() {
        return {
            calendar: null as null | Calendar,
        };
    },
    computed: {
        calendarOptions() {
            const calendarLocales = {
                en: englishLocale,
                da: danishLocale,
                sv: swedishLocale,
                es: spanishLocale,
                pt: portuguese,
            } as { [key: string]: LocaleInput };
            return {
                plugins: [dayGridPlugin, interaction],
                locale: calendarLocales[this.$i18n.locale] || calendarLocales.en,
                initialView: 'dayGridMonth',
                selectable: false, // Calendar is selectable from dateClick-event. This option prevents multiple select
                weekNumbers: true,
                weekText: '',
                contentHeight: 370,
                events: this.$props.events,
                weekNumberClassNames: 'bg-transparent text-xs right-2.5 left-auto',
                eventTextColor: 'transparent',
                dayCellClassNames: '',
                dayCellContent(dayCellContentArguments) {
                    return `${dayCellContentArguments.date.getDate()}`;
                },
                headerToolbar: {
                    left: '',
                    center: 'title',
                    right: '',
                },
                eventClick: (info) => {
                    document.querySelector('.fc-selected-date')?.classList.remove('fc-selected-date');
                    info.el.parentElement?.parentElement?.parentElement?.parentElement?.classList.add('fc-selected-date');
                    (this.calendar as any).select(info.event.start);
                    this.$emit('update:dateString', info.event.start?.toISOString());
                },
                dateClick: (info) => {
                    document.querySelector('.fc-selected-date')?.classList.remove('fc-selected-date');
                    info.dayEl.classList.add('fc-selected-date');
                    (this.calendar as any).select(info.date);
                    this.$emit('update:dateString', info.date.toISOString());
                },
            } as CalendarOptions;
        },
    },
    mounted() {
        this.calendar = new Calendar(this.$refs.calendar as HTMLDivElement, this.calendarOptions);
        this.calendar.render();

        if (this.$props.dateString) {
            const initialDate = new Date(Date.parse(this.$props.dateString));
            const day = initialDate.getDate();
            const month = initialDate.getMonth() + 1;
            const year = initialDate.getFullYear();
            const queryString = `[data-date="${year}-${month <= 9 ? `0${month}` : month}-${day <= 9 ? `0${day}` : day}"]`;
            const initialDateElement = document.querySelector(queryString);
            this.calendar.select(initialDate);
            initialDateElement?.classList.add('fc-selected-date');
        }

        this.fixDom();
    },
    methods: {
        fixDom() {
            const rightSource = store.state.icons ? store.state.icons[IconSource.Right] : '';
            const headerToolbarChunks = document.querySelectorAll('.fc-toolbar-chunk');
            this.addCustomButton(
                `<div style="padding: 0.35rem;" class="bg-primary-500 rounded-full cursor-pointer mr-4">
                    <img style="transform: rotate(180deg);" class="m-auto w-2 h-2" src="${rightSource}" />
                </div>`,
                headerToolbarChunks[0],
                () => this.calendar?.prev()
            );
            this.addCustomButton(
                `<div style="padding: 0.35rem;" class="bg-primary-500 rounded-full cursor-pointer ml-4">
                    <img class="m-auto w-2 h-2" src="${rightSource}" />
                </div>`,
                headerToolbarChunks[headerToolbarChunks.length - 1],
                () => this.calendar?.next()
            );
        },
        addCustomButton(html: string, parent: Element, onClick: () => void) {
            const replacementElement = document.createElement('div');
            replacementElement.innerHTML = html;
            replacementElement.addEventListener('click', onClick);
            parent.appendChild(replacementElement);
        },
    },
});
</script>

<style>
/* Set correct color for dates/week numbers */
.fc-daygrid-week-number {
    color: rgba(209, 213, 219, 1) !important;
}
.fc-selected-date .fc-daygrid-week-number {
    color: #161121 !important;
}
.fc-selected-date .fc-daygrid-day-top {
    opacity: 1 !important;
}

.fc-selected-date {
    background-color: rgba(205, 221, 221, 1) !important;
}

/* Makes the background colors of dates match the design */
.fc-highlight {
    background-color: rgba(205, 221, 221, 1) !important;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
}

/* Fixes the bug that causes the calendar to initially render with a wrong width */
.fc-daygrid-body,
.fc-scrollgrid-sync-table,
.fc-col-header {
    width: 100% !important;
    height: 100% !important;
}

/* Align toolbar title and buttons */
.fc-toolbar-title {
    width: 14rem !important;
    text-align: center !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
}
.fc-toolbar {
    justify-content: center !important;
}

/* Aligns the size and content of each day frame */
.fc-daygrid-day-frame {
    display: grid;
    grid-template-rows: 2rem 1rem 0rem;
    align-content: center;
    cursor: pointer;
    padding-bottom: 8px !important;
}

.fc-daygrid-day-top {
    align-self: end;
}
.fc .fc-daygrid-day-number {
    padding: 0px 0px 0px 0px !important;
}

/* Rounds each day's frame */
.fc-daygrid-day,
.fc-daygrid-day-frame,
.fc-highlight,
.fc-daygrid-bg-harness {
    border-radius: 10px !important;
}

/* Removes the default :after/:before elements that mess up the events grid */
.fc-daygrid-day-frame:before,
.fc-daygrid-day-events:before,
.fc-daygrid-event-harness:before,
.fc-daygrid-day-frame:after,
.fc-daygrid-day-events:after,
.fc-daygrid-event-harness:after {
    content: none;
}

/* Sets the size/shape of events */
.fc-daygrid-event {
    width: 0.375rem !important;
    height: 0.375rem !important;
    color: transparent !important;
    cursor: pointer !important;
    border-radius: 9999px !important;
}

/* Hides extra event stuff that messes up the grid */
.fc-event-main,
.fc-daygrid-day-bottom {
    display: none !important;
}

/* Sets up the grid of events how we want it. Be careful when changing it, cause the entire site might crash if the
grid fills more than FullCalendar expects it to whenever a render/re-render function is called */
.fc-daygrid-day-events {
    align-self: start;
    min-height: 0 !important;
    display: flex;
    flex-wrap: wrap;
    padding-left: 6px;
    padding-right: 6px;
    justify-content: center;
    justify-items: center;
}

/* Remove table borders */
.fc-theme-standard .fc-scrollgrid {
    border-style: none;
}

/* Remove cell borders */
.fc-theme-standard td,
.fc-theme-standard th {
    border-style: none;
}

/* Center the date text */
.fc .fc-daygrid-day-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
