import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreatmentsTable = _resolveComponent("TreatmentsTable")!
  const _component_AssignmentsTable = _resolveComponent("AssignmentsTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TreatmentsTable, { "completed-tasks": _ctx.completedTasks }, null, 8, ["completed-tasks"]),
    _createVNode(_component_AssignmentsTable, { "completed-tasks": _ctx.completedTasks }, null, 8, ["completed-tasks"])
  ]))
}