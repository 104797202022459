<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="m-auto border-0" mask-class="grid" @closing="$emit('closing')">
        <template #toggle="scope">
            <slot :scope="scope"></slot>
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-gray-100 py-6 px-8 space-y-4 rounded-xl">
                <MultiStepFormHeaderIcon v-if="state.event" :icon-source="state.event.icon_url"></MultiStepFormHeaderIcon>
                <h2 class="text-xl font-semibold text-center">{{ $t('global.forms.steps.forceSubmission.forceSubmissionHeader') }}</h2>
                <div v-if="computedState.errors.hasErrors()" class="space-y-4">
                    <div v-for="problemElement of problemElements" :key="problemElement.element?.id">
                        <div>{{ `${problemElement.element?.label || 'N/A'}: ${formatValue(problemElement.element)}` }}</div>
                        <div class="text-gray-500">{{ problemElement.errorMessage }}</div>
                    </div>
                </div>
                <div class="flex space-x-4">
                    <CustomButton :disabled="disableButtons" color-preset="secondary" @click="onEditClicked(scope.close)">
                        {{ $t('global.forms.steps.forceSubmission.editButtonLabel') }}
                    </CustomButton>
                    <CustomButton v-if="submissionCanBeForced" :disabled="disableButtons" @click="onSubmitClicked(scope.close)">
                        {{ $t('global.forms.steps.forceSubmission.forceSubmissionConfirmButtonLabel') }}
                    </CustomButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Form } from '@makeabledk/vue-ui/support/http';
import { MultiStepFormState, FormElement } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, CustomButton, MultiStepFormHeaderIcon, CloseModalButton },
    emits: ['update:state', 'submit', 'back', 'closing'],
    props: {
        state: {
            type: Object as () => MultiStepFormState & Form<MultiStepFormState>,
            required: true,
        },
        formatValue: {
            type: Function,
            required: true,
        },
        submissionCanBeForced: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            disableButtons: false,
        };
    },
    computed: {
        computedState: {
            get(): MultiStepFormState & Form<MultiStepFormState> {
                return this.$props.state;
            },
            set(newValue: MultiStepFormState) {
                this.$emit('update:state', newValue);
            },
        },
        problemElements(): { element: FormElement | undefined; errorMessage: string }[] {
            const errors = this.computedState.errors.all();
            return errors.map((error: { id: string; value: string }) => {
                const elementIdConstituents = error.id.split('.');
                const section = this.computedState.form?.sections.find((currentSection) => currentSection.id === elementIdConstituents[0]);
                const element = section?.children.find((currentElement) => currentElement.id === elementIdConstituents[1]);
                return {
                    element,
                    errorMessage: error.value,
                };
            });
        },
    },
    methods: {
        onEditClicked(close: CallableFunction) {
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            close();
            this.$emit('back');
        },
        onSubmitClicked(close: CallableFunction) {
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            Object.assign(this.computedState, { confirmed: true });
            close();
            this.$emit('submit');
        },
    },
});
</script>
