<template>
    <div class="space-y-6">
        <h2 class="text-xl font-semibold text-center">{{ header?.text || state.event?.name || '' }}</h2>
        <div class="flex flex-col h-full min-h-44">
            <CustomSelect
                v-model:value="selectedLocation"
                class="self-start w-full"
                :items="farms"
                groups
                group-key="locations"
                group-text-key="name"
                :group-select-options="({
                    selectableCondition: (group) => siteWorksForAction(group),
                    intermediateSelectableCondition: (group) => group.locations && group.locations.length === 1 && siteWorksForAction(group.locations[0]),
                    value: (group) => (siteWorksForAction(group) ? group : group.locations[0]),
                } as any)"
                sub-group-key="locations"
                sub-group-text-key="name"
                :sub-group-select-options="({
                    selectableCondition: (group) => siteWorksForAction(group),
                    intermediateSelectableCondition: (group) => group.locations && group.locations.length === 1 && siteWorksForAction(group.locations[0]),
                    value: (group) => (siteWorksForAction(group) ? group : group.locations[0]),
                } as any)"
                return-object
                required
                show-required-indication
                item-value="name"
                :allow-empty="false"
                dropdown-height-class="max-h-84"
                item-text="name"
                :rules="[{ validate: (value) => value !== null, message: '' }]"
                :label="$t('global.forms.steps.location.selectLocationFieldLabel')"
                :disabled="false"
                :show-hint="false"
            ></CustomSelect>
            <ExpandCollapseTransition>
                <div v-if="isMissingSelectedSection">
                    <CustomSelect
                        v-model:value="selectedSection"
                        class="self-start w-full"
                        :items="activeLocationSections"
                        return-object
                        show-required-indication
                        item-value="id"
                        :allow-empty="false"
                        :required="sectionIsRequired"
                        dropdown-height-class="max-h-84"
                        item-text="name"
                        :rules="[{ validate: (value) => value !== null, message: '' }]"
                        :label="$t('global.forms.steps.location.selectSectionFieldLabel')"
                        :show-hint="false"
                    ></CustomSelect>
                    <CustomButton :disabled="sectionIsRequired && !selectedSection" @click="onNextButtonClicked">{{ $t('global.forms.steps.location.nextButtonLabel') }}</CustomButton>
                </div>
            </ExpandCollapseTransition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Barn, Farm, Batch, Action, MultiStepFormState, BatchEvent, DirectoryBatch, DirectoryBarn, Module, Section, DirectorySection } from '@/types';
import ExpandCollapseTransition from '../ExpandCollapseTransition.vue';

export default defineComponent({
    components: { CustomSelect, CustomButton, ExpandCollapseTransition },
    emits: ['update:state', 'update:validForm', 'submit', 'goToStep'],
    props: {
        state: {
            type: Object as () => MultiStepFormState,
            required: true,
        },
        header: {
            type: Object as () => null | { icon_url: string; text: string },
            default: null,
        },
    },
    data() {
        return {
            selectedLocation: this.state.location,
            selectedSection: this.state.section,
            isMissingSelectedSection: false,
            sectionIsRequired: false,
            isLoading: false,
        };
    },
    computed: {
        hasSectionsModuleEnabled(): boolean {
            return store.getters.hasModuleEnabled(Module.Sections);
        },
        activeLocationSections(): Section[] {
            return (this.selectedLocation as Barn)?.sections || [];
        },
        farms(): Farm[] {
            if (!this.activeAction) {
                return [];
            }
            const farms: Farm[] = store.getters.getLocations(true, true, false);

            farms.forEach((farm: Farm) => {
                farm.locations = (farm.locations as Barn[])?.flatMap((barn: Barn): Barn[] => {
                    let result: Barn[] = [];

                    if (barn.is_batch_production) {
                        const filteredBatches = Array.isArray(barn.open_batches)
                            ? barn.open_batches
                                  .filter((currentBatch) => this.siteWorksForAction(currentBatch))
                                  .map((batch) => ({
                                      ...batch,
                                      barn_id: barn.barn_id,
                                      farm_id: barn.farm_id,
                                  }))
                            : [];

                        if (this.siteWorksForAction(barn) || filteredBatches.length) {
                            if (filteredBatches.length) {
                                barn.locations = filteredBatches;
                            }

                            result = [barn];
                        }
                    } else if (this.siteWorksForAction(barn)) {
                        result = [barn];
                    }

                    return result;
                });
            });

            return farms.filter((currentFarm) => (currentFarm.locations?.length || this.siteWorksForAction(currentFarm)) && currentFarm.permission.key === 'ReadWrite');
        },
        computedState: {
            get(): MultiStepFormState {
                return this.$props.state;
            },
            set(newValue: MultiStepFormState) {
                this.$emit('update:state', newValue);
            },
        },
        activeAction(): Action | undefined {
            return store.state.actions.find((currentAction) => currentAction.id === (this.$props.state.event as BatchEvent).action_id);
        },
        activeFormId(): number | null {
            return this.activeAction?.form_id || null;
        },
    },
    watch: {
        selectedLocation(newValue: Barn | Batch | DirectoryBarn | DirectoryBatch | null | undefined) {
            // Show section select if the selected location has sections and the current action is allowed to be performed on any of those sections, else go to next step
            if (this.hasSectionsModuleEnabled && (newValue as any)?.sections?.some((s: Section) => s.accessible_actions.includes(this.state.event?.action_id!))) {
                this.isMissingSelectedSection = true;
                this.sectionIsRequired = !(newValue as Barn).accessible_actions.includes(this.state.event?.action_id!);
            } else {
                this.sectionIsRequired = false;
                this.isMissingSelectedSection = false;
                this.computedState.location = newValue;
            }
        },
        selectedSection(newValue: Section | DirectorySection | null | undefined) {
            if (newValue) {
                this.computedState.section = newValue;
            }
        },
        'computedState.location': {
            async handler(newValue: Barn | Batch | null) {
                if (newValue) {
                    await this.prepareNextStep();
                }
            },
            deep: true,
        },
    },
    created() {
        store.dispatch(ActionType.GetFarms);
        store.dispatch(ActionType.GetBarns);
        store.dispatch(ActionType.GetSections);
    },
    methods: {
        siteWorksForAction(site: Farm | Barn | Batch) {
            return (
                site.accessible_actions.find((a) => a === this.activeAction?.id) ||
                (this.hasSectionsModuleEnabled && (site as Barn).sections?.some((s: Section) => s.accessible_actions.includes(this.state.event?.action_id!)))
            );
        },
        async prepareNextStep() {
            if (this.isLoading || this.activeFormId === null || !this.selectedLocation) {
                return;
            }
            try {
                this.isLoading = true;
                const location = this.selectedLocation;
                const form = await store.dispatch(ActionType.GetForm, {
                    form: {
                        // Note: The typecasts are not accurate, but accessing undefined properties is fine
                        barn_id: (location as Barn).barn_id,
                        farm_id: (location as Barn).farm_id,
                        form_id: this.activeFormId,
                        section_id: this.selectedSection?.id,
                        batch_id: (location as Batch).batch_id,
                    },
                });

                if (form) {
                    const farm = store.state.farms.find((currentFarm) => currentFarm.farm_id === (location as Barn)?.farm_id);
                    Object.assign(this.computedState, { form, farm });
                    this.$emit('goToStep', 1);
                }
            } finally {
                this.isLoading = false;
            }
        },
        onNextButtonClicked() {
            if (this.selectedSection) {
                // Setting the section as the selected location also triggers the prepareNextStep via the watcher
                this.computedState.location = this.selectedLocation;
            } else {
                this.prepareNextStep();
            }
        },
    },
});
</script>
