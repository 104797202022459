import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-92 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_LocationTabs = _resolveComponent("LocationTabs")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.settings.locations.header.heading'),
    "has-period-picker": false,
    "has-pig-type-picker": false
  }, {
    "header-top-right": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SearchInput, {
          value: _ctx.searchRef,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchRef) = $event)),
          class: "my-auto",
          label: _ctx.$t('views.settings.locations.header.searchFieldLabel'),
          "background-color": 'bg-white'
        }, null, 8, ["value", "label"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_LocationTabs, { search: _ctx.searchRef }, null, 8, ["search"])
    ]),
    _: 1
  }, 8, ["heading"]))
}