import { useLocalStorage } from '@vueuse/core';
import { computed, ref } from 'vue';
import { SortingState } from '@tanstack/vue-table';
import { useRouteQuery } from '@/composables/useRouteQuery';
import { NotificationsFetchParams } from '@/plugins/store/actions/queries/notifications';

const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];
const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];
const DEFAULT_ORDER_BY: NotificationsFetchParams['orderBy'] = 'PublishedAt';
const DEFAULT_ORDER_DIRECTION: NotificationsFetchParams['orderDirection'] = 'desc';
const sortingMap: Record<NotificationsFetchParams['orderBy'], string> = {
    PublishedAt: 'publish_date',
    Message: 'message',
    Type: '_type',
    Status: '_status',
};
const sortingMapReverse: Record<string, NotificationsFetchParams['orderBy']> = Object.fromEntries(Object.entries(sortingMap).map(([key, value]) => [value, key as NotificationsFetchParams['orderBy']]));

export function useNotificationsSearchParams() {
    const storedPageSize = useLocalStorage<number>('notifications:table-page-size', DEFAULT_PAGE_SIZE);

    const unread = useRouteQuery<string, boolean>('unread', 'false', { transform: (value) => value === 'true' });
    const types = useRouteQuery<string | undefined>('types', undefined, {
        transform: (value) => value?.split(',').join(',') || undefined,
    });
    const page = useRouteQuery<number>('page', 1, { transform: Number });
    const pageSize = useRouteQuery<number>('pageSize', PAGE_SIZE_OPTIONS.includes(storedPageSize.value) ? storedPageSize.value : DEFAULT_PAGE_SIZE, {
        transform: (value) => Number(value && PAGE_SIZE_OPTIONS.includes(Number(value)) ? value : DEFAULT_PAGE_SIZE),
    });
    const orderBy = useRouteQuery<NotificationsFetchParams['orderBy'] | undefined>('orderBy', DEFAULT_ORDER_BY);
    const orderDirection = useRouteQuery<NotificationsFetchParams['orderDirection'] | undefined>('orderDirection', DEFAULT_ORDER_DIRECTION);
    const searchQuery = useRouteQuery<string>('search', '');
    const fetchSearchQuery = ref(searchQuery.value);
    const statuses = useRouteQuery<string | undefined>('statuses', undefined, {
        transform: (value) => value?.split(',').join(',') || undefined,
    });

    const sorting = computed({
        get() {
            return [
                {
                    id: sortingMap[orderBy.value ?? DEFAULT_ORDER_BY] ?? DEFAULT_ORDER_BY,
                    desc: orderDirection.value === 'desc',
                },
            ];
        },
        set(value: SortingState) {
            if (value.length) {
                orderBy.value = sortingMapReverse[value[0].id] as NotificationsFetchParams['orderBy'];
                orderDirection.value = value[0].desc ? 'desc' : 'asc';
            } else {
                orderBy.value = undefined;
                orderDirection.value = undefined;
            }
        },
    });

    const computedPageSize = computed({
        get() {
            return pageSize.value;
        },
        set(value) {
            pageSize.value = value;
            storedPageSize.value = value;
        },
    });
    const computedTypes = computed({
        get() {
            return types.value?.split(',') || [];
        },
        set(value) {
            types.value = value.join(',');
        },
    });
    const computedStatuses = computed({
        get() {
            return statuses.value?.split(',') || [];
        },
        set(value) {
            statuses.value = value.join(',');
        },
    });

    const fetchParams = computed(() => ({
        page: page.value,
        pageSize: pageSize.value,
        searchQuery: fetchSearchQuery.value,
        orderBy: orderBy.value ?? DEFAULT_ORDER_BY,
        orderDirection: orderDirection.value ?? DEFAULT_ORDER_DIRECTION,
        isUnread: unread.value ?? undefined,
    }));

    return {
        page,
        pageSize: computedPageSize,
        searchQuery,
        fetchSearchQuery,
        fetchParams,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        sorting,
        types: computedTypes,
        statuses: computedStatuses,
        unread,
    };
}
