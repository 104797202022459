import { ref, computed, Ref, ComputedRef, unref } from 'vue';
import { TableHeader } from '@/types';
import getItemProperty from '@/components/common/composables/useGetItemProperty';

export default function useFilterableTable(items: Ref<any[]> | ComputedRef<any[]>, headers: Ref<TableHeader[]> | ComputedRef<TableHeader[]> | TableHeader[]) {
    const search = ref('');

    const filteredItems = computed(() => {
        const _items = unref(items) as any[];
        const _headers = unref(headers) as TableHeader[];
        let filteredItems = _items;

        if (search.value) {
            const searchableHeaders = _headers.filter((header) => header.searchable);

            filteredItems = _items.filter((currentItem: any) =>
                Boolean(
                    searchableHeaders.find((searchableHeader) =>
                        searchableHeader.searchComparison
                            ? searchableHeader.searchComparison(currentItem, search.value)
                            : `${getItemProperty(searchableHeader, currentItem)}`.toLowerCase().trim().includes(search.value.toLowerCase().trim())
                    )
                )
            );
        }

        return filteredItems;
    });

    return {
        search,
        filteredItems,
    };
}
