import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { className: "flex space-x-1" }

import { CirclePlus, RefreshCw } from 'lucide-vue-next';
import { ref, computed, watch } from 'vue';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuItem, DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';

type AcceptableValue = string[] | undefined;

interface Props {
    title: string;
    options: any[];
    itemValue: string;
    itemLabel: string;
    /* Does only update the model when the dropdown is closed */
    updateOnlyOnClose?: boolean;
    modelValue?: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTableFilter',
  props: {
    title: {},
    options: {},
    itemValue: {},
    itemLabel: {},
    updateOnlyOnClose: { type: Boolean },
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const internalSelectedOptions = ref<AcceptableValue>(props.modelValue || []);
const selectedOptions = computed<string[]>({
    get() {
        return (props.updateOnlyOnClose ? internalSelectedOptions.value : props.modelValue) || [];
    },
    set(value: AcceptableValue) {
        if (props.updateOnlyOnClose) {
            internalSelectedOptions.value = value;
        } else {
            emit('update:modelValue', value);
        }
    },
});
const selectedOptionsWithLabel = computed(() => selectedOptions.value.map((value) => ({ value, label: props.options.find((option) => option[props.itemValue] === value)?.[props.itemLabel] })));

const isOpen = ref(false);

watch(
    () => props.modelValue,
    (value) => {
        internalSelectedOptions.value = value || [];
    }
);

function clearFilters() {
    selectedOptions.value = [];
}

function handleSelect(value: any) {
    if (selectedOptions.value.includes(value)) {
        selectedOptions.value = selectedOptions.value.filter((v) => v !== value);
    } else {
        selectedOptions.value = [...selectedOptions.value, value];
    }
}

function handleUpdateOpen(open: boolean) {
    if (open) {
        internalSelectedOptions.value = selectedOptions.value;
    } else {
        emit('update:modelValue', internalSelectedOptions.value);
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DropdownMenu), {
    open: isOpen.value,
    "onUpdate:open": [
      _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event)),
      handleUpdateOpen
    ],
    modal: false
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
        default: _withCtx(() => [
          _createVNode(_unref(Button), {
            variant: "outline",
            class: "border-dashed gap-x-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(CirclePlus), {
                class: "size-4 mr-2",
                "aria-hidden": "true"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.title) + " ", 1),
              (selectedOptions.value.length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_unref(Separator), {
                      orientation: "vertical",
                      class: "h-5 mx-2"
                    }),
                    _createElementVNode("div", _hoisted_1, [
                      (selectedOptions.value.length > 1)
                        ? (_openBlock(), _createBlock(_unref(Badge), {
                            key: "selected-count",
                            variant: "secondary",
                            class: "rounded-md px-1.5 py-1 font-normal"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(selectedOptions.value.length) + " " + _toDisplayString(_ctx.$t('global.uiElements.dataTable.filter.texts.selected')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(selectedOptionsWithLabel.value, (item, itemIndex) => {
                            return (_openBlock(), _createBlock(_unref(Badge), {
                              key: itemIndex,
                              variant: "secondary",
                              class: "rounded-md px-1.5 py-1 font-normal"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(DropdownMenuContent), {
        class: "min-w-44 max-w-full",
        align: "start"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
            return (_openBlock(), _createBlock(_unref(DropdownMenuCheckboxItem), {
              key: item[_ctx.itemValue],
              checked: selectedOptions.value.includes(item[_ctx.itemValue]),
              onSelect: _withModifiers(($event: any) => (handleSelect(item[_ctx.itemValue])), ["prevent"])
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "item", { item: item }, () => [
                  _createTextVNode(_toDisplayString(item[_ctx.itemLabel]), 1)
                ])
              ]),
              _: 2
            }, 1032, ["checked", "onSelect"]))
          }), 128)),
          _createVNode(_unref(DropdownMenuSeparator)),
          _createVNode(_unref(DropdownMenuItem), {
            disabled: selectedOptions.value.length === 0,
            class: "justify-center text-center",
            onSelect: _withModifiers(clearFilters, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(RefreshCw), {
                class: "size-4 mr-2",
                "aria-hidden": "true"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('global.uiElements.dataTable.filter.buttons.clear')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["open"]))
}
}

})