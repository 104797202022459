import { ref, computed } from 'vue';

export default function useMultiStepForm(steps: any[]) {
    const currentStepIndex = ref(0);

    const currentStep = computed(() => steps[currentStepIndex.value]);
    const isFinalStep = computed(() => currentStepIndex.value === steps.length - 1);
    let initial = 0;

    const multiStepApi = {
        next() {
            currentStepIndex.value++;
        },
        previous() {
            currentStepIndex.value--;
        },
        goToStep(stepIndex: number) {
            currentStepIndex.value = stepIndex;
        },
        setInitial(initialStep: number) {
            initial = initialStep;
            currentStepIndex.value = initialStep;
            return this;
        },
        reset() {
            currentStepIndex.value = initial;
        },
        currentStep,
        isFinalStep,
        currentStepIndex,
    };

    return multiStepApi;
}
