<template>
    <div>
        <div class="instruction-tabs default-tabs">
            <Tabs teleport="#instruction-tabs-target" active-tab-class="--active">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-4">
                        <Tab :name="$t('views.health.instructions.tabs.active')" :default="true">
                            <InstructionsTable :active="true" class="mt-8"></InstructionsTable>
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.health.instructions.tabs.inactive')">
                            <InstructionsTable :active="false" class="mt-8"></InstructionsTable>
                        </Tab>
                    </div>
                </nav>
            </Tabs>

            <div id="instruction-tabs-target" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import InstructionsTable from '@/views/dashboard/health/instructions/InstructionsTable.vue';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

export default defineComponent({
    components: { Tab, Tabs, InstructionsTable },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetTreatmentProcedures, { watchSites: true, watchFormSubmissions: true });
    },
});
</script>
