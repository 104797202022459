import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { HelpWidgetRouteItem } from '@/types';
import apiClient from '@/plugins/store/actions/api/ApiClient';
import { i18n } from '@/plugins/internationalization/i18n';

export function useGetAndOpenRouteItem() {
    const isLoading = ref(false);

    async function getAndOpenRouteItem(item: HelpWidgetRouteItem, options: { onSuccess?: CallableFunction } = {}) {
        try {
            if (isLoading.value) {
                return;
            }

            isLoading.value = true;

            const response = await apiClient.send('get', item.route, { returnResponse: true, responseType: 'blob' });

            const contentType = response.headers['content-type'];

            const blob = new Blob([response.data], { type: contentType });
            const url = window.URL.createObjectURL(blob);

            window.open(url, '_blank');

            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 200);

            if (options.onSuccess) {
                options.onSuccess();
            }
        } catch (error) {
            const toast = useToast();

            toast.error(i18n.global.t('global.errors.unknown'));
        } finally {
            isLoading.value = false;
        }
    }

    return {
        getAndOpenRouteItem,
        isLoading,
    };
}
