import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mx-6 space-x-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "w-8 my-auto" }
const _hoisted_4 = { class: "flex justify-end" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SettingsFormModal = _resolveComponent("SettingsFormModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, { class: "space-y-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.setup.content.table.title')), 1)
      ]),
      _createVNode(_component_CustomTable, {
        expandable: "",
        items: _ctx.items,
        headers: _ctx.headers
      }, {
        header_actions: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("th", { class: "w-8" }, null, -1)
        ])),
        actions: _withCtx(({ item }) => [
          _createElementVNode("td", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_SettingsFormModal, {
                header: _ctx.settingsFormModalHeader,
                "initial-state": _ctx.settingsFormModalInitialState
              }, {
                default: _withCtx(({ open }) => [
                  _createElementVNode("button", {
                    type: "button",
                    class: "p-2 -mr-2",
                    onClick: ($event: any) => (_ctx.onConfigEventClicked(item, open))
                  }, [
                    _createVNode(_component_Icon, {
                      class: "h-3 w-3",
                      src: _ctx.IconSource.Right
                    }, null, 8, ["src"])
                  ], 8, _hoisted_5)
                ]),
                _: 2
              }, 1032, ["header", "initial-state"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["items", "headers"])
    ]),
    _: 1
  }))
}