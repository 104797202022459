import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    role: "alert",
    class: _normalizeClass(["inline-flex items-center text-gray-900 py-1 pl-1.5 pr-2.5 rounded-full font-medium", _ctx.typeClasses])
  }, [
    _createVNode(_component_Icon, {
      src: _ctx.iconSrc,
      class: "w-6 h-6 mr-1.5"
    }, null, 8, ["src"]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}