import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreloadSpinner = _resolveComponent("PreloadSpinner")!
  const _component_InvalidScreenSizeWarning = _resolveComponent("InvalidScreenSizeWarning")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DashboardLayout = _resolveComponent("DashboardLayout")!
  const _component_LoadingPageOverlay = _resolveComponent("LoadingPageOverlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PreloadSpinner, {
      show: !_ctx.preloaderState.minTimePassed || !_ctx.preloaderState.dashboardLoaded
    }, null, 8, ["show"]),
    _withDirectives(_createVNode(_component_InvalidScreenSizeWarning, null, null, 512), [
      [_vShow, _ctx.windowWidth < 1024]
    ]),
    _createVNode(_component_DashboardLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    _createVNode(_component_LoadingPageOverlay)
  ]))
}