<template>
    <MainViewCard>
        <div class="px-6 py-6">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.reports.reportsPage.tables.insertions.title') }}</h4>
        </div>
        <CustomTable :headers="headers" :items="data.items">
            <template #amount="{ header, getWidth, getItemProperty, getHeaderClass, item }">
                <td
                    :key="header.value"
                    :class="`${getHeaderClass(header, false)} ${item.record_type_ids?.length ? 'underline' : ''} my-auto break-words min-h-6.5`"
                    :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                >
                    <ActivityTableModal
                        :title="$t('views.reports.reportsPage.tables.insertions.activitiesModalHeader')"
                        :record-type-ids="item.record_type_ids"
                        :records="records"
                        @fetch-records="$emit('fetch-records')"
                    >
                        <template #default="scope">
                            <span :class="{ 'cursor-pointer': item.record_type_ids?.length }" @click="item.record_type_ids?.length && scope.open()">{{ getItemProperty(header, item) }}</span>
                        </template>
                    </ActivityTableModal>
                </td>
            </template>
            <template #append-rows="{ getWidth, getItemProperty, getHeaderClass }">
                <tr v-for="i in emptyRows" :key="i" class="rounded flex py-4 px-6 gap-x-4 odd:bg-dark-gray-200">
                    <td v-for="header of headers" :key="header.value" class="min-h-6.5"></td>
                </tr>
                <tr class="rounded flex py-4 px-6 gap-x-4 odd:bg-dark-gray-200 font-semibold">
                    <td v-for="header of headers" :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words min-h-6.5`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                        {{ getItemProperty(header, data.total) }}
                    </td>
                </tr>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import ActivityTableModal from '@/views/dashboard/reports/reportsPage/ActivityTableModal.vue';
import { TableHeader, Record } from '@/types';

export default defineComponent({
    components: { CustomTable, MainViewCard, ActivityTableModal },
    emits: ['fetch-records'],
    props: {
        data: {
            type: Object as () => {
                total: { action: string; amount: number | null; weight: number | null; average_weight: number | null };
                items: {
                    action: string;
                    amount: number | null;
                    weight: number | null;
                    average_weight: number | null;
                    record_type_ids: number[];
                }[];
            },
            required: true,
        },
        records: {
            type: Array as () => Record[],
            default: [],
        },
        emptyRows: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.reports.reportsPage.tables.insertions.headers.action'),
                    value: 'action',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: this.$t('views.reports.reportsPage.tables.insertions.headers.amount'),
                    value: 'amount',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.reports.reportsPage.tables.insertions.headers.weight'),
                    value: 'weight',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: this.$t('views.reports.reportsPage.tables.insertions.headers.averageWeight'),
                    value: 'average_weight',
                    align: 'left',
                    sortable: true,
                    headerClass: 'xl:min-w-36',
                },
            ];
        },
    },
});
</script>
