import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { HTMLAttributes } from 'vue';
import { SelectLabel, SelectLabelProps } from 'radix-vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectLabel',
  props: {
    for: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectLabel), {
    class: _normalizeClass(_unref(cn)('py-1.5 pl-8 pr-2 text-normal font-semibold', props.class))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})