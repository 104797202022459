<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.notes.header.heading')" has-period-picker>
        <NotesTable></NotesTable>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import NotesTable from './NotesTable.vue';

export default defineComponent({
    components: { DashboardContentLayout, NotesTable },
});
</script>
