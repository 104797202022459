<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.feed.consumption.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-8">
            <MainViewCard class="pt-4 pb-2 px-2">
                <GenericChartWithApi :chart-name="chart.name" :height="chart.height" :chart-type="chart.type"></GenericChartWithApi>
            </MainViewCard>
            <MainViewCard>
                <GenericTableWithApi :source="feedConsumptionTableSource"></GenericTableWithApi>
            </MainViewCard>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import GenericTableWithApi from '@/components/common/GenericTableWithApi.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import { Table, Chart, ChartType } from '@/types';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, GenericTableWithApi, GenericChartWithApi },
    data() {
        return {
            feedConsumptionTableSource: Table.FeedConsumption,
            chart: {
                name: Chart.FeedConsumption,
                type: ChartType.LineChart,
                height: 300,
            },
        };
    },
});
</script>
