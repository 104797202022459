import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChart = _resolveComponent("GenericChart")!
  const _component_EmptyChartState = _resolveComponent("EmptyChartState")!

  return (_openBlock(), _createBlock(_component_EmptyChartState, {
    options: _ctx.chartOptions,
    class: "w-full grid"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_GenericChart, { options: _ctx.chartOptions }, null, 8, ["options"])
    ]),
    _: 1
  }, 8, ["options"]))
}