import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericMultiStepModal = _resolveComponent("GenericMultiStepModal")!

  return (_openBlock(), _createBlock(_component_GenericMultiStepModal, {
    "header-icon": _ctx.header?.iconUrl || _ctx.form?.event?.icon_url || '',
    "is-final-step": _ctx.isFinalStep,
    "modal-class": "w-128",
    onClosing: _ctx.onModalClose
  }, {
    default: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    component: _withCtx((scope) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentStep.component), {
        state: _ctx.form,
        "onUpdate:state": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event)),
        config: _ctx.currentStepConfig,
        header: { icon_url: _ctx.header?.iconUrl || _ctx.form?.event?.icon_url },
        onSubmit: _ctx.next,
        onSkip: _cache[1] || (_cache[1] = (stepsToSkip = 2) => _ctx.goToStep(_ctx.currentStepIndex + stepsToSkip)),
        onBack: _ctx.previous,
        onClose: scope.close
      }, null, 40, ["state", "config", "header", "onSubmit", "onBack", "onClose"]))
    ]),
    _: 3
  }, 8, ["header-icon", "is-final-step", "onClosing"]))
}