import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue';
import { Separator, type SeparatorProps } from 'radix-vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'Separator',
  props: {
    orientation: {},
    decorative: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: {},
    label: {}
  },
  setup(__props: any) {

const props = __props;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Separator), _mergeProps(delegatedProps.value, {
    class: _unref(cn)('shrink-0 bg-gray-200 relative', props.orientation === 'vertical' ? 'w-px h-full' : 'h-px w-full', props.class)
  }), {
    default: _withCtx(() => [
      (props.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(
                _unref(cn)(
                    'text-xs text-gray-500 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center',
                    props.orientation === 'vertical' ? 'w-[1px] px-1 py-2' : 'h-[1px] py-1 px-2'
                )
            )
          }, _toDisplayString(props.label), 3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["class"]))
}
}

})