import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { Select as UISelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { ChartPeriod } from '@/types';

interface Props {
    modelValue: ChartPeriod;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChartPeriodSelect',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const computedModelValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UISelect), {
    modelValue: computedModelValue.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((computedModelValue).value = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(SelectTrigger), { class: "rounded-full min-w-28 font-medium bg-primary-800 h-auto text-base border-none hover:bg-primary-700 transition-colors text-white w-auto px-4 py-2" }, {
        default: _withCtx(() => [
          _createVNode(_unref(SelectValue))
        ]),
        _: 1
      }),
      _createVNode(_unref(SelectContent), {
        align: "end",
        "body-lock": false
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(SelectGroup), null, {
            default: _withCtx(() => [
              _createVNode(_unref(SelectItem), {
                value: _unref(ChartPeriod).THREE_MONTHS
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.charts.periods.threeMonths')), 1)
                ]),
                _: 1
              }, 8, ["value"]),
              _createVNode(_unref(SelectItem), {
                value: _unref(ChartPeriod).SIX_MONTHS
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.charts.periods.sixMonths')), 1)
                ]),
                _: 1
              }, 8, ["value"]),
              _createVNode(_unref(SelectItem), {
                value: _unref(ChartPeriod).NINE_MONTHS
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.charts.periods.nineMonths')), 1)
                ]),
                _: 1
              }, 8, ["value"]),
              _createVNode(_unref(SelectItem), {
                value: _unref(ChartPeriod).TWELVE_MONTHS
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.charts.periods.twelveMonths')), 1)
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})