import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"z-index":"1060"},
  class: "fixed w-full h-full left-0 top-0 grid bg-white"
}
const _hoisted_2 = {
  key: 0,
  class: "m-auto"
}
const _hoisted_3 = {
  key: 1,
  class: "m-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide-fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.lottieAnimation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_Vue3Lottie, {
                    "animation-link": _ctx.lottieAnimation,
                    width: 300
                  }, null, 8, ["animation-link"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_Icon, {
                    class: "w-36 mx-auto",
                    src: _ctx.SPINNING_ICON_SOURCE
                  }, null, 8, ["src"]),
                  _createVNode(_component_Icon, { src: _ctx.STATIC_ICON_SOURCE }, null, 8, ["src"])
                ]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}