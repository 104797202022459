import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-6" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "w-6 my-auto" }
const _hoisted_4 = { class: "flex justify-end" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_PigsBatchesModal = _resolveComponent("PigsBatchesModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.pigs.batches.table.header').replace('$START_DATE', _ctx.dateStrings[0]).replace('$END_DATE', _ctx.dateStrings[1])), 1)
      ]),
      _createVNode(_component_CustomTable, {
        headers: _ctx.headers,
        items: _ctx.items
      }, {
        header_actions: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("th", { class: "w-6" }, null, -1)
        ])),
        is_active: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          _createElementVNode("td", {
            class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex rounded-full h-5 w-5 bg-opacity-20", item.is_active ? 'bg-green-200' : 'bg-red-400'])
            }, [
              _createVNode(_component_Icon, {
                class: "m-auto",
                src: item.is_active ? _ctx.IconSource.Active : _ctx.IconSource.Inactive
              }, null, 8, ["src"])
            ], 2)
          ], 6)
        ]),
        start_date: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          _createElementVNode("td", {
            class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, _toDisplayString(_ctx.$d(item.start_date, 'short')), 7)
        ]),
        end_date: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
          _createElementVNode("td", {
            class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} my-auto`]),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, _toDisplayString(item.end_date === null ? '-' : _ctx.$d(item.end_date, 'short')), 7)
        ]),
        actions: _withCtx(({ item }) => [
          _createElementVNode("td", _hoisted_3, [
            _createVNode(_component_PigsBatchesModal, { batch: item }, {
              default: _withCtx(({ open }) => [
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "p-2 -mr-2",
                    onClick: open
                  }, [
                    _createVNode(_component_Icon, {
                      class: "h-3 w-3",
                      src: _ctx.IconSource.Right
                    }, null, 8, ["src"])
                  ], 8, _hoisted_5)
                ])
              ]),
              _: 2
            }, 1032, ["batch"])
          ])
        ]),
        _: 1
      }, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}