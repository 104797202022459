export type MiscEvent = {
    id: number;
    name?: string;
    icon_id: number;
    action_id: number;
};

// ID of the event in the database
export enum MiscEventType {
    assignmentTemplate = 1,
}
