import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full relative overflow-hidden text-dark-gray-800 text-opacity-40 font-light" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.uid,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      class: _normalizeClass([_ctx.backgroundColor, "cursor-text font-light w-full py-2 pl-10 pr-1 rounded-xl outline-none"]),
      type: "text",
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
      onKeydown: [
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"]), ["enter"])),
        _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.$emit('onTab')), ["tab"]))
      ],
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur'))),
      onKeyup: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEscape && _ctx.onEscape(...args)), ["esc"]))
    }, null, 42, _hoisted_2), [
      [_vModelText, _ctx.inputValue]
    ]),
    _createElementVNode("label", {
      for: _ctx.uid,
      class: "flex absolute top-2 left-2 h-6"
    }, [
      _createVNode(_component_Icon, {
        class: "my-auto pr-2 cursor-text",
        src: _ctx.IconSource.Search
      }, null, 8, ["src"]),
      _withDirectives(_createElementVNode("span", { class: "pl-0.5 cursor-text" }, _toDisplayString(_ctx.label), 513), [
        [_vShow, !_ctx.inputValue]
      ])
    ], 8, _hoisted_3)
  ]))
}