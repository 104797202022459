<template>
    <GenericMultiStepModal :header-icon="IconSource.Treatment" :is-final-step="isFinalStep" modal-class="w-128" @closing="onModalClose">
        <template #default="scope">
            <slot v-bind="scope" />
        </template>
        <template #component="scope">
            <component
                :is="currentStep.component"
                v-model:state="form"
                :text="submissionStatusText"
                :config="currentStepConfig"
                :header="{ text: $t('views.health.instructions.modals.createInstruction.header') }"
                @back="previous"
                @submit="next"
                @close="scope.close"
                @submission="$emit('submission')"
            >
            </component>
        </template>
    </GenericMultiStepModal>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import isEqual from 'lodash-es/isEqual';
import { ActionType } from '@/plugins/store/actions';
import CreateInstructionForm from '@/views/dashboard/health/instructions/createInstructionModal/createInstructionForm.vue';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import useResettableForm from '@/components/common/composables/useResettableForm';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import GenericMultiStepModal from '@/components/common/GenericMultiStepModal.vue';
import GenericSubmissionStatus from '@/components/common/GenericSubmissionStatus.vue';
import DynamicForm from '@/components/common/multiStepFormSteps/DynamicForm.vue';
import ConfirmationForm from '@/components/common/multiStepFormSteps/ConfirmationForm.vue';

export default defineComponent({
    components: { GenericMultiStepModal, MultiStepFormHeaderIcon, CloseModalButton },
    props: {
        stepConfig: {
            type: Array,
            default: [
                {},
                {},
                {
                    disableRefetch: true,
                    submitActionType: ActionType.PutForm,
                },
                {},
            ],
        },
    },
    emits: ['closing', 'submission'],
    setup(props, context) {
        const multiSteps = useMultiSteps([
            {
                component: CreateInstructionForm,
            },
            {
                component: DynamicForm,
            },
            {
                component: ConfirmationForm,
            },
            {
                component: GenericSubmissionStatus,
            },
        ]);

        const resettableForm = useResettableForm().setInitial({
            farms: [],
            resource: null,
        });

        const form = resettableForm.getInstance();
        const currentStepConfig = computed(() => props.stepConfig[multiSteps.currentStepIndex.value]);

        const onModalClose = (next: (closeModal: boolean) => boolean) => {
            if ([0, 1, 2].includes(multiSteps.currentStepIndex.value) && !isEqual(resettableForm.getInitial(), form.value.data())) {
                if (!window.confirm(i18n.global.t('global.forms.confirmCloseModalMessage'))) {
                    return next(false);
                }
            }

            context.emit('closing', multiSteps.isFinalStep);

            multiSteps.reset();
            resettableForm.reset();

            return true;
        };

        const submissionStatusText = computed(() => i18n.global.t('views.health.instructions.modals.createInstruction.successMessage'));

        return {
            onModalClose,
            ...multiSteps,
            form,
            submissionStatusText,
            currentStepConfig,
        };
    },
});
</script>
