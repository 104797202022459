<template>
    <div class="h-full min-h-60 space-y-4">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.settings.users.content.modals.edit.steps.form.title') }}</h2>
        <div>
            <div>
                <CustomInput
                    v-model:value="computedForm.email"
                    :label="$t('views.settings.users.content.modals.edit.steps.form.emailFieldLabel')"
                    input-type="email"
                    :rules="[{ validate: validateEmail, message: '' }]"
                ></CustomInput>
                <CustomInput
                    v-model:value="computedForm.name"
                    :label="$t('views.settings.users.content.modals.edit.steps.form.nameFieldLabel')"
                    input-type="text"
                    :rules="[{ validate: validateName, message: '' }]"
                ></CustomInput>
                <CustomInput
                    v-model:value="computedForm.phoneNumber"
                    :label="$t('views.settings.users.content.modals.edit.steps.form.phoneNumberFieldLabel')"
                    input-type="text"
                    :rules="[{ validate: validatePhoneNumber, message: '' }]"
                ></CustomInput>
            </div>
            <div class="flex space-x-4">
                <SettingsModal>
                    <template #default="{ open }">
                        <AddButton @click="open">
                            {{ $t('views.settings.users.content.modals.edit.steps.form.permissionsButtonLabel') }}
                        </AddButton>
                    </template>
                    <template #form="{ close }">
                        <EditUserPermissionsForm :directory-user="computedForm.directoryUser" @cancel="close" @submit="close"></EditUserPermissionsForm>
                    </template>
                </SettingsModal>
                <div>
                    <DeleteUserConfirmationModal v-if="showDeleteUserButton" :directory-user="computedForm.directoryUser" @submit="$emit('close')">
                        <template #default="scope">
                            <DeleteButton @click="scope.open">
                                {{ $t('views.settings.users.content.modals.delete.deleteModalButtonLabel') }}
                            </DeleteButton>
                        </template>
                    </DeleteUserConfirmationModal>
                </div>
            </div>
            <div class="mt-4 grid gap-y-4 h-full">
                <div class="flex space-x-4 self-end">
                    <div class="flex-grow">
                        <CustomButton :loading="disableButtons" :disabled="!validForm" @click="onNextButtonClicked">
                            {{ $t('views.settings.users.content.modals.edit.steps.form.nextButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import { EditUserForm, Module } from '@/types';
import { store } from '@/plugins/store';
import AddButton from '@/components/ui/AddButton.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import SettingsModal from '@/views/dashboard/settings/SettingsModal.vue';
import EditUserPermissionsForm from '@/views/dashboard/settings/users/forms/EditUserPermissionsForm.vue';
import DeleteUserConfirmationModal from '@/views/dashboard/settings/users/DeleteUserConfirmationModal.vue';

export default defineComponent({
    components: { AddButton, DeleteButton, CustomInput, CustomButton, SettingsModal, EditUserPermissionsForm, DeleteUserConfirmationModal },
    emits: ['update:form', 'submit', 'skip', 'close'],
    props: {
        form: {
            type: Object as () => EditUserForm & Form<EditUserForm>,
            required: true,
        },
    },
    data() {
        return { disableButtons: false };
    },
    computed: {
        computedForm: {
            get(): EditUserForm & Form<EditUserForm> {
                return this.$props.form;
            },
            set(newValue: EditUserForm) {
                this.$emit('update:form', newValue);
            },
        },
        isLoggedInUser(): boolean {
            return this.computedForm.directoryUser.id === store.state.user?.id;
        },
        showDeleteUserButton(): boolean {
            return store.getters.hasModuleEnabled(Module.UserManagement) && !this.isLoggedInUser;
        },
        validForm() {
            return this.validateEmail(this.computedForm.email) && this.validateName(this.computedForm.name) && this.validatePhoneNumber(this.computedForm.phoneNumber);
        },
    },
    methods: {
        onNextButtonClicked() {
            if (this.validForm && !this.disableButtons) {
                this.disableButtons = true;
                this.$emit('submit');
            }
        },
        validateEmail(value: string) {
            return (
                value && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase())
            );
        },
        validateName(value: string) {
            return value.length > 3;
        },
        validatePhoneNumber(value: string) {
            return value.length > 6;
        },
    },
});
</script>
