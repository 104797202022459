<script setup lang="ts">
import { HTMLAttributes, computed } from 'vue';
import { SelectItem, SelectItemProps, SelectItemText, useForwardProps } from 'radix-vue';
import { cn } from '@/lib/utils';

const props = defineProps<SelectItemProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <SelectItem
        v-bind="forwardedProps"
        :class="
            cn(
                'relative flex w-full cursor-pointer select-none items-center py-2 px-3 text-base outline-none truncate data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                'hover:bg-gray-100 data-[state=checked]:!bg-primary-300 data-[state=checked]:!bg-opacity-40',
                'data-[highlighted]:bg-gray-100',
                props.class
            )
        "
    >
        <SelectItemText>
            <slot />
        </SelectItemText>
    </SelectItem>
</template>
