import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "flex mx-6 space-x-6 pt-4" }
const _hoisted_3 = { class: "flex-1 font-medium" }
const _hoisted_4 = { class: "text-lg my-auto" }
const _hoisted_5 = {
  key: 0,
  class: "opacity-60"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EconomyPostPaymentsGlobalPrices = _resolveComponent("EconomyPostPaymentsGlobalPrices")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_CreateOrEditPriceChargeModal = _resolveComponent("CreateOrEditPriceChargeModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.canManageSolution)
      ? (_openBlock(), _createBlock(_component_EconomyPostPaymentsGlobalPrices, {
          key: 0,
          "post-payments": _ctx.items
        }, null, 8, ["post-payments"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MainViewCard, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('views.economy.postPayments.table.header')), 1),
            (_ctx.displaySubheader)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.$t('views.economy.postPayments.table.subheader')), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_CreateOrEditPriceChargeModal, { "step-config": _ctx.priceChargeStepConfig }, {
            default: _withCtx(({ open }) => [
              _createVNode(_component_AddButton, {
                "text-class": "font-medium",
                onClick: open
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.economy.postPayments.table.addButtonLabel')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["step-config"])
        ]),
        _createVNode(_component_CustomTable, {
          headers: _ctx.headers,
          items: _ctx.items
        }, null, 8, ["headers", "items"])
      ]),
      _: 1
    })
  ]))
}