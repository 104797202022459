import { computed } from 'vue';
import useDefaultChartOptions from '@/components/charts/composables/useDefaultChartOptions';

export default function useSplineChartOptions(chartInstanceOptions: any) {
    return useDefaultChartOptions(chartInstanceOptions, {
        chart: { type: 'spline' },
        plotOptions: {
            series: {
                lineWidth: 2,
                marker: {
                    radius: 6,
                },
                dashStyle: 'LongDash',
            },
        },
    });
}
