export enum ActivityTableRecordType {
    Insertion = 1,
    Departure = 2,
    Transfer = 3,
    Slaughter = 4,
    Dead = 5,
    Status = 6,
    Consumption = 7,
    Delivery = 8,
    Economy = 9,
    Treatment = 11,
    PreSlaughter = 13,
}
