<template>
    <div :class="classes">
        <div class="grid h-full">
            <div :class="[cursorClass]" class="flex flex-1 p-3 rounded-md border bg-green-200 border-green-700 border-opacity-50 bg-opacity-20" @click.prevent="onClicked">
                <label class="flex">
                    <CustomCheckbox v-model:checked="computedValue" :disabled="disabled" class="my-auto"></CustomCheckbox>
                    <span :class="[textColorClass, cursorClass, labelClasses]">{{ label }}</span>
                </label>
            </div>
        </div>
        <span v-show="details" class="h-4 flex text-xs text-left w-full ml-1" :class="{ 'text-red-600': details?.type === 'error', 'text-gray-500': details?.type === 'hint' }">{{ details?.text || '' }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';

export default defineComponent({
    components: { CustomCheckbox },
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
        classes: {
            type: String,
            default: 'w-full max-w-52',
        },
        labelClasses: {
            type: String,
            default: 'pl-2 my-auto',
        },
    },
    computed: {
        textColorClass(): string {
            return this.disabled ? 'text-gray-400' : 'text-dark-gray-600';
        },
        cursorClass(): string {
            return this.disabled ? '' : 'cursor-pointer';
        },
        computedValue: {
            get(): boolean {
                return this.value;
            },
            set(newValue: boolean) {
                this.$emit('update:value', newValue);
            },
        },
    },
    methods: {
        onClicked() {
            if (!this.$props.disabled) {
                this.computedValue = !this.computedValue;
            }
        },
    },
});
</script>

<style scoped>
input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    border-radius: 100%;
    height: 1em;
    width: 1em;
    color: #4299e1;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    margin-bottom: 2px;
}

input[type='radio']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: #91c391;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
