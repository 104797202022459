import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-8 py-6 space-y-8 h-full overflow-hidden" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = { class: "pb-8 h-full overflow-auto" }
const _hoisted_4 = { class: "space-y-4 overflow-auto max-h-full" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex relative h-full my-auto" }
const _hoisted_7 = { class: "flex flex-shrink-0 flex-1 h-full my-auto" }
const _hoisted_8 = { class: "my-auto" }
const _hoisted_9 = { class: "font-semibold text-left" }
const _hoisted_10 = { class: "text-left text-sm" }
const _hoisted_11 = { class: "flex text-gray-400 text-sm" }
const _hoisted_12 = { class: "flex-shrink-0" }
const _hoisted_13 = { class: "flex flex-shrink-0 h-full my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ActionModal = _resolveComponent("ActionModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t('views.monitoring.log.calendarModal.header')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ActionModal, {
        "initial-step-index": 1,
        "initial-state": _ctx.actionModalInitialState,
        "step-config": _ctx.actionModalStepConfig,
        header: _ctx.actionModalHeader
      }, {
        default: _withCtx(({ open }) => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alarms, (alarm) => {
              return (_openBlock(), _createElementBlock("button", {
                key: alarm.id,
                type: "button",
                class: "flex items-center space-x-4 bg-dark-gray-200 bg-opacity-80 py-2 px-4 w-full rounded-lg h-20",
                onClick: ($event: any) => (_ctx.onAlarmClicked(alarm, open))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Icon, {
                    class: "my-auto h-12 w-12",
                    src: alarm.icon
                  }, null, 8, ["src"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(alarm.type), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(alarm.cause), 1),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$d(alarm.date, 'long')), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (alarm.severity_level === 3)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 0,
                        class: "h-6 w-6",
                        src: _ctx.IconSource.AlarmIcon
                      }, null, 8, ["src"]))
                    : (alarm.severity_level === 2)
                      ? (_openBlock(), _createBlock(_component_Icon, {
                          key: 1,
                          class: "h-6 w-6",
                          src: _ctx.IconSource.AttentionIcon
                        }, null, 8, ["src"]))
                      : (_openBlock(), _createBlock(_component_Icon, {
                          key: 2,
                          class: "h-6 w-6",
                          src: _ctx.IconSource.OKIcon
                        }, null, 8, ["src"]))
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_Icon, {
                    class: "my-auto h-3 w-3 mr-2",
                    src: _ctx.IconSource.Right
                  }, null, 8, ["src"])
                ])
              ], 8, _hoisted_5))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["initial-state", "step-config", "header"])
    ])
  ]))
}