import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex space-x-4" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex h-full whitespace-nowrap" }
const _hoisted_4 = { class: "font-semibold my-auto mr-1" }
const _hoisted_5 = { class: "my-auto w-21" }
const _hoisted_6 = { class: "h-full flex my-auto ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExpandIcon = _resolveComponent("ExpandIcon")!
  const _component_FlatPickrWrapper = _resolveComponent("FlatPickrWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pickers, (picker, pickerIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: picker.id,
        class: "period-picker relative"
      }, [
        _createVNode(_component_FlatPickrWrapper, {
          show: picker.expandPicker,
          "onUpdate:show": ($event: any) => ((picker.expandPicker) = $event),
          value: picker.value,
          "auto-width": "",
          class: "h-full",
          config: _ctx.pickerConfigs[pickerIndex],
          "onUpdate:value": (newValue) => _ctx.onValueChanged(picker, newValue)
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              autocomplete: "off",
              class: _normalizeClass([picker.expandPicker ? 'bg-white border-black' : 'bg-transparent border-gray-200', "text-left flex text-base text-dark-gray-600 content-center h-full px-2 py-3 border-solid border rounded-md"]),
              onClick: _withModifiers(($event: any) => (picker.expandPicker = !picker.expandPicker), ["prevent"])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.pickerPrefixes[pickerIndex]), 1),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.pickerInputValues[pickerIndex]), 1),
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_ExpandIcon, {
                    class: "my-auto w-4 h-2",
                    color: "black",
                    expand: picker.expandPicker
                  }, null, 8, ["expand"])
                ])
              ])
            ], 10, _hoisted_2)
          ]),
          _: 2
        }, 1032, ["show", "onUpdate:show", "value", "config", "onUpdate:value"])
      ]))
    }), 128))
  ]))
}