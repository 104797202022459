<template>
    <div class="relative">
        <DropDown v-model:expand="expandPicker" dropdown-class="w-full -mt-4 flex flex-col" :dropdown-content-style="'overflow-y: visible !important'">
            <template #dropdown-content>
                <div class="grid">
                    <FlatPickrWrapper v-if="showCalendar" v-model:value="computedValue.start" :show="true" :disabled="false" :config="flatpickrConfig" mode="single" inline></FlatPickrWrapper>
                    <Modal close-esc-event="keydown" :close-button="false" modal-class="w-128 m-auto border-0" mask-class="grid">
                        <template #toggle="scope">
                            <button class="bg-primary-500 flex w-full space-x-4 rounded-b-md py-3 px-4" type="button" @click="scope.open">
                                <div class="flex mx-auto space-x-4">
                                    <Icon class="h-4 my-auto" :src="IconSource.RefreshActivities"></Icon>
                                    <span class="underline">{{ $t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.openRecurrenceButtonLabel') }}</span>
                                </div>
                            </button>
                        </template>
                        <template #header="scope">
                            <CloseModalButton @close="scope.close"></CloseModalButton>
                        </template>
                        <template #body>
                            <div class="py-6 px-8">
                                <div class="text-center pb-4">
                                    <h2 class="font-semibold text-xl">{{ $t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.header') }}</h2>
                                </div>
                                <CustomSelect
                                    v-model:value="computedValue.type"
                                    :items="frequencySelectItems"
                                    required
                                    item-value="id"
                                    :allow-empty="false"
                                    dropdown-height-class="max-h-84"
                                    item-text="name"
                                    :rules="[]"
                                    :label="$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.typeFieldLabel')"
                                    :show-required-indication="false"
                                    :show-hint="false"
                                ></CustomSelect>
                                <ExpandCollapseTransition>
                                    <div v-if="computedValue?.type === 'weekly' || computedValue?.type === 'daily'">
                                        <CustomSelect
                                            v-model:value="intervalSelectValue"
                                            :items="intervalSelectItems"
                                            required
                                            item-value="id"
                                            :allow-empty="false"
                                            dropdown-height-class="max-h-84"
                                            item-text="name"
                                            :rules="[]"
                                            :label="$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervalFieldLabel')"
                                            :show-required-indication="false"
                                            :show-hint="false"
                                        ></CustomSelect>
                                    </div>
                                </ExpandCollapseTransition>
                                <ExpandCollapseTransition>
                                    <div v-if="computedValue?.type === 'weekly'">
                                        <CustomMultiSelect
                                            v-model:value="daysOfWeekMultiSelectValue"
                                            :items="daysOfWeekMultiSelectItems"
                                            :label="$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeekFieldLabel')"
                                            item-text="name"
                                            item-value="id"
                                            :allow-empty="false"
                                            :show-required-indication="false"
                                        ></CustomMultiSelect>
                                    </div>
                                </ExpandCollapseTransition>
                                <ExpandCollapseTransition>
                                    <div v-if="computedValue?.type === 'weekly' || computedValue?.type === 'daily'">
                                        <DatePicker
                                            v-model:value="computedValue.end"
                                            :min="computedValue.start"
                                            :max="max"
                                            :label="$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.endDateFieldlabel')"
                                        ></DatePicker>
                                    </div>
                                </ExpandCollapseTransition>
                            </div>
                        </template>
                    </Modal>
                </div>
            </template>
            <CustomInput
                ref="input-field"
                v-model:value="inputValue"
                :required="required"
                :show-required-indication="required"
                :rules="[]"
                input-type="text"
                :label="label"
                :details="details"
                :disabled="disabled"
                readonly
                :add-input-class="inputValueFontSize"
                cursor="cursor-pointer"
                @submit="$emit('submit')"
                @focus="expandPicker = true"
                @keydown.esc.stop="onEscape"
            ></CustomInput>
            <div class="relative inset-0 absolute pointer-events-none">
                <span :class="[{ 'cursor-default opacity-40': disabled }, details ? '-mt-3.5' : '-mt-2']" class="absolute h-full top-0 right-5 grid content-center" @click="$refs['input-field'].focusInput()">
                    <Icon class="m-auto" :src="IconSource.DatePicker"></Icon>
                </span>
            </div>
        </DropDown>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import FlatPickrWrapper from '@/components/ui/FlatpickrWrapper.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import DropDown from '@/components/common/DropDown.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';

type DateTimeRecurrencePickerValue = {
    type: 'weekly' | 'daily' | 'single';
    start: string;
    end: string | null;
    interval: number | null;
    weekdays: number[] | null;
};

export default defineComponent({
    components: { FlatPickrWrapper, CustomInput, DropDown, CustomSelect, CustomMultiSelect, DatePicker, Modal, CloseModalButton, ExpandCollapseTransition },
    emits: ['update:value', 'submit'],
    props: {
        value: {
            type: Object as () => null | DateTimeRecurrencePickerValue,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: String,
            default: '',
        },
        max: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
    },
    data() {
        return {
            expandPicker: false,
            showCalendar: false,
            previousType: this.$props.value?.type || null,
        };
    },
    computed: {
        intervalSelectValue: {
            get() {
                return `${this.computedValue.interval}`;
            },
            set(newValue: string) {
                this.computedValue.interval = parseInt(newValue);
            },
        },
        intervalSelectItems() {
            if (this.computedValue.type === 'daily') {
                return [
                    { id: '1', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.daily[0]') },
                    { id: '2', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.daily[1]') },
                    { id: '3', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.daily[2]') },
                    { id: '4', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.daily[3]') },
                    { id: '5', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.daily[4]') },
                    { id: '6', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.daily[5]') },
                ];
            }
            if (this.computedValue.type === 'weekly') {
                return [
                    { id: '1', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.weekly[0]') },
                    { id: '2', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.weekly[1]') },
                    { id: '3', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.weekly[2]') },
                    { id: '4', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.intervals.weekly[3]') },
                ];
            }
            return [];
        },
        frequencySelectItems() {
            return [
                { id: 'single', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.types.single') },
                { id: 'daily', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.types.daily') },
                { id: 'weekly', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.types.weekly') },
            ];
        },
        daysOfWeekMultiSelectItems() {
            return [
                { id: '0', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeek[0]') },
                { id: '1', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeek[1]') },
                { id: '2', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeek[2]') },
                { id: '3', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeek[3]') },
                { id: '4', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeek[4]') },
                { id: '5', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeek[5]') },
                { id: '6', name: this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.recurrenceModal.daysOfWeek[6]') },
            ];
        },
        flatpickrConfig(): any {
            return {
                maxDate: this.$props.max ? new Date(this.$props.max) : null,
                minDate: this.$props.min ? new Date(this.$props.min) : null,
                enableTime: true,
                dateFormat: 'Z',
                time_24hr: true,
            };
        },
        computedValue: {
            get(): DateTimeRecurrencePickerValue {
                return this.$props.value!;
            },
            set(newValue: DateTimeRecurrencePickerValue) {
                this.$emit('update:value', newValue);
            },
        },
        daysOfWeekMultiSelectValue: {
            get() {
                return this.computedValue.weekdays?.map((daysOfWeek) => this.daysOfWeekMultiSelectItems.find((item) => item.id === `${daysOfWeek}`)) || [];
            },
            set(newValue: { id: string; name: string }[]) {
                this.computedValue.weekdays = newValue.map((item) => parseInt(item.id));
            },
        },
        inputValue(): string {
            if (this.computedValue && this.computedValue.start && this.computedValue.type === 'single') {
                return this.$d(new Date(this.computedValue.start), 'long').replace(/( \d{2})(.)(\d{2})/, '$1:$3');
            }
            const endsAt = this.computedValue.end
                ? `${this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.endsAt')}`.replace('$DATE', this.$d(new Date(this.computedValue.end), 'short'))
                : '';
            const from = this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.from').replace('$DATE', this.$d(new Date(this.computedValue.start), 'short'));

            if (this.computedValue && this.computedValue.start && this.computedValue.type === 'daily') {
                const interval = `${
                    this.computedValue.interval === 1
                        ? this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.day')
                        : `${this.computedValue.interval} ${this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.days')}`
                }`;
                return this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.labelDaily').replace('$INTERVAL', interval).replace('$FROM', from).replace('$ENDS_AT', endsAt);
            }
            if (this.computedValue && this.computedValue.start && this.computedValue.type === 'weekly') {
                const daysOfWeek =
                    (this.computedValue.weekdays?.length || 1) === 1
                        ? this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.daysOfWeekSingular')
                        : this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.daysOfWeekPlural').replace('$NUMBER', `${this.computedValue.weekdays?.length}`);
                let interval =
                    this.computedValue.interval === 1
                        ? this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.intervalSingular')
                        : `${this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.intervalPlural')}`.replace('$NUMBER', `${this.computedValue.interval}`);
                if (!daysOfWeek) {
                    interval = interval.charAt(0).toUpperCase() + interval.slice(1);
                }

                return this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.inputFieldLabelComponents.labelWeekly')
                    .replace('$DAYS_OF_WEEK', daysOfWeek)
                    .replace('$INTERVAL', interval)
                    .replace('$FROM', from)
                    .replace('$ENDS_AT', endsAt);
            }
            return '';
        },
        inputValueFontSize() {
            return this.inputValue.length > 45 ? 'text-sm leading-6' : 'text-base';
        },
    },
    watch: {
        expandPicker(value: boolean) {
            if (value) {
                this.$nextTick(() => {
                    this.showCalendar = true;
                });
            }
        },
        computedValue: {
            handler(newValue: DateTimeRecurrencePickerValue) {
                if (newValue.type === this.previousType) {
                    return;
                }
                switch (newValue.type) {
                    case 'single': {
                        this.computedValue = {
                            ...newValue,
                            end: null,
                            interval: null,
                            weekdays: null,
                        };
                        break;
                    }
                    case 'daily': {
                        this.computedValue = {
                            ...newValue,
                            interval: newValue.interval || 1,
                            weekdays: null,
                        };
                        break;
                    }
                    default: {
                        this.computedValue = {
                            ...newValue,
                            interval: newValue.interval || 1,
                            weekdays: [0],
                        };
                    }
                }
                this.previousType = newValue.type;
            },
            deep: true,
        },
    },
    created() {
        this.$nextTick(() => {
            if (!this.value) {
                this.computedValue = {
                    type: 'single',
                    start: new Date().toISOString(),
                    end: null,
                    interval: null,
                    weekdays: null,
                };
            } else if (this.value.type === 'weekly') {
                this.computedValue = {
                    ...(this.value as DateTimeRecurrencePickerValue),
                    interval: this.value!.interval || 1,
                    weekdays: this.value!.weekdays || [0],
                };
            } else if (this.value.type === 'daily') {
                this.computedValue = {
                    ...this.value,
                    interval: this.value.interval || 1,
                    weekdays: null,
                };
            } else {
                this.computedValue = {
                    ...this.value,
                    interval: null,
                    weekdays: null,
                };
            }
        });
    },
    methods: {
        onEscape() {
            this.expandPicker = false;
            (document.activeElement as HTMLElement)?.blur();
        },
    },
});
</script>
<style>
.form-date-time-picker .flatpickr-wrapper {
    top: 2rem;
}
</style>
