import { Options } from 'highcharts';
import { computed, ref, Ref, watch, unref } from 'vue';
import isEqual from 'lodash-es/isEqual';
import { Chart, GraphOptions } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default function useChartAPI(
    chartType: Chart,
    fetchActionType: ActionType,
    addParameters?: null | Ref<string>,
    fetchOptions?: {
        usePigtypeFilter?: boolean;
    }
) {
    const options = ref(null) as Ref<null | Options>;
    const series = ref([]) as Ref<{ name: string; data: any[][] }[]>;
    const title = ref('');

    const graphPreconditions = computed(() => ({
        siteIds: fetchOptions?.usePigtypeFilter ? store.getters.getSelectedSiteIdsFilteredByPigType : store.getters.getSelectedSiteIds,
        selectedPeriod: store.getters.getSelectedPeriod as string,
    }));

    const prevGraphPreconditions = ref(null) as Ref<null | { siteIds: number[]; selectedPeriod: string }>;

    watch(
        () => graphPreconditions,
        (newValue) => {
            if ((graphPreconditions.value.siteIds.length || prevGraphPreconditions.value?.siteIds.length) && newValue.value.selectedPeriod.length && !isEqual(newValue.value, prevGraphPreconditions.value)) {
                store.dispatch(fetchActionType, { form: { chartType, addParameters: unref(addParameters), usePigtypeFilter: fetchOptions?.usePigtypeFilter } }).then((graphResponse: GraphOptions) => {
                    if (!graphResponse) return;

                    options.value = graphResponse.options;
                    title.value = graphResponse.title;
                    series.value = graphResponse.series;
                });
            }
            prevGraphPreconditions.value = newValue.value;
        },
        { deep: true, immediate: true }
    );

    if (addParameters) {
        watch(addParameters, (newValue: null | undefined | Ref<string>) => {
            store.dispatch(fetchActionType, { form: { chartType, addParameters: unref(newValue), usePigtypeFilter: fetchOptions?.usePigtypeFilter } }).then((graphResponse: GraphOptions) => {
                if (!graphResponse) return;

                options.value = graphResponse.options;
                title.value = graphResponse.title;
                series.value = graphResponse.series;
            });
        });
    }

    return { options, series, title };
}
