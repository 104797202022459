import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative border border-gray-400 rounded-xl h-60 w-100 bg-white" }
const _hoisted_2 = { class: "absolute -top-10 left-40 p-3.5 h-20 w-20 mx-auto bg-primary-800 rounded-full" }
const _hoisted_3 = { class: "flex h-full w-full px-4" }
const _hoisted_4 = { class: "mt-auto w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        class: "w-full h-full",
        src: _ctx.IconSource.Login
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("form", {
        class: "pt-20 pb-4 w-full flex flex-col",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_CustomInput, {
            value: _ctx.form.email,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
            "hide-auth-state": "",
            "show-required-indication": false,
            label: _ctx.$t('views.auth.forgotPassword.emailFieldLabel'),
            "input-type": "email",
            onSubmit: _ctx.onSubmit
          }, null, 8, ["value", "label", "onSubmit"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CustomButton, {
            disabled: _ctx.disableButtons,
            onClick: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.auth.forgotPassword.submitButtonLabel')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ], 32)
    ])
  ]))
}