import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "text-sm tracking-normal text-gray-400 min-h-4" }
const _hoisted_3 = { class: "truncate" }
const _hoisted_4 = { class: "leading-none overflow-y-visible" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
    ]),
    _createElementVNode("span", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}