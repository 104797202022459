<template>
    <div class="w-full bg-white shadow-md rounded-lg">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
