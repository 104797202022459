/* Contains a value for each chart on the page. Used to fetch data from the API */
export enum Chart {
    /* Overview page */
    OverviewDailyGain = 'daily-gains-overview',
    OverviewFeedConversion = 'feedconversion-overview',
    OverviewDeaths = 'death-percentage-overview',
    OverviewPigsTally = 'pig-tally',

    /* Pigs status */
    PigsStatus = 'pig-status',

    /* PigsDeaths */
    DailyDead = 'daily-dead',
    DeathPercentages = 'death-percentages',

    /* Deliveries */
    Deliveries = 'feed-deliveries',

    /* Consumption */
    FeedConsumption = 'feed-consumption-per-day',

    /* Production */
    DailyGainSpread = 'daily-gain-spread',
    WeightSpan = 'weight-span',
    DaysInBarn = 'days-in-barn',
    ProductionPigsTally = 'pig-tally',

    /* Debug */
    Debug = 'debug',
}
