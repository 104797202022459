<template>
    <div v-click-outside="() => (expandOptions = false)" class="relative">
        <SearchInput
            ref="input-field"
            v-model:value="computedSearch"
            :label="label"
            prevent-esc-propagation
            @submit="onSearchSubmit"
            @on-escape="onEscape"
            @on-tab="expandOptions = false"
            @focus="expandOptions = true"
        ></SearchInput>
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-100 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <div v-if="expandOptions" :class="[dropdownHeightClass]" class="absolute top-full left-0 w-full bg-white border z-30 rounded overflow-y-auto">
                <div v-for="item of filteredItems" :key="item[itemValue]" class="h-full hover:bg-gray-100 cursor-pointer" @mousedown="onItemClicked(item)">
                    <span class="p-3 h-full w-full inline-block" v-html="getBoldenedString(item[itemText])"></span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import sanitizeHtml from 'sanitize-html';
import ClickOutside from '@makeabledk/vue-ui/directives/click-outside';
import SearchInput from '@/components/ui/SearchInput.vue';

export default defineComponent({
    directives: {
        ClickOutside,
    },
    components: {
        SearchInput,
    },
    emits: ['update:value', 'update:search', 'item-clicked'],
    props: {
        label: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            default: [],
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        dropdownHeightClass: {
            type: String,
            default: 'max-h-64',
        },
        search: {
            type: String as () => string | null,
            default: null,
        },
    },
    data() {
        return {
            expandOptions: false,
            localSearch: '',
        };
    },
    computed: {
        computedSearch: {
            get(): string {
                return this.$props.search === null ? this.localSearch : this.$props.search;
            },
            set(newValue: any) {
                if (this.$props.search === null) {
                    this.localSearch = newValue;
                } else {
                    this.$emit('update:search', newValue);
                }
            },
        },
        filteredItems(): any[] {
            return this.items.filter((item: any) => {
                const searchString = this.computedSearch.trim().toLowerCase();
                return item[this.itemText].trim().toLowerCase().includes(searchString);
            });
        },
    },
    methods: {
        /* Marks the part of the string that matches our search */
        getBoldenedString(string: string) {
            return sanitizeHtml(string).replace(new RegExp(`(${this.computedSearch})`, 'gi'), `<b>$1</b>`);
        },
        onSearchSubmit() {
            this.$emit('item-clicked', this.filteredItems[0]);
        },
        onEscape() {
            this.expandOptions = false;
            (document.activeElement as HTMLElement)?.blur();
        },
        onItemClicked(item: any) {
            this.expandOptions = false;
            this.$emit('item-clicked', item);
        },
    },
});
</script>
