import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mx-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "text-right mr-6 w-80" }
const _hoisted_4 = { class: "w-8 my-auto" }
const _hoisted_5 = { class: "flex justify-end" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_DynamicFormModal = _resolveComponent("DynamicFormModal")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, { class: "space-y-4 pt-2" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.assignments.tabs.templates.table.header')), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_SearchInput, {
            value: _ctx.search,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            label: _ctx.$t('views.assignments.tabs.templates.table.searchFieldLabel')
          }, null, 8, ["value", "label"])
        ]),
        _createVNode(_component_DynamicFormModal, {
          "initial-state": {
                    event: _ctx.createTaskTemplateEvent,
                    form: null,
                    confirmed: false,
                },
          "step-config": _ctx.createTaskTemplateStepConfig,
          header: _ctx.createTaskModalHeader,
          "refetch-actions": _ctx.createRefetchActions,
          "submission-text": _ctx.$t('views.assignments.tabs.templates.modals.createTemplate.successMessage')
        }, {
          default: _withCtx(({ open }) => [
            _createVNode(_component_AddButton, {
              "text-class": "font-medium",
              onClick: ($event: any) => (_ctx.onAddTemplateClicked(open))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.assignments.tabs.templates.content.createTemplateButton')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }, 8, ["initial-state", "step-config", "header", "refetch-actions", "submission-text"])
      ]),
      _createVNode(_component_ActionModal, {
        "initial-step-index": 1,
        "initial-state": _ctx.actionModalInitialState,
        "step-config": _ctx.actionModalStepConfig,
        header: _ctx.actionModalHeader,
        onSubmission: _ctx.onEditSubmission
      }, {
        default: _withCtx(({ open }) => [
          _createVNode(_component_CustomTable, {
            headers: _ctx.headers,
            items: _ctx.filteredItems
          }, {
            header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("th", { class: "w-8" }, null, -1)
            ])),
            actions: _withCtx(({ item }) => [
              _createElementVNode("td", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "p-2 -mr-2",
                    onClick: ($event: any) => (_ctx.onEditTemplateClicked(item, open))
                  }, [
                    _createVNode(_component_Icon, {
                      class: "h-3 w-3 cursor-pointer",
                      src: _ctx.IconSource.Right
                    }, null, 8, ["src"])
                  ], 8, _hoisted_6)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["headers", "items"])
        ]),
        _: 1
      }, 8, ["initial-state", "step-config", "header", "onSubmission"])
    ]),
    _: 1
  }))
}