<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.reports.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <ReportViewSteps></ReportViewSteps>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import ReportViewSteps from '@/views/dashboard/reports/ReportViewSteps.vue';

export default defineComponent({
    components: { DashboardContentLayout, ReportViewSteps },
});
</script>
