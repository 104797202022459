import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

import { HTMLAttributes, computed } from 'vue';
import { SelectIcon, SelectTrigger, SelectTriggerProps, useForwardProps } from 'radix-vue';
import { ChevronDown, Loader2 } from 'lucide-vue-next';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectTrigger',
  props: {
    disabled: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: {},
    loading: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectTrigger), _mergeProps(_unref(forwardedProps), {
    disabled: _ctx.loading || _ctx.disabled,
    class: 
            _unref(cn)(
                'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-normal ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:truncate text-start',
                props.class
            )
        
  }), {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('common.texts.loading')), 1)
          ], 64))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }),
      _createVNode(_unref(SelectIcon), {
        "as-child": "",
        class: "ml-2"
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_unref(Loader2), {
                key: 0,
                class: "text-primary w-5 h-5 shrink-0 animate-spin"
              }))
            : _renderSlot(_ctx.$slots, "icon", { key: 1 }, () => [
                _createVNode(_unref(ChevronDown), { class: "w-5 h-5 opacity-70 shrink-0" })
              ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 16, ["disabled", "class"]))
}
}

})