import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-center px-4 py-16"
}
const _hoisted_2 = {
  key: 1,
  class: "py-14 text-center px-4 text-gray-400 text-lg"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 0,
  class: "space-y-6"
}
const _hoisted_5 = { class: "flex items-center justify-between gap-x-3" }
const _hoisted_6 = { class: "flex items-center gap-x-3" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "space-y-1" }
const _hoisted_9 = {
  key: 0,
  class: "font-bold text-lg leading-tight"
}
const _hoisted_10 = {
  key: 1,
  class: "text-gray-500 leading-tight"
}
const _hoisted_11 = {
  key: 0,
  class: "flex-shrink-0"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "grid grid-cols-2 items-stretch gap-4"
}
const _hoisted_14 = {
  key: 1,
  class: "grid grid-cols-2 items-stretch gap-4"
}

import { computed, ref } from 'vue';
import { merge } from 'lodash-es';
import * as Highcharts from 'highcharts';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Dashboard, DashboardBurgerMenuOption, DashboardItem, DashboardSectionWithItems, IconSource } from '@/types';
import Spinner from '@/components/ui/Spinner.vue';
import GenericDashboardElement from './GenericDashboardElement.vue';
import Icon from '@/components/icons/Icon.vue';
import ExpandCollapseTransition from '../ExpandCollapseTransition.vue';
import useDefaultChartOptions from '@/components/charts/composables/useDefaultChartOptions';

interface Props {
    data: Dashboard | null | undefined;
    loading: boolean;
    fetchParams?: Record<string, any>;
    fetchActionType: ActionType;
    persistentLoading?: boolean;
    noDataText?: string;
}

const DEFAULT_CHART_HEIGHT = 300; // pixels


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericDashboard',
  props: {
    data: {},
    loading: { type: Boolean, default: false },
    fetchParams: { default: () => ({}) },
    fetchActionType: {},
    persistentLoading: { type: Boolean, default: false },
    noDataText: { default: '' }
  },
  emits: ["update:item", "update:section-collapse"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const chartInstances = ref<Record<number, Highcharts.Chart>>({});
const isChartsUpdating = ref(false);

function setChartInstance(instance: Highcharts.Chart, itemNo: number) {
    chartInstances.value[itemNo] = instance;
}

function setChartExtremes(event: any, updatedChartId: number) {
    if (event.trigger !== 'navigator' || isChartsUpdating.value) {
        return;
    }

    isChartsUpdating.value = true;

    for (const chartId in chartInstances.value) {
        if (chartId.toString() !== updatedChartId.toString() && chartInstances.value[chartId]) {
            chartInstances.value?.[chartId]?.xAxis?.[0]?.setExtremes(event.min, event.max);
            chartInstances.value?.[chartId]?.redraw();
        }
    }

    isChartsUpdating.value = false;
}

function handleLegendClick(legendId: string) {
    Object.values(chartInstances.value).forEach((chart) => {
        const series = chart.series?.find((s: any) => s.options && s.options.id === legendId);

        if (series) {
            if (series.visible) {
                series.hide();
            } else {
                series.show();
            }
        }
    });
}

function toggleSectionCollapse(sectionIndex: number) {
    const clonedData = props.data;

    if (!clonedData?.sections) {
        return;
    }

    const section = (clonedData.sections as DashboardSectionWithItems[])[sectionIndex];

    if (!section) {
        return;
    }

    emit('update:section-collapse', { sectionIndex, isCollapsed: !section.is_collapsed });
}

const loadingState = ref<Record<number, boolean>>([]);

async function onChartOptionsUpdate(payload: { options: DashboardBurgerMenuOption[]; hasChanged: boolean }, itemNo: number, sectionIndex?: number) {
    if (!payload.hasChanged) {
        return;
    }

    try {
        loadingState.value[itemNo] = true;

        const requestOptions = {
            ...props.fetchParams,
            filterItems: itemNo.toString(),
            filterOptions: payload.options.map((option) => option.query_parameter).join(','),
            ignoreCache: true,
            cacheResponse: false,
        };

        const data = await store.dispatch(props.fetchActionType, { options: requestOptions });

        emit('update:item', { item: data.items[0], sectionIndex });
    } finally {
        loadingState.value[itemNo] = false;
    }
}

function getChartItemWithOptions(item: DashboardItem) {
    const defaultOptions = useDefaultChartOptions(item.chart_options, null, item.apply_custom_tooltip);

    if (item.chart_options.xAxis?.[0]) {
        item.chart_options.xAxis[0].events = {
            setExtremes: (event: Highcharts.AxisSetExtremesEventCallbackFunction) => setChartExtremes(event, item.item_no),
        };
    }

    const mergedOptions = merge({}, defaultOptions.value, {
        chart: {
            height: DEFAULT_CHART_HEIGHT * (item.height || 1),
        },
    });

    mergedOptions.plotOptions = mergedOptions.plotOptions || {};
    mergedOptions.plotOptions.series = mergedOptions.plotOptions.series || {};
    mergedOptions.plotOptions.series.events = {
        ...(defaultOptions.value?.plotOptions?.series?.events || {}),
        legendItemClick(event: Highcharts.SeriesLegendItemClickEventObject) {
            const legendId = (this as any).options.id;

            if (!legendId.includes('generic-chart-modal')) {
                handleLegendClick(legendId);
                event?.preventDefault();
            }
        },
    };

    return {
        ...item,
        chart_options: mergedOptions,
    };
}

const computedData = computed(() => {
    if (!props.data) {
        return null;
    }

    const items = props.data.items.map((item) => {
        if (item.type === 'chart' && item.chart_options) {
            return getChartItemWithOptions(item);
        }

        return item;
    });

    if (props.data.sections?.length) {
        const sections = props.data.sections.map((section) => ({
            ...section,
            items: items.filter((item) => section.item_numbers?.includes(item.item_no)),
        }));

        return {
            sections,
        } as { sections: DashboardSectionWithItems[] };
    }

    return {
        ...store.state.dashboardOverview,
        items,
    };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "transition duration-200 ease-out",
    "enter-from-class": "opacity-0",
    "enter-to-class": "opacity-100",
    "leave-active-class": "transition duration-200 ease-in",
    "leave-from-class": "opacity-0",
    "leave-to-class": "opacity-0",
    appear: "",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      ((_ctx.persistentLoading && _ctx.loading) || (!computedData.value && _ctx.loading))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(Spinner, { class: "h-10 w-10" })
          ]))
        : (_ctx.noDataText && computedData.value && (computedData.value as Dashboard).items?.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.noDataText), 1))
          : (computedData.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (computedData.value.sections?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(computedData.value.sections, (section, sectionIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `section-${sectionIndex}`,
                          class: "border border-gray-300 rounded-lg px-4 py-5 space-y-5"
                        }, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              (section.icon)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(Icon, {
                                      src: section.icon.icon_id,
                                      height: section.icon.height,
                                      style: _normalizeStyle({ height: `${section.icon.height * 28}px` })
                                    }, null, 8, ["src", "height", "style"])
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_8, [
                                (section.title)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(section.title), 1))
                                  : _createCommentVNode("", true),
                                (section.subtitle)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(section.subtitle), 1))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            (section.is_collapsible)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: "p-3 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200",
                                    onClick: ($event: any) => (toggleSectionCollapse(sectionIndex))
                                  }, [
                                    _createVNode(Icon, {
                                      class: _normalizeClass(["h-4 w-4 transform transition-transform rotate-90 cursor-pointer", { 'rotate-[270deg]': !section.is_collapsed }]),
                                      src: _unref(IconSource).Right
                                    }, null, 8, ["class", "src"])
                                  ], 8, _hoisted_12)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(ExpandCollapseTransition, null, {
                            default: _withCtx(() => [
                              (!section.is_collapsed || !section.is_collapsible)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((section as DashboardSectionWithItems).items, (item) => {
                                      return (_openBlock(), _createBlock(GenericDashboardElement, {
                                        key: `${item.item_no}-${item.width}`,
                                        item: item,
                                        loading: loadingState.value[item.item_no],
                                        "onUpdate:options": ($event: any) => (onChartOptionsUpdate($event, item.item_no)),
                                        "onUpdate:chart": ($event: any) => (setChartInstance($event, item.item_no))
                                      }, null, 8, ["item", "loading", "onUpdate:options", "onUpdate:chart"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((computedData.value as Dashboard).items, (item) => {
                        return (_openBlock(), _createBlock(GenericDashboardElement, {
                          key: `${item.item_no}-${item.width}`,
                          item: item,
                          loading: loadingState.value[item.item_no],
                          "onUpdate:options": ($event: any) => (onChartOptionsUpdate($event, item.item_no)),
                          "onUpdate:chart": ($event: any) => (setChartInstance($event, item.item_no))
                        }, null, 8, ["item", "loading", "onUpdate:options", "onUpdate:chart"]))
                      }), 128))
                    ]))
              ]))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})