import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Transition as _Transition, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  ref: "intersectionTarget",
  class: "border border-gray-200 rounded-md overflow-hidden"
}
const _hoisted_2 = { class: "relative w-full flex items-center justify-center" }
const _hoisted_3 = { class: "absolute z-10 top-0 left-0 py-4 px-6" }
const _hoisted_4 = {
  key: 0,
  class: "absolute inset-0 bg-white flex items-center justify-center"
}
const _hoisted_5 = {
  key: 1,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_GenericChart = _resolveComponent("GenericChart")!
  const _component_EmptyChartState = _resolveComponent("EmptyChartState")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.row.section_name), 1),
      _createElementVNode("div", {
        class: "flex w-full items-center justify-center",
        style: _normalizeStyle({ minHeight: _ctx.chartOptions?.chart?.height ? `${_ctx.chartOptions.chart.height}px` : '400px' })
      }, [
        _createVNode(_Transition, {
          name: "fade-fast",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (!_ctx.hasIntersected || _ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_Spinner, { class: "h-12 w-12 mx-auto" })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_EmptyChartState, { options: _ctx.chartOptions }, {
                    default: _withCtx(() => [
                      _createVNode(_component_GenericChart, {
                        options: (_ctx.chartOptions as any),
                        "destroy-on-update": "",
                        onReady: _ctx.setChartInstance,
                        onUpdate: _ctx.setChartInstance
                      }, null, 8, ["options", "onReady", "onUpdate"])
                    ]),
                    _: 1
                  }, 8, ["options"])
                ]))
          ]),
          _: 1
        })
      ], 4)
    ])
  ], 512))
}