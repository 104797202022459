import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["aria-label", "aria-valuenow"]

import { computed } from 'vue';
import { DataQualityCategory } from '@/types';

interface Props {
    qualityAssessmentCategory: DataQualityCategory;
}

enum DataQualityCategoryStatus {
    Excellent = 1,
    Uncertain = 2,
    Inferior = 3,
    Analyzing = 4,
    Unprocessable = 5,
}
/* eslint-enable */


export default /*@__PURE__*/_defineComponent({
  __name: 'DataQualityStatusBar',
  props: {
    qualityAssessmentCategory: {}
  },
  setup(__props: any) {

const props = __props;

/* eslint-disable */
const valueNow = computed(() => {
    switch (props.qualityAssessmentCategory.id) {
        case DataQualityCategoryStatus.Excellent:
            return 100;
        case DataQualityCategoryStatus.Uncertain:
            return 66;
        case DataQualityCategoryStatus.Inferior:
            return 33;
        case DataQualityCategoryStatus.Analyzing:
        case DataQualityCategoryStatus.Unprocessable:
        default:
            return 0;
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    role: "progressbar",
    "aria-label": _ctx.qualityAssessmentCategory.name,
    "aria-valuenow": valueNow.value,
    "aria-valuemin": "100",
    "aria-valuemax": "100",
    class: "flex flex-shrink-0 space-x-1.5 py-2"
  }, _ctx.$attrs), [
    (_ctx.qualityAssessmentCategory.id === DataQualityCategoryStatus.Excellent)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-green-400" }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-green-400" }, null, -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-green-400" }, null, -1))
        ], 64))
      : (_ctx.qualityAssessmentCategory.id === DataQualityCategoryStatus.Uncertain)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-yellow-500" }, null, -1)),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-yellow-500" }, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-gray-300 bg-opacity-60" }, null, -1))
          ], 64))
        : (_ctx.qualityAssessmentCategory.id === DataQualityCategoryStatus.Inferior)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-red-400" }, null, -1)),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-gray-300 bg-opacity-60" }, null, -1)),
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-gray-300 bg-opacity-60" }, null, -1))
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-gray-300 bg-opacity-60" }, null, -1)),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-gray-300 bg-opacity-60" }, null, -1)),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "w-6 h-2 rounded-full bg-gray-300 bg-opacity-60" }, null, -1))
            ], 64))
  ], 16, _hoisted_1))
}
}

})