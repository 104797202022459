import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "min-h-20 flex justify-center"
}
const _hoisted_3 = { class: "text-lg font-medium my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.chartOptions?.series?.length || _ctx.chartOptions?.type === _ctx.ChartType.Dashboard)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t('layouts.dashboard.charts.noChartData')), 1)
        ]))
  ]))
}