import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { SelectRootEmits, SelectRootProps, SelectRoot, useForwardPropsEmits } from 'radix-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Select',
  props: {
    open: { type: Boolean },
    defaultOpen: { type: Boolean },
    defaultValue: {},
    modelValue: {},
    dir: {},
    name: {},
    autocomplete: {},
    disabled: { type: Boolean },
    required: { type: Boolean }
  },
  emits: ["update:modelValue", "update:open"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const forwarded = useForwardPropsEmits(props, emits);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectRoot), _normalizeProps(_guardReactiveProps(_unref(forwarded))), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}
}

})