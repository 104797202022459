import { onBeforeUnmount, onMounted, ref } from 'vue';
import { TableHeader } from '@/types';

export default function useResizableTable({ resizeDelay = 0 }: { resizeDelay?: number } = {}) {
    const headerRefs = ref<any>({});
    const headerWidths = ref<any>({});

    function handleResize() {
        Object.entries(headerRefs.value).forEach((currentHeaderRef: any) => {
            headerWidths.value[currentHeaderRef[0]] = currentHeaderRef[1].getBoundingClientRect().width;
        });
    }

    function setItemRef(el: any, header: TableHeader) {
        if (el && !headerRefs.value[header.value]) {
            headerRefs.value[header.value] = el;
        }
    }

    function getWidth(header: TableHeader): number {
        return headerWidths.value[header.value] || -1;
    }

    onMounted(() => {
        window.addEventListener('resize', handleResize);

        handleResize();

        if (resizeDelay) {
            setTimeout(() => {
                handleResize();
            }, resizeDelay);
        }
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
    });

    return {
        handleResize,
        setItemRef,
        getWidth,
    };
}
