<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.settings.locations.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <template #header-top-right>
            <div class="w-92 flex"><SearchInput v-model:value="searchRef" class="my-auto" :label="$t('views.settings.locations.header.searchFieldLabel')" :background-color="'bg-white'"></SearchInput></div>
        </template>
        <LocationTabs :search="searchRef"></LocationTabs>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import LocationTabs from '@/views/dashboard/settings/locations/LocationTabs.vue';
import useDebouncedRef from '@/components/common/composables/useDebouncedRef';

export default defineComponent({
    components: { DashboardContentLayout, SearchInput, LocationTabs },
    setup() {
        return {
            searchRef: useDebouncedRef('', 200, false),
        };
    },
});
</script>
