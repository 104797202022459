<template>
    <Modal close-esc-event="keydown" :close-button="false" :modal-class="`m-auto border-0 max-w-6xl transition-width duration-500`" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128">
                <MultiStepFormHeaderIcon :icon-source="IconSource.ButtonUserManagement" :is-final-step="false"></MultiStepFormHeaderIcon>
                <h2 class="text-xl font-semibold text-center">{{ $t('views.settings.users.content.modals.delete.title') }}</h2>
                <p class="text-center">
                    <span>{{ $t('views.settings.users.content.modals.delete.confirmationMessage') }}</span>
                    <span class="font-bold text-primary-700">{{ `${directoryUser.first_name} ${directoryUser.last_name}` }}</span>
                    <span>{{ $t('views.settings.users.content.modals.delete.fromMessage') }}</span>
                    <span class="font-bold text-primary-700">{{ activeDirectoryName }}?</span>
                </p>
                <div class="flex space-x-4">
                    <CustomButton color-preset="secondary" @click="scope.close">
                        {{ $t('views.settings.users.content.modals.delete.cancelButton') }}
                    </CustomButton>
                    <CustomButton :disabled="disableButtons" color-preset="warning" @click="onDeleteUserClicked(scope.close)">
                        {{ $t('views.settings.users.content.modals.delete.confirmButton') }}
                    </CustomButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { useToast } from 'vue-toastification';
import CustomButton from '@/components/ui/CustomButton.vue';
import { DirectoryUser } from '@/types';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, CustomButton, CloseModalButton },
    emits: ['submit'],
    props: {
        directoryUser: {
            type: Object as () => DirectoryUser,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            disableButtons: false,
        };
    },
    computed: {
        activeDirectoryName(): string {
            return store.getters.activeDirectoryName;
        },
    },
    methods: {
        async onDeleteUserClicked(close: CallableFunction) {
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;

            try {
                await store.dispatch(ActionType.DeleteDirectoryUser, { form: { user_id: this.directoryUser.id } });

                this.toast.success(this.$t('views.settings.users.content.modals.delete.deleteSuccess'));
                store.dispatch(ActionType.GetDirectoryUsers, { options: { ignoreCache: true } });
                this.$emit('submit');
                close();
            } catch (err) {
                //
            } finally {
                this.disableButtons = false;
            }
        },
    },
});
</script>
