import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "px-6 flex my-2 rounded text-dark-gray-800 text-opacity-50" }
const _hoisted_3 = { class: "flex font-medium" }
const _hoisted_4 = { class: "flex py-4 px-6 my-4 border-1 rounded-lg bg-feed-600 bg-opacity-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
          return _renderSlot(_ctx.$slots, [`header_${header.value}`], {}, () => [
            (_openBlock(), _createElementBlock("th", {
              key: header.value,
              class: "flex flex-1 px-2"
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(header.text), 1)
            ]))
          ])
        }), 256))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: item.material.id,
          class: "rounded flex py-4 px-6 my-4 odd:bg-dark-gray-100 border-2 border-opacity-10 rounded-lg"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
            return _renderSlot(_ctx.$slots, [`${header.value}`], {
              item: item,
              header: header
            }, () => [
              (_openBlock(), _createElementBlock("td", {
                key: header.value,
                class: "flex px-2 flex-1 my-auto word-break"
              }, _toDisplayString(_ctx.getItemProperty(header, item, { numberFormat: header.numberFormat })), 1))
            ])
          }), 256))
        ]))
      }), 128)),
      _createElementVNode("tr", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sumHeaders, (sumHeader) => {
          return _renderSlot(_ctx.$slots, [`sum_${sumHeader}`], { sumHeader: sumHeader }, () => [
            (_openBlock(), _createElementBlock("td", {
              key: sumHeader,
              class: "flex px-2 flex-1 my-auto word-break"
            }, _toDisplayString(_ctx.getItemProperty({ value: sumHeader }, _ctx.value)), 1))
          ])
        }), 256))
      ])
    ])
  ]))
}