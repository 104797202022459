<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.feed.consumptionData.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div v-if="feedConsumptionData" class="space-y-8">
            <MainViewCard class="pt-4 pb-2 px-2">
                <GenericChart :options="feedConsumptionData.chart.options"></GenericChart>
            </MainViewCard>
            <div class="feed-consumption-data-tabs default-tabs">
                <Tabs teleport="#feed-consumption-data-tabs-target" active-tab-class="--active">
                    <nav class="space-x-8 flex border-b border-black border-opacity-10 mb-4">
                        <div class="flex pr-4">
                            <Tab :name="$t('views.feed.consumptionData.content.tabs.feedUsage.title')" :default="true">
                                <MainViewCard>
                                    <GenericTable :table-data="feedConsumptionData.feed_usage"></GenericTable>
                                </MainViewCard>
                            </Tab>
                        </div>
                        <div class="flex">
                            <Tab :name="$t('views.feed.consumptionData.content.tabs.rawMaterialsUsage.title')">
                                <MainViewCard>
                                    <GenericTable :table-data="feedConsumptionData.ingredient_usage"></GenericTable>
                                </MainViewCard>
                            </Tab>
                        </div>
                    </nav>
                </Tabs>

                <div id="feed-consumption-data-tabs-target" />
            </div>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, Ref } from 'vue';
import isEqual from 'lodash-es/isEqual';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import GenericTable from '@/components/common/GenericTable.vue';
import GenericChart from '@/components/charts/GenericChart.vue';
import { FeedConsumptionData } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, GenericTable, GenericChart, Tab, Tabs },
    setup() {
        const feedConsumptionData = ref<FeedConsumptionData>();

        const graphPreconditions = computed(() => ({
            siteIds: store.getters.getSelectedSiteIds as number[],
            selectedPeriod: store.getters.getSelectedPeriod as string,
        }));

        const prevGraphPreconditions = ref(null) as Ref<null | { siteIds: number[]; selectedPeriod: string }>;

        watch(
            () => graphPreconditions,
            async (newValue) => {
                if ((graphPreconditions.value.siteIds.length || prevGraphPreconditions.value?.siteIds.length) && newValue.value.selectedPeriod.length && !isEqual(newValue.value, prevGraphPreconditions.value)) {
                    feedConsumptionData.value = await store.dispatch(ActionType.GetFeedConsumptionData);
                }
                prevGraphPreconditions.value = newValue.value;
            },
            { deep: true, immediate: true }
        );

        return { feedConsumptionData };
    },
});
</script>
