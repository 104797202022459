import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "space-y-4" }
const _hoisted_4 = { class: "flex mx-6 space-x-6 pt-4 min-h-14" }
const _hoisted_5 = { class: "font-medium flex-1" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = { class: "opacity-60" }
const _hoisted_8 = { class: "overflow-x-auto" }
const _hoisted_9 = { class: "space-y-4" }
const _hoisted_10 = { class: "flex mx-6 space-x-6 pt-4 min-h-14" }
const _hoisted_11 = { class: "font-medium flex-1" }
const _hoisted_12 = { class: "text-lg" }
const _hoisted_13 = { class: "opacity-60" }
const _hoisted_14 = { class: "overflow-x-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listings, (listing) => {
      return (_openBlock(), _createElementBlock("div", {
        key: listing.title,
        class: "space-y-4"
      }, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(listing.title), 1),
        _createVNode(_component_MainViewCard, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h4", _hoisted_6, _toDisplayString(listing.price_listing.title), 1),
                  _createElementVNode("h5", _hoisted_7, _toDisplayString(listing.price_listing.sub_title), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_CustomTable, {
                  headers: listing.price_listing.headers,
                  items: listing.price_listing.data
                }, null, 8, ["headers", "items"])
              ])
            ])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_MainViewCard, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h4", _hoisted_12, _toDisplayString(listing.regulations.title), 1),
                  _createElementVNode("h5", _hoisted_13, _toDisplayString(listing.regulations.sub_title), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_CustomTable, {
                  headers: listing.regulations.headers,
                  items: listing.regulations.data
                }, null, 8, ["headers", "items"])
              ])
            ])
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}