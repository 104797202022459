const defaultTheme = require('tailwindcss/defaultTheme');
const animate = require('tailwindcss-animate');

const colors = require(`./public/solutions/${process.env.VUE_APP_SOLUTION_PATH}/colors`);

module.exports = {
    content: ['./public/index.html', './src/**/*.vue', './src/**/*.ts', './node_modules/@makeabledk/**/*.vue'],
    theme: {
        extend: {
            transitionDuration: {
                2000: '2000ms',
            },
            transitionProperty: {
                width: 'width',
                'margin-left': 'margin-left',
                height: 'height',
                borderRadius: 'border-radius',
            },
            willChange: {
                height: 'height',
            },
            fontFamily: {
                sans: ['Noto Sans', ...defaultTheme.fontFamily.sans],
            },
            spacing: {
                2.5: '0.625rem',
                2.75: '0.6875rem',
                6.5: '1.625rem',
                16.5: '4.125rem',
                17: '4.25rem',
                19: '4.75rem',
                21: '5.25rem',
                42: '10.5rem',
                46: '12.5rem',
                76: '17rem',
                84: '21rem',
                88: '22rem',
                92: '23rem',
                100: '25rem',
                112: '28rem',
                120: '30rem',
                128: '32rem',
                160: '40rem',
                228: '57rem',
                256: '64rem',
                280: '70rem',
                288: '72rem',
                dashboard: '1500px',
            },
            minHeight: (theme) => ({
                ...theme('spacing'),
            }),

            minWidth: (theme) => ({
                ...theme('spacing'),
            }),

            maxWidth: (theme) => ({
                ...theme('spacing'),
                screen: '100vw',
            }),

            maxHeight: (theme) => ({
                ...theme('spacing'),
            }),
            backgroundOpacity: {
                35: '0.35',
            },
            colors: {
                ...colors,
                gray: {
                    ...defaultTheme.colors.gray,
                    50: '#f9fafb',
                },
                green: {
                    ...defaultTheme.colors.green,
                    200: '#91C391',
                    600: '#CDDDDD',
                },
                'dark-gray': {
                    100: 'rgba(245,246,250,0.63)',
                    200: '#F5F6FA',
                    600: 'rgba(22,17,33,0.8)',
                    800: '#161121',
                },
                red: {
                    ...defaultTheme.colors.red,
                    400: '#F5492B',
                },
                sky: {
                    50: '#f0f9ff',
                    100: '#e0f2fe',
                    200: '#bae6fd',
                    300: '#7dd3fc',
                    400: '#38bdf8',
                    500: '#0ea5e9',
                    600: '#0284c7',
                    700: '#0369a1',
                    800: '#075985',
                    900: '#0c4a6e',
                    950: '#082f49',
                },
                success: {
                    50: '#f1fcf4',
                    100: '#defae9',
                    200: '#c0f3d3',
                    300: '#8de8b0',
                    400: '#54d486',
                    500: '#2dba64',
                    600: '#209950',
                    700: '#1c7941',
                    800: '#1b6037',
                    900: '#184f2f',
                    950: '#082b17',
                },
                error: {
                    50: '#fef4f2',
                    100: '#fde7e3',
                    200: '#fddbd5',
                    300: '#fab3a7',
                    400: '#f48875',
                    500: '#ea6149',
                    600: '#d7452b',
                    700: '#b53620',
                    800: '#95311f',
                    900: '#7c2e20',
                    950: '#43140c',
                },
                warning: {
                    50: '#fef8ee',
                    100: '#fbe6c6',
                    200: '#f8d9b0',
                    300: '#f4be7d',
                    400: '#ee9849',
                    500: '#ea7c25',
                    600: '#db631b',
                    700: '#b64b18',
                    800: '#913c1b',
                    900: '#753319',
                    950: '#3f180b',
                },
            },
            fontSize: {
                xs: ['0.6875rem', '1rem'],
                sm: ['0.8125rem', '1.1875rem'],
                normal: ['0.875rem', '1.25rem'],
                base: ['1rem', '1.5rem'],
                lg: ['1.125rem', '1.625rem'],
                xl: ['1.5rem', '2.25rem'],
                '2xl': ['1.75rem', '2.625rem'],
                '3xl': ['2rem', '2.875rem'],
            },
            screens: {
                print: {
                    raw: 'print',
                },
            },
        },

        container: () => ({
            center: true,
        }),
    },
    variants: {
        opacity: ['responsive', 'hover', 'focus', 'disabled'],
        extend: {
            padding: ['first', 'last'],
            borderRadius: ['first', 'last'],
            margin: ['first', 'last'],
            borderWidth: ['first', 'last'],
            backgroundColor: ['odd', 'even'],
        },
    },
    plugins: [animate],
    important: true,
};
