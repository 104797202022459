import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center" }

import { type HTMLAttributes, computed } from 'vue';
import { DropdownMenuItemIndicator, DropdownMenuRadioItem, type DropdownMenuRadioItemEmits, type DropdownMenuRadioItemProps, useForwardPropsEmits } from 'radix-vue';
import { Circle } from 'lucide-vue-next';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuRadioItem',
  props: {
    value: {},
    disabled: { type: Boolean },
    textValue: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DropdownMenuRadioItem), _mergeProps(_unref(forwarded), {
    class: 
            _unref(cn)(
                'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                props.class
            )
        
  }), {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_unref(DropdownMenuItemIndicator), null, {
          default: _withCtx(() => [
            _createVNode(_unref(Circle), { class: "h-2 w-2 fill-current" })
          ]),
          _: 1
        })
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})