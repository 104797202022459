export type SettingEvent = {
    id: number;
    name: string;
    action_id: number;
    type: SettingEventType;
    icon_url: string;
};

export enum SettingEventType {
    directoryCreate = 'directory-create',
    directoryData = 'directory-data',
    farmCreate = 'farm-create',
    barnCreate = 'barn-create',
    sectionCreate = 'section-create',
    farmEdit = 'farm-edit',
    barnEdit = 'barn-edit',
    sectionEdit = 'section-edit',
    instructionCreate = 'instruction-create',
}
