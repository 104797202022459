<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.economy.main.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-6">
            <EconomyPostingsTabs></EconomyPostingsTabs>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import EconomyPostingsTabs from '@/views/dashboard/economy/economyMain/EconomyPostingsTabs.vue';

export default defineComponent({
    components: { DashboardContentLayout, EconomyPostingsTabs },
});
</script>

<style scoped></style>
