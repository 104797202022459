import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-2xl font-semibold text-center" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "flex space-x-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_MultiStepFormHeaderIcon, {
      "is-final-step": false,
      "icon-source": _ctx.IconSource.ButtonUserManagement
    }, null, 8, ["icon-source"]),
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('views.settings.users.content.modals.create.title')), 1),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_CustomInput, {
        value: _ctx.form.email,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
        label: _ctx.$t('views.settings.users.content.modals.create.emailFieldLabel'),
        required: "",
        "show-required-indication": "",
        "input-type": "email",
        rules: [],
        "error-state": !_ctx.validForm,
        "focus-on-render": ""
      }, null, 8, ["value", "label", "error-state"]), [
        [_directive_focus]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        "color-preset": "secondary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.create.cancelButton')), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_CustomButton, {
        loading: _ctx.disableButtons,
        disabled: !_ctx.validForm,
        type: "submit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.users.content.modals.create.confirmButton')), 1)
        ]),
        _: 1
      }, 8, ["loading", "disabled"])
    ])
  ], 32))
}