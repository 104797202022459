<template>
    <div class="flex flex-col">
        <label class="text-right mb-3">{{ label }}</label>
        <input v-model="currentIndex" :style="`background: linear-gradient(90deg, #FFB9C8 ${progress}%, rgb(214, 214, 214) ${progress}%);`" :min="min" :max="max" type="range" class="slider" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/* Slider decides which index of sliderValues should be used to update value-prop */

export default defineComponent({
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            default: null,
        },
        sliderValues: {
            type: Array as () => Number[],
            default: [],
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    computed: {
        min(): number {
            return 0;
        },
        max(): number {
            return this.$props.sliderValues.length - 1;
        },
        computedValue: {
            get(): any {
                return this.$props.value;
            },
            set(newValue: any) {
                this.$emit('update:value', newValue);
            },
        },
        progress(): number {
            return (this.currentIndex / this.max) * 100;
        },
    },
    watch: {
        currentIndex(newIndex: number) {
            this.computedValue = this.sliderValues[newIndex];
        },
    },
    mounted() {
        const initialIndex = this.sliderValues.indexOf(this.value);
        this.currentIndex = initialIndex >= 0 ? initialIndex : Math.floor(this.$props.sliderValues.length / 2);
    },
});
</script>

<style scoped>
input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    border-radius: 12px;
    margin-bottom: 1rem;
}
input[type='range']:hover {
    opacity: 1;
}

/* Chrome */
input[type='range']::-webkit-slider-thumb {
    width: 30px;
    -webkit-appearance: none;
    height: 30px;
    background: white;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 0px gray;
    border: 1px solid gray;
}

/* FF/Safari */
input[type='range']::-moz-range-thumb {
    width: 30px;
    -webkit-appearance: none;
    height: 30px;
    background: white;
    border-radius: 50%;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

/* IE */
input[type='range']::-ms-thumb {
    width: 30px;
    -webkit-appearance: none;
    height: 30px;
    background: white;
    border-radius: 50%;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
</style>
