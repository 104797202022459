<template>
    <MainViewCard class="pt-2 space-y-4">
        <div class="flex items-center justify-end mx-6 pt-4">
            <span class="text-right w-80">
                <SearchInput v-model:value="search" :label="$t('views.notes.content.table.searchFieldLabel')" @keydown.esc.prevent="search = ''"></SearchInput>
            </span>
            <CreateNoteModal>
                <template #default="{ open }">
                    <AddButton text-class="font-medium" class="ml-6" @click="open">
                        {{ $t('views.notes.content.table.addButtonLabel') }}
                    </AddButton>
                </template>
            </CreateNoteModal>
        </div>
        <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader">
            <template #default="{ open }">
                <CustomTable :headers="headers" :items="filteredItems">
                    <template #header_actions>
                        <th class="w-6"></th>
                    </template>
                    <template #actions="{ item }">
                        <td class="flex-shrink-0 w-6 my-auto">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="onNoteClicked(item, open)">
                                    <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </td>
                    </template>
                </CustomTable>
            </template>
        </ActionModal>
    </MainViewCard>
</template>

<script lang="ts">
import { computed, defineComponent, ref, nextTick } from 'vue';
import truncate from 'lodash-es/truncate';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { Note, TableHeader, RecordForm, IconSource } from '@/types';
import AddButton from '@/components/ui/AddButton.vue';
import Icon from '@/components/icons/Icon.vue';
import CreateNoteModal from './createNoteModal/CreateNoteModal.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import useFilterableTable from '@/composables/useFilterableTable';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';

export default defineComponent({
    components: { MainViewCard, CustomTable, AddButton, Icon, CreateNoteModal, SearchInput, ActionModal },
    setup() {
        const headers = computed<TableHeader[]>(() => [
            { text: i18n.global.t('views.notes.content.table.headings.date'), value: 'date', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.notes.content.table.headings.farm'), value: 'farm_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.notes.content.table.headings.barn'), value: 'barn_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.notes.content.table.headings.section'), value: 'section_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.notes.content.table.headings.note'), value: '_note', align: 'left', sortable: true, searchable: true },
            { text: '', value: 'actions', align: 'left' },
        ]);
        const items = computed(() => {
            if (!store.state.noteResponse?.data) return [];

            return store.state.noteResponse.data.map((n) => ({
                ...n,
                _note: truncate(n.name || '', { length: 30 }),
            }));
        });
        const { search, filteredItems } = useFilterableTable(items, headers);

        useRefetchDataOnDashboardStateChanged(ActionType.GetNotes, { watchSites: true, watchFarmsInsteadOfSites: false, watchFormSubmissions: true });

        const actionModalInitialState = ref();
        const actionModalStepConfig = ref([{}, { deleteButton: true, editable: true }, { disableRefetch: true, submitActionType: ActionType.PutNoteForm }, {}]);
        const actionModalHeader = ref();
        const isLoading = ref(false);
        const noteToEdit = ref<Note | null>(null);

        const onNoteClicked = async (note: Note, open: CallableFunction) => {
            try {
                if (isLoading.value) return;

                noteToEdit.value = note;

                await nextTick();

                isLoading.value = true;

                const noteForm: RecordForm = await store.dispatch(ActionType.GetNoteForm, { form: { note_id: note.id } });

                actionModalInitialState.value = { location: null, form: noteForm.form, confirmed: false, farm: null, event: null, record_id: null, note_id: note.id, notification: null };
                actionModalStepConfig.value[1].deleteButton = noteForm.deletable;
                actionModalStepConfig.value[1].editable = noteForm.editable;
                actionModalHeader.value = { icon_url: IconSource.NoteIcon, text: i18n.global.t('views.notes.modals.editNote.header') };

                open();
            } finally {
                isLoading.value = false;
            }
        };

        return {
            headers,
            filteredItems,
            store,
            search,
            onNoteClicked,
            actionModalInitialState,
            actionModalStepConfig,
            actionModalHeader,
            noteToEdit,
            IconSource,
        };
    },
});
</script>
