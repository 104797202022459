import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "flex justify-end mb-8 min-h-[71px]" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex items-center gap-x-3.5" }
const _hoisted_4 = { class: "text-lg font-medium" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-gray-500"
}
const _hoisted_6 = ["innerHTML"]

import { computed } from 'vue';
import sanitize from 'sanitize-html';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import DataQualityBarStatusBar from '@/components/common/dataQuality/DataQualityStatusBar.vue';
import { i18n } from '@/plugins/internationalization/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataQualityBar',
  setup(__props) {

store.dispatch(ActionType.GetDataQualityCategories);
useRefetchDataOnDashboardStateChanged(ActionType.GetDataQualityAssessment, { watchSites: true });

const assessmentCategory = computed(() => {
    if (!store.state.dataQualityAssessment || !store.state.dataQualityCategories) {
        return null;
    }

    return store.state.dataQualityCategories.find((c) => c.id === store.state.dataQualityAssessment?.quality_assessment_id);
});
const assesmentDate = computed(() => {
    if (!store.state.dataQualityAssessment) {
        return null;
    }

    return new Date(store.state.dataQualityAssessment.created_at_utc);
});
const tooltip = computed(() => i18n.global.t('views.overview.tooltips.dataQuality').replace('$ASSESSMENT', `<span class="font-semibold">${assessmentCategory.value?.name}</span>`));

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        (assessmentCategory.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_unref(TooltipProvider), { "delay-duration": 200 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Tooltip), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_RouterLink, {
                            to: { name: 'DataQuality' },
                            class: "inline-flex flex-col gap-y-0.55 bg-white shadow-md px-5 py-3 rounded-xl"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('views.overview.headings.dataQuality')), 1),
                                _createVNode(DataQualityBarStatusBar, { "quality-assessment-category": assessmentCategory.value }, null, 8, ["quality-assessment-category"])
                              ]),
                              (assesmentDate.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('views.overview.texts.lastCalculated')) + ": " + _toDisplayString(_ctx.$d(assesmentDate.value, 'long')), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(TooltipContent), {
                        class: "font-normal",
                        "collision-padding": 12
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", {
                            innerHTML: _unref(sanitize)(tooltip.value, { allowedAttributes: { span: ['class'] } })
                          }, null, 8, _hoisted_6)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})