import { nextTick, ref } from 'vue';
import { Assignment, AssignmentForm, IconSource } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { i18n } from '@/plugins/internationalization/i18n';

export default function useAssignmentForms() {
    const actionModalInitialState = ref();
    const actionModalStepConfig = ref([{}, { deleteButton: true, editable: true }, { disableRefetch: true, submitActionType: ActionType.PutAssignmentForm }, {}]);
    const actionModalHeader = ref();
    const isLoading = ref(false);
    const assignmentToEdit = ref<Assignment | null>(null);

    const onAssignmentClicked = async (assignment: Assignment, open: CallableFunction) => {
        try {
            if (isLoading.value) return;

            assignmentToEdit.value = assignment;

            await nextTick();

            isLoading.value = true;

            const assignmentForm: AssignmentForm = await store.dispatch(ActionType.GetAssignmentForm, { form: { assignment_id: assignment.id } });

            actionModalInitialState.value = { location: null, form: assignmentForm.form, confirmed: false, farm: null, event: null, record_id: null, assignment_id: assignment.id, notification: null };
            actionModalStepConfig.value[1].deleteButton = assignmentForm.deletable;
            actionModalStepConfig.value[1].editable = assignmentForm.editable;
            actionModalHeader.value = { icon_url: IconSource.AssignmentIcon, text: i18n.global.t('views.assignments.tabs.assignments.modals.editAssignment.header') };

            open();
        } finally {
            isLoading.value = false;
        }
    };

    return { actionModalInitialState, actionModalStepConfig, actionModalHeader, onAssignmentClicked, assignmentToEdit };
}
