<template>
    <DropDown v-model:expand="expandDropdown" :dropdown-class="`mt-2 ${dropdownClass}`">
        <template #dropdown-content>
            <ActionModal :initial-step-index="0" :initial-state="actionModalInitialState" class="divide-y">
                <template #default="{ open }">
                    <div v-for="(event, index) of events" :key="event.id" class="hover:bg-gray-100 first:rounded-t-md last:rounded-b-md cursor-pointer">
                        <button class="px-6 py-3 font-medium w-full" role="menuitem" :tabindex="index" @click="onEventClicked(event, open)" @keydown.escape="expandDropdown = false">
                            <div style="grid-template-columns: 2em auto" class="grid gap-2">
                                <div class="grid content-center">
                                    <Icon :src="event.icon_url"></Icon>
                                </div>
                                <span class="h-full text-gray-800 inline-flex items-center text-left">{{ event.name }}</span>
                            </div>
                        </button>
                    </div>
                </template>
            </ActionModal>
        </template>

        <slot name="button" :expandDropdown="expandDropdown" :toggleExpandDropdown="toggleExpandDropdown"></slot>
    </DropDown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';
import { MultiStepFormState, BatchEvent, Event } from '@/types';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';

export default defineComponent({
    components: { DropDown, ActionModal },
    props: {
        events: {
            type: Array as () => Event[] | BatchEvent[],
            default: [],
        },
        dropdownClass: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selectedEvent: null as null | Event | BatchEvent,
            expandDropdown: false,
        };
    },
    computed: {
        actionModalInitialState(): MultiStepFormState {
            return {
                location: null,
                form: null,
                confirmed: false,
                farm: null,
                record_id: null,
                notification: null,
                event: this.selectedEvent,
            };
        },
    },
    methods: {
        toggleExpandDropdown() {
            this.expandDropdown = !this.expandDropdown;
        },
        onEventClicked(event: Event | BatchEvent, open: CallableFunction) {
            this.selectedEvent = event;
            open();
        },
    },
});
</script>
