import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "min-h-28 relative bg-white rounded-2xl px-4 flex items-center justify-between shadow-md w-full space-x-4 text-left"
}
const _hoisted_2 = { class: "flex my-auto" }
const _hoisted_3 = { class: "flex my-auto flex-1" }
const _hoisted_4 = { class: "my-auto font-semibold text-lg" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex flex-shrink-0 items-center justify-end gap-x-1" }
const _hoisted_8 = { class: "flex justify-end my-auto w-12" }
const _hoisted_9 = { class: "relative my-auto h-12 w-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_HelpWidgetButton = _resolveComponent("HelpWidgetButton")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        class: "my-auto h-14 min-w-14",
        src: _ctx.kpiType.icon_url
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h4", _hoisted_4, [
        _createElementVNode("span", { innerHTML: _ctx.kpiText }, null, 8, _hoisted_5),
        (_ctx.kpiType.suffix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(` (${_ctx.kpiType.suffix})`), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.kpiType?.help_widget_item)
        ? (_openBlock(), _createBlock(_component_HelpWidgetButton, {
            key: 0,
            item: _ctx.kpiType.help_widget_item
          }, null, 8, ["item"]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "absolute my-auto border top-0.5 left-0.5 h-10 w-10 rounded-full border-primary-300" }, null, -1)),
              _createVNode(_Transition, {
                "enter-active-class": "transition duration-300",
                "enter-from-class": "transform opacity-0",
                "enter-to-class": "transform opacity-100",
                "leave-active-class": "transition duration-300",
                "leave-from-class": "transform opacity-100",
                "leave-to-class": "transform opacity-0"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_Icon, {
                    class: "h-11 w-11 rounded-full",
                    src: _ctx.IconSource.Selected
                  }, null, 8, ["src"]), [
                    [_vShow, _ctx.selected]
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}