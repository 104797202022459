<template>
    <div class="space-y-6 h-full min-h-60">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.settings.users.content.modals.edit.steps.confirmation.title') }}</h2>
        <div>
            <div class="grid gap-y-4 h-full">
                <div class="grid grid-cols-2 py-4 px-6 bg-gray-100 rounded-lg">
                    <ConfirmationElement class="p-2" :label="$t('views.settings.users.content.modals.edit.steps.confirmation.emailFieldLabel')">
                        {{ computedForm.email }}
                    </ConfirmationElement>
                    <ConfirmationElement class="p-2" :label="$t('views.settings.users.content.modals.edit.steps.confirmation.nameFieldLabel')">
                        {{ computedForm.name }}
                    </ConfirmationElement>
                    <ConfirmationElement class="p-2" :label="$t('views.settings.users.content.modals.edit.steps.confirmation.phoneNumberFieldLabel')">
                        {{ computedForm.phoneNumber }}
                    </ConfirmationElement>
                </div>
                <div class="flex">
                    <div class="w-40 self-end mr-2">
                        <CustomButton :disabled="disableButtons" color-preset="secondary" @click="onBackClicked">
                            {{ $t('views.settings.users.content.modals.edit.steps.confirmation.backButtonLabel') }}
                        </CustomButton>
                    </div>
                    <div class="ml-2 self-end flex-grow">
                        <CustomButton :disabled="disableButtons" @click="onSubmit">
                            {{ $t('views.settings.users.content.modals.edit.steps.confirmation.confirmButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import { Form as FormType, EditUserForm } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import ConfirmationElement from '@/components/ui/ConfirmationElement.vue';

export default defineComponent({
    components: { CustomButton, ConfirmationElement },
    emits: ['submit', 'back', 'update:form'],
    props: {
        form: {
            type: Object as () => EditUserForm & Form<EditUserForm>,
            required: true,
        },
    },
    data() {
        return { disableButtons: false };
    },
    computed: {
        computedForm: {
            get(): EditUserForm & Form<EditUserForm> {
                return this.$props.form;
            },
            set(newValue: EditUserForm) {
                this.$emit('update:form', newValue);
            },
        },
    },
    methods: {
        onBackClicked() {
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            this.$emit('back');
        },
        onSubmit() {
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            this.$emit('submit');
        },
    },
});
</script>
