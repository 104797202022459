module.exports = {
    primary: {
        300: '#d2ebf0',
        500: '#8be2f0',
        700: '#0fbad4',
        800: '#01abc4',
    },
    pigs: '#F4BCC8',
    deaths: '#E3563A',
    feed: {
        400: '#9BC195',
        600: '#CDDDDD',
    },
    slaughters: '#E6A439',
    status: '#8EB8E5',
    transfer: '#37286E',
    batch: '#224678',
    medicine: '#37286E',
    loader: '#89d3e0',
};
