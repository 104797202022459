import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

export const buttonVariants = cva(
    'inline-flex items-center justify-center gap-x-2 whitespace-nowrap rounded-lg text-normal font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'bg-primary-700 text-white hover:bg-primary-800',
                destructive: 'bg-red-600 text-white hover:bg-red-700',
                outline: 'border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900',
                secondary: 'bg-gray-200 text-gray-900 hover:bg-gray-300',
                ghost: 'hover:bg-gray-100 hover:text-gray-900',
                link: 'text-primary-700 underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-10 px-3 py-2',
                xs: 'h-7 rounded px-2',
                sm: 'h-9 rounded-md px-3',
                lg: 'h-11 rounded-md px-8',
                icon: 'h-10 w-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
