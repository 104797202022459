<template>
    <div class="space-y-4">
        <EconomyPostPaymentsGlobalPrices v-if="canManageSolution" :post-payments="items"></EconomyPostPaymentsGlobalPrices>
        <MainViewCard>
            <div class="flex mx-6 space-x-6 pt-4">
                <div class="flex-1 font-medium">
                    <h4 class="text-lg my-auto">{{ $t('views.economy.postPayments.table.header') }}</h4>
                    <h5 v-if="displaySubheader" class="opacity-60">{{ $t('views.economy.postPayments.table.subheader') }}</h5>
                </div>
                <CreateOrEditPriceChargeModal :step-config="priceChargeStepConfig">
                    <template #default="{ open }">
                        <AddButton text-class="font-medium" @click="open">
                            {{ $t('views.economy.postPayments.table.addButtonLabel') }}
                        </AddButton>
                    </template>
                </CreateOrEditPriceChargeModal>
            </div>
            <CustomTable :headers="headers" :items="items"> </CustomTable>
        </MainViewCard>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MultiStepFormState, TableHeader, Event, EconomyPostPayment, Solution } from '@/types';
import { store } from '@/plugins/store';
import CustomTable from '@/components/ui/CustomTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import AddButton from '@/components/ui/AddButton.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import EconomyPostPaymentsGlobalPrices from '@/views/dashboard/economy/economyPostPayments/EconomyPostPaymentsGlobalPrices.vue';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ActionType } from '@/plugins/store/actions';
import CreateOrEditPriceChargeModal from '@/components/common/economy/priceCharges/CreateOrEditPriceChargeModal.vue';

export default defineComponent({
    components: { CustomTable, MainViewCard, ActionModal, AddButton, EconomyPostPaymentsGlobalPrices, CreateOrEditPriceChargeModal },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetEconomyPostPayments, { watchSites: false, watchFarmsInsteadOfSites: false, watchFormSubmissions: false });
    },
    computed: {
        displaySubheader() {
            return [Solution.DanishCrown, Solution.Kls].includes(process.env.MIX_SOLUTION as Solution);
        },
        canManageSolution(): boolean {
            return store.getters.loggedInUserCanManageSolution;
        },
        priceChargeStepConfig(): any {
            return [
                {
                    text: this.$t('views.economy.postPayments.modals.createOrEditPriceChargeModal.header'),
                    numberFields: [
                        { value: 'sl_pig_price', label: this.$t('views.economy.postPayments.modals.createOrEditPriceChargeModal.fatteningPigsFieldLabel'), colSpan: 'col-span-2' },
                        { value: 'res_org_price', label: this.$t('views.economy.postPayments.modals.createOrEditPriceChargeModal.organicPigsFieldLabel'), colSpan: 'col-span-2' },
                        { value: 'res_free_range_price', label: this.$t('views.economy.postPayments.modals.createOrEditPriceChargeModal.freeRangePigsFieldLabel'), colSpan: 'col-span-2' },
                    ],
                    chargeKey: 'entry',
                    fetchActionType: ActionType.GetEconomyPostPayments,
                    createActionType: ActionType.CreatePostPaymentCharge,
                    updateActionType: ActionType.EditPostPaymentCharge,
                    confirmButtonLabel: this.$t('views.economy.postPayments.modals.createOrEditPriceChargeModal.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.postPayments.modals.createOrEditPriceChargeModal.periodFieldLabel'),
                    deleteButtonLabel: this.$t('views.economy.postPayments.modals.deletePriceChargeModal.buttonLabel'),
                    items: this.items,
                    deleteModal: {
                        header: this.$t('views.economy.postPayments.modals.deletePriceChargeModal.header'),
                        message: this.$t('views.economy.postPayments.modals.deletePriceChargeModal.message'),
                        cancelButtonLabel: this.$t('views.economy.postPayments.modals.deletePriceChargeModal.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('views.economy.postPayments.modals.deletePriceChargeModal.confirmButtonLabel'),
                        chargeKey: 'entry',
                        deleteActionType: ActionType.DeletePostPaymentCharge,
                        fetchActionType: ActionType.GetEconomyPostPayments,
                    },
                },
                { text: this.$t('views.economy.postPayments.modals.createOrEditPriceChargeModal.successMessage') },
                { text: this.$t('views.economy.postPayments.modals.deletePriceChargeModal.successMessage') },
            ];
        },
        items(): EconomyPostPayment[] {
            return store.state.economyPostPayments.map((currentEconomyPostPayment) => ({
                ...currentEconomyPostPayment,
                tableProperties: {
                    sl_pig_price: `${this.$n(currentEconomyPostPayment.sl_pig_price, 'price')}${
                        currentEconomyPostPayment.entry?.sl_pig_price
                            ? ` (${(currentEconomyPostPayment.entry?.sl_pig_price || 0) >= 0 ? '+' : ''}${this.$n(currentEconomyPostPayment.entry?.sl_pig_price || 0, 'price')})`
                            : ''
                    }`,
                    res_org_price: `${this.$n(currentEconomyPostPayment.res_org_price, 'price')}${
                        currentEconomyPostPayment.entry?.res_org_price
                            ? ` (${(currentEconomyPostPayment.entry?.res_org_price || 0) >= 0 ? '+' : ''}${this.$n(currentEconomyPostPayment.entry?.res_org_price || 0, 'price')})`
                            : ''
                    }`,
                    res_free_range_price: `${this.$n(currentEconomyPostPayment.res_free_range_price, 'price')}${
                        currentEconomyPostPayment.entry?.res_free_range_price
                            ? ` (${(currentEconomyPostPayment.entry?.res_free_range_price || 0) >= 0 ? '+' : ''}${this.$n(currentEconomyPostPayment.entry?.res_free_range_price || 0, 'price')})`
                            : ''
                    }`,
                },
            }));
        },
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.economy.postPayments.table.headings.period'),
                    value: 'period',
                    align: 'left',
                    sortable: true,
                    sortComparison: (a: EconomyPostPayment, b: EconomyPostPayment, orderMultiplier: number) => (new Date(a.from).getTime() - new Date(b.from).getTime()) * orderMultiplier,
                },
                {
                    text: this.$t('views.economy.postPayments.table.headings.pigPrice'),
                    value: 'tableProperties.sl_pig_price',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: this.$t('views.economy.postPayments.table.headings.orgPrice'),
                    value: 'tableProperties.res_org_price',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: this.$t('views.economy.postPayments.table.headings.freeRangePrice'),
                    value: 'tableProperties.res_free_range_price',
                    align: 'left',
                    sortable: false,
                },
            ];
        },
    },
});
</script>
