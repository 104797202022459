<template>
    <ExpandCollapseTransition>
        <div v-if="isVisible" class="z-[1020] sticky top-24 w-full overflow-hidden">
            <div class="flex items-center justify-center bg-warning-100 py-2 pl-4 pr-12 relative gap-x-1">
                <Icon :src="IconSource.AttentionIcon" class="w-6 h-6 mr-1.5" aria-hidden="true" />
                <div>
                    {{ $t('layouts.dashboard.notificationsBar.notificationText').replace('%VALUE%', unhandledNotificationsCount.toString()) }}
                </div>
                <button class="font-semibold underline underline-offset-4 hover:opacity-80 transition-opacity" @click.prevent="handleNavigation">
                    {{ $t('layouts.dashboard.notificationsBar.buttons.reactNow') }}
                </button>
                <button class="flex items-center justify-center absolute right-4 h-8 w-8 rounded-full hover:bg-warning-200 transition-colors" @click="closeNotificationsBar">
                    <span class="sr-only">{{ $t('layouts.dashboard.notificationsBar.buttons.close') }}</span>
                    <X class="w-6 h-6" aria-hidden="true" />
                </button>
            </div>
        </div>
    </ExpandCollapseTransition>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { useLocalStorage, StorageSerializers } from '@vueuse/core';
import { addDays } from 'date-fns';
import { X } from 'lucide-vue-next';
import { useQueryClient } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';
import { store } from '@/plugins/store';
import { IconSource, NotificationStatusType } from '@/types';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import { queryKeys } from '@/plugins/store/actions/queries/notifications';

const queryClient = useQueryClient();
const router = useRouter();

const isVisible = ref(false);
const unhandledNotificationsCount = computed(() => store.state.meta?.notifications?.unhandled || 0);
const isDashboardLoaded = computed(() => store.state.dashboardLoaded);

const STORAGE_KEY = 'settings:notifications-bar-hidden';

const storedIsHidden = useLocalStorage<{ hidden: boolean; expires: number } | null>(STORAGE_KEY, null, { serializer: StorageSerializers.object });

function closeNotificationsBar() {
    isVisible.value = false;

    const expires = addDays(new Date(), 1).getTime(); // 1 day

    storedIsHidden.value = { hidden: true, expires };
}

function isNotificationsBarVisible() {
    if (storedIsHidden.value?.expires && storedIsHidden.value.expires <= Date.now()) {
        storedIsHidden.value = null;

        return false;
    }

    return storedIsHidden.value?.hidden !== true;
}

async function handleNavigation() {
    try {
        await queryClient.invalidateQueries({ queryKey: queryKeys.notifications });

        router.push({ name: 'NotificationsIndex', query: { statuses: NotificationStatusType.REQUIRES_ACTION } });
    } catch (err) {
        //
    }
}

watch(
    [() => isDashboardLoaded.value, () => unhandledNotificationsCount.value],
    ([newIsDashboardLoaded, newUnhandledNotificationsCount]) => {
        if (newIsDashboardLoaded && newUnhandledNotificationsCount > 0 && isNotificationsBarVisible()) {
            isVisible.value = true;
        } else {
            isVisible.value = false;
        }
    },
    { immediate: true }
);
</script>
