import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HealthOverviewTabs = _resolveComponent("HealthOverviewTabs")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.health.overview.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_HealthOverviewTabs)
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}