import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full grid" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GenericChartModal = _resolveComponent("GenericChartModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.expandable)
      ? (_openBlock(), _createBlock(_component_GenericChartModal, {
          key: 0,
          "chart-module": _ctx.chartModule,
          options: _ctx.options,
          "chart-state": _ctx.chartState,
          "print-subtitle": _ctx.printSubtitle,
          onClose: _ctx.onModalClose
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              class: "relative grid",
              style: _normalizeStyle({ minHeight: (_ctx.options as any)?.chart?.height ? `${(_ctx.options as any).chart.height}px` : 'auto' })
            }, [
              _createElementVNode("div", {
                ref: "chartElement",
                class: _normalizeClass(["w-full", {
                            'highcharts-dashboards-container': _ctx.options?.value?.type === _ctx.ChartType.Dashboard,
                        }])
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["absolute top-0", { 'right-[48px]': _ctx.hasOptions, 'right-2': !_ctx.hasOptions }])
              }, [
                _createElementVNode("button", {
                  class: "flex items-center justify-center p-1 rounded-full transition-colors hover:bg-gray-100",
                  onClick: ($event: any) => (_ctx.openModal(scope.open))
                }, [
                  _createVNode(_component_Icon, {
                    class: "h-6 w-auto",
                    src: _ctx.IconSource.SearchPlus
                  }, null, 8, ["src"])
                ], 8, _hoisted_2)
              ], 2)
            ], 4)
          ]),
          _: 1
        }, 8, ["chart-module", "options", "chart-state", "print-subtitle", "onClose"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "chartElement",
          class: _normalizeClass(["w-full", { 'highcharts-dashboards-container': _ctx.options?.value?.type === _ctx.ChartType.Dashboard }])
        }, null, 2))
  ]))
}