<template>
    <div>
        <div class="flex justify-center">
            <transition name="fade" mode="out-in">
                <MainViewCard v-if="currentStep < 2" :class="currentWidth" class="px-4 py-4">
                    <transition name="fade" mode="out-in">
                        <component :is="currentComponent" :state="state" @next="next(0)" @skip="next(1)" @reset="onReset"></component>
                    </transition>
                </MainViewCard>
                <div v-else class="w-full">
                    <component :is="currentComponent" :state="state" @next="next(0)" @skip="next(1)" @reset="onReset"></component>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ReportsPage from '@/views/dashboard/reports/reportsPage/Index.vue';
import SelectDates from '@/views/dashboard/reports/selectDates/Index.vue';
import SelectSites from '@/views/dashboard/reports/selectSites/Index.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { ReportViewState } from '@/types';

export default defineComponent({
    components: { MainViewCard },
    data() {
        return {
            currentStep: 0,
            state: null as null | ReportViewState,
        };
    },
    computed: {
        currentComponent(): any {
            switch (this.currentStep) {
                case 1: {
                    return SelectDates;
                }
                case 2: {
                    return ReportsPage;
                }
                default: {
                    return SelectSites;
                }
            }
        },
        currentWidth(): string {
            switch (this.currentStep) {
                case 0: {
                    return 'max-w-3xl';
                }
                default: {
                    return 'max-w-lg';
                }
            }
        },
    },
    created() {
        this.setDefaultState();
    },
    methods: {
        next(skipSteps: number) {
            this.currentStep = this.currentStep + 1 + skipSteps;
        },
        setDefaultState() {
            this.state = {
                siteIds: [],
                dates: [],
                selectableDates: [],
                isBatchReport: false,
                reports: [],
            };
        },
        onReset() {
            this.currentStep = 0;
            this.setDefaultState();
        },
    },
});
</script>
