<script setup lang="ts">
import { HTMLAttributes, computed } from 'vue';
import { SelectIcon, SelectTrigger, SelectTriggerProps, useForwardProps } from 'radix-vue';
import { ChevronDown, Loader2 } from 'lucide-vue-next';
import { cn } from '@/lib/utils';

const props = defineProps<SelectTriggerProps & { class?: HTMLAttributes['class']; loading?: boolean; disabled?: boolean }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <SelectTrigger
        v-bind="forwardedProps"
        :disabled="loading || disabled"
        :class="
            cn(
                'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-normal ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:truncate text-start',
                props.class
            )
        "
    >
        <template v-if="loading">
            {{ $t('common.texts.loading') }}
        </template>
        <slot v-else />
        <SelectIcon as-child class="ml-2">
            <Loader2 v-if="loading" class="text-primary w-5 h-5 shrink-0 animate-spin" />
            <slot v-else name="icon">
                <ChevronDown class="w-5 h-5 opacity-70 shrink-0" />
            </slot>
        </SelectIcon>
    </SelectTrigger>
</template>
