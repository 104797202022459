<template>
    <div class="h-full pb-6">
        <h2 class="text-xl font-semibold text-center">{{ state.submissionMessage || $t('global.forms.steps.submission.success') }}</h2>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MultiStepFormState } from '@/types';
import { store } from '@/plugins/store';

export default defineComponent({
    emits: ['close', 'submission'],
    props: {
        state: {
            type: Object as () => MultiStepFormState,
            required: true,
        },
        // Not used by this component
        config: {
            type: Object,
            required: true,
        },
        // Not used by this component
        header: {
            type: Object as () => null | { icon_url: string; text: string },
            default: null,
        },
    },
    mounted() {
        if (this.config && this.config.refetchActions) {
            const actions = Array.isArray(this.config.refetchActions) ? this.config.refetchActions : [this.config.refetchActions];

            actions.forEach((action) => store.dispatch(action, { options: { ignoreCache: true } }));
        }

        this.$emit('submission');

        setTimeout(() => this.$emit('close'), 2000);
    },
});
</script>
