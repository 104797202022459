<template>
    <div class="space-y-4">
        <EconomyStatusGlobalPrices v-if="canManageSolution" :entries="statusEntries"></EconomyStatusGlobalPrices>
        <div v-for="item of items" :key="item.farm" class="space-y-4">
            <div class="ml-1 flex space-x-2">
                <Icon class="h-6.5 my-auto" :src="IconSource.FarmsPrimary"></Icon>
                <span class="text-lg my-auto pt-2 font-medium">{{ item.farm }}</span>
            </div>
            <MainViewCard>
                <EconomyStatusTable :action-modal-initial-state="actionModalInitialState" :headers="headers" :items="item.items"></EconomyStatusTable>
            </MainViewCard>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MultiStepFormState, TableHeader, Event, EconomyStatusEntry } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import CustomTable from '@/components/ui/CustomTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import EconomyStatusTable from '@/views/dashboard/economy/economyStatus/EconomyStatusTable.vue';
import EconomyStatusGlobalPrices from '@/views/dashboard/economy/economyStatus/EconomyStatusGlobalPrices.vue';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

export default defineComponent({
    components: { CustomTable, MainViewCard, EconomyStatusTable, EconomyStatusGlobalPrices },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetEconomyStatusEntries, { watchSites: false, watchFarmsInsteadOfSites: false, watchFormSubmissions: false });
    },
    computed: {
        canManageSolution(): boolean {
            return store.getters.loggedInUserCanManageSolution;
        },
        actionModalInitialState(): MultiStepFormState {
            return {
                location: null,
                form: null,
                confirmed: false,
                farm: null,
                record_id: null,
                notification: null,
                event: store.state.events.find((currentEvent) => currentEvent.id === 20) as Event,
            };
        },
        statusEntries() {
            return store.state.economyStatusEntries;
        },
        items() {
            const sites = store.state.economyStatusEntries.reduce((accum, current) => {
                if (accum[current.site.id]) {
                    accum[current.site.id].push(current);
                } else {
                    accum[current.site.id] = [current];
                }
                return accum;
            }, {} as { [key: string]: EconomyStatusEntry[] });

            return Object.values(sites).map((currentSiteStatusEntries) => ({
                farm: currentSiteStatusEntries[0].site.name,
                items: currentSiteStatusEntries
                    .map((currentEconomyStatusEntry) => ({
                        ...currentEconomyStatusEntry,
                        tableProperties: {
                            piglet_dkk: `${this.$n(currentEconomyStatusEntry.piglet_dkk, 'price')}${
                                currentEconomyStatusEntry.charge?.piglet_dkk
                                    ? ` (${(currentEconomyStatusEntry.charge?.piglet_dkk || 0) >= 0 ? '+' : ''}${this.$n(currentEconomyStatusEntry.charge?.piglet_dkk || 0, 'price')})`
                                    : ''
                            }`,
                            piglet_dkk_pr_kg: `${this.$n(currentEconomyStatusEntry.piglet_dkk_pr_kg, 'price')}${
                                currentEconomyStatusEntry.charge?.piglet_dkk_pr_kg
                                    ? ` (${(currentEconomyStatusEntry.charge?.piglet_dkk_pr_kg || 0) >= 0 ? '+' : ''}${this.$n(currentEconomyStatusEntry.charge?.piglet_dkk_pr_kg || 0, 'price')})`
                                    : ''
                            }`,
                            slpig_dkk: `${this.$n(currentEconomyStatusEntry.slpig_dkk, 'price')}${
                                currentEconomyStatusEntry.charge?.slpig_dkk
                                    ? ` (${(currentEconomyStatusEntry.charge?.slpig_dkk || 0) >= 0 ? '+' : ''}${this.$n(currentEconomyStatusEntry.charge?.slpig_dkk || 0, 'price')})`
                                    : ''
                            }`,
                            slpig_dkk_pr_kg: `${this.$n(currentEconomyStatusEntry.slpig_dkk_pr_kg, 'price')}${
                                currentEconomyStatusEntry.charge?.slpig_dkk_pr_kg
                                    ? ` (${(currentEconomyStatusEntry.charge?.slpig_dkk_pr_kg || 0) >= 0 ? '+' : ''}${this.$n(currentEconomyStatusEntry.charge?.slpig_dkk_pr_kg || 0, 'price')})`
                                    : ''
                            }`,
                        },
                    }))
                    .sort((a, b) => new Date(a.period_date).getTime() - new Date(b.period_date).getTime()),
            }));
        },
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.economy.status.table.headings.period'),
                    value: 'period',
                    align: 'left',
                    sortable: true,
                    sortComparison: (a: EconomyStatusEntry, b: EconomyStatusEntry, orderMultiplier: number) => (new Date(a.period_date).getTime() - new Date(b.period_date).getTime()) * orderMultiplier,
                },
                {
                    text: this.$t('views.economy.status.table.headings.pigletsPrice'),
                    value: 'tableProperties.piglet_dkk',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: this.$t('views.economy.status.table.headings.pigletsPricePerKg'),
                    value: 'tableProperties.piglet_dkk_pr_kg',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: this.$t('views.economy.status.table.headings.fatteningPigsPrice'),
                    value: 'tableProperties.slpig_dkk',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: this.$t('views.economy.status.table.headings.fatteningPigsPricePerKg'),
                    value: 'tableProperties.slpig_dkk_pr_kg',
                    align: 'left',
                    sortable: false,
                },
            ];
        },
    },
});
</script>
