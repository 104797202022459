<template>
    <Modal close-esc-event="keydown" :close-button="false" size="lg" modal-class="m-auto border-0 w-full" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-white relative rounded-lg grid pb-6 pt-10 gap-y-16 px-10 m-auto">
                <PigsBatchesForm :batch="batch" @cancel="scope.close" @submit="scope.close"></PigsBatchesForm>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import PigsBatchesForm from '@/views/dashboard/pigs/pigsBatches/PigsBatchesForm.vue';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, CloseModalButton, PigsBatchesForm },
    props: {
        batch: {
            type: Object as any,
            required: true,
        },
    },
});
</script>
