import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { HTMLAttributes, computed } from 'vue';
import { SelectContent, SelectContentEmits, SelectContentProps, SelectPortal, SelectViewport, useForwardPropsEmits } from 'radix-vue';
import { SelectScrollDownButton, SelectScrollUpButton } from '.';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  ...{
    inheritAttrs: false,
},
  __name: 'SelectContent',
  props: {
    forceMount: { type: Boolean },
    position: { default: 'popper' },
    bodyLock: { type: Boolean },
    side: {},
    sideOffset: {},
    align: {},
    alignOffset: {},
    avoidCollisions: { type: Boolean },
    collisionBoundary: {},
    collisionPadding: {},
    arrowPadding: {},
    sticky: {},
    hideWhenDetached: { type: Boolean },
    updatePositionStrategy: {},
    prioritizePosition: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: { default: '' }
  },
  emits: ["closeAutoFocus", "escapeKeyDown", "pointerDownOutside"],
  setup(__props: any, { emit: __emit }) {



const props = __props;
const emits = __emit;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectPortal), null, {
    default: _withCtx(() => [
      _createVNode(_unref(SelectContent), _mergeProps({ ..._unref(forwarded), ..._ctx.$attrs }, {
        class: 
                _unref(cn)(
                    'relative z-50 max-h-96 min-w-32 overflow-hidden rounded-md border bg-white shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                    _ctx.position === 'popper' && 'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
                    props.class
                )
            
      }), {
        default: _withCtx(() => [
          _createVNode(_unref(SelectScrollUpButton)),
          _createVNode(_unref(SelectViewport), {
            class: _normalizeClass(_unref(cn)('py-1', _ctx.position === 'popper' && 'h-[--radix-select-trigger-height] w-full min-w-[--radix-select-trigger-width]'))
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["class"]),
          _createVNode(_unref(SelectScrollDownButton))
        ]),
        _: 3
      }, 16, ["class"])
    ]),
    _: 3
  }))
}
}

})