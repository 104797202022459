<script lang="ts" setup>
import { HelpWidgetItem } from '@/types';
import HelpVideoModal from '@/components/common/helpWidget/HelpVideoModal.vue';
import { HelpItemType, HelpWidgetRouteItem, HelpWidgetVideoItem } from '@/types/HelpWidget';
import { useGetAndOpenRouteItem } from './composables/useGetAndOpenRouteItem';
import Spinner from '@/components/ui/Spinner.vue';
import InfoIcon from '@/components/icons/InfoIcon.vue';

interface Props {
    item: HelpWidgetItem;
    iconSizeClass?: string;
}

withDefaults(defineProps<Props>(), {
    iconSizeClass: 'size-6',
});

const { getAndOpenRouteItem, isLoading } = useGetAndOpenRouteItem();

function onSelectRouteItem(routeItem: HelpWidgetRouteItem) {
    if (isLoading.value) {
        return;
    }

    getAndOpenRouteItem(routeItem);
}
</script>

<template>
    <div class="flex flex-shrink-0">
        <HelpVideoModal v-if="item.type === HelpItemType.Video" :item="(item as HelpWidgetVideoItem)">
            <template #default="{ open }">
                <button class="rounded-full p-1 hover:bg-gray-100 transition-colors" @click.stop="open">
                    <span class="sr-only">{{ item.text }}</span>
                    <InfoIcon :class="iconSizeClass" aria-hidden="true" />
                </button>
            </template>
        </HelpVideoModal>
        <a v-else-if="item.type === HelpItemType.Url" :href="item.url" target="_blank" class="rounded-full p-1 hover:bg-gray-100 transition-colors" @click.stop>
            <span class="sr-only">{{ item.text }}</span>
            <InfoIcon :class="iconSizeClass" aria-hidden="true" />
        </a>
        <button v-else-if="item.type === HelpItemType.Route" :disabled="isLoading" class="rounded-full p-1 hover:bg-gray-100 transition-colors" @click.stop="onSelectRouteItem(item as HelpWidgetRouteItem)">
            <span class="sr-only">{{ item.text }}</span>
            <Spinner v-if="isLoading" :class="iconSizeClass" />
            <InfoIcon v-else :class="iconSizeClass" aria-hidden="true" />
        </button>
    </div>
</template>
