<script setup lang="ts">
import { computed, ref } from 'vue';
import { useElementSize } from '@vueuse/core';
import { getUniqueId } from '@/util/uniqueId';
import Icon from '@/components/icons/Icon.vue';
import { IconSource } from '@/types';

interface Props {
    show?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    show: undefined,
});
const emit = defineEmits<{
    (e: 'update:show', value: boolean): void;
}>();

const internalShow = ref(false);
const show = computed(() => props.show ?? internalShow.value);
const contents = ref<HTMLElement>();

const { height: targetHeight } = useElementSize(contents, undefined, {
    box: 'border-box',
});

const height = computed(() => (show.value ? targetHeight.value : 0));
const id = computed(() => `expansion-panel-${getUniqueId()}`);

function toggle() {
    if (props.show === undefined) {
        internalShow.value = !internalShow.value;
    } else {
        emit('update:show', !props.show);
    }
}
</script>

<template>
    <div class="rounded-lg bg-gray-50 overflow-hidden shadow-md">
        <button
            :aria-controls="id"
            :aria-expanded="show"
            class="relative z-10 flex bg-white hover:bg-gray-50 duration-300 filter drop-shadow rounded-t-lg w-full cursor-pointer justify-between items-center py-6 px-6"
            :class="{ 'rounded-b-lg': !show }"
            style="transition-property: background-color, border-radius; transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)"
            @click="toggle"
        >
            <div class="text-lg flex-grow font-semibold mr-2">
                <slot name="header" />
            </div>
            <Icon :src="IconSource.Right" class="h-3.5 w-3.5 flex-shrink-0 transform transition-transform duration-300 rotate-90" :class="{ 'rotate-[-90deg]': show }" aria-hidden="true" />
        </button>
        <div
            :id="id"
            tabindex="-1"
            :style="{
                height: `${height}px`,
            }"
            class="overflow-hidden transition-height duration-300 will-change-height"
        >
            <div ref="contents">
                <slot />
            </div>
        </div>
    </div>
</template>
