import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-6 mx-auto" }
const _hoisted_2 = { class: "flex items-center space-x-3 pl-6 pr-12" }
const _hoisted_3 = { class: "font-bold text-lg" }
const _hoisted_4 = { class: "text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "!max-w-6xl !w-full m-auto border-0",
    "mask-class": "grid",
    onClosing: _ctx.onClose,
    onOpening: _ctx.onOpen
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Icon, {
            src: _ctx.IconSource.Treatment,
            class: "w-12 h-12"
          }, null, 8, ["src"]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('views.monitoring.main.tables.treatmentsTable.header')), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.sectionName) + " (" + _toDisplayString(_ctx.barnName) + ")", 1)
          ])
        ]),
        _createVNode(_component_CustomTable, {
          headers: _ctx.headers,
          items: _ctx.treatments,
          "resize-on-mount": "",
          "resize-delay": 300,
          class: _normalizeClass(["mt-4 opacity-0 transition-opacity", { 'opacity-100': _ctx.visible }])
        }, {
          day: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
            _createElementVNode("td", {
              class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
              style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
            }, _toDisplayString(item.day) + "/" + _toDisplayString(item.total_days), 7)
          ]),
          dosis_pr_animal: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
            _createElementVNode("td", {
              class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
              style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
            }, _toDisplayString(item.dosis_pr_animal) + " " + _toDisplayString(item.dosis_unit ? item.dosis_unit : ''), 7)
          ]),
          _: 1
        }, 8, ["headers", "items", "class"])
      ])
    ]),
    _: 3
  }, 8, ["onClosing", "onOpening"]))
}