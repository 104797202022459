<template>
    <TooltipProvider :disabled="!enableTooltip">
        <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
                <div :class="[{ 'underline decoration-dotted underline-offset-4': enableTooltip }]">{{ text }}</div>
            </TooltipTrigger>
            <TooltipContent class="overflow-hidden p-0 min-w-44">
                <div class="max-h-56 overflow-y-auto py-2 space-y-2 divide-y divide-gray-200">
                    <div v-for="(searchTopic, searchTopicIndex) of directory.search_topics" :key="searchTopic.name" class="px-3" :class="{ 'pt-2': searchTopicIndex !== 0 }">
                        <h4 class="text-base font-semibold whitespace-nowrap">{{ searchTopic.name }}</h4>
                        <div class="text-sm mt-0.5">
                            <ul class="space-y-0.5">
                                <li v-for="querable of searchTopic.querables" :key="querable">
                                    {{ querable }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Directory } from '@/types';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { i18n } from '@/plugins/internationalization/i18n';

const props = withDefaults(
    defineProps<{
        directory: Directory;
        enableTooltip?: boolean;
    }>(),
    {
        enableTooltip: true,
    }
);

const enableTooltip = computed(() => props.directory.search_topics.flatMap((topic) => topic.querables).length > 0);
const text = computed(() => {
    if (!enableTooltip.value) {
        return '-';
    }

    return i18n.global.t('views.settings.directories.content.table.moreInfoTableRow');
});
</script>
