import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx } from "vue"

import { HelpWidgetVideoItem } from '@/types/HelpWidget';
import Icon from '@/components/icons/Icon.vue';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';

interface Props {
    item: HelpWidgetVideoItem;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpWidgetVideoItem',
  props: {
    item: {}
  },
  emits: ["select-video"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function open() {
    emit('select-video', props.item);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DropdownMenuItem), {
    class: "gap-x-2.5 text-base",
    onClick: open
  }, {
    default: _withCtx(() => [
      (_ctx.item.icon_id !== null && _ctx.item.icon_id !== undefined)
        ? (_openBlock(), _createBlock(Icon, {
            key: 0,
            src: _ctx.item.icon_id,
            class: "flex-shrink-0 size-5"
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.item.text), 1)
    ]),
    _: 1
  }))
}
}

})