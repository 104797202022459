import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

import type { HTMLAttributes } from 'vue';
import { Primitive, type PrimitiveProps } from 'radix-vue';
import { Loader2 } from 'lucide-vue-next';
import { type ButtonVariants, buttonVariants } from '.';
import { cn } from '@/lib/utils';

interface Props extends PrimitiveProps {
    variant?: ButtonVariants['variant'];
    size?: ButtonVariants['size'];
    class?: HTMLAttributes['class'];
    loading?: boolean;
    disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
    variant: { default: 'default' },
    size: { default: 'default' },
    class: { default: '' },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    asChild: { type: Boolean },
    as: { default: 'button' }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Primitive), {
    as: _ctx.as,
    "as-child": _ctx.asChild,
    class: _normalizeClass(_unref(cn)(_unref(buttonVariants)({ variant: _ctx.variant, size: _ctx.size }), props.class)),
    disabled: _ctx.loading || _ctx.disabled
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_unref(Loader2), {
            key: 0,
            class: "w-4 h-4 animate-spin",
            "aria-hidden": "true"
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["as", "as-child", "class", "disabled"]))
}
}

})