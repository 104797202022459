<template>
    <div class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <div class="flex-1 font-medium">
                <h4 class="text-lg my-auto">{{ $t('views.economy.budget.table.header') }}</h4>
            </div>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.economy.budget.table.searchFieldLabel')"></SearchInput>
            </span>
            <ActionModal
                v-if="economyEvents.length && actions.length"
                :initial-state="createBudgetEntryModal?.initialState"
                :header="createBudgetEntryModal?.header"
                :initial-step-index="1"
                @closing="onCreateBudgetEntryModalClosing"
            >
                <template #default="{ open }">
                    <AddButton :loading="isLoading" text-class="font-medium" @click="onCreateBudgetEntryClicked(open)">
                        {{ $t('views.economy.budget.table.addButtonLabel') }}
                    </AddButton>
                </template>
            </ActionModal>
        </div>
        <CustomTable :headers="headers" :items="filteredItems">
            <template #header_actions>
                <th class="w-6"></th>
            </template>

            <template #is_income="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <span>{{ getStyledType(item) }}</span>
                </td>
            </template>

            <template #locations="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2 cursor-pointer`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <LocationsTooltip
                        :locations="item.locations"
                        number-of-locations-replace-text="$NUMBER_OF_LOCATIONS"
                        :number-of-locations-text="$t('views.economy.budget.table.locationsTooltip.tableText')"
                        :tooltip-header="$t('views.economy.budget.table.locationsTooltip.header')"
                    ></LocationsTooltip>
                </td>
            </template>

            <template #prices="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <span>{{ getStyledPrice(item) }}</span>
                </td>
            </template>

            <template #actions="{ item }">
                <td class="w-6 my-auto">
                    <EditBudgetEntryModal :entry="item">
                        <template #default="{ open }">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="open">
                                    <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </template>
                    </EditBudgetEntryModal>
                </td>
            </template>
        </CustomTable>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { MultiStepFormState, TableHeader, EconomyEventType, Form, EconomyBudgetEntry } from '@/types';
import { store } from '@/plugins/store';
import CustomTable from '@/components/ui/CustomTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import LocationsTooltip from '@/components/common/LocationsTooltip.vue';
import AddButton from '@/components/ui/AddButton.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import { ActionType } from '@/plugins/store/actions';
import useGetPrice from '@/components/common/composables/useGetPrice';
import EditBudgetEntryModal from '@/views/dashboard/economy/economyBudget/editBudgetEntryModal/EditBudgetEntryModal.vue';
import useFilterableTable from '@/composables/useFilterableTable';
import { i18n } from '@/plugins/internationalization/i18n';

export default defineComponent({
    components: { CustomTable, MainViewCard, ActionModal, AddButton, SearchInput, LocationsTooltip, EditBudgetEntryModal },
    setup() {
        const economyEvents = computed(() => store.state.economyEvents);
        const actions = computed(() => store.state.actions);

        const headers = computed<TableHeader[]>(() => [
            {
                text: i18n.global.t('views.economy.budget.table.headings.type'),
                value: 'is_income',
                align: 'left',
                sortable: true,
                searchable: true,
                searchComparison: (item: EconomyBudgetEntry, search) => getStyledType(item).toLowerCase().trim().includes(search.toLowerCase().trim()),
            },
            {
                text: i18n.global.t('views.economy.budget.table.headings.category'),
                value: 'category_name',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.economy.budget.table.headings.remark'),
                value: 'remark',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.economy.budget.table.headings.locations'),
                value: 'locations',
                align: 'left',
                sortable: true,
                searchable: true,
                searchComparison: (item: EconomyBudgetEntry, search) =>
                    item.locations &&
                    ((item.locations.length === 0 && search === '-') ||
                        item.locations
                            .map((farm: { name: string; id: number }) => farm.name)
                            .join(',')
                            .toLowerCase()
                            .trim()
                            .includes(search.toLowerCase().trim())),
            },
            {
                text: i18n.global.t('views.economy.budget.table.headings.price'),
                value: 'prices',
                align: 'left',
                sortable: true,
                searchable: true,
                numberFormat: 'price',
                searchComparison: (item: EconomyBudgetEntry, search) => getStyledPrice(item).includes(search.toLowerCase().trim()),
                sortComparison: (a: EconomyBudgetEntry, b: EconomyBudgetEntry, orderMultiplier: number) => {
                    const aPrice = useGetPrice(a);
                    const bPrice = useGetPrice(b);
                    if (!aPrice) {
                        return -1 * orderMultiplier;
                    }
                    if (!bPrice) {
                        return 1 * orderMultiplier;
                    }
                    return (aPrice.price - bPrice.price) * orderMultiplier;
                },
            },
            {
                text: i18n.global.t('views.economy.budget.table.headings.unit'),
                value: 'price_unit',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                searchable: false,
            },
        ]);
        const items = computed(() => store.state.economyBudgetEntries);
        const { search, filteredItems } = useFilterableTable(items, headers);

        const createBudgetEntryModal = ref<null | { header: { icon_url: string | null; text: string | null }; initialState: MultiStepFormState }>(null);
        const isLoading = ref(false);

        async function onCreateBudgetEntryClicked(open: CallableFunction) {
            try {
                if (isLoading.value) return;

                isLoading.value = true;

                const event = economyEvents.value.find((currentEvent) => currentEvent.type_id === EconomyEventType.BudgetCreate);
                const action = actions.value.find((currentAction) => currentAction.id === event?.action_id);
                const form: Form = await store.dispatch(ActionType.GetForm, { form: { form_id: action!.form_id } });

                if (form) {
                    createBudgetEntryModal.value = {
                        header: {
                            icon_url: event && store.state.icons ? store.state.icons[event.icon_id] : null,
                            text: event?.name || null,
                        },
                        initialState: {
                            location: null,
                            form,
                            confirmed: false,
                            event,
                        } as MultiStepFormState,
                    };

                    open();
                }
            } finally {
                isLoading.value = false;
            }
        }

        function getStyledPrice(budgetEntry: EconomyBudgetEntry) {
            const priceObject = useGetPrice(budgetEntry);
            return priceObject === null ? '-' : i18n.global.n(priceObject.price, 'price');
        }

        function getStyledType(budgetEntry: EconomyBudgetEntry) {
            return budgetEntry.is_income ? i18n.global.t('views.economy.budget.table.types.income') : i18n.global.t('views.economy.budget.table.types.expense');
        }

        function onCreateBudgetEntryModalClosing(isFinalStep: boolean) {
            if (isFinalStep) {
                store.dispatch(ActionType.GetEconomyBudgetEntries, { options: { ignoreCache: true } });
            }
        }

        return {
            economyEvents,
            actions,
            headers,
            search,
            filteredItems,
            createBudgetEntryModal,
            onCreateBudgetEntryClicked,
            getStyledPrice,
            getStyledType,
            onCreateBudgetEntryModalClosing,
        };
    },
    data() {
        return {
            createBudgetEntryModal: null as null | { header: { icon_url: string | null; text: string | null }; initialState: MultiStepFormState },
            isLoading: false,
        };
    },
    created() {
        store.dispatch(ActionType.GetEconomyEvents);
        store.dispatch(ActionType.GetActions);
        store.dispatch(ActionType.GetEconomyBudgetEntries, { options: { ignoreCache: true } });
    },
});
</script>
