<template>
    <DropDown v-model:expand="expandDropdown" dropdown-class="mt-1 w-36">
        <template #dropdown-content>
            <button
                v-for="language of languages"
                :key="language.code"
                class="text-left w-full px-6 py-2 hover:bg-gray-100 first:rounded-t-md last:rounded-b-md cursor-pointer"
                @click="onLanguageclicked(language)"
                @keydown.esc="expandDropdown = false"
            >
                <div class="py-2 font-medium flex space-x-4" role="menuitem">
                    <Icon class="h-6" :src="language.icon_url"></Icon>
                    <span>{{ language.native_name }}</span>
                </div>
            </button>
        </template>
        <button class="h-full w-7 flex" type="button" @click="expandDropdown = !expandDropdown">
            <Icon class="cursor-pointer m-auto" :src="IconSource.Language"></Icon>
        </button>
    </DropDown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Language } from '@/types';

export default defineComponent({
    components: { DropDown },
    data() {
        return {
            expandDropdown: false,
        };
    },
    computed: {
        profile() {
            return store.state.user;
        },
        languages(): Language[] {
            return store.state.languages.filter((currentLanguage) => currentLanguage.is_supported);
        },
    },
    created() {
        store.dispatch(ActionType.GetLanguages);
    },
    methods: {
        async onLanguageclicked(language: Language) {
            /* Update endpoint and refresh page */
            if (!this.profile) {
                return;
            }
            const form = [
                {
                    value: language.id,
                    path: `/language_id`,
                    op: 'replace',
                },
            ];
            await store.dispatch(ActionType.EditProfile, { form });
            window.location.reload();
        },
    },
});
</script>
