<template>
    <MainViewCard class="pt-2 space-y-4">
        <div class="flex mx-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.assignments.tabs.assignments.tables.assignments.header') }}</h4>
            <span class="text-right w-80">
                <SearchInput v-model:value="search" :label="$t('views.assignments.tabs.assignments.tables.assignments.searchFieldLabel')" @keydown.esc.prevent="search = ''"></SearchInput>
            </span>
            <CreateAssignmentModal @closing="onCreateTaskClose">
                <template #default="{ open }">
                    <AddButton v-if="!completedTasks" text-class="font-medium" class="ml-6" @click="open">
                        {{ $t('views.assignments.tabs.assignments.tables.assignments.addButtonLabel') }}
                    </AddButton>
                </template>
            </CreateAssignmentModal>
        </div>
        <ActionModal
            :initial-step-index="1"
            :initial-state="actionModalInitialState"
            :step-config="actionModalStepConfig"
            :header="actionModalHeader"
            :assignment="assignmentToEdit"
            @submission="onEditSubmission"
        >
            <template #default="{ open }">
                <CustomTable :headers="headers" :items="filteredItems">
                    <template #header_actions>
                        <th class="w-8"></th>
                    </template>
                    <template #actions="{ item }">
                        <td class="w-8 my-auto">
                            <span class="flex justify-end">
                                <button type="button" @click="onAssignmentClicked(item, open)" class="p-2 -mr-2">
                                    <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </td>
                    </template>
                </CustomTable>
            </template>
        </ActionModal>
    </MainViewCard>
</template>

<script lang="ts">
import { computed, defineComponent, Ref } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { store } from '@/plugins/store';
import { Assignment, Module, TableHeader } from '@/types';
import useAssignmentForms from '@/views/dashboard/assignments/tasks/useAssignmentForms';
import AddButton from '@/components/ui/AddButton.vue';
import CreateAssignmentModal from './createTaskModal/CreateAssignmentModal.vue';
import { ActionType } from '@/plugins/store/actions';
import SearchInput from '@/components/ui/SearchInput.vue';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, CustomTable, ActionModal, AddButton, CreateAssignmentModal, SearchInput },
    props: {
        completedTasks: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const enableSectionsModule = computed<boolean>(() => store.getters.hasModuleEnabled(Module.Sections));
        const headers = computed<TableHeader[]>(() => [
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.assignments.headings.date'), value: 'date', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.assignments.headings.farm'), value: 'farm_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.assignments.headings.barn'), value: 'barn_name', align: 'left', sortable: true, searchable: true },
            ...(enableSectionsModule.value
                ? ([{ text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.section'), value: 'section_name', align: 'left', sortable: true, searchable: true }] as TableHeader[])
                : []),
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.assignments.headings.task'), value: 'name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.assignments.headings.performedBy'), value: '_assigneeName', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.assignments.headings.priority'), value: 'priority', align: 'center', sortable: true, searchable: true, type: 'number' },
            { text: '', value: 'actions', align: 'right', sortable: false, searchable: false },
        ]);
        const items = computed(
            () =>
                store.state.assignmentResponse?.data
                    .filter((assignment: Assignment) => assignment.type_id === 1 && assignment.is_completed === props.completedTasks)
                    .map((assignment: Assignment) => ({
                        ...assignment,
                        _assigneeName: assignment.assignee?.full_name || '',
                    })) ?? []
        );
        const { search, filteredItems } = useFilterableTable(items, headers);

        const onCreateTaskClose = (isFinalStep: Ref<boolean>) => {
            if (isFinalStep.value) {
                store.dispatch(ActionType.GetAssignments, { options: { ignoreCache: true } });
            }
        };

        const onEditSubmission = () => {
            store.dispatch(ActionType.GetAssignments, { options: { ignoreCache: true } });
        };

        return { headers, ...useAssignmentForms(), onCreateTaskClose, onEditSubmission, search, filteredItems };
    },
});
</script>
