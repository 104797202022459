import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-8" }

import { computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import KpiGrid from '@/views/dashboard/overview/KpiGrid.vue';
import DashboardContentHeader from '@/components/layouts/dashboardLayout/content/DashboardContentHeader.vue';
import DataQualityBar from './DataQualityBar.vue';
import { store } from '@/plugins/store';
import { DashboardItem, DashboardSectionWithItems, Module } from '@/types';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ActionType } from '@/plugins/store/actions';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { MutationType } from '@/plugins/store/mutations';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

useRefetchDataOnDashboardStateChanged(ActionType.GetDashboardOverview, { watchSites: true });

const hasDataQualityAssessmentModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.DataQualityAssessment));
const dashboardData = computed(() => store.state.dashboardOverview);
const isDashboardLoading = computed(() => store.state.dashboardOverviewLoading);

function onItemUpdate({ item, sectionIndex }: { item: DashboardItem; sectionIndex?: number }) {
    const clonedData = store.state.dashboardOverview;

    if (!clonedData) {
        return;
    }

    if (sectionIndex) {
        (clonedData.sections as DashboardSectionWithItems[])[sectionIndex].items[item.item_no] = item;
    } else {
        const itemIndex = clonedData.items.findIndex((i) => item.item_no === i.item_no);

        clonedData.items.splice(itemIndex, 1, item);
    }

    store.commit(MutationType.SetDashboardOverview, clonedData);
}

function onSectionCollapse({ sectionIndex, isCollapsed }: { sectionIndex: number; isCollapsed: boolean }) {
    const clonedData = store.state.dashboardOverview;

    if (!clonedData?.sections) {
        return;
    }

    (clonedData.sections as DashboardSectionWithItems[])[sectionIndex].is_collapsed = isCollapsed;

    store.commit(MutationType.SetDashboardOverview, clonedData);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardContentLayout, {
    heading: "",
    "has-period-picker": false,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      (hasDataQualityAssessmentModuleEnabled.value)
        ? (_openBlock(), _createBlock(DataQualityBar, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(KpiGrid),
        _createVNode(DashboardContentHeader, {
          heading: _ctx.$t('views.overview.header.heading'),
          "has-period-picker": true,
          "has-pig-type-picker": true
        }, null, 8, ["heading"]),
        _createVNode(GenericDashboard, {
          data: dashboardData.value,
          loading: isDashboardLoading.value,
          "fetch-params": { initialRequest: false },
          "fetch-action-type": _unref(ActionType).GetDashboardOverview,
          "onUpdate:item": onItemUpdate,
          "onUpdate:sectionCollapse": onSectionCollapse
        }, null, 8, ["data", "loading", "fetch-action-type"])
      ])
    ]),
    _: 1
  }))
}
}

})