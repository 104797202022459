<template>
    <GenericMultiStepModal :header-icon="IconSource.Food" :modal-class="currentStep.width" :is-final-step="isFinalStep" @closing="onModalClose">
        <template #default="scope">
            <slot v-bind="scope" />
        </template>
        <template #component="scope">
            <component :is="currentStep.component" v-model:value="form" :text="submissionStatusText" @submit="next" @close="scope.close"></component>
        </template>
    </GenericMultiStepModal>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef, Ref, watch } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import GenericMultiStepModal from '@/components/common/GenericMultiStepModal.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { MixType, Farm, MixForm } from '@/types';
import MixProperties from '@/views/dashboard/feed/feedMixes/newMixModal/MixProperties.vue';
import MixTypeProperties from '@/views/dashboard/feed/feedMixes/newMixModal/MixTypeProperties.vue';
import GenericSubmissionStatus from '@/components/common/GenericSubmissionStatus.vue';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import useResettableForm from '@/components/common/composables/useResettableForm';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { GenericMultiStepModal, MultiStepFormHeaderIcon, CloseModalButton },
    setup() {
        store.dispatch(ActionType.GetRawMaterials);
        store.dispatch(ActionType.GetMixTypes);
        store.dispatch(ActionType.GetMixRegisterItems);

        const types: ComputedRef<MixType[]> = computed(() => store.state.mixTypes);

        const multiSteps = useMultiSteps([
            {
                component: MixProperties,
                width: 'w-128',
            },
            {
                component: MixTypeProperties,
                width: 'w-256 xl:w-288',
            },
            {
                component: GenericSubmissionStatus,
                width: 'w-128',
            },
        ]);

        const resettableForm = useResettableForm().setInitial({
            type: types.value[0],
            farms: [],
            prices: [{ price: 0, valid_from: new Date().toISOString() }],
            name: '',
            wgt_avg_fu: 0,
            wgt_avg_crude_protein: 0,
            wgt_avg_phosphorus: 0,
            surcharge: 0,
            register: null,
            components: [],
        } as MixForm);
        const form = resettableForm.getInstance() as Ref<Form<MixForm> & MixForm>;

        watch(types, (newValue) => {
            if (!form.value.type) {
                resettableForm.fillFormAndInitial({ type: newValue[0] });
            }
        });

        const onModalClose = (next: (closeModal: boolean) => boolean) => {
            if (multiSteps.currentStepIndex.value === 1) {
                if (!window.confirm(i18n.global.t('global.forms.confirmCloseModalMessage'))) {
                    return next(false);
                }
            }
            multiSteps.reset();
            resettableForm.reset();
            resettableForm.fillFormAndInitial({
                farms: [],
                prices: [{ price: 0, valid_from: new Date().toISOString() }],
            });
            return true;
        };

        const submissionStatusText = computed(() => (multiSteps.isFinalStep.value ? i18n.global.t('views.feed.mixes.newMixModal.confirmation.header') : null));

        return {
            onModalClose,
            ...multiSteps,
            form,
            types,
            submissionStatusText,
        };
    },
});
</script>
