<template>
    <Modal close-esc-event="keydown" size="5xl" :close-button="false" modal-class="m-auto w-full border-0" mask-class="grid">
        <template #toggle="scope">
            <button type="button" class="bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3 flex" @click="scope.open">
                <span class="m-auto pr-2">
                    <Icon :src="IconSource.DatePicker"></Icon>
                </span>
                <span class="font-medium">{{ $t('views.activityTable.calendarButtonLabel') }}</span>
            </button>
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="bg-white divide-x flex rounded-xl w-full h-120">
                <div class="py-8 px-4 flex-1">
                    <FullCalendarWrapper v-model:dateString="date" :events="calendarEvents"></FullCalendarWrapper>
                </div>
                <div class="py-4 flex-1">
                    <TableCalendarModalEvents v-model:events="filteredEvents"></TableCalendarModalEvents>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { EconomyPosting, EconomyPostingCategory, Record, RecordType } from '@/types';
import FullCalendarWrapper from '@/components/ui/FullCalendarWrapper.vue';
import TableCalendarModalEvents from '@/components/common/TableCalendarModalEvents.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, FullCalendarWrapper, TableCalendarModalEvents, CloseModalButton },
    emits: ['update:selectedIds'],
    props: {
        events: {
            type: Array as () => (Record | EconomyPosting)[],
            default: [],
        },
        categories: {
            type: Array as () => (RecordType | EconomyPostingCategory)[],
            default: [],
        },
    },
    data() {
        return {
            date: '',
        };
    },
    computed: {
        calendarEvents(): any[] {
            const colors = this.$props.categories.reduce((accum, current) => {
                accum[current.id] = current.cal_color_hex;
                return accum;
            }, {} as { [key: string]: string });

            return Object.values(
                this.$props.events.reduce((accum, current) => {
                    const currentDate = new Date(Date.parse(current.date));
                    const currentId = typeof (current as Record).type_id === 'number' ? (current as Record).type_id : (current as EconomyPosting).category_id;
                    accum[`${currentId}${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDate()}`] = {
                        start: new Date(Date.parse(current.date)),
                        allDay: true,
                        color: colors[currentId],
                    };
                    return accum;
                }, {} as { [key: string]: { start: Date; allDay: boolean; color: string } })
            );
        },
        filteredEvents(): (Record | EconomyPosting)[] {
            const selectedDate = new Date(Date.parse(this.date));
            return this.$props.events.filter((event) => {
                const eventDate = new Date(Date.parse(event.date));
                return selectedDate.getFullYear() === eventDate.getFullYear() && selectedDate.getMonth() === eventDate.getMonth() && selectedDate.getDate() === eventDate.getDate();
            });
        },
    },
});
</script>
