import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "flex rounded-md bg-gray-200 relative h-full px-4",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isWeaners = !_ctx.isWeaners))
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(`width: ${_ctx.isWeaners ? _ctx.pigTypePickerOptions.weanersWidth + 24 : _ctx.pigTypePickerOptions.slaughterPigsWidth + 24}px; left: ${
                _ctx.isWeaners ? `calc(100% - ${_ctx.pigTypePickerOptions.weanersWidth + 28}px)` : '4px'
            }`),
      class: "absolute z-10 top-1 bottom-1 bg-primary-700 rounded-md transition-all duration-300"
    }, null, 4),
    _createElementVNode("div", {
      ref: "slaughters",
      class: _normalizeClass([{ 'text-white': !_ctx.isWeaners }, "relative z-20 my-auto mr-4 whitespace-nowrap transition-all duration-300"])
    }, _toDisplayString(_ctx.$t('views.pigTypePicker.slaughterPigs')), 3),
    _createElementVNode("div", {
      ref: "weaners",
      class: _normalizeClass([{ 'text-white': _ctx.isWeaners }, "relative z-20 my-auto ml-4 whitespace-nowrap transition-all duration-300"])
    }, _toDisplayString(_ctx.$t('views.pigTypePicker.weaners')), 3)
  ]))
}