import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative z-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "absolute right-6 top-6 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, [
      _createVNode(_component_Icon, {
        src: _ctx.IconSource.Close
      }, null, 8, ["src"])
    ])
  ]))
}