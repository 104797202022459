import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w-full py-8 px-6 mx-auto",
  style: {"min-height":"calc(100vh - 12rem)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_DashboardContentHeader, {
      heading: _ctx.heading,
      subheading: _ctx.subheading,
      "has-period-picker": _ctx.hasPeriodPicker,
      "has-pig-type-picker": _ctx.hasPigTypePicker
    }, {
      "top-right": _withCtx(() => [
        _renderSlot(_ctx.$slots, "header-top-right")
      ]),
      _: 3
    }, 8, ["heading", "subheading", "has-period-picker", "has-pig-type-picker"]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}