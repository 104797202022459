<template>
    <button
        v-if="element.type_settings.variant?.type || (element.type_settings.variant?.color && Object.values(element.type_settings.variant.color).some((color) => color))"
        type="button"
        class="disabled:cursor-default transition-colors rounded-lg py-2 px-3 flex relative"
        :class="buttonClasses"
        :style="buttonStyles"
        :disabled="disabled"
        @click="onButtonClicked"
        @mouseover="isHovering = true"
        @mouseleave="isHovering = false"
    >
        <span :class="{ invisible: disabled }">
            {{ element.label }}
        </span>
        <div v-if="disabled" class="absolute rounded-lg inset-0 flex justify-center items-center" aria-hidden="true">
            <Spinner class="h-6 w-6 mx-auto" :class="spinnerTextClass" :style="{ color: spinnerTextColor }"></Spinner>
        </div>
    </button>
    <AddButton v-else :loading="disabled" class="mb-2" @click="onButtonClicked">
        {{ element.label }}
    </AddButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AddButton from '@/components/ui/AddButton.vue';
import { CloneFormSectionOptions, FormElement } from '@/types';
import Spinner from '@/components/ui/Spinner.vue';

export default defineComponent({
    components: { AddButton, Spinner },
    props: {
        element: {
            type: Object as () => FormElement,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['clone-section', 'backend-retrieval'],
    data() {
        return {
            isHovering: false,
        };
    },
    computed: {
        spinnerTextColor() {
            if (this.element.type_settings.variant?.color?.text) {
                return this.toHexCode(this.element.type_settings.variant.color.text);
            }

            return undefined;
        },
        spinnerTextClass() {
            if (this.element.type_settings.variant?.color?.text) {
                return '';
            }

            // Success
            if (this.element.type_settings.variant?.type === 'success') {
                return '!text-[#09a142]';
            }

            // Danger
            if (this.element.type_settings.variant?.type === 'danger') {
                return '!text-red-400';
            }

            // Default
            return '!text-primary-800';
        },
        buttonStyles() {
            const styles: Record<string, string> = {};

            if (this.element.type_settings.variant?.color?.background) {
                styles.backgroundColor = this.toHexCode(this.element.type_settings.variant.color.background);
            }

            if (this.element.type_settings.variant?.color?.hoverBackground && this.isHovering) {
                styles.backgroundColor = this.toHexCode(this.element.type_settings.variant.color.hoverBackground);
            }

            if (this.element.type_settings.variant?.color?.text) {
                styles.color = this.toHexCode(this.element.type_settings.variant.color.text);
            }

            return styles;
        },
        buttonClasses() {
            const classes: string[] = [];

            // Success
            if (this.element.type_settings.variant?.type === 'success') {
                if (!this.element.type_settings.variant?.color?.background) {
                    classes.push('bg-[#e0f9e9]');
                }

                if (!this.element.type_settings.variant?.color?.hoverBackground) {
                    classes.push('hover:bg-[#c3f4d5]');
                }

                if (!this.element.type_settings.variant?.color?.text) {
                    classes.push('text-[#09a142]');
                }
                // Danger
            } else if (this.element.type_settings.variant?.type === 'danger') {
                if (!this.element.type_settings.variant?.color?.background) {
                    classes.push('bg-red-400 bg-opacity-25');
                }

                if (!this.element.type_settings.variant?.color?.hoverBackground) {
                    classes.push('hover:bg-opacity-35');

                    if (this.disabled) {
                        classes.push('hover:bg-opacity-35');
                    }
                }

                if (!this.element.type_settings.variant?.color?.text) {
                    classes.push('text-red-400');
                }
                // Default
            } else {
                if (!this.element.type_settings.variant?.color?.background) {
                    classes.push('bg-primary-700 bg-opacity-20');
                }

                if (!this.element.type_settings.variant?.color?.hoverBackground) {
                    classes.push('hover:bg-opacity-30');

                    if (this.disabled) {
                        classes.push('hover:bg-opacity-30');
                    }
                }

                if (!this.element.type_settings.variant?.color?.text) {
                    classes.push('text-primary-800');
                }
            }

            return classes.join(' ');
        },
    },
    methods: {
        onButtonClicked(event: MouseEvent) {
            if (this.disabled) {
                if (event?.preventDefault) {
                    event.preventDefault();
                }

                return;
            }

            switch (this.element.type_settings.action) {
                case 'clone-section': {
                    this.$emit('clone-section', {
                        sectionReference: this.element.type_settings.reference,
                        newSectionName: this.element.type_settings.values.find((currentValue: { id: string; value: null | string }) => currentValue.id === 'cloned-section-name')?.value,
                    } as CloneFormSectionOptions);
                    break;
                }
                case 'backend-retrieval': {
                    this.$emit('backend-retrieval', this.$props.element);
                    break;
                }
                default: {
                }
            }
        },
        toHexCode(color: string) {
            return color.startsWith('#') ? `${color} !important` : `#${color} !important`;
        },
    },
});
</script>
