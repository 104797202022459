import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "location-type-tabs default-tabs"
}
const _hoisted_2 = { class: "space-x-8 flex border-b border-black border-opacity-10" }
const _hoisted_3 = { class: "flex pr-8" }
const _hoisted_4 = { class: "flex pr-8" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Farms = _resolveComponent("Farms")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Barns = _resolveComponent("Barns")!
  const _component_Sections = _resolveComponent("Sections")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Tabs, {
            teleport: "#location-type-tabs-target",
            "active-tab-class": "--active"
          }, {
            default: _withCtx(() => [
              _createElementVNode("nav", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Tab, {
                    name: _ctx.$t('views.settings.locations.tabs.farms'),
                    default: true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Farms, {
                        "can-manage-directory": _ctx.canManageDirectory,
                        search: _ctx.search,
                        class: "mt-8"
                      }, null, 8, ["can-manage-directory", "search"])
                    ]),
                    _: 1
                  }, 8, ["name"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Tab, {
                    name: _ctx.$t('views.settings.locations.tabs.barns')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Barns, {
                        "can-manage-directory": _ctx.canManageDirectory,
                        search: _ctx.search,
                        class: "mt-8"
                      }, null, 8, ["can-manage-directory", "search"])
                    ]),
                    _: 1
                  }, 8, ["name"])
                ]),
                (_ctx.enableSectionsModule)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_Tab, {
                        name: _ctx.$t('views.settings.locations.tabs.sections')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Sections, {
                            "can-manage-directory": _ctx.canManageDirectory,
                            search: _ctx.search,
                            class: "mt-8"
                          }, null, 8, ["can-manage-directory", "search"])
                        ]),
                        _: 1
                      }, 8, ["name"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _cache[0] || (_cache[0] = _createElementVNode("div", { id: "location-type-tabs-target" }, null, -1))
        ]))
      : _createCommentVNode("", true)
  ]))
}