import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "economy-main-tabs default-tabs" }
const _hoisted_2 = { class: "space-x-8 flex border-b border-black border-opacity-10" }
const _hoisted_3 = { class: "space-y-4 pt-4" }
const _hoisted_4 = { class: "flex mx-6 space-x-6" }
const _hoisted_5 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_6 = { class: "m-auto pr-2" }
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "m-auto pr-2" }
const _hoisted_10 = { class: "font-medium" }
const _hoisted_11 = {
  key: 0,
  class: "bg-primary-800 rounded-full text-white font-medium leading-none px-2 py-1 ml-2"
}
const _hoisted_12 = { class: "w-16 my-auto" }
const _hoisted_13 = { class: "w-6 my-auto" }
const _hoisted_14 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TableCalendarModal = _resolveComponent("TableCalendarModal")!
  const _component_FilterModal = _resolveComponent("FilterModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tabs, {
      teleport: "#economy-type-tabs-target",
      "active-tab-class": "--active"
    }, {
      default: _withCtx(() => [
        _createElementVNode("nav", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tab.name,
              class: "flex pr-4"
            }, [
              _createVNode(_component_Tab, {
                name: tab.name,
                default: tab.isDefault
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MainViewCard, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("h4", _hoisted_5, _toDisplayString(tab.name), 1),
                          _createElementVNode("button", {
                            type: "button",
                            class: "bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3 flex",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fetchPostings && _ctx.fetchPostings(...args)))
                          }, [
                            _createElementVNode("span", _hoisted_6, [
                              _createVNode(_component_Icon, {
                                src: _ctx.IconSource.RefreshActivities
                              }, null, 8, ["src"])
                            ]),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('views.economy.main.fetchActivitiesButtonLabel')), 1)
                          ]),
                          _createVNode(_component_TableCalendarModal, {
                            events: tab.postings,
                            categories: _ctx.economyPostingCategories
                          }, null, 8, ["events", "categories"]),
                          _createVNode(_component_FilterModal, {
                            "selected-ids": _ctx.selectedEconomyCategories,
                            "onUpdate:selectedIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedEconomyCategories) = $event)),
                            header: _ctx.$t('views.economy.main.filterModal.header'),
                            "sub-header": _ctx.$t('views.economy.main.filterModal.subheader'),
                            types: _ctx.economyPostingCategories
                          }, {
                            default: _withCtx(({ scope }) => [
                              _createElementVNode("button", {
                                type: "button",
                                class: "flex items-center bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3",
                                onClick: scope.open
                              }, [
                                _createElementVNode("span", _hoisted_9, [
                                  _createVNode(_component_Icon, {
                                    src: _ctx.IconSource.Filter
                                  }, null, 8, ["src"])
                                ]),
                                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('views.economy.main.filterButtonLabel')), 1),
                                (_ctx.economyPostingCategories.length && _ctx.selectedEconomyCategories.length !== _ctx.economyPostingCategories.length)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.selectedEconomyCategories.length), 1))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_8)
                            ]),
                            _: 1
                          }, 8, ["selected-ids", "header", "sub-header", "types"])
                        ]),
                        _createVNode(_component_ActionModal, {
                          "initial-step-index": 1,
                          "initial-state": _ctx.actionModalInitialState,
                          "step-config": _ctx.actionModalStepConfig,
                          header: _ctx.actionModalHeader,
                          onClosing: _ctx.onActionModalClosing
                        }, {
                          default: _withCtx(({ open }) => [
                            _createVNode(_component_CustomTable, {
                              items: tab.postings,
                              headers: _ctx.headers,
                              "clickable-items": "",
                              onItemClicked: (item) => _ctx.onEconomyPostingClicked(item, open)
                            }, {
                              header_icon_url: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createElementVNode("th", { class: "w-16" }, null, -1)
                              ])),
                              header_actions: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createElementVNode("th", { class: "w-6" }, null, -1)
                              ])),
                              date: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                                _createElementVNode("td", {
                                  class: _normalizeClass(["flex-1", `${getHeaderClass(header, true)} my-auto`]),
                                  style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                                }, _toDisplayString(_ctx.$d(item.date, 'short')), 7)
                              ]),
                              icon_url: _withCtx(({ item }) => [
                                _createElementVNode("td", _hoisted_12, [
                                  _createVNode(_component_Icon, {
                                    src: item.icon_id,
                                    class: "w-10 h-10"
                                  }, null, 8, ["src"])
                                ])
                              ]),
                              actions: _withCtx(() => [
                                _createElementVNode("td", _hoisted_13, [
                                  _createElementVNode("span", _hoisted_14, [
                                    _createVNode(_component_Icon, {
                                      class: "h-3 w-3 cursor-pointer",
                                      src: _ctx.IconSource.Right
                                    }, null, 8, ["src"])
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1032, ["items", "headers", "onItemClicked"])
                          ]),
                          _: 2
                        }, 1032, ["initial-state", "step-config", "header", "onClosing"])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["name", "default"])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }),
    _cache[4] || (_cache[4] = _createElementVNode("div", {
      id: "economy-type-tabs-target",
      class: "mt-10"
    }, null, -1))
  ]))
}