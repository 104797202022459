<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.pigs.status.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-6">
            <MainViewCard class="py-4 px-2">
                <GenericChartWithApi :chart-name="chart.name" :series-options="chart.seriesOptions" :height="chart.height" :chart-type="chart.type" :chart-instance-options="chart.options"></GenericChartWithApi>
            </MainViewCard>
            <MainViewCard>
                <GenericTableWithApi :source="pigsMovementsTableSource"></GenericTableWithApi>
            </MainViewCard>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import GenericTableWithApi from '@/components/common/GenericTableWithApi.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import { Table, Chart, ChartType } from '@/types';
import colors from '@/plugins/tailwind';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, GenericTableWithApi, GenericChartWithApi },
    data() {
        return {
            pigsMovementsTableSource: Table.PigsMovements,
        };
    },
    computed: {
        chart() {
            return {
                name: Chart.PigsStatus,
                type: ChartType.ColumnChart,
                height: 300,
                seriesOptions: [
                    {
                        type: 'line',
                        stickyTracking: false,
                    },
                ],
                options: {
                    yAxis: {
                        allowDecimals: false,
                    },
                    colors: ['#2f73a4', colors.pigs, colors.deaths, colors.slaughters, colors.primary[800], colors.feed[400], '#e8b24a'],
                },
            };
        },
    },
});
</script>
