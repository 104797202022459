import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "m-auto pr-2" }
const _hoisted_3 = { class: "font-medium" }
const _hoisted_4 = { class: "bg-white divide-x flex rounded-xl w-full h-120" }
const _hoisted_5 = { class: "py-8 px-4 flex-1" }
const _hoisted_6 = { class: "py-4 flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_FullCalendarWrapper = _resolveComponent("FullCalendarWrapper")!
  const _component_TableCalendarModalEvents = _resolveComponent("TableCalendarModalEvents")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    size: "5xl",
    "close-button": false,
    "modal-class": "m-auto w-full border-0",
    "mask-class": "grid"
  }, {
    toggle: _withCtx((scope) => [
      _createElementVNode("button", {
        type: "button",
        class: "bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3 flex",
        onClick: scope.open
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_Icon, {
            src: _ctx.IconSource.DatePicker
          }, null, 8, ["src"])
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('views.activityTable.calendarButtonLabel')), 1)
      ], 8, _hoisted_1)
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_FullCalendarWrapper, {
            dateString: _ctx.date,
            "onUpdate:dateString": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
            events: _ctx.calendarEvents
          }, null, 8, ["dateString", "events"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_TableCalendarModalEvents, {
            events: _ctx.filteredEvents,
            "onUpdate:events": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filteredEvents) = $event))
          }, null, 8, ["events"])
        ])
      ])
    ]),
    _: 1
  }))
}