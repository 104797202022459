import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  class: "py-2 font-medium flex space-x-4",
  role: "menuitem"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createBlock(_component_DropDown, {
    expand: _ctx.expandDropdown,
    "onUpdate:expand": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.expandDropdown) = $event)),
    "dropdown-class": "mt-1 w-36"
  }, {
    "dropdown-content": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
        return (_openBlock(), _createElementBlock("button", {
          key: language.code,
          class: "text-left w-full px-6 py-2 hover:bg-gray-100 first:rounded-t-md last:rounded-b-md cursor-pointer",
          onClick: ($event: any) => (_ctx.onLanguageclicked(language)),
          onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.expandDropdown = false), ["esc"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Icon, {
              class: "h-6",
              src: language.icon_url
            }, null, 8, ["src"]),
            _createElementVNode("span", null, _toDisplayString(language.native_name), 1)
          ])
        ], 40, _hoisted_1))
      }), 128))
    ]),
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "h-full w-7 flex",
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandDropdown = !_ctx.expandDropdown))
      }, [
        _createVNode(_component_Icon, {
          class: "cursor-pointer m-auto",
          src: _ctx.IconSource.Language
        }, null, 8, ["src"])
      ])
    ]),
    _: 1
  }, 8, ["expand"]))
}