<template>
    <DashboardContentLayout
        class="space-y-8"
        :heading="$t('views.dataQuality.header.heading')"
        :subheading="assesmentDate ? `${$t('views.dataQuality.texts.lastCalculated')}: ${$d(new Date(assesmentDate), 'long')}` : ''"
    >
        <template #header-top-right>
            <div class="w-92 flex">
                <SearchInput v-model:value="searchQuery" class="my-auto" :label="$t('views.dataQuality.tables.searchFieldLabel')" :background-color="'bg-white'" @on-escape="searchQuery = ''"></SearchInput>
            </div>
        </template>
        <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader">
            <template #default="{ open }">
                <div class="pt-8 border-t border-t-gray-200 space-y-6">
                    <ExpansionPanel v-for="summary in filteredFarmAssessmentSummaries" :key="summary.id">
                        <template #header>
                            <div class="flex items-center gap-x-5">
                                <DataQualityStatusBarTooltip :quality-assessment-id="summary.quality_assessment_id" />
                                <div class="flex gap-x-2 gap-y-1 items-center">
                                    <Icon :src="IconSource.FarmsPrimary" class="h-7 w-7 flex-shrink-0" aria-hidden="true" />
                                    <span>{{ summary.farm.name }}</span>
                                </div>
                            </div>
                        </template>
                        <ul class="divide-y divide-y-gray-100">
                            <li v-for="item in summary._items" :key="`method-${summary.id}-${item.id}`" class="relative hover:bg-gray-100 transition-colors flex items-center">
                                <DataQualityStatusBarTooltip :quality-assessment-id="item.quality_assessment_id" class="absolute left-6" />
                                <button class="flex pl-32 outline-none flex-grow items-center justify-between space-x-2 pr-6 py-6" @click="openForm(item.id, open)">
                                    <div>{{ item._method?.name }}</div>
                                    <Icon :src="IconSource.Right" class="h-3.5 w-3.5 flex-shrink-0" aria-hidden="true" />
                                </button>
                            </li>
                        </ul>
                    </ExpansionPanel>
                </div>
            </template>
        </ActionModal>
    </DashboardContentLayout>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import ExpansionPanel from '@/components/ui/ExpansionPanel.vue';
import { DataQualityAssessmentFarmSummary, DataQualityAssessmentMethod, DataQualityCategory, IconSource, DataQualityAssessmentFarmLocationMethodAggregate, MultiStepFormState, Form } from '@/types';
import Icon from '@/components/icons/Icon.vue';
import { store } from '@/plugins/store';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ActionType } from '@/plugins/store/actions';
import DataQualityStatusBarTooltip from '@/components/common/dataQuality/DataQualityStatusBarTooltip.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';

store.dispatch(ActionType.GetDataQualityCategories);
store.dispatch(ActionType.GetDataQualityAssessmentMethods);
useRefetchDataOnDashboardStateChanged(ActionType.GetDataQualityAssessment, { watchSites: true });

const searchQuery = ref('');

interface PopulatedDataQualityAssessmentMethod extends DataQualityAssessmentFarmLocationMethodAggregate {
    _method: DataQualityAssessmentMethod | undefined;
}

interface PopulatedDataQualityAssessmentFarmSummary extends DataQualityAssessmentFarmSummary {
    _category: DataQualityCategory | undefined;
    _items: PopulatedDataQualityAssessmentMethod[];
}

const farmAssessmentSummaries = computed<PopulatedDataQualityAssessmentFarmSummary[]>(() => {
    if (!store.state.dataQualityAssessment || !store.state.dataQualityCategories || !store.state.dataQualityAssessmentMethods) {
        return [];
    }

    return (
        store.state.dataQualityAssessment.farm_summaries?.map((f) => ({
            ...f,
            _category: store.state.dataQualityCategories!.find((c) => c.id === f.quality_assessment_id),
            _items:
                f.method_aggregate
                    ?.map((m) => {
                        const method = store.state.dataQualityAssessmentMethods!.find((dm) => dm.id === m.assessment_method_id);

                        return {
                            ...m,
                            _method: method,
                        };
                    })
                    .filter((m) => m._method) || [],
        })) || []
    );
});
const assesmentDate = computed(() => (store.state.dataQualityAssessment ? store.state.dataQualityAssessment.created_at_utc : ''));

const filteredFarmAssessmentSummaries = computed(() => {
    if (!searchQuery.value) {
        return farmAssessmentSummaries.value;
    }

    return farmAssessmentSummaries.value.filter((f) => f.farm.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
});

const actionModalInitialState = ref<MultiStepFormState>({ form: null, confirmed: false, event: null });
const actionModalStepConfig = ref([{}, { deleteButton: false, editable: false }, { disableRefetch: true, submitActionType: ActionType.GetForm }, {}]);
const actionModalHeader = ref<{ icon_url: string; text: string } | null>(null);
const isLoadingActionForm = ref(false);

const FORM_ID = 43; // DQA

async function openForm(methodId: DataQualityAssessmentFarmLocationMethodAggregate['id'], open: CallableFunction) {
    try {
        if (isLoadingActionForm.value) {
            return;
        }

        isLoadingActionForm.value = true;

        const form: Form = await store.dispatch(ActionType.GetForm, {
            form: { form_id: FORM_ID, resource_id: methodId },
        });

        actionModalInitialState.value = { ...actionModalInitialState.value, form };
        actionModalHeader.value = { icon_url: IconSource.DataQualityIcon as string, text: form.sections?.[0]?.name || '' };

        open();
    } catch {
        //
    } finally {
        isLoadingActionForm.value = false;
    }
}
</script>
