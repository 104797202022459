<template>
    <div class="space-y-8">
        <div v-for="directoryFarm of filteredDirectoryFarms" :key="directoryFarm.id" class="space-y-6">
            <div class="flex space-x-2">
                <Icon class="h-6.5 my-auto" :src="IconSource.FarmsPrimary"></Icon>
                <span class="text-lg my-auto pt-2 font-medium">{{ directoryFarm.name }}</span>
            </div>
            <div class="space-y-8">
                <MainViewCard v-for="directoryBarn of directoryFarm.barns" :key="directoryBarn.id" class="pt-2">
                    <div class="flex mt-2 mb-4">
                        <div class="flex ml-6 space-x-2 flex-1">
                            <Icon class="h-6.5 my-auto" :src="IconSource.Location"></Icon>
                            <span class="text-lg my-auto pt-2 font-medium">{{ directoryBarn.name }}</span>
                        </div>
                        <span class="flex flex-1 justify-end mr-6">
                            <SettingsFormModal v-if="createSettingsEvent && canManageDirectory" :initial-state="createInitialState" @closing="onModalClosing">
                                <template #default="{ open }">
                                    <AddButton text-class="font-medium" @click="onCreateSectionClicked(directoryBarn, open)">
                                        {{ $t('views.settings.locations.sections.newSectionButtonLabel') }}
                                    </AddButton>
                                </template>
                            </SettingsFormModal>
                            <div></div>
                        </span>
                    </div>
                    <CustomTable :headers="headers" :items="directoryBarn.sections">
                        <template #header_actions>
                            <th class="w-8"></th>
                        </template>
                        <template #actions="{ item }">
                            <td class="w-8 my-auto">
                                <span class="flex justify-end">
                                    <SettingsFormModal
                                        v-if="editSettingsEvent && canManageDirectory"
                                        :initial-state="editInitialState"
                                        :step-config="[
                                            {},
                                            {},
                                            {
                                                rejectable: false,
                                                submitActionType: putFormActionType,
                                            },
                                            {},
                                        ]"
                                        @closing="onModalClosing"
                                    >
                                        <template #default="{ open }">
                                            <button type="button" class="p-2 -mr-2" @click="onEditSectionClicked(item, open)">
                                                <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                            </button>
                                        </template>
                                    </SettingsFormModal>
                                </span>
                            </td>
                        </template>
                    </CustomTable>
                </MainViewCard>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { TableHeader, DirectoryFarm, DirectoryBarn, Section, SettingEvent, SettingEventType } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import AddButton from '@/components/ui/AddButton.vue';
import useGetItemProperty from '@/components/common/composables/useGetItemProperty';
import useLocationSettingEvents from '@/views/dashboard/settings/locations/useLocationSettingEvents';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { MainViewCard, CustomTable, AddButton, SettingsFormModal },
    props: {
        canManageDirectory: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: '',
        },
    },
    setup() {
        return useLocationSettingEvents(
            { event: null, form: null, confirmed: false },
            { event: null, form: null, confirmed: false, section: null },
            SettingEventType.sectionCreate,
            SettingEventType.sectionEdit,
            ActionType.GetDirectorySections
        );
    },
    computed: {
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.settings.locations.sections.headers.name'),
                    value: 'name',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: this.$t('views.settings.locations.sections.headers.pigCapacity'),
                    value: 'pig_capacity',
                    align: 'left',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                },
            ];
        },
        directoryFarms(): DirectoryFarm[] {
            return (store.getters.getDirectoryFarms(true, true) as DirectoryFarm[]).filter((currentDirectoryFarm: DirectoryFarm) => currentDirectoryFarm.barns && currentDirectoryFarm.barns.length);
        },
        filteredDirectoryFarms(): DirectoryFarm[] {
            const searchableHeaders = this.headers.filter((header) => header.searchable);
            const filteredDirectoryFarms: DirectoryFarm[] = [];
            if (this.$props.search.length === 0) {
                return this.directoryFarms;
            }

            this.directoryFarms.forEach((currentDirectoryFarm) => {
                const farmMatchesSearch = currentDirectoryFarm.name.toLowerCase().trim().includes(this.$props.search.toLowerCase().trim());
                if (farmMatchesSearch) {
                    filteredDirectoryFarms.push(currentDirectoryFarm);
                } else if (currentDirectoryFarm.barns) {
                    const barnsMatchingSearch: DirectoryBarn[] = [];
                    currentDirectoryFarm.barns.forEach((currentDirectoryBarn) => {
                        if (currentDirectoryBarn.name.toLowerCase().trim().includes(this.$props.search.toLowerCase().trim())) {
                            barnsMatchingSearch.push(currentDirectoryBarn);
                        } else if (currentDirectoryBarn.sections) {
                            const sectionsMatchingSearch = currentDirectoryBarn.sections.filter((currentSection) =>
                                Boolean(
                                    searchableHeaders.find((currentSearchableHeader) =>
                                        currentSearchableHeader.searchComparison
                                            ? currentSearchableHeader.searchComparison(currentSection, this.$props.search)
                                            : `${this.getItemProperty(currentSearchableHeader, currentSection)}`.toLowerCase().trim().includes(this.search.toLowerCase().trim())
                                    )
                                )
                            );

                            if (sectionsMatchingSearch.length) {
                                barnsMatchingSearch.push({ ...currentDirectoryBarn, sections: sectionsMatchingSearch });
                            }
                        }
                    });

                    if (barnsMatchingSearch.length) {
                        filteredDirectoryFarms.push({ ...currentDirectoryFarm, barns: barnsMatchingSearch });
                    }
                }
            });

            return filteredDirectoryFarms.filter((currentFarm) => currentFarm.barns?.length);
        },
    },
    methods: {
        onCreateSectionClicked(barn: DirectoryBarn, open: CallableFunction) {
            this.createInitialState.location = barn;
            open();
        },
        onEditSectionClicked(section: Section, open: CallableFunction) {
            this.editInitialState.section = section;
            open();
        },
        getItemProperty: useGetItemProperty,
    },
});
</script>
