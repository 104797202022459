<template>
    <div class="w-full space-y-4">
        <h2 class="font-semibold text-xl text-center">{{ $t('views.reports.selectSitesPage.header') }}</h2>

        <div class="select-site-tabs h-88">
            <Tabs teleport="#site-tabs-target" active-tab-class="--active">
                <nav class="flex justify-center overflow-x-auto mb-4">
                    <Tab class="mx-2" :name="$t('views.reports.selectSitesPage.farmsTabLabel')" :default="true" @click="onTabClicked(false)">
                        <SiteSelector :state="computedState" :selectable-sites="reportFarms" :batch-tab-is-active="batchTabIsActive"></SiteSelector>
                    </Tab>
                    <Tab class="mx-2" :name="$t('views.reports.selectSitesPage.batchesTabLabel')" @click="onTabClicked(true)">
                        <SiteSelector :state="state" :selectable-sites="reportBatches" :batch-tab-is-active="batchTabIsActive"></SiteSelector>
                    </Tab>
                </nav>
            </Tabs>
            <div id="site-tabs-target" class="flex justify-center max-h-64 h-full" />
        </div>

        <div class="flex justify-center">
            <CustomButton :disabled="disableButtons" class="max-w-32" :class="{ 'cursor-not-allowed': disableNext }" @click="onNextClicked">
                {{ $t('views.reports.nextButtonLabel') }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { Form } from '@makeabledk/vue-ui/support/http';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import SiteSelector from '@/views/dashboard/reports/selectSites/SiteSelector.vue';
import { Batch, DirectoryBatch, Farm, ReportBatch, ReportFarm, ReportViewState } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { MainViewCard, CustomButton, Tab, Tabs, SiteSelector },
    emits: ['next', 'skip', 'update:state'],
    props: {
        state: {
            type: Object as () => ReportViewState,
            required: true,
        },
    },
    data() {
        return {
            batchTabIsActive: false,
            reportBatches: [] as ReportBatch[],
            reportFarms: [] as ReportFarm[],
            disableButtons: false,
        };
    },
    computed: {
        computedState: {
            get(): ReportViewState {
                return this.$props.state;
            },
            set(newValue: ReportViewState) {
                this.$emit('update:state', newValue);
            },
        },
        disableNext() {
            return this.computedState.siteIds.length < this.minNumberOfSelections || this.computedState.siteIds.length > this.maxNumberOfSelections;
        },
        minNumberOfSelections() {
            return 1;
        },
        maxNumberOfSelections() {
            return this.batchTabIsActive ? 4 : Number.MAX_SAFE_INTEGER;
        },
    },
    created() {
        store.dispatch(ActionType.GetReportBatches).then((batches: ReportBatch[]) => {
            this.reportBatches = batches;
        });
        store.dispatch(ActionType.GetReportFarms).then((farms: ReportFarm[]) => {
            this.reportFarms = farms;
        });
    },
    methods: {
        onTabClicked(isBatchTab: boolean) {
            this.batchTabIsActive = isBatchTab;
            Object.assign(this.computedState, { siteIds: [] });
        },
        async onNextClicked() {
            if (this.disableNext || this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            this.computedState.isBatchReport = this.batchTabIsActive;

            if (this.batchTabIsActive) {
                this.computedState.reports = await store.dispatch(ActionType.GetReportData, { form: { site_ids: this.computedState.siteIds } });
                this.$emit('skip');
            } else {
                this.computedState.selectableDates = await store.dispatch(ActionType.GetReportDates, { form: { site_ids: this.computedState.siteIds, fetchBatchDates: this.batchTabIsActive } });
                this.$emit('next');
            }
        },
    },
});
</script>

<style scoped>
.select-site-tabs nav a {
    border-width: 1px;
    border-radius: 0.5rem;
    @apply border-opacity-20;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    font-weight: 600;
}

.select-site-tabs nav a.--active {
    @apply bg-primary-800;
    @apply border-primary-700;
    color: white;
}
</style>
