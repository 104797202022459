<template>
    <div class="inline-block text-left w-full h-full">
        <div class="relative h-full">
            <div :class="slotContainerClass" class="w-full h-full">
                <slot></slot>
            </div>
            <TrapFocus :disabled="!computedExpand">
                <transition
                    enter-active-class="transform transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-0"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-100"
                    leave-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-0"
                    appear
                >
                    <div v-show="computedExpand">
                        <div ref="init-focus" tabindex="0" @keydown.esc="computedExpand = false"></div>
                        <div
                            :class="[dropdownClass, { 'z-40': computedExpand }]"
                            class="origin-top-right right-0 absolute rounded-md shadow-lg bg-white"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            <div>
                                <slot name="dropdown-header"></slot>
                            </div>
                            <div class="divide-y divide-gray-100 overflow-y-auto" :style="dropdownContentStyle">
                                <slot name="dropdown-content"></slot>
                            </div>
                        </div>
                    </div>
                </transition>
            </TrapFocus>
        </div>
        <div v-show="computedExpand" style="background-color: rgba(0, 0, 0, 0.1)" class="cursor-default z-30 fixed top-0 left-0 bottom-0 right-0" @click="computedExpand = false"></div>
        <!-- Fixes a strange bug with the stacking context in Safari when using DropDowns outside of the TopBar -->
        <div
            v-if="topbarOverlay"
            v-show="computedExpand"
            style="background-color: rgba(0, 0, 0, 0.1); z-index: 1041"
            class="cursor-default h-24 fixed top-0 left-0 right-0"
            @click="computedExpand = false"
        ></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TrapFocus from '@makeabledk/vue-ui/components/trap-focus';

export default defineComponent({
    components: { TrapFocus },
    props: {
        expand: {
            type: Boolean,
            default: false,
        },
        dropdownClass: {
            type: String,
            default: '',
        },
        dropdownContentStyle: {
            type: String,
            default: '',
        },
        slotContainerClass: {
            type: String,
            default: '',
        },
        topbarOverlay: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:expand'],
    computed: {
        computedExpand: {
            get(): boolean {
                return this.expand;
            },
            set(newValue: boolean) {
                this.$emit('update:expand', newValue);
            },
        },
    },
    watch: {
        expand(newValue) {
            if (newValue) {
                (this.$refs['init-focus'] as HTMLDivElement).focus();
            }
        },
    },
});
</script>

<style scoped>
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
