<template>
    <div class="h-full min-h-60 w-128 space-y-4">
        <h2 class="text-xl font-semibold text-center">{{ text }}</h2>
        <div class="grid grid-cols-2 gap-y-1 gap-x-2">
            <CustomSelect
                v-model:value="selectedItem"
                item-text="period"
                return-object
                item-value="id"
                :allow-empty="false"
                :label="config.periodFieldLabel"
                :items="config.items"
                class="col-span-2"
                required
                :rules="[{ validate: (value) => Boolean(value), message: '' }]"
            ></CustomSelect>
            <CustomInput
                v-for="numberField of config.numberFields"
                :key="numberField.value"
                v-model:value="form[numberField.value]"
                :class="[numberField.colSpan]"
                :label="numberField.label"
                input-type="number"
                required
                :rules="[{ validate: (value) => typeof value === 'number', message: '' }]"
                :disabled="!selectedItem"
            ></CustomInput>
            <div class="col-span-2">
                <DeletePriceChargeConfirmationModal :config="config.deleteModal" :item="selectedItem" @submit="onPriceDeleted">
                    <template #default="{ open }">
                        <DeleteButton :disabled="!selectedItem || !selectedItem[config.chargeKey]" @click="onDeletePriceChargeClicked(open)">
                            {{ config.deleteButtonLabel }}
                        </DeleteButton>
                    </template>
                </DeletePriceChargeConfirmationModal>
            </div>
        </div>
        <div class="flex space-x-4 self-end mt-4">
            <CustomButton :disabled="disableButtons" :disable="!validForm" @click="onSubmitClicked">
                {{ config.confirmButtonLabel }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import DeletePriceChargeConfirmationModal from '@/components/common/economy/priceCharges/DeletePriceChargeConfirmationModal.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { EconomyPostPayment, EconomyStatusEntry } from '@/types';

export default defineComponent({
    components: { CustomInput, CustomSelect, CustomButton, DeleteButton, DeletePriceChargeConfirmationModal },
    emits: ['submit', 'skip'],
    props: {
        text: {
            type: String,
            default: '',
        },
        config: {
            type: Object as () => {
                text: string;
                numberFields: { value: string; label: string; colSpan: string }[];
                chargeKey: 'charge' | 'entry';
                createActionType: ActionType;
                updateActionType: ActionType;
                fetchActionType: ActionType;
                confirmButtonLabel: string;
                periodFieldLabel: string;
                deleteButtonLabel: string;
                items: (EconomyStatusEntry | EconomyPostPayment)[];
                deleteModal: {
                    header: string;
                    message: string;
                    chargeKey: 'charge' | 'entry';
                    cancelButtonLabel: string;
                    confirmButtonLabel: string;
                    deleteActionType: ActionType;
                    fetchActionType: ActionType;
                };
            },
            required: true,
        },
    },
    data() {
        return {
            form: {
                ...this.config?.numberFields.reduce((accum, current) => {
                    accum[current.value] = '';
                    return accum;
                }, {} as { [key: string]: string }),
            } as { [key: string]: any },
            disableButtons: false,
            selectedItem: null as null | EconomyStatusEntry | EconomyPostPayment,
        };
    },
    computed: {
        validForm(): boolean {
            return Object.values(this.form).every((currentValue) => typeof currentValue === 'number');
        },
    },
    watch: {
        selectedItem(newValue: EconomyStatusEntry | EconomyPostPayment) {
            Object.keys(this.form).forEach((currentKey) => {
                if ((newValue as any)[this.$props.config.chargeKey]) {
                    this.form[currentKey] = ((newValue as any)[this.$props.config.chargeKey] as any)[currentKey];
                }
            });
            if ((newValue as EconomyStatusEntry).site) {
                this.form.site_id = (newValue as EconomyStatusEntry).site.id;
            }
        },
    },
    methods: {
        async onSubmitClicked() {
            if (!this.selectedItem || !this.validForm) {
                return;
            }
            if ((this.selectedItem as any)[this.$props.config.chargeKey]) {
                await store.dispatch(this.$props.config.updateActionType, { form: { itemId: this.selectedItem.id, chargeId: (this.selectedItem as any)[this.$props.config.chargeKey].id, update: this.form } });
            } else {
                await store.dispatch(this.$props.config.createActionType, { form: { id: this.selectedItem.id, update: this.form } });
            }
            store.dispatch(this.$props.config.fetchActionType, { options: { ignoreCache: true } });
            this.$emit('submit');
        },
        onDeletePriceChargeClicked(open: CallableFunction) {
            if (!this.selectedItem) {
                return;
            }
            open();
        },
        onPriceDeleted() {
            this.$emit('skip');
        },
    },
});
</script>
