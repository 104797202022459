import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "max-w-76 w-full h-full" }
const _hoisted_2 = { class: "text-center pb-2 font-semibold" }
const _hoisted_3 = { class: "border-2 h-full overflow-y-auto border-opacity-10 rounded-lg py-2 max-h-80" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "pr-4"
}
const _hoisted_7 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('views.reports.selectDatesPage.selectDatesConditions')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("table", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, null, -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.selectableDates, (date) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: date,
              class: "hover:bg-gray-100 cursor-pointer",
              onClick: ($event: any) => (_ctx.onDateClicked(date))
            }, [
              _createElementVNode("td", {
                class: _normalizeClass([_ctx.state.isBatchReport ? 'text-center px-4' : 'pl-4', "font-medium"])
              }, _toDisplayString(date), 3),
              (!_ctx.state.isBatchReport)
                ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_CustomCheckbox, {
                        checked: _ctx.computedState.dates.includes(date),
                        "onUpdate:checked": ($event: any) => (_ctx.onDateClicked(date))
                      }, null, 8, ["checked", "onUpdate:checked"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_5))
          }), 128))
        ])
      ])
    ])
  ]))
}