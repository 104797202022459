import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white relative pt-4 shadow-md rounded-lg px-2 grid" }
const _hoisted_2 = { class: "absolute top-4 right-4 z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartMultiSelect = _resolveComponent("ChartMultiSelect")!
  const _component_GenericChart = _resolveComponent("GenericChart")!
  const _component_EmptyChartState = _resolveComponent("EmptyChartState")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.multiselectOptions.enabled)
        ? (_openBlock(), _createBlock(_component_ChartMultiSelect, {
            key: 0,
            value: _ctx.selectedMultiselectItems,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMultiselectItems) = $event)),
            items: _ctx.multiselectOptions.items,
            "item-text": "text",
            "item-value": "value",
            onClosing: _ctx.onMultiselectClosing
          }, null, 8, ["value", "items", "onClosing"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_EmptyChartState, {
      options: _ctx.chartOptions,
      class: "w-full grid"
    }, {
      default: _withCtx(() => [
        (_ctx.chartOptions)
          ? (_openBlock(), _createBlock(_component_GenericChart, {
              key: 0,
              options: _ctx.chartOptions,
              "has-options": _ctx.multiselectOptions.enabled
            }, null, 8, ["options", "has-options"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["options"])
  ]))
}