import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "flex mx-6 space-x-6 pt-4" }
const _hoisted_3 = { class: "flex-1 font-medium" }
const _hoisted_4 = { class: "text-lg my-auto" }
const _hoisted_5 = { class: "text-right mr-6 w-80" }
const _hoisted_6 = { class: "w-6 my-auto" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_LocationsTooltip = _resolveComponent("LocationsTooltip")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_EditBudgetEntryModal = _resolveComponent("EditBudgetEntryModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('views.economy.budget.table.header')), 1)
      ]),
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_SearchInput, {
          value: _ctx.search,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          label: _ctx.$t('views.economy.budget.table.searchFieldLabel')
        }, null, 8, ["value", "label"])
      ]),
      (_ctx.economyEvents.length && _ctx.actions.length)
        ? (_openBlock(), _createBlock(_component_ActionModal, {
            key: 0,
            "initial-state": _ctx.createBudgetEntryModal?.initialState,
            header: _ctx.createBudgetEntryModal?.header,
            "initial-step-index": 1,
            onClosing: _ctx.onCreateBudgetEntryModalClosing
          }, {
            default: _withCtx(({ open }) => [
              _createVNode(_component_AddButton, {
                loading: _ctx.isLoading,
                "text-class": "font-medium",
                onClick: ($event: any) => (_ctx.onCreateBudgetEntryClicked(open))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.economy.budget.table.addButtonLabel')), 1)
                ]),
                _: 2
              }, 1032, ["loading", "onClick"])
            ]),
            _: 1
          }, 8, ["initial-state", "header", "onClosing"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_CustomTable, {
      headers: _ctx.headers,
      items: _ctx.filteredItems
    }, {
      header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("th", { class: "w-6" }, null, -1)
      ])),
      is_income: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
        (_openBlock(), _createElementBlock("td", {
          key: header.value,
          class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`),
          style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.getStyledType(item)), 1)
        ], 6))
      ]),
      locations: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
        (_openBlock(), _createElementBlock("td", {
          key: header.value,
          class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words flex space-x-2 cursor-pointer`),
          style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
        }, [
          _createVNode(_component_LocationsTooltip, {
            locations: item.locations,
            "number-of-locations-replace-text": "$NUMBER_OF_LOCATIONS",
            "number-of-locations-text": _ctx.$t('views.economy.budget.table.locationsTooltip.tableText'),
            "tooltip-header": _ctx.$t('views.economy.budget.table.locationsTooltip.header')
          }, null, 8, ["locations", "number-of-locations-text", "tooltip-header"])
        ], 6))
      ]),
      prices: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
        (_openBlock(), _createElementBlock("td", {
          key: header.value,
          class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`),
          style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.getStyledPrice(item)), 1)
        ], 6))
      ]),
      actions: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_6, [
          _createVNode(_component_EditBudgetEntryModal, { entry: item }, {
            default: _withCtx(({ open }) => [
              _createElementVNode("span", _hoisted_7, [
                _createElementVNode("button", {
                  type: "button",
                  class: "p-2 -mr-2",
                  onClick: open
                }, [
                  _createVNode(_component_Icon, {
                    class: "h-3 w-3 cursor-pointer",
                    src: _ctx.IconSource.Right
                  }, null, 8, ["src"])
                ], 8, _hoisted_8)
              ])
            ]),
            _: 2
          }, 1032, ["entry"])
        ])
      ]),
      _: 1
    }, 8, ["headers", "items"])
  ]))
}