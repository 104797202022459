import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-1 py-3.5 border-b border-b-gray-100" }
const _hoisted_2 = { class: "flex items-center gap-x-3.5" }
const _hoisted_3 = { class: "font-medium text-lg" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500"
}

import { computed } from 'vue';
import { FormElement } from '@/types';
import DataQualityStatusBarTooltip from './dataQuality/DataQualityStatusBarTooltip.vue';

interface Props {
    element: FormElement;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DynamicFormQualityDescriptor',
  props: {
    element: {}
  },
  setup(__props: any) {

const props = __props;

const qualityAssessmentId = computed(() => {
    const value = props.element.type_settings?.values?.[0]?.value;

    return value ? parseInt(value) : undefined;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(DataQualityStatusBarTooltip, { "quality-assessment-id": qualityAssessmentId.value }, null, 8, ["quality-assessment-id"]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.element.label), 1)
    ]),
    (_ctx.element.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.element.value), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})