<template>
    <div class="flex space-x-4">
        <AddGlobalPriceModal :step-config="addGlobalPriceStepConfig">
            <template #default="scope">
                <AddButton text-class="font-medium" @click="scope.open">
                    {{ $t('views.economy.postPayments.modals.addGlobalPrice.buttonLabel') }}
                </AddButton>
            </template>
        </AddGlobalPriceModal>
        <EditGlobalPriceModal :step-config="editGlobalPriceStepConfig">
            <template #default="scope">
                <AddButton text-class="font-medium" @click="scope.open">
                    {{ $t('views.economy.postPayments.modals.editGlobalPrice.buttonLabel') }}
                </AddButton>
            </template>
        </EditGlobalPriceModal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import AddGlobalPriceModal from '@/components/common/economy/globalPrices/AddGlobalPriceModal.vue';
import EditGlobalPriceModal from '@/components/common/economy/globalPrices/EditGlobalPriceModal.vue';
import AddButton from '@/components/ui/AddButton.vue';
import { ActionType } from '@/plugins/store/actions';
import { EconomyPostPayment } from '@/types';

export default defineComponent({
    components: { AddGlobalPriceModal, EditGlobalPriceModal, AddButton },
    props: {
        postPayments: {
            type: Array as () => EconomyPostPayment[],
            default: [],
        },
    },
    computed: {
        addGlobalPriceStepConfig() {
            const MIN_YEAR_OPTION = -2;
            const MAX_YEAR_OPTION = 10;
            const SEASON_STARTS = { month: 9, day: 1 };
            const SEASON_ENDS = { month: 8, day: 30 };
            const currentYear = new Date().getFullYear();
            const alreadyTakenYears = Object.keys(
                this.$props.postPayments.reduce((accum, current) => {
                    accum[`${new Date(current.from).getFullYear()}`] = current;
                    return accum;
                }, {} as { [key: string]: EconomyPostPayment })
            );
            const pickableYears = [];
            for (let i = MIN_YEAR_OPTION; i <= MAX_YEAR_OPTION; i++) {
                const year = currentYear + i;
                if (!alreadyTakenYears.includes(`${year}`)) {
                    pickableYears.push(currentYear + i);
                }
            }

            const items = pickableYears.map((currentPickableYear) => {
                const from = new Date(currentPickableYear, SEASON_STARTS.month, SEASON_STARTS.day);
                const to = new Date(currentPickableYear + 1, SEASON_ENDS.month, SEASON_ENDS.day);
                return { text: `${this.$d(from, 'middle')} - ${this.$d(to, 'middle')}`, period: `${from.toISOString()} - ${to.toISOString()}` };
            });

            return [
                {
                    text: this.$t('views.economy.postPayments.modals.addGlobalPrice.header'),
                    numberFields: [
                        { value: 'sl_pig_price', label: this.$t('views.economy.postPayments.modals.addGlobalPrice.fatteningPigsFieldLabel'), colSpan: 'col-span-2' },
                        { value: 'res_org_price', label: this.$t('views.economy.postPayments.modals.addGlobalPrice.organicPigsFieldLabel'), colSpan: 'col-span-2' },
                        { value: 'res_free_range_price', label: this.$t('views.economy.postPayments.modals.addGlobalPrice.freeRangePigsFieldLabel'), colSpan: 'col-span-2' },
                    ],
                    transformPeriod: (period: { period: string; text: string }) => {
                        const periodConstituents = period.period.split(' - ');
                        return { from: format(new Date(periodConstituents[0]), 'yyyy-MM-dd'), to: format(new Date(periodConstituents[1]), 'yyyy-MM-dd') };
                    },
                    submitActionType: ActionType.CreateGlobalPostPayment,
                    fetchActionType: ActionType.GetEconomyPostPayments,
                    yearPicker: { items },
                    confirmButtonLabel: this.$t('views.economy.postPayments.modals.addGlobalPrice.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.postPayments.modals.addGlobalPrice.periodFieldLabel'),
                },
                { text: this.$t('views.economy.postPayments.modals.addGlobalPrice.successMessage') },
            ];
        },
        editGlobalPriceStepConfig() {
            return [
                {
                    text: this.$t('views.economy.postPayments.modals.editGlobalPrice.header'),
                    numberFields: [
                        { value: 'sl_pig_price', label: this.$t('views.economy.postPayments.modals.editGlobalPrice.fatteningPigsFieldLabel'), colSpan: 'col-span-2' },
                        { value: 'res_org_price', label: this.$t('views.economy.postPayments.modals.editGlobalPrice.organicPigsFieldLabel'), colSpan: 'col-span-2' },
                        { value: 'res_free_range_price', label: this.$t('views.economy.postPayments.modals.editGlobalPrice.freeRangePigsFieldLabel'), colSpan: 'col-span-2' },
                    ],
                    items: this.$props.postPayments,
                    fetchActionType: ActionType.GetEconomyPostPayments,
                    confirmButtonLabel: this.$t('views.economy.postPayments.modals.editGlobalPrice.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.postPayments.modals.editGlobalPrice.periodFieldLabel'),
                    updateActionType: ActionType.EditGlobalPostPayment,
                    deleteButtonLabel: this.$t('views.economy.postPayments.modals.deleteGlobalPriceConfirmationModal.deleteButtonLabel'),
                    deleteModal: {
                        header: this.$t('views.economy.postPayments.modals.deleteGlobalPriceConfirmationModal.header'),
                        message: this.$t('views.economy.postPayments.modals.deleteGlobalPriceConfirmationModal.message'),
                        cancelButtonLabel: this.$t('views.economy.postPayments.modals.deleteGlobalPriceConfirmationModal.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('views.economy.postPayments.modals.deleteGlobalPriceConfirmationModal.confirmButtonLabel'),
                        deleteActionType: ActionType.DeleteGlobalPostPayment,
                        fetchActionType: ActionType.GetEconomyPostPayments,
                    },
                },
                { text: this.$t('views.economy.postPayments.modals.editGlobalPrice.successMessage') },
                { text: this.$t('views.economy.postPayments.modals.deleteGlobalPriceConfirmationModal.successMessage') },
            ];
        },
    },
});
</script>
