<template>
    <GenericTable :table-data="tableData"></GenericTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import GenericTable from '@/components/common/GenericTable.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import { TableHeader, GenericTableOptions } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { CustomTable, GenericTable, SearchInput },
    props: {
        source: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            tableData: null as null | GenericTableOptions,
        };
    },
    computed: {
        selectedSiteIds(): number[] {
            return store.getters.getSelectedSiteIds;
        },
        selectedPeriod(): string {
            return store.getters.getSelectedPeriod;
        },
    },
    watch: {
        selectedSiteIds: {
            immediate: false,
            handler(newValue) {
                this.fetchData();
            },
        },
        selectedPeriod: {
            immediate: true,
            handler(newValue) {
                if (this.selectedSiteIds.length) {
                    this.fetchData();
                }
            },
        },
    },
    methods: {
        fetchData() {
            store.dispatch(ActionType.GetTable, { form: { url: this.$props.source } }).then((tableOptions: GenericTableOptions) => {
                this.tableData = tableOptions;
            });
        },
    },
});
</script>
