<template>
    <DropdownMenuItem class="gap-x-2.5 text-base" @click="open">
        <Icon v-if="item.icon_id !== null && item.icon_id !== undefined" :src="item.icon_id" class="flex-shrink-0 size-5" />
        {{ item.text }}
    </DropdownMenuItem>
</template>

<script lang="ts" setup>
import { HelpWidgetVideoItem } from '@/types/HelpWidget';
import Icon from '@/components/icons/Icon.vue';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';

interface Props {
    item: HelpWidgetVideoItem;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    (e: 'select-video', item: HelpWidgetVideoItem): void;
}>();

function open() {
    emit('select-video', props.item);
}
</script>
