<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.health.overview.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-8">
            <HealthOverviewTabs></HealthOverviewTabs>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import GenericTableWithApi from '@/components/common/GenericTableWithApi.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import HealthOverviewTabs from './HealthOverviewTabs.vue';

export default defineComponent({
    components: { DashboardContentLayout, HealthOverviewTabs, MainViewCard, GenericTableWithApi, GenericChartWithApi },
    data() {
        return {};
    },
});
</script>
