import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = {
  style: {"grid-template-columns":"repeat(auto-fit, minmax(280px, 1fr))"},
  class: "grid gap-8"
}
const _hoisted_3 = { class: "w-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableKpiType = _resolveComponent("EditableKpiType")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpiTypes, (kpiType) => {
        return (_openBlock(), _createBlock(_component_EditableKpiType, {
          key: kpiType.id,
          selected: kpiType.selected,
          "kpi-type": kpiType,
          onClick: ($event: any) => (_ctx.kpiTypeClicked(kpiType))
        }, null, 8, ["selected", "kpi-type", "onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        onClick: _ctx.updateKpiPreferences
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.kpis.content.editButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}