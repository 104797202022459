<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.feed.mixes.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <MixTable></MixTable>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MixTable from '@/views/dashboard/feed/feedMixes/MixTable.vue';

export default defineComponent({
    components: { DashboardContentLayout, MixTable },
});
</script>
