import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative w-full overflow-auto" }

import type { HTMLAttributes } from 'vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
    class: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", {
      class: _normalizeClass(_unref(cn)('w-full caption-bottom text-normal', props.class))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}
}

})