<template>
    <div class="space-y-8">
        <h2 class="text-xl font-semibold text-center outline-none">{{ $t('views.feed.mixes.newMixModal.mixProperties.header') }}</h2>
        <div class="h-full">
            <CustomInput
                v-model:value="computedValue.name"
                required
                :rules="[]"
                :error-state="!computedValue.name || computedValue.name.length === 0"
                show-required-indication
                :label="$t('views.feed.mixes.newMixModal.mixProperties.nameFieldLabel')"
                focus-on-render
            ></CustomInput>
            <CustomSelect
                v-model:value="computedValue.type"
                :prepend="$t('views.feed.mixes.newMixModal.mixProperties.selectFieldLabel')"
                label=""
                return-object
                :items="mixTypes"
                item-text="name"
                item-value="id"
                :show-required-indication="false"
                :allow-empty="false"
            ></CustomSelect>
            <CustomMultiSelect
                v-model:value="computedValue.farms"
                :prepend="$t('views.feed.mixes.newMixModal.mixProperties.farmsFieldLabel')"
                label=""
                :items="farms"
                item-text="name"
                item-value="id"
                :show-required-indication="false"
            ></CustomMultiSelect>
            <CustomButton :disabled="disableButtons" :disable="!Boolean(computedValue.type && computedValue.name.length)" @click="onSubmitButtonClicked">
                {{ $t('views.feed.mixes.newMixModal.mixProperties.confirmButtonLabel') }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import Focus from '@makeabledk/vue-ui/directives/focus/src/Focus';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import { MixForm, MixType, Farm } from '@/types';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { CustomButton, CustomSelect, CustomMultiSelect, CustomInput },
    directives: { Focus },
    props: {
        value: {
            type: Object as () => Form<MixForm> & MixForm,
            required: true,
        },
    },
    data() {
        return { disableButtons: false };
    },
    computed: {
        computedValue: {
            get(): Form<MixForm> & MixForm {
                return this.value;
            },
            set(newValue: any) {
                this.$emit('update:value', newValue);
            },
        },
        mixTypes(): MixType[] {
            return store.state.mixTypes;
        },
        farms(): { id: number; name: string }[] {
            return store.state.farms.map((farm) => ({ name: farm.name, id: farm.farm_id }));
        },
    },
    methods: {
        onSubmitButtonClicked() {
            if (this.computedValue.name && this.computedValue.type && !this.disableButtons) {
                this.disableButtons = true;
                this.$emit('submit');
            }
        },
    },
});
</script>
