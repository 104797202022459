import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatPickr = _resolveComponent("FlatPickr")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.wrapperClass, { 'flatpickr-wrapper-inline': _ctx.inline }], "lg:pt-0 overflow-hidden h-full"])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.dashboardLoaded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{ 'auto-width': _ctx.autoWidth }, { 'h-0': !_ctx.inline }])
        }, [
          _createVNode(_component_FlatPickr, {
            ref: "datePicker",
            modelValue: _ctx.computedValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
            class: "w-0 h-0 outline-none relative",
            config: _ctx.resolvedConfig,
            onOnOpen: _cache[1] || (_cache[1] = ($event: any) => (_ctx.computedShow = true)),
            onOnClose: _ctx.onClose,
            onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(() => {}, ["stop"]), ["esc"]))
          }, null, 8, ["modelValue", "config", "onOnClose"])
        ], 2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      style: {"background-color":"rgba(0, 0, 0, 0.1)"},
      class: "cursor-default w-full fixed h-full top-24 left-0 bottom-0 right-0 z-50",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
    }, null, 512), [
      [_vShow, _ctx.computedShow && !_ctx.inline]
    ]),
    _withDirectives(_createElementVNode("div", {
      style: {"background-color":"rgba(0, 0, 0, 0.1)","z-index":"1041"},
      class: "cursor-default h-24 fixed top-0 left-0 right-0",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
    }, null, 512), [
      [_vShow, _ctx.computedShow && !_ctx.inline]
    ])
  ], 2))
}