<template>
    <div class="space-y-6 min-h-40">
        <h2 v-if="config.headerText || state.event?.name" class="text-xl font-semibold text-center">
            {{ config.headerText || state.event?.name || '' }}
        </h2>
        <Spinner class="w-16 h-16 mx-auto" active></Spinner>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, PropType } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import Spinner from '@/components/ui/Spinner.vue';
import { Action, MultiStepFormState, DirectoryFarm, DirectoryBarn, Directory } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { Spinner },
    emits: ['update:state', 'submit'],
    props: {
        state: {
            type: Object as PropType<Form<MultiStepFormState> & MultiStepFormState>,
            required: true,
        },
        config: {
            type: Object as PropType<{ headerText?: string; directory?: Directory }>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const computedState = computed({
            get() {
                return props.state;
            },
            set(newValue: Form<MultiStepFormState>) {
                emit('update:state', newValue);
            },
        });
        const activeAction = computed(() => store.state.actions.find((currentAction) => currentAction.id === props.state.event?.action_id));
        const activeFormId = computed(() => props.state.form_id);

        async function getForm(formId: number) {
            const form = await store.dispatch(ActionType.GetForm, {
                form: {
                    form_id: formId,
                    farm_id: (props.state.farm as DirectoryFarm | undefined)?.id,
                    barn_id: (props.state.location as DirectoryBarn | undefined)?.id,
                    section_id: props.state.section?.id,
                    batch_id: props.state.batch_id,
                    directory_id: props.config.directory?.id,
                },
            });

            if (form) {
                Object.assign(computedState.value, { form });

                emit('submit');
            }
        }

        watch(
            activeAction,
            async (newValue: Action | undefined) => {
                if (newValue?.form_id) {
                    getForm(newValue.form_id);
                }
            },
            { immediate: true, deep: true }
        );

        watch(
            activeFormId,
            async (newValue: number | undefined) => {
                if (newValue !== undefined && !activeAction.value) {
                    getForm(newValue);
                }
            },
            { immediate: true }
        );

        return {
            computedState,
            activeAction,
        };
    },
});
</script>
