<script setup lang="ts">
import { CirclePlus, RefreshCw } from 'lucide-vue-next';
import { ref, computed, watch } from 'vue';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuItem, DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';

type AcceptableValue = string[] | undefined;

interface Props {
    title: string;
    options: any[];
    itemValue: string;
    itemLabel: string;
    /* Does only update the model when the dropdown is closed */
    updateOnlyOnClose?: boolean;
    modelValue?: string[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
    'update:modelValue': [value: AcceptableValue];
}>();

const internalSelectedOptions = ref<AcceptableValue>(props.modelValue || []);
const selectedOptions = computed<string[]>({
    get() {
        return (props.updateOnlyOnClose ? internalSelectedOptions.value : props.modelValue) || [];
    },
    set(value: AcceptableValue) {
        if (props.updateOnlyOnClose) {
            internalSelectedOptions.value = value;
        } else {
            emit('update:modelValue', value);
        }
    },
});
const selectedOptionsWithLabel = computed(() => selectedOptions.value.map((value) => ({ value, label: props.options.find((option) => option[props.itemValue] === value)?.[props.itemLabel] })));

const isOpen = ref(false);

watch(
    () => props.modelValue,
    (value) => {
        internalSelectedOptions.value = value || [];
    }
);

function clearFilters() {
    selectedOptions.value = [];
}

function handleSelect(value: any) {
    if (selectedOptions.value.includes(value)) {
        selectedOptions.value = selectedOptions.value.filter((v) => v !== value);
    } else {
        selectedOptions.value = [...selectedOptions.value, value];
    }
}

function handleUpdateOpen(open: boolean) {
    if (open) {
        internalSelectedOptions.value = selectedOptions.value;
    } else {
        emit('update:modelValue', internalSelectedOptions.value);
    }
}
</script>

<template>
    <DropdownMenu v-model:open="isOpen" :modal="false" @update:open="handleUpdateOpen">
        <DropdownMenuTrigger as-child>
            <Button variant="outline" class="border-dashed gap-x-0">
                <CirclePlus class="size-4 mr-2" aria-hidden="true" />
                {{ title }}
                <template v-if="selectedOptions.length > 0">
                    <Separator orientation="vertical" class="h-5 mx-2" />
                    <div className="flex space-x-1">
                        <Badge v-if="selectedOptions.length > 1" key="selected-count" variant="secondary" class="rounded-md px-1.5 py-1 font-normal">
                            {{ selectedOptions.length }} {{ $t('global.uiElements.dataTable.filter.texts.selected') }}
                        </Badge>
                        <Badge v-for="(item, itemIndex) in selectedOptionsWithLabel" v-else :key="itemIndex" variant="secondary" class="rounded-md px-1.5 py-1 font-normal">{{ item.label }}</Badge>
                    </div>
                </template>
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent class="min-w-44 max-w-full" align="start">
            <DropdownMenuCheckboxItem v-for="item in options" :key="item[itemValue]" :checked="selectedOptions.includes(item[itemValue])" @select.prevent="handleSelect(item[itemValue])">
                <slot name="item" :item="item">
                    {{ item[itemLabel] }}
                </slot>
            </DropdownMenuCheckboxItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem :disabled="selectedOptions.length === 0" class="justify-center text-center" @select.prevent="clearFilters">
                <RefreshCw class="size-4 mr-2" aria-hidden="true" />
                {{ $t('global.uiElements.dataTable.filter.buttons.clear') }}
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
