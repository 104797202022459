<template>
    <div>
        <div class="h-52">
            <div v-if="isLoading" class="flex items-center justify-center w-full h-52">
                <Spinner class="h-10 w-10"></Spinner>
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="formattedImages.length" class="flex space-x-2 h-52 overflow-x-auto">
                    <div v-for="(image, index) in formattedImages" :key="image.id" class="flex-shrink-0 h-full max-w-72 overflow-hidden rounded-lg p-2 border border-gray-200">
                        <img :src="image.src" class="cursor-pointer hover:opacity-80 transition-opacity w-full h-full object-contain" :alt="`Image ${image.title}`" @click="showImage(index)" />
                    </div>
                </div>
            </transition>
        </div>
        <vue-easy-lightbox :visible="showLightbox" :imgs="formattedImages" :index="activeImageIndex" @hide="showLightbox = false"></vue-easy-lightbox>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import { FormElement } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import Spinner from '@/components/ui/Spinner.vue';

export default defineComponent({
    components: {
        Spinner,
        VueEasyLightbox,
    },
    emit: ['update:element'],
    props: {
        element: {
            type: Object as () => FormElement,
            required: true,
        },
        assignmentId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showLightbox: false,
            activeImageIndex: 0,
            isLoading: false,
        };
    },
    computed: {
        formattedImages() {
            return (
                this.$props.element.retrieved_images?.map((image, index) => ({
                    id: image.id,
                    title: index + 1,
                    src: `data:image/jpeg;base64,${image}`,
                })) || []
            );
        },
    },
    created() {
        if (!this.$props.element.retrieved_images) {
            this.getImages();
        }
    },
    methods: {
        async showImage(index: number) {
            this.activeImageIndex = index;
            this.showLightbox = true;
        },
        async getImages() {
            try {
                this.isLoading = true;

                const images = await Promise.all(
                    this.$props.element.type_settings.values.map((currentImage: { id: string; value: string }) => store.dispatch(ActionType.GetImages, { form: { url: currentImage.value } }))
                );

                this.$emit('update:element', {
                    ...this.$props.element,
                    retrieved_images: images,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
});
</script>
