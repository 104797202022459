<template>
    <a :class="{ 'pointer-events-none text-opacity-50': disabled }" class="bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3 inline-flex space-x-2" target="_blank" :href="element.value">
        <span>{{ element.label }}</span>
        <span class="flex">
            <Icon :class="{ 'opacity-50': disabled }" class="-rotate-90 transform my-auto" :src="IconSource.DropdownPrimary"></Icon>
        </span>
    </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FormElement } from '@/types';

export default defineComponent({
    components: {},
    emits: ['clone-section', 'backend-retrieval'],
    props: {
        element: {
            type: Object as () => FormElement,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
