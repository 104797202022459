import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "w-120 table-headers-medium" }
const _hoisted_4 = { class: "px-6 flex mt-2 rounded text-dark-gray-800 text-opacity-50" }
const _hoisted_5 = { class: "flex flex-1" }
const _hoisted_6 = { class: "mx-auto" }
const _hoisted_7 = { class: "flex flex-1" }
const _hoisted_8 = { class: "mx-auto" }
const _hoisted_9 = { class: "rounded flex py-4 px-6 my-4 bg-feed-600 bg-opacity-60 border-2 border-opacity-10 rounded-lg" }
const _hoisted_10 = { class: "flex flex-1 my-auto break-words" }
const _hoisted_11 = { class: "mx-auto" }
const _hoisted_12 = { class: "flex flex-1 my-auto break-words" }
const _hoisted_13 = { class: "mx-auto w-32" }
const _hoisted_14 = { class: "flex w-12 my-auto" }
const _hoisted_15 = { class: "flex flex-1 my-auto break-words" }
const _hoisted_16 = { class: "mx-auto" }
const _hoisted_17 = { class: "flex flex-1 my-auto break-words" }
const _hoisted_18 = { class: "mx-auto" }
const _hoisted_19 = { class: "flex w-12 my-auto" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleInput = _resolveComponent("SimpleInput")!
  const _component_FeedDatePicker = _resolveComponent("FeedDatePicker")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DeleteButton = _resolveComponent("DeleteButton")!
  const _component_DeleteBudgetEntryConfirmationModal = _resolveComponent("DeleteBudgetEntryConfirmationModal")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.economy.budget.editBudgetEntryModal.header')), 1),
    _createElementVNode("div", null, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_4, [
            _createElementVNode("th", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('views.economy.budget.editBudgetEntryModal.table.headers.price')), 1)
            ]),
            _createElementVNode("th", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('views.economy.budget.editBudgetEntryModal.table.headers.validFrom')), 1)
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("th", { class: "flex w-12" }, null, -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", _hoisted_9, [
            _createElementVNode("td", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_SimpleInput, {
                  value: _ctx.priceToAdd.price,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.priceToAdd.price) = $event)),
                  class: "w-20",
                  type: "number",
                  placeholder: _ctx.$t('views.economy.budget.editBudgetEntryModal.table.newPricePlaceholder')
                }, null, 8, ["value", "placeholder"])
              ])
            ]),
            _createElementVNode("td", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, [
                _createVNode(_component_FeedDatePicker, {
                  value: _ctx.priceToAdd.valid_from,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.priceToAdd.valid_from) = $event)),
                  placeholder: _ctx.$t('views.economy.budget.editBudgetEntryModal.table.newValidFromPlaceholder'),
                  disabled: false
                }, null, 8, ["value", "placeholder"])
              ])
            ]),
            _createElementVNode("td", _hoisted_14, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass([{ 'opacity-25 cursor-default': !_ctx.validPriceToAdd, 'bg-primary-800 text-white rounded font-medium': _ctx.validPriceToAdd }, "mx-auto px-2 py-1"]),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onAddPriceClicked && _ctx.onAddPriceClicked(...args)))
              }, _toDisplayString(_ctx.$t('views.economy.budget.editBudgetEntryModal.table.addPriceButtonLabel')), 3)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prices, (price, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: "rounded flex py-4 px-6 my-4 odd:bg-dark-gray-100 border-2 border-opacity-10 rounded-lg"
            }, [
              _createElementVNode("td", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$n(price.price, 'price')), 1)
              ]),
              _createElementVNode("td", _hoisted_17, [
                _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$d(price.valid_from, 'short')), 1)
              ]),
              _createElementVNode("td", _hoisted_19, [
                (_ctx.entry.prices.length > 1)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "mx-auto",
                      onClick: ($event: any) => (_ctx.onDeletePriceClicked(price))
                    }, [
                      _createVNode(_component_Icon, {
                        class: "w-6",
                        src: _ctx.IconSource.Delete
                      }, null, 8, ["src"])
                    ], 8, _hoisted_20))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createVNode(_component_DeleteBudgetEntryConfirmationModal, {
        entry: _ctx.entry,
        onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('submit')))
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_DeleteButton, {
            disabled: _ctx.isLoading,
            onClick: scope.open
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.economy.budget.deleteBudgetEntryModal.deleteButtonLabel')), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["entry"]),
      _createVNode(_component_ActionModal, {
        header: _ctx.editBudgetEntryModal?.header,
        "initial-step-index": 1,
        "initial-state": _ctx.editBudgetEntryModal?.initialState,
        onClosing: _ctx.onEditBudgetEntryModalClosing
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_AddButton, {
            loading: _ctx.isLoading,
            onClick: ($event: any) => (_ctx.onEditEntryClicked(scope.open))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.economy.budget.editBudgetEntryModal.editFormButtonLabel')), 1)
            ]),
            _: 2
          }, 1032, ["loading", "onClick"])
        ]),
        _: 1
      }, 8, ["header", "initial-state", "onClosing"])
    ])
  ]))
}