import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["onMousedown"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchInput, {
      ref: "input-field",
      value: _ctx.computedSearch,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedSearch) = $event)),
      label: _ctx.label,
      "prevent-esc-propagation": "",
      onSubmit: _ctx.onSearchSubmit,
      onOnEscape: _ctx.onEscape,
      onOnTab: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandOptions = false)),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.expandOptions = true))
    }, null, 8, ["value", "label", "onSubmit", "onOnEscape"]),
    _createVNode(_Transition, {
      "enter-active-class": "transition duration-200 ease-out",
      "enter-from-class": "transform scale-95 opacity-0",
      "enter-to-class": "transform scale-100 opacity-100",
      "leave-active-class": "transition duration-100 ease-out",
      "leave-from-class": "transform scale-100 opacity-100",
      "leave-to-class": "transform scale-95 opacity-0"
    }, {
      default: _withCtx(() => [
        (_ctx.expandOptions)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([[_ctx.dropdownHeightClass], "absolute top-full left-0 w-full bg-white border z-30 rounded overflow-y-auto"])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item[_ctx.itemValue],
                  class: "h-full hover:bg-gray-100 cursor-pointer",
                  onMousedown: ($event: any) => (_ctx.onItemClicked(item))
                }, [
                  _createElementVNode("span", {
                    class: "p-3 h-full w-full inline-block",
                    innerHTML: _ctx.getBoldenedString(item[_ctx.itemText])
                  }, null, 8, _hoisted_3)
                ], 40, _hoisted_2))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_click_outside, () => (_ctx.expandOptions = false)]
  ])
}