import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between w-full space-x-2 text-[0.8125rem] mb-2" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "font-medium" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["id", "value", "disabled", "readonly", "min", "max", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col items-center w-full", { 'opacity-60': _ctx.disabled }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: "text-gray-400"
      }, _toDisplayString(_ctx.label), 9, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.value) + " ", 1),
        (_ctx.unit)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.unit), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("input", {
      id: _ctx.id,
      value: _ctx.value,
      class: "transparent h-2 w-full rounded-lg cursor-pointer appearance-none border-transparent accent-primary-800 bg-gray-100",
      type: "range",
      disabled: _ctx.disabled,
      readonly: _ctx.readonly,
      min: _ctx.min,
      max: _ctx.max,
      step: _ctx.step,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_5),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["min-h-4 flex text-xs text-left w-full ml-1", { 'text-red-600': _ctx.hint?.type === 'error', 'text-gray-500': _ctx.hint?.type === 'hint' }])
    }, _toDisplayString(_ctx.hint?.text || ''), 3), [
      [_vShow, _ctx.hint.text]
    ])
  ], 2))
}