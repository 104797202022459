import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import type { HTMLAttributes } from 'vue';
import { cn } from '@/lib/utils';

interface SkeletonProps {
    class?: HTMLAttributes['class'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Skeleton',
  props: {
    class: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(cn)('animate-pulse rounded-md bg-gray-100 max-w-full', props.class))
  }, null, 2))
}
}

})