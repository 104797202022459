import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-gray-100 py-6 px-8 space-y-4 rounded-xl" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "font-semibold text-xl" }
const _hoisted_4 = { class: "grid gap-y-4 grid-cols-1" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "flex h-10" }
const _hoisted_8 = { class: "my-auto font-semibold" }
const _hoisted_9 = { class: "flex flex-1 justify-end h-10 w-8" }
const _hoisted_10 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "m-auto border-0",
    "mask-class": "grid",
    onClosing: _ctx.onModalClose
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", { scope: scope })
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.header), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.subHeader), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (currentType) => {
            return (_openBlock(), _createElementBlock("button", {
              key: currentType.id,
              type: "button",
              class: "bg-white shadow rounded-2xl p-4 px-4 space-x-4 flex",
              onClick: ($event: any) => (_ctx.onTypeClicked(currentType))
            }, [
              _createElementVNode("div", _hoisted_6, [
                (currentType.icon_id)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 0,
                      src: currentType.icon_id,
                      class: "my-auto w-10 h-10"
                    }, null, 8, ["src"]))
                  : (_openBlock(), _createBlock(_component_Icon, {
                      key: 1,
                      class: "my-auto w-10 h-10",
                      src: currentType.icon_url
                    }, null, 8, ["src"]))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(currentType.name), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createVNode(_component_Icon, {
                  class: "my-auto ml-auto h-8",
                  src: _ctx.IconSource.FormSuccess
                }, null, 8, ["src"]), [
                  [_vShow, _ctx.selectedIds.includes(currentType.id)]
                ])
              ])
            ], 8, _hoisted_5))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            type: "button",
            class: "underline font-medium text-primary-800 text-sm pr-2",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onToggleAllClicked && _ctx.onToggleAllClicked(...args)))
          }, _toDisplayString(_ctx.allSelected ? _ctx.$t('global.filterModal.deselectAllButonLabel') : _ctx.$t('global.filterModal.selectAllButtonLabel')), 1)
        ])
      ])
    ]),
    _: 3
  }, 8, ["onClosing"]))
}