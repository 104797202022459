import { computed, ref, watch } from 'vue';
import { Barn, DirectoryBarn, DirectoryFarm, Section, SettingEvent, SettingEventType } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default function useLocationSettingEvents(
    createState: { event: null | SettingEvent; form: null; confirmed: false; farm?: DirectoryFarm | null; location?: DirectoryBarn | Barn | null },
    editState: { event: null | SettingEvent; form: null; confirmed: false; farm?: DirectoryFarm | null; location?: DirectoryBarn | Barn | null; section?: Section | null },
    createSectionsEventType: SettingEventType,
    editSectionsEventType: SettingEventType,
    refetchActionType: ActionType
) {
    const createInitialState = ref(createState);
    const editInitialState = ref(editState);

    const createSettingsEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === createSectionsEventType));
    const editSettingsEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === editSectionsEventType));

    watch(
        createSettingsEvent,
        (newCreateSettingsEvent) => {
            if (newCreateSettingsEvent) {
                createInitialState.value.event = newCreateSettingsEvent;
            }
        },
        { immediate: true }
    );
    watch(
        editSettingsEvent,
        (newEditSettingsEvent) => {
            if (newEditSettingsEvent) {
                editInitialState.value.event = newEditSettingsEvent;
            }
        },
        { immediate: true }
    );

    const onModalClosing = (isFinalStep: boolean) => {
        if (isFinalStep) {
            store.dispatch(refetchActionType, { options: { ignoreCache: true } });
        }
    };

    const putFormActionType = ActionType.PutForm;

    return { createInitialState, editInitialState, createSettingsEvent, editSettingsEvent, onModalClosing, putFormActionType };
}
