<template>
    <button
        type="button"
        class="custom-button"
        :class="[
            {
                'custom-button--disabled': disabled,
                'custom-button--loading': loading,
                'custom-button--primary': colorPreset === 'primary',
                'custom-button--secondary': colorPreset === 'secondary',
                'custom-button--warning': colorPreset === 'warning',
                'custom-button--white': colorPreset === 'white',
            },
        ]"
        :disabled="disabled || loading"
        @click="handleClick"
    >
        <slot></slot>
        <div v-if="loading" class="absolute rounded-lg inset-0 flex justify-center items-center bg-gray-300" aria-hidden="true">
            <Spinner class="h-6 w-6 mx-auto"></Spinner>
        </div>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Spinner from '@/components/ui/Spinner.vue';

type colorPreset = 'primary' | 'secondary' | 'warning' | 'white';

export default defineComponent({
    components: { Spinner },
    emits: ['click'],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        colorPreset: {
            type: String as () => colorPreset,
            default: 'primary',
        },
    },
    setup(props, { emit }) {
        function handleClick(event: MouseEvent) {
            if (props.disabled || props.loading) {
                event.preventDefault();

                return;
            }

            emit('click', event);
        }

        return {
            handleClick,
        };
    },
});
</script>

<style>
.custom-button {
    @apply relative font-semibold py-4 rounded-lg w-full transition-colors duration-300;
}

.custom-button--disabled {
    @apply cursor-default bg-gray-300 text-gray-500;
}

.custom-button--loading {
    @apply cursor-default;
}

.custom-button--primary:not(.custom-button--disabled) {
    @apply bg-primary-800 hover:bg-opacity-70 text-white;
}

.custom-button--secondary:not(.custom-button--disabled) {
    @apply bg-primary-500 hover:bg-opacity-50 bg-opacity-80 text-primary-800;
}

.custom-button--warning:not(.custom-button--disabled) {
    @apply bg-red-400 bg-opacity-25 hover:bg-opacity-35 text-red-400;
}

.custom-button--white:not(.custom-button--disabled) {
    @apply bg-white hover:bg-gray-100 hover:bg-opacity-80 text-gray-600;
}
</style>
