<template>
    <TooltipProvider v-if="qualityAssessmentCategory" :delay-duration="200">
        <Tooltip>
            <TooltipTrigger as-child>
                <DataQualityStatusBar :quality-assessment-category="qualityAssessmentCategory" v-bind="$attrs" />
            </TooltipTrigger>
            <TooltipContent :collision-padding="12">
                <p>{{ qualityAssessmentCategory?.name }}</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { store } from '@/plugins/store';
import DataQualityStatusBar from '@/components/common/dataQuality/DataQualityStatusBar.vue';

export default defineComponent({
    components: {
        Tooltip,
        TooltipContent,
        TooltipProvider,
        TooltipTrigger,
        DataQualityStatusBar,
    },
    inheritAttrs: false,
    props: {
        qualityAssessmentId: {
            type: Number,
            default: null,
        },
    },
    setup(props) {
        const qualityAssessmentCategory = computed(() => store.state.dataQualityCategories?.find((c) => c.id === props.qualityAssessmentId));

        return { qualityAssessmentCategory };
    },
});
</script>
