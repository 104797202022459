import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mx-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "text-right w-80" }
const _hoisted_4 = { class: "overflow-y-auto" }
const _hoisted_5 = { class: "flex-shrink-0 ml-auto w-5 my-auto" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-end"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex-shrink-0 w-6 my-auto" }
const _hoisted_9 = { class: "flex justify-end" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex-shrink-0 w-6 my-auto" }
const _hoisted_12 = { class: "flex justify-end" }
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, { class: "space-y-4 overflow-hidden" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.completed ? _ctx.$t('views.health.overview.tables.completedHeader') : _ctx.$t('views.health.overview.tables.ongoingHeader')), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_SearchInput, {
            value: _ctx.search,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            label: _ctx.$t('views.health.overview.tables.searchFieldLabel')
          }, null, 8, ["value", "label"])
        ])
      ]),
      _createVNode(_component_ActionModal, {
        "initial-step-index": 1,
        "initial-state": _ctx.actionModalInitialState,
        "step-config": _ctx.actionModalStepConfig,
        header: _ctx.actionModalHeader
      }, {
        default: _withCtx(({ open }) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_CustomTable, {
              headers: _ctx.tableHeaders,
              items: _ctx.filteredItems,
              "expanded-items": _ctx.expandedRows,
              "expand-items-key": "previous_treatments",
              onSort: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandedRows = []))
            }, {
              header_expand: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("th", { class: "w-8" }, null, -1)
              ])),
              header_actions: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("th", { class: "w-6" }, null, -1)
              ])),
              description: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                _createElementVNode("td", {
                  class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
                  style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                }, _toDisplayString(_ctx.truncate(item.description || '', { length: 30 })), 7)
              ]),
              day: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                _createElementVNode("td", {
                  class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
                  style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                }, _toDisplayString(item.day) + "/" + _toDisplayString(item.total_days), 7)
              ]),
              "day-expand": _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                _createElementVNode("td", {
                  class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
                  style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                }, _toDisplayString(item.day) + "/" + _toDisplayString(item.total_days), 7)
              ]),
              dosis_pr_animal: _withCtx(({ item, getHeaderClass, getWidth, header, getItemProperty }) => [
                _createElementVNode("td", {
                  class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
                  style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                }, _toDisplayString(getItemProperty(header, item)) + " " + _toDisplayString(item.dosis_unit ? item.dosis_unit : ''), 7)
              ]),
              "dosis_pr_animal-expand": _withCtx(({ item, getHeaderClass, getWidth, header, getItemProperty }) => [
                _createElementVNode("td", {
                  class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
                  style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                }, _toDisplayString(getItemProperty(header, item)) + " " + _toDisplayString(item.dosis_unit ? item.dosis_unit : ''), 7)
              ]),
              expand: _withCtx(({ item, index, expanded }) => [
                _createElementVNode("td", _hoisted_5, [
                  (item.previous_treatments && item.previous_treatments.length)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "flex items-center justify-center w-5 h-5",
                          onClick: ($event: any) => (_ctx.expandRow(index))
                        }, [
                          _createVNode(_component_Icon, {
                            class: _normalizeClass([{ 'rotate-90': !expanded, 'rotate-[-90deg]': expanded }, "h-3 w-3 cursor-pointer transform transition-transform"]),
                            src: _ctx.IconSource.Right
                          }, null, 8, ["class", "src"])
                        ], 8, _hoisted_7)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              "expand-expand": _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("td", { class: "flex-shrink-0 ml-auto w-8 my-auto" }, null, -1)
              ])),
              "description-expand": _withCtx(({ item, getHeaderClass, getWidth, header }) => [
                _createElementVNode("td", {
                  class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
                  style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                }, _toDisplayString(_ctx.truncate(item.description || '', { length: 30 })), 7)
              ]),
              actions: _withCtx(({ item }) => [
                _createElementVNode("td", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "flex items-center justify-center w-5 h-5",
                      onClick: ($event: any) => (_ctx.onEditClicked(item, open))
                    }, [
                      _createVNode(_component_Icon, {
                        class: "h-3 w-3 cursor-pointer",
                        src: _ctx.IconSource.Right
                      }, null, 8, ["src"])
                    ], 8, _hoisted_10)
                  ])
                ])
              ]),
              "actions-expand": _withCtx(({ item }) => [
                _createElementVNode("td", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "flex items-center justify-center w-5 h-5",
                      onClick: ($event: any) => (_ctx.onEditClicked(item, open))
                    }, [
                      _createVNode(_component_Icon, {
                        class: "h-3 w-3 cursor-pointer",
                        src: _ctx.IconSource.Right
                      }, null, 8, ["src"])
                    ], 8, _hoisted_13)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["headers", "items", "expanded-items"])
          ])
        ]),
        _: 1
      }, 8, ["initial-state", "step-config", "header"])
    ]),
    _: 1
  }))
}