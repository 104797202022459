<template>
    <Modal close-esc-event="keydown" :close-button="false" size="screen" :modal-class="`m-auto border-0 transition-width duration-500 w-280 h-160 generic-chart-modal`" mask-class="grid" @open="onOpening">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #body="scope">
            <div>
                <div class="pt-6 px-6 flex h-16.5">
                    <div class="flex space-x-4">
                        <h1 class="text-2xl">{{ chartTitle }}</h1>
                        <AddButton class="text-base py-1.5 my-auto" :icon-source="IconSource.Print" @click="printChart">
                            {{ $t('layouts.dashboard.charts.printChartButtonLabel') }}
                        </AddButton>
                    </div>
                    <button class="ml-auto mb-auto" @click="scope.close">
                        <Icon :src="IconSource.Close"></Icon>
                    </button>
                </div>
                <div class="bg-white h-full w-full relative rounded-lg grid pt-4 pb-2 px-4 my-auto overflow-x-auto max-w-screen grid">
                    <div ref="chartElement" class="w-full"></div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, ComputedRef, ref, onBeforeUnmount, watch, computed, unref } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import * as Highcharts from 'highcharts';
import merge from 'lodash-es/merge';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import AddButton from '@/components/ui/AddButton.vue';
import { store } from '@/plugins/store';
import { i18n } from '@/plugins/internationalization/i18n';
import { IconSource } from '@/types';

export default defineComponent({
    components: { Modal, AddButton },
    props: {
        options: {
            type: Object as () => ComputedRef<Highcharts.Options>,
            default: null,
        },
        chartState: {
            type: Object as () => {
                options: Highcharts.Options;
                seriesVisibility: boolean[];
                navigator: {
                    min: number | undefined;
                    max: number | undefined;
                };
            },
            default: null,
        },
        chartModule: {
            type: Function,
            default: null,
        },
        printSubtitle: {
            type: String as () => null | string,
            default: null,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const chartElement = ref(null);
        const highcharts = ref<Highcharts.Chart>();

        const chartModalOptions = () =>
            merge(cloneDeep(unref(props.chartState?.options || props.options)), {
                chart: {
                    height: `${((160 - 16.5) / 280) * 100}%`,
                    events: {
                        beforePrint() {
                            const dates = store.state.periodPickerDateString.split(' - ');
                            const styledDateString =
                                dates.length === 2
                                    ? `${i18n.global.d(new Date(dates[0]), 'short')} - ${i18n.global.d(new Date(dates[1]), 'short')}`
                                    : i18n.global.d(new Date(Date.parse(store.state.periodPickerDateString)), 'short');
                            highcharts.value?.setTitle({ text: chartTitle.value });
                            highcharts.value?.setSubtitle({ text: props.printSubtitle === null ? styledDateString : props.printSubtitle, align: 'left' });
                            const HEIGHT_OF_LINE_OF_LEGENDS = 14;
                            const DEFAULT_HEIGHT_OF_LEGEND = 141;
                            const LEGEND_ITEMS_PER_LINE = 2;
                            (this as any).setSize(
                                (this as any).chartWidth,
                                (this as any).chartHeight + Math.ceil((this as any).legend.allItems.length / LEGEND_ITEMS_PER_LINE) * HEIGHT_OF_LINE_OF_LEGENDS - DEFAULT_HEIGHT_OF_LEGEND,
                                false
                            );
                            highcharts.value?.update({ legend: { navigation: { enabled: false } } });
                        },
                        afterPrint() {
                            highcharts.value?.setTitle({ text: '' });
                            highcharts.value?.setSubtitle({ text: '' });
                            (this as any).setSize((this as any).chartWidth, ((160 - 16.5) / 280) * (this as any).chartWidth, false);
                            highcharts.value?.update({ legend: { navigation: { enabled: true } } });
                        },
                    },
                },
                navigation: {
                    buttonOptions: {
                        enabled: false,
                    },
                },
            });

        const onOpening = () => {
            if (props.chartModule) {
                props.chartModule(Highcharts);
            }

            highcharts.value = Highcharts.chart(chartElement.value as unknown as HTMLElement, merge(chartModalOptions(), { title: { text: '' } }));

            if (props.chartState) {
                highcharts.value.series.forEach((series, index) => {
                    if (props.chartState.seriesVisibility[index] !== series.visible) {
                        series.setVisible(props.chartState.seriesVisibility[index], false);
                    }
                });

                if (props.chartState.navigator) {
                    highcharts.value.xAxis[0].setExtremes(props.chartState.navigator.min, props.chartState.navigator.max, false);
                }

                highcharts.value.redraw();
            }
        };

        watch(
            () => props.options,
            (newValue: ComputedRef<Highcharts.Options>, previousValue) => {
                if (highcharts.value && !isEqual(newValue.value, previousValue.value)) {
                    highcharts.value.update(newValue.value, true, true, true);
                }
            },
            { deep: true }
        );

        const onClosing = () => {
            if (highcharts.value) {
                highcharts.value.destroy();
            }

            emit('close');
        };

        const chartTitle = computed(() => unref(props.options).title?.text || '');

        onBeforeUnmount(() => {
            onClosing();
        });

        const printChart = () => {
            highcharts.value?.print();
        };

        return { chartElement, onOpening, highcharts, onClosing, onBeforeUnmount, chartTitle, printChart, IconSource };
    },
});
</script>
