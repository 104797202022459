import { ref, nextTick, onBeforeUnmount } from 'vue';

export default function useWindowWidthListener() {
    const windowWidth = ref(window.innerWidth);

    const resizeHandler = () => {
        windowWidth.value = window.innerWidth;
    };

    nextTick(() => {
        window.addEventListener('resize', resizeHandler);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', resizeHandler);
    });

    return { windowWidth };
}
