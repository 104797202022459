<template>
    <li class="flex flex-col divide-y">
        <div class="relative flex items-start">
            <button
                v-if="option.children"
                type="button"
                class="absolute top-5 hover:bg-gray-100 p-2.5 rounded-full"
                :style="`left: ${leftOffset.icon + leftOffset.nesting}rem`"
                @click.prevent="expand = !expand"
            >
                <Icon :src="IconSource.DropdownDark" :class="{ 'rotate-180': expand }" class="h-4 w-4 transition-transform transform"></Icon>
            </button>
            <label class="flex items-center justify-between flex-grow cursor-pointer py-4 pr-4 hover:bg-gray-100 space-x-4" :style="`padding-left: ${leftOffset.nesting + leftOffset.text}rem`">
                <div class="flex items-center space-x-2.5">
                    <Icon :src="iconSource" class="h-7 w-7"></Icon>
                    <div class="flex flex-col">
                        <div class="flex items-center flex-wrap leading-tight">
                            <span class="mr-1">{{ option.title }}</span>
                            <span v-if="option.chr && option.chr.length" class="text-sm text-gray-500">({{ option.chr.join(',') }})</span>
                        </div>
                        <div class="text-sm opacity-50">{{ option.subtitle }}</div>
                    </div>
                </div>
                <div>
                    <CustomCheckbox :checked="option.selected" @update:checked="onToggle"></CustomCheckbox>
                </div>
            </label>
        </div>
        <ExpandCollapseTransition v-if="option.children">
            <ul v-show="expand" :class="{ 'divide-y': level !== 1 }">
                <div v-if="isBarnWithSections" class="flex justify-end pr-4">
                    <button class="text-sm font-medium text-primary-800 hover:text-primary-700 transition-colors py-2" @click.prevent="toggleAllSections">
                        {{ isAllChildrenSelected ? $t('layouts.dashboard.topBar.locationPicker.deselectAllSectionsButtonLabel') : $t('layouts.dashboard.topBar.locationPicker.selectAllSectionsButtonLabel') }}
                    </button>
                </div>
                <NestedLocation
                    v-for="currentChildOption in option.children"
                    :key="currentChildOption.id"
                    :option="currentChildOption"
                    :path="`${path}.${currentChildOption.id}`"
                    :level="level + 1"
                    :nested="isNested"
                    @location-toggled="$emit('location-toggled', $event)"
                ></NestedLocation>
            </ul>
        </ExpandCollapseTransition>
    </li>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import { IconSource } from '@/types';

type LocationPickerOption = {
    id: string;
    title: string;
    subtitle: string;
    selected: boolean;
    children?: LocationPickerOption[];
    chr?: string[];
};

export default defineComponent({
    name: 'NestedLocation',
    components: { CustomCheckbox, ExpandCollapseTransition },
    props: {
        option: {
            type: Object as () => LocationPickerOption,
            required: true,
        },
        path: {
            type: String,
            required: true,
        },
        level: {
            type: Number,
            default: 0,
        },
        nested: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['location-toggled', 'toggle-expand'],
    setup(props, context) {
        const onToggle = () => {
            context.emit('location-toggled', { level: props.level, option: props.option });
        };

        const expand = ref(false);
        const leftOffset = computed(() => ({
            nesting: props.level * 2,
            icon: 0.5,
            text: props.option.children?.length || props.nested ? 3 : 1,
        }));
        const isNested = computed(() => props.option.children?.some((c) => c.children && c.children.length > 0));

        watch(expand, (value) => {
            context.emit('toggle-expand', value);
        });

        const isBarnWithSections = computed(() => (props.option as any).children?.some((c: any) => c.isSection));

        const iconSource = computed(() => {
            if ((props.option as any).isBatch) {
                return IconSource.BatchIcon;
            }

            if ((props.option as any).isSection) {
                return IconSource.SectionIcon;
            }

            if ((props.option as any).isLocation) {
                return IconSource.Location;
            }

            return IconSource.FarmsPrimary;
        });

        const isAllChildrenSelected = computed(() => props.option.children?.every((c) => c.selected));

        function toggleAllSections() {
            if (!props.option.children) {
                return;
            }

            for (const child of props.option.children) {
                context.emit('location-toggled', { level: props.level + 1, option: child, forceState: !isAllChildrenSelected.value });
            }
        }

        return { onToggle, expand, leftOffset, iconSource, isNested, isBarnWithSections, isAllChildrenSelected, IconSource, toggleAllSections };
    },
});
</script>
