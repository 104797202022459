import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "max-w-128 w-full h-full" }
const _hoisted_2 = { class: "text-center pb-2 font-semibold" }
const _hoisted_3 = { class: "border-2 h-full overflow-y-auto border-opacity-10 rounded-lg py-2 max-h-80" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "pl-4 font-medium" }
const _hoisted_7 = { class: "w-6.5 pr-4" }
const _hoisted_8 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.batchTabIsActive ? _ctx.$t('views.reports.selectSitesPage.selectBatchesConditions') : _ctx.$t('views.reports.selectSitesPage.selectFarmsConditions')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("table", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, null, -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectableSites, (site) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: site.site_ids,
              class: "hover:bg-gray-100 cursor-pointer",
              onClick: ($event: any) => (_ctx.onSiteClicked(site))
            }, [
              _createElementVNode("td", _hoisted_6, _toDisplayString(site.name), 1),
              _createElementVNode("td", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_CustomCheckbox, {
                    checked: _ctx.computedState.siteIds.includes(site.site_ids),
                    "onUpdate:checked": ($event: any) => (_ctx.onSiteClicked(site))
                  }, null, 8, ["checked", "onUpdate:checked"])
                ])
              ])
            ], 8, _hoisted_5))
          }), 128))
        ])
      ])
    ])
  ]))
}