<template>
    <DashboardContentLayout class="space-y-4" :heading="$t('views.economy.postPayments.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <div class="space-y-6">
            <EconomyPostPaymentsTable></EconomyPostPaymentsTable>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import EconomyPostPaymentsTable from '@/views/dashboard/economy/economyPostPayments/EconomyPostPaymentsTable.vue';

export default defineComponent({
    components: { DashboardContentLayout, EconomyPostPaymentsTable },
});
</script>

<style scoped></style>
