import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = { class: "flex w-full flex-col-reverse items-center justify-between gap-4 overflow-auto sm:flex-row sm:gap-8 px-4 py-3 border-t border-gray-200" }
const _hoisted_5 = { class: "flex-1 whitespace-nowrap text-normal text-gray-500" }
const _hoisted_6 = { class: "flex items-center space-x-2" }
const _hoisted_7 = { class: "whitespace-nowrap text-normal font-medium" }
const _hoisted_8 = { class: "flex items-center gap-x-4" }
const _hoisted_9 = { className: "flex items-center justify-center text-normal font-medium" }
const _hoisted_10 = { class: "flex items-center space-x-1" }
const _hoisted_11 = { class: "sr-only" }
const _hoisted_12 = { class: "sr-only" }
const _hoisted_13 = { class: "sr-only" }
const _hoisted_14 = { class: "sr-only" }
const _hoisted_15 = {
  key: 0,
  class: "absolute inset-0 flex items-center justify-center bg-white/80"
}
const _hoisted_16 = { class: "flex justify-center items-center gap-2" }
const _hoisted_17 = { class: "font-medium" }

import { ColumnDef, SortingState, PaginationState, Row, FlexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useVueTable } from '@tanstack/vue-table';
import { ArrowUp, ArrowDown, ChevronsUpDown, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-vue-next';
import { computed, watch } from 'vue';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import Spinner from '@/components/ui/Spinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTable',
  props: {
    columns: {},
    data: {},
    totalRows: { default: undefined },
    loading: { type: Boolean, default: false },
    serverSide: { type: Boolean, default: false },
    pageSize: {},
    pageSizeOptions: {},
    page: {},
    totalPages: { default: undefined },
    selectable: { type: Boolean, default: false },
    rowId: {},
    rowClass: { type: Function, default: undefined },
    globalFilterFunction: {},
    sorting: { default: () => [] },
    rowSelection: { default: () => ({}) },
    filter: { default: undefined }
  },
  emits: ["update:filter", "update:sorting", "update:pageSize", "update:page", "update:selectedRows", "update:rowSelection", "row-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const pagination = computed<PaginationState>({
    get: () => ({
        pageSize: props.pageSize,
        pageIndex: props.page - 1,
    }),
    set: ({ pageSize, pageIndex }: PaginationState) => {
        emit('update:pageSize', pageSize);
        emit('update:page', pageIndex + 1);
    },
});
const sorting = computed({
    get: () => props.sorting ?? [],
    set: (value) => emit('update:sorting', value),
});
const rowSelection = computed({
    get: () => props.rowSelection ?? {},
    set: (value) => emit('update:rowSelection', value),
});
const filter = computed({
    get: () => props.filter,
    set: (value) => emit('update:filter', value || ''),
});

const columns = computed(() =>
    props.columns.map((column) => ({
        ...column,
        enableGlobalFilter: props.globalFilterFunction ? true : column.enableGlobalFilter ?? false,
        enableSorting: column.enableSorting ?? false,
    }))
);

const table = useVueTable({
    initialState: {
        sorting: sorting.value,
        globalFilter: filter.value,
        pagination: pagination.value,
    },
    get data() {
        return props.data;
    },
    get columns() {
        return columns.value;
    },
    get pageCount() {
        return props.serverSide ? props.totalPages : undefined;
    },
    get rowCount() {
        return props.serverSide ? props.totalRows : undefined;
    },
    state: {
        get sorting() {
            return sorting.value;
        },
        get pagination() {
            return pagination.value;
        },
        get rowSelection() {
            return rowSelection.value;
        },
        get globalFilter() {
            return filter.value;
        },
    },
    onSortingChange: (updater) => {
        sorting.value = typeof updater === 'function' ? updater(sorting.value) : updater;
    },
    onPaginationChange: (updater) => {
        pagination.value = typeof updater === 'function' ? updater(pagination.value) : updater;

        rowSelection.value = {};
    },
    onRowSelectionChange: (updater) => {
        rowSelection.value = typeof updater === 'function' ? updater(rowSelection.value) : updater;
    },
    onGlobalFilterChange: (updater) => {
        filter.value = typeof updater === 'function' ? updater(filter.value) : updater;
    },
    globalFilterFn: props.globalFilterFunction,
    manualSorting: props.serverSide,
    manualPagination: props.serverSide,
    manualFiltering: props.serverSide,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: props.rowId ?? ((row: any) => row.id?.toString() ?? ''),
    autoResetPageIndex: false,
});

function getAlignClass(align: string | undefined): string {
    switch (align) {
        case 'left':
            return 'text-left';
        case 'right':
            return 'text-right';
        case 'center':
            return 'text-center';
        default:
            return 'text-left';
    }
}

const rows = computed(() => (props.serverSide ? table.getFilteredRowModel().rows : table.getRowModel().rows) || []);
const rowsCount = computed(() => props.totalRows ?? table.getFilteredRowModel().rows.length);

const start = computed(() => {
    const pageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    return pageIndex * pageSize + 1;
});
const end = computed(() => {
    const itemEnd = (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize;

    return rowsCount.value > itemEnd ? itemEnd : rowsCount.value;
});

watch(
    () => rows.value,
    (newValue) => {
        if (Object.keys(rowSelection.value).every((k) => newValue.find((r) => r.id === k)) === false) {
            rowSelection.value = {};
        }

        if (props.loading) {
            return;
        }

        const pageCount = props.totalPages ?? table.getPageCount();

        // Set the pagination to the first page if the data changes and the current page is out of bounds
        if (table.getState().pagination.pageIndex > pageCount - 1) {
            table.setPageIndex(0);
            table.initialState.pagination.pageIndex = 0;
        }
    },
    { immediate: true }
);

watch(
    () => filter.value,
    () => {
        table.setPageIndex(0);
    }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Table), null, {
      default: _withCtx(() => [
        _createVNode(_unref(TableHeader), null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(table).getHeaderGroups(), (headerGroup) => {
              return (_openBlock(), _createBlock(_unref(TableRow), {
                key: headerGroup.id,
                class: "hover:bg-inherit"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headerGroup.headers, (header) => {
                    return (_openBlock(), _createBlock(_unref(TableHead), {
                      key: header.id,
                      class: _normalizeClass([getAlignClass((header.column.columnDef.meta as any)?.align), (header.column.columnDef.meta as any)?.headerClass || ''])
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, `header[${(header.column.columnDef as any).accessorKey}]`, {}, () => [
                          (header.column.getCanSort() && !header.isPlaceholder)
                            ? (_openBlock(), _createBlock(_unref(DropdownMenu), {
                                key: 0,
                                modal: false
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
                                    default: _withCtx(() => [
                                      (header.column.getCanSort() && !header.isPlaceholder)
                                        ? (_openBlock(), _createBlock(_unref(Button), {
                                            key: 0,
                                            "aria-label": 
                                            header.column.getIsSorted()
                                                ? header.column.getIsSorted() === 'asc'
                                                    ? _ctx.$t('global.uiElements.dataTable.buttons.sortedAscendingClickToSort')
                                                    : _ctx.$t('global.uiElements.dataTable.buttons.sortedDescendingClickToSort')
                                                : _ctx.$t('global.uiElements.dataTable.buttons.clickToSort')
                                        ,
                                            variant: "ghost",
                                            class: _normalizeClass(["px-3 -ml-3 gap-x-1.5 data-[state=open]:bg-gray-100", { '-ml-3': (header.column.columnDef.meta as any)?.align === 'left', 'ml-3': (header.column.columnDef.meta as any)?.align === 'right' }])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_unref(FlexRender), {
                                                render: header.column.columnDef.header,
                                                props: header.getContext()
                                              }, null, 8, ["render", "props"]),
                                              (header.column.getCanSort())
                                                ? (_openBlock(), _createBlock(FadeTransition, {
                                                    key: 0,
                                                    mode: "out-in"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (header.column.getIsSorted() && sorting.value?.[0]?.desc === false)
                                                        ? (_openBlock(), _createBlock(_unref(ArrowUp), {
                                                            key: 0,
                                                            class: "h-4 w-4 -mr-0.5",
                                                            "aria-hidden": "true"
                                                          }))
                                                        : (header.column.getIsSorted())
                                                          ? (_openBlock(), _createBlock(_unref(ArrowDown), {
                                                              key: 1,
                                                              class: "h-4 w-4 -mr-0.5",
                                                              "aria-hidden": "true"
                                                            }))
                                                          : (_openBlock(), _createBlock(_unref(ChevronsUpDown), {
                                                              key: 2,
                                                              class: "h-4 w-4 transition-transform -mr-0.5",
                                                              "aria-hidden": "true"
                                                            }))
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1032, ["aria-label", "class"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_unref(DropdownMenuContent), {
                                    align: "start",
                                    class: "space-y-0.5"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(DropdownMenuItem), {
                                        "aria-label": _ctx.$t('global.uiElements.dataTable.buttons.sortAscending'),
                                        class: _normalizeClass({ 'bg-gray-100': header.column.getIsSorted() === 'asc' }),
                                        onSelect: ($event: any) => (header.column.toggleSorting(false))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_unref(ArrowUp), { class: "mr-2 h-4 w-4" }),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.ascending')), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["aria-label", "class", "onSelect"]),
                                      _createVNode(_unref(DropdownMenuItem), {
                                        "aria-label": _ctx.$t('global.uiElements.dataTable.buttons.sortDescending'),
                                        class: _normalizeClass({ 'bg-gray-100': header.column.getIsSorted() === 'desc' }),
                                        onSelect: ($event: any) => (header.column.toggleSorting(true))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_unref(ArrowDown), { class: "mr-2 h-4 w-4" }),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.descending')), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["aria-label", "class", "onSelect"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            : (!header.isPlaceholder)
                              ? (_openBlock(), _createBlock(_unref(FlexRender), {
                                  key: 1,
                                  render: header.column.columnDef.header,
                                  props: header.getContext()
                                }, null, 8, ["render", "props"]))
                              : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["class"]))
                  }), 128))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 3
        }),
        _createVNode(_unref(TableBody), { class: "relative" }, {
          default: _withCtx(() => [
            (rows.value.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(rows.value, (row) => {
                  return (_openBlock(), _createBlock(_unref(TableRow), {
                    key: row.id,
                    "data-state": row.getIsSelected() ? 'selected' : undefined,
                    class: _normalizeClass(_ctx.rowClass?.(row.original as TData))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.getVisibleCells(), (cell) => {
                        return (_openBlock(), _createBlock(_unref(TableCell), {
                          key: cell.id,
                          class: _normalizeClass([getAlignClass((cell.column.columnDef.meta as any)?.align), (cell.column.columnDef.meta as any)?.cellClass || ''])
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, `item[${(cell.column.columnDef as any).accessorKey}]`, {
                              item: row.original,
                              value: cell.getValue()
                            }, () => [
                              _createVNode(_unref(FlexRender), {
                                render: cell.column.columnDef.cell,
                                props: cell.getContext()
                              }, null, 8, ["render", "props"])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["class"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["data-state", "class"]))
                }), 128))
              : (_openBlock(), _createElementBlock("tr", _hoisted_2, [
                  _createElementVNode("td", {
                    colspan: _unref(table).getFlatHeaders().length,
                    class: "h-24 text-center text-gray-500"
                  }, _toDisplayString(_ctx.loading ? '' : _ctx.$t('global.uiElements.dataTable.texts.noResults')), 9, _hoisted_3)
                ]))
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.selectable && Object.keys(rowSelection.value).length
                        ? _ctx.$t('global.uiElements.dataTable.texts.selectedRows')
                              .replace('%SELECTED%', _unref(table).getFilteredSelectedRowModel().rows.length.toString())
                              .replace('%TOTAL%', _unref(table).getFilteredRowModel().rows.length.toString())
                        : _ctx.$t('global.uiElements.dataTable.texts.showingRange')
                              .replace('%START%', rows.value.length ? start.value.toString() : '0')
                              .replace('%END%', end.value.toString())
                              .replace('%TOTAL%', rowsCount.value.toString())), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('global.uiElements.dataTable.texts.pageSize')), 1),
        _createVNode(_unref(Select), {
          "model-value": String(_unref(table).getState().pagination.pageSize),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(table).setPageSize(Number($event))))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SelectTrigger), { class: "w-24" }, {
              icon: _withCtx(() => [
                _createVNode(_unref(ChevronsUpDown), {
                  class: "size-4 opacity-50 flex-shrink-0",
                  "aria-hidden": "true"
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_unref(SelectValue), {
                  placeholder: String(_unref(table).getState().pagination.pageSize)
                }, null, 8, ["placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_unref(SelectContent), { side: "top" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageSizeOptions || [10, 20, 30, 40, 50], (option) => {
                  return (_openBlock(), _createBlock(_unref(SelectItem), {
                    key: option,
                    value: String(option),
                    class: "text-normal"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model-value"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('global.uiElements.dataTable.texts.page')
                            .replace('%CURRENT_PAGE%', (_unref(table).getState().pagination.pageIndex + 1).toString())
                            .replace('%TOTAL%', (_unref(table).getPageCount() || 1).toString())), 1),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_unref(TooltipProvider), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Tooltip), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        variant: "outline",
                        size: "icon",
                        disabled: !_unref(table).getCanPreviousPage() || _ctx.loading,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(table).setPageIndex(0)))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.firstPage')), 1),
                          _createVNode(_unref(ChevronsLeft), {
                            class: "size-5",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TooltipContent), null, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.firstPage')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TooltipProvider), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Tooltip), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        variant: "outline",
                        size: "icon",
                        disabled: !_unref(table).getCanPreviousPage() || _ctx.loading,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(table).previousPage()))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.previousPage')), 1),
                          _createVNode(_unref(ChevronLeft), {
                            class: "size-5",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TooltipContent), null, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.previousPage')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TooltipProvider), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Tooltip), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        variant: "outline",
                        size: "icon",
                        disabled: !_unref(table).getCanNextPage() || _ctx.loading,
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(table).nextPage()))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.nextPage')), 1),
                          _createVNode(_unref(ChevronRight), {
                            class: "size-5",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TooltipContent), null, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.nextPage')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TooltipProvider), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Tooltip), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        variant: "outline",
                        size: "icon",
                        disabled: !_unref(table).getCanNextPage() || _ctx.loading,
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(table).setPageIndex(_unref(table).getPageCount() - 1)))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.lastPage')), 1),
                          _createVNode(_unref(ChevronsRight), {
                            class: "size-5",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TooltipContent), null, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('global.uiElements.dataTable.buttons.lastPage')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_Transition, {
      "enter-active-class": "duration-200 ease-out",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "duration-100 ease-in",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0"
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('global.uiElements.dataTable.texts.loading')), 1),
                _createVNode(Spinner, { class: "w-5 h-5 animate-spin" })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})