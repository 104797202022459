import { keepPreviousData, useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import ApiRequest from '@/plugins/store/actions/api/ApiRequest';
import { Dashboard } from '@/types';

const DEFAULT_OPTIONS: UseQueryOptions<any> = {
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 5, // 5 minutes
};

export const queryKeys = {
    getDashboardRoot: ['dashboards', 'analysis'],
    getDashboard: ({
        typeId,
        isWeaners,
        scope,
        locationIds,
        period,
        includeCompleteBatch,
    }: {
        typeId: Ref<string>;
        isWeaners: Ref<boolean | null>;
        scope: Ref<AnalysisDashboardScope>;
        locationIds: Ref<number[]>;
        period: Ref<string>;
        includeCompleteBatch: Ref<boolean>;
    }) => [...queryKeys.getDashboardRoot, { typeId, isWeaners, scope, locationIds, period, includeCompleteBatch }],
};

/* eslint-disable */
export enum AnalysisDashboardScope {
    Directory = 0,
    Farm = 1,
    Barn = 2,
    Section = 3,
    Batch = 4,
}
/* eslint-enable */

export function useAnalysisDashboard(
    {
        typeId,
        isWeaners,
        scope,
        locationIds,
        period,
        includeCompleteBatch,
    }: {
        typeId: Ref<string>;
        isWeaners: Ref<boolean | null>;
        scope: Ref<AnalysisDashboardScope>;
        locationIds: Ref<number[]>;
        period: Ref<string>;
        includeCompleteBatch: Ref<boolean>;
    },
    options: UseQueryOptions<Dashboard> = {}
) {
    return useQuery<Dashboard>({
        queryKey: queryKeys.getDashboard({ typeId, isWeaners, scope, locationIds, period, includeCompleteBatch }),
        queryFn: () => {
            let query = `?${period.value}&type_id=${typeId.value}&scope=${scope.value}&location_ids=${locationIds.value.toString()}&initialrequest=true&includeCompleteBatch=${includeCompleteBatch.value}`;

            if (isWeaners.value !== null) {
                query += `&isweaners=${isWeaners.value}`;
            }

            return new ApiRequest().send('get', `dashboards/analysis${query}`);
        },
        placeholderData: keepPreviousData,
        ...DEFAULT_OPTIONS,
        ...options,
    });
}
