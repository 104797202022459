import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mx-6 pt-4" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = { class: "text-right w-80" }
const _hoisted_4 = { class: "break-all" }
const _hoisted_5 = { class: "w-8 my-auto" }
const _hoisted_6 = { class: "flex justify-end" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, { class: "pt-2 overflow-x-auto space-y-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.assignments.tabs.assignments.tables.treatments.header')), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_SearchInput, {
            value: _ctx.search,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            label: _ctx.$t('views.assignments.tabs.assignments.tables.treatments.searchFieldLabel')
          }, null, 8, ["value", "label"])
        ])
      ]),
      _createVNode(_component_ActionModal, {
        "initial-step-index": 1,
        "initial-state": _ctx.actionModalInitialState,
        "step-config": _ctx.actionModalStepConfig,
        header: _ctx.actionModalHeader,
        onSubmission: _ctx.onTreatmentAssignmentEdit
      }, {
        default: _withCtx(({ open }) => [
          _createVNode(_component_CustomTable, {
            headers: _ctx.headers,
            items: _ctx.filteredItems
          }, {
            header_actions: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("th", { class: "w-8" }, null, -1)
            ])),
            _day: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
              _createElementVNode("td", {
                class: _normalizeClass(["flex-1", `${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`]),
                style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
              }, _toDisplayString(item.treatment.day) + "/" + _toDisplayString(item.treatment.total_days), 7)
            ]),
            _dosis: _withCtx(({ item, getHeaderClass, getWidth, header, getItemProperty }) => [
              (_openBlock(), _createElementBlock("td", {
                key: header.value,
                class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`),
                style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.treatment && item.treatment.dosis_pr_animal !== null ? getItemProperty(header, item) : '') + " " + _toDisplayString((item.treatment && item.treatment.dosis_unit) || ''), 1)
              ], 6))
            ]),
            actions: _withCtx(({ item }) => [
              _createElementVNode("td", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: ($event: any) => (_ctx.onTreatmentAssignmentClicked(item, open))
                  }, [
                    _createVNode(_component_Icon, {
                      class: "h-4 w-4 cursor-pointer",
                      src: _ctx.IconSource.Right
                    }, null, 8, ["src"])
                  ], 8, _hoisted_7)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["headers", "items"])
        ]),
        _: 1
      }, 8, ["initial-state", "step-config", "header", "onSubmission"])
    ]),
    _: 1
  }))
}