import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-6" }
const _hoisted_2 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "rounded flex py-4 px-6 gap-x-4 odd:bg-dark-gray-200 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActivityTableModal = _resolveComponent("ActivityTableModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('views.reports.reportsPage.tables.insertions.title')), 1)
      ]),
      _createVNode(_component_CustomTable, {
        headers: _ctx.headers,
        items: _ctx.data.items
      }, {
        amount: _withCtx(({ header, getWidth, getItemProperty, getHeaderClass, item }) => [
          (_openBlock(), _createElementBlock("td", {
            key: header.value,
            class: _normalizeClass(`${getHeaderClass(header, false)} ${item.record_type_ids?.length ? 'underline' : ''} my-auto break-words min-h-6.5`),
            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
          }, [
            _createVNode(_component_ActivityTableModal, {
              title: _ctx.$t('views.reports.reportsPage.tables.insertions.activitiesModalHeader'),
              "record-type-ids": item.record_type_ids,
              records: _ctx.records,
              onFetchRecords: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('fetch-records')))
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'cursor-pointer': item.record_type_ids?.length }),
                  onClick: ($event: any) => (item.record_type_ids?.length && scope.open())
                }, _toDisplayString(getItemProperty(header, item)), 11, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["title", "record-type-ids", "records"])
          ], 6))
        ]),
        "append-rows": _withCtx(({ getWidth, getItemProperty, getHeaderClass }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emptyRows, (i) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: i,
              class: "rounded flex py-4 px-6 gap-x-4 odd:bg-dark-gray-200"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: header.value,
                  class: "min-h-6.5"
                }))
              }), 128))
            ]))
          }), 128)),
          _createElementVNode("tr", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
              return (_openBlock(), _createElementBlock("td", {
                key: header.value,
                class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words min-h-6.5`),
                style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
              }, _toDisplayString(getItemProperty(header, _ctx.data.total)), 7))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}