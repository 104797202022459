export type NotificationType = {
    icon_url: string | number;
    name: string;
    id: NotificationTypeId;
    filterCallback: (notification: any) => boolean;
};

export enum NotificationTypeId {
    read = 'NOTIFICATION_READ',
    unread = 'NOTIFICATION_UNREAD',
    rejected = 'NOTIFICATION_REJECTED',
    requiresAction = 'NOTIFICATION_REQUIRES_ACTION',
    approved = 'NOTIFICATION_APPROVED',
}
