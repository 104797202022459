<template>
    <MainViewCard class="space-y-4 pt-2">
        <div class="flex mx-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.assignments.tabs.templates.table.header') }}</h4>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.assignments.tabs.templates.table.searchFieldLabel')"></SearchInput>
            </span>
            <DynamicFormModal
                :initial-state="{
                    event: createTaskTemplateEvent,
                    form: null,
                    confirmed: false,
                }"
                :step-config="createTaskTemplateStepConfig"
                :header="createTaskModalHeader"
                :refetch-actions="createRefetchActions"
                :submission-text="$t('views.assignments.tabs.templates.modals.createTemplate.successMessage')"
            >
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="onAddTemplateClicked(open)">
                        {{ $t('views.assignments.tabs.templates.content.createTemplateButton') }}
                    </AddButton>
                </template>
            </DynamicFormModal>
        </div>
        <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader" @submission="onEditSubmission">
            <template #default="{ open }">
                <CustomTable :headers="headers" :items="filteredItems">
                    <template #header_actions>
                        <th class="w-8"></th>
                    </template>
                    <template #actions="{ item }">
                        <td class="w-8 my-auto">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="onEditTemplateClicked(item, open)">
                                    <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </td>
                    </template>
                </CustomTable>
            </template>
        </ActionModal>
    </MainViewCard>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import SearchInput from '@/components/ui/SearchInput.vue';
import AddButton from '@/components/ui/AddButton.vue';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';
import { store } from '@/plugins/store';
import { AssignmentTemplate, MiscEventType, RecordForm, IconSource, TableHeader } from '@/types';
import DynamicFormModal from '@/components/common/dynamicFormModal/DynamicFormModal.vue';
import { ActionType } from '@/plugins/store/actions';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, SearchInput, AddButton, CustomTable, SettingsFormModal, DynamicFormModal, ActionModal },
    setup() {
        const headers = computed<TableHeader[]>(() => [
            { text: i18n.global.t('views.assignments.tabs.templates.table.headings.task'), value: 'name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.assignments.tabs.templates.table.headings.creationDate'), value: 'created_at_utc', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: '', value: 'actions', align: 'right', sortable: false, searchable: false },
        ]);
        const items = computed(() => store.state.assignmentTemplates);
        const { search, filteredItems } = useFilterableTable(items, headers);

        const createTaskTemplateEvent = computed(() => store.state.miscEvents.find((e) => e.id === MiscEventType.assignmentTemplate));
        const createTaskModalHeader = computed(() => ({
            iconUrl: store.state.icons ? store.state.icons[`${createTaskTemplateEvent.value?.icon_id}`] : '',
        }));
        const createTaskTemplateStepConfig = ref([{}, { deleteButton: false, editable: true }, {}, {}]);
        const createRefetchActions = ref([ActionType.GetAssignmentTemplates]);

        const onAddTemplateClicked = (open: CallableFunction) => {
            if (!createTaskTemplateEvent.value) return;

            open();
        };

        const actionModalInitialState = ref({});
        const actionModalStepConfig = ref([{}, { deleteButton: true, editable: true }, { disableRefetch: true, submitActionType: ActionType.PutAssignmentTemplateForm }, {}]);
        const actionModalHeader = ref({});

        const onEditTemplateClicked = async (assignmentTemplate: AssignmentTemplate, open: CallableFunction) => {
            const templateForm: RecordForm = await store.dispatch(ActionType.GetAssignmentTemplateForm, { form: { assignment_template_id: assignmentTemplate.id! } });
            actionModalInitialState.value = {
                location: null,
                form: templateForm.form,
                confirmed: false,
                farm: null,
                event: null,
                assignment_template_id: assignmentTemplate.id,
                record_id: templateForm.record_id,
                notification: null,
            };
            actionModalHeader.value = { icon_url: IconSource.Treatment, text: templateForm.form.sections?.[0]?.name || assignmentTemplate.name };

            open();
        };

        const onEditSubmission = () => {
            store.dispatch(ActionType.GetAssignmentTemplates, { options: { ignoreCache: true } });
        };

        return {
            onEditTemplateClicked,
            headers,
            items,
            createTaskTemplateEvent,
            createTaskModalHeader,
            createTaskTemplateStepConfig,
            createRefetchActions,
            actionModalInitialState,
            actionModalStepConfig,
            actionModalHeader,
            onAddTemplateClicked,
            onEditSubmission,
            search,
            filteredItems,
        };
    },
});
</script>
