<template>
    <MainViewCard class="pt-2 overflow-x-auto space-y-4">
        <div class="flex mx-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.assignments.tabs.assignments.tables.treatments.header') }}</h4>
            <span class="text-right w-80">
                <SearchInput v-model:value="search" :label="$t('views.assignments.tabs.assignments.tables.treatments.searchFieldLabel')"></SearchInput>
            </span>
        </div>
        <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader" @submission="onTreatmentAssignmentEdit">
            <template #default="{ open }">
                <CustomTable :headers="headers" :items="filteredItems">
                    <template #header_actions>
                        <th class="w-8"></th>
                    </template>
                    <template #_day="{ item, getHeaderClass, getWidth, header }">
                        <td
                            class="flex-1"
                            :class="`${getHeaderClass(header, false)} text-center justify-center cursor-auto my-auto break-words min-h-6.5`"
                            :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                        >
                            {{ item.treatment.day }}/{{ item.treatment.total_days }}
                        </td>
                    </template>
                    <template #_dosis="{ item, getHeaderClass, getWidth, header, getItemProperty }">
                        <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                            <span class="break-all">
                                {{ item.treatment && item.treatment.dosis_pr_animal !== null ? getItemProperty(header, item) : '' }} {{ (item.treatment && item.treatment.dosis_unit) || '' }}
                            </span>
                        </td>
                    </template>
                    <template #actions="{ item }">
                        <td class="w-8 my-auto">
                            <span class="flex justify-end">
                                <button type="button" @click="onTreatmentAssignmentClicked(item, open)">
                                    <Icon class="h-4 w-4 cursor-pointer" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </td>
                    </template>
                </CustomTable>
            </template>
        </ActionModal>
    </MainViewCard>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { store } from '@/plugins/store';
import { Assignment, Form, IconSource, TableHeader, RecordForm, Module } from '@/types';
import AddButton from '@/components/ui/AddButton.vue';
import { ActionType } from '@/plugins/store/actions';
import SearchInput from '@/components/ui/SearchInput.vue';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, CustomTable, ActionModal, AddButton, SearchInput },
    props: {
        completedTasks: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const enableSectionsModule = computed<boolean>(() => store.getters.hasModuleEnabled(Module.Sections));
        const headers = computed<TableHeader[]>(() => [
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.date'), value: 'date', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.farm'), value: 'farm_name', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.barn'), value: 'barn_name', align: 'left', sortable: true, searchable: true },
            ...(enableSectionsModule.value
                ? ([{ text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.section'), value: 'section_name', align: 'left', sortable: true, searchable: true }] as TableHeader[])
                : []),
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.pen'), value: 'pen', align: 'left', sortable: true, searchable: true },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.treatmentDay'), value: '_day', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.retentionDate'), value: '_retentionDate', align: 'left', sortable: true, searchable: true, type: 'date' },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.numberOfAnimals'), value: '_animalCount', align: 'left', sortable: true, searchable: true, type: 'number' },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.dosagePerAnimal'), value: '_dosis', align: 'left', sortable: true, searchable: true, type: 'number' },
            { text: i18n.global.t('views.assignments.tabs.assignments.tables.treatments.headings.label'), value: '_label', align: 'left', sortable: true, searchable: true },
            { text: '', value: 'actions', align: 'right', sortable: false, searchable: false },
        ]);
        const items = computed(
            () =>
                store.state.assignmentResponse?.data
                    .filter((assignment: Assignment) => assignment.type_id === 2 && assignment.is_completed === props.completedTasks)
                    .map((item) => ({
                        ...item,
                        _retentionDate: item.treatment.retention_date,
                        _animalCount: item.treatment.animal_count,
                        _dosis: item.treatment.dosis_pr_animal,
                        _day: item.treatment.day,
                        _label: item.treatment.label || '-',
                    })) || []
        );
        const { search, filteredItems } = useFilterableTable(items, headers);

        const actionModalInitialState = ref({});
        const actionModalStepConfig = ref([{}, { deleteButton: true, editable: true }, { disableRefetch: true, submitActionType: ActionType.PutForm }, {}]);
        const actionModalHeader = ref({});
        const isLoadingActionForm = ref(false);

        const onTreatmentAssignmentClicked = async (assignment: Assignment, open: CallableFunction) => {
            try {
                if (isLoadingActionForm.value) return;

                isLoadingActionForm.value = true;

                if (assignment.form.record_id !== undefined) {
                    const recordForm: RecordForm = await store.dispatch(ActionType.GetRecordForm, { form: { form_id: assignment.form.form_id, record_id: assignment.form.record_id } });

                    if (recordForm) {
                        actionModalStepConfig.value[1] = { deleteButton: recordForm.deletable, editable: recordForm.editable };
                        actionModalInitialState.value = {
                            location: null,
                            form: recordForm.form,
                            confirmed: false,
                            farm: null,
                            event: null,
                            record_id: assignment.form.record_id,
                            assignment_id: assignment.id,
                            notification: null,
                        };
                        actionModalHeader.value = { icon_url: IconSource.Treatment, text: recordForm.form.sections?.[0]?.name || assignment.name };

                        open();
                    }
                } else {
                    // Temporary fix for detemining whether the user has write permissions on the farm with the assignment
                    let farm = store.state.farms?.find((currentFarm) => currentFarm.farm_id === assignment.location_id);

                    if (!farm) {
                        const barn = store.state.barns?.find((currentBarn) => currentBarn.barn_id === assignment.location_id);
                        if (barn) {
                            farm = store.state.farms?.find((currentFarm) => currentFarm.farm_id === barn.farm_id);
                        }
                    }

                    if (!farm) {
                        const section = store.state.sections?.find((currentSection) => currentSection.id === assignment.location_id);
                        if (section) {
                            const barn = store.state.barns?.find((currentBarn) => currentBarn.barn_id === section.barn_id);
                            if (barn) {
                                farm = store.state.farms?.find((currentFarm) => currentFarm.farm_id === barn.farm_id);
                            }
                        }
                    }

                    actionModalStepConfig.value[1] = { deleteButton: farm?.permission.key === 'ReadWrite', editable: farm?.permission.key === 'ReadWrite' };

                    const treatmentForm: Form = await store.dispatch(ActionType.GetForm, {
                        form: { form_id: assignment.form.form_id, resource_id: assignment.treatment.treatment_id || assignment.form.resource_id },
                    });

                    if (treatmentForm) {
                        actionModalInitialState.value = { location: null, form: treatmentForm, confirmed: false, farm: null, event: null, record_id: null, assignment_id: assignment.id, notification: null };
                        actionModalHeader.value = { icon_url: IconSource.Treatment, text: treatmentForm.sections?.[0]?.name || assignment.name };

                        open();
                    }
                }
            } finally {
                isLoadingActionForm.value = false;
            }
        };

        const onTreatmentAssignmentEdit = () => {
            store.dispatch(ActionType.GetAssignments, { options: { ignoreCache: true } });
        };

        return {
            headers,
            items,
            actionModalInitialState,
            actionModalStepConfig,
            actionModalHeader,
            onTreatmentAssignmentClicked,
            onTreatmentAssignmentEdit,
            search,
            filteredItems,
        };
    },
});
</script>
