<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="m-auto border-0" mask-class="grid" @closing="onModalClose">
        <template #toggle="scope">
            <slot :scope="scope"></slot>
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="bg-gray-100 py-6 px-8 space-y-4 rounded-xl">
                <div class="text-center">
                    <h2 class="font-semibold text-xl">{{ header }}</h2>
                    <p>{{ subHeader }}</p>
                </div>
                <div class="grid gap-y-4 grid-cols-1">
                    <button v-for="currentType of types" :key="currentType.id" type="button" class="bg-white shadow rounded-2xl p-4 px-4 space-x-4 flex" @click="onTypeClicked(currentType)">
                        <div class="flex">
                            <Icon v-if="currentType.icon_id" :src="currentType.icon_id" class="my-auto w-10 h-10"></Icon>
                            <Icon v-else class="my-auto w-10 h-10" :src="currentType.icon_url"></Icon>
                        </div>
                        <div class="flex h-10">
                            <span class="my-auto font-semibold">
                                {{ currentType.name }}
                            </span>
                        </div>
                        <div class="flex flex-1 justify-end h-10 w-8">
                            <Icon v-show="selectedIds.includes(currentType.id)" class="my-auto ml-auto h-8" :src="IconSource.FormSuccess"></Icon>
                        </div>
                    </button>
                </div>
                <div class="flex justify-end">
                    <button type="button" class="underline font-medium text-primary-800 text-sm pr-2" @click="onToggleAllClicked">
                        {{ allSelected ? $t('global.filterModal.deselectAllButonLabel') : $t('global.filterModal.selectAllButtonLabel') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

type Type<T> = { name: string; id: T; icon_url?: string; icon_id?: string };

export default defineComponent({
    components: { Modal, CloseModalButton },
    emits: ['update:selectedIds'],
    props: {
        types: {
            type: Array as () => Type<number | string>[],
            default: [],
        },
        selectedIds: {
            type: Array as () => (number | string)[],
            default: [],
        },
        header: {
            type: String,
            default: '',
        },
        subHeader: {
            type: String,
            default: '',
        },
    },
    computed: {
        computedSelectedIds: {
            get(): (number | string)[] {
                return this.$props.selectedIds;
            },
            set(newValue: number | string[]) {
                this.$emit('update:selectedIds', newValue);
            },
        },
        allSelected() {
            return this.computedSelectedIds.length === this.types.length;
        },
    },
    methods: {
        onTypeClicked(type: Type<number | string>) {
            const indexOfClickedType = this.computedSelectedIds.indexOf(type.id);
            if (indexOfClickedType === -1) {
                this.computedSelectedIds.push(type.id);
            } else {
                this.computedSelectedIds.splice(indexOfClickedType, 1);
            }
        },
        onModalClose(next: (closeModal: boolean) => boolean) {
            if (this.computedSelectedIds.length === 0) {
                if (!window.confirm(this.$t('global.filterModal.confirmCloseWhenAllDeselectedMessage'))) {
                    return next(false);
                }
            }

            return true;
        },
        onToggleAllClicked() {
            if (this.allSelected) {
                this.computedSelectedIds = [];
            } else {
                this.computedSelectedIds = this.types.map((currentType) => currentType.id);
            }
        },
    },
});
</script>
