import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full space-y-4" }
const _hoisted_2 = { class: "font-semibold text-xl text-center" }
const _hoisted_3 = { class: "select-site-tabs h-88" }
const _hoisted_4 = { class: "flex justify-center overflow-x-auto mb-4" }
const _hoisted_5 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteSelector = _resolveComponent("SiteSelector")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.reports.selectSitesPage.header')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Tabs, {
        teleport: "#site-tabs-target",
        "active-tab-class": "--active"
      }, {
        default: _withCtx(() => [
          _createElementVNode("nav", _hoisted_4, [
            _createVNode(_component_Tab, {
              class: "mx-2",
              name: _ctx.$t('views.reports.selectSitesPage.farmsTabLabel'),
              default: true,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTabClicked(false)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SiteSelector, {
                  state: _ctx.computedState,
                  "selectable-sites": _ctx.reportFarms,
                  "batch-tab-is-active": _ctx.batchTabIsActive
                }, null, 8, ["state", "selectable-sites", "batch-tab-is-active"])
              ]),
              _: 1
            }, 8, ["name"]),
            _createVNode(_component_Tab, {
              class: "mx-2",
              name: _ctx.$t('views.reports.selectSitesPage.batchesTabLabel'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onTabClicked(true)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SiteSelector, {
                  state: _ctx.state,
                  "selectable-sites": _ctx.reportBatches,
                  "batch-tab-is-active": _ctx.batchTabIsActive
                }, null, 8, ["state", "selectable-sites", "batch-tab-is-active"])
              ]),
              _: 1
            }, 8, ["name"])
          ])
        ]),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        id: "site-tabs-target",
        class: "flex justify-center max-h-64 h-full"
      }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        class: _normalizeClass(["max-w-32", { 'cursor-not-allowed': _ctx.disableNext }]),
        onClick: _ctx.onNextClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.reports.nextButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "class", "onClick"])
    ])
  ]))
}