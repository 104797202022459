import { renderSlot as _renderSlot, TransitionGroup as _TransitionGroup, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.group)
    ? (_openBlock(), _createBlock(_TransitionGroup, {
        key: 0,
        "enter-active-class": "collapse-expand-enter-active",
        "leave-active-class": "collapse-expand-leave-active",
        onBeforeEnter: _ctx.beforeEnter,
        onEnter: _ctx.enter,
        onAfterEnter: _ctx.afterEnter,
        onBeforeLeave: _ctx.beforeLeave,
        onLeave: _ctx.leave,
        onAfterLeave: _ctx.afterLeave
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["onBeforeEnter", "onEnter", "onAfterEnter", "onBeforeLeave", "onLeave", "onAfterLeave"]))
    : (_openBlock(), _createBlock(_Transition, {
        key: 1,
        "enter-active-class": "collapse-expand-enter-active",
        "leave-active-class": "collapse-expand-leave-active",
        onBeforeEnter: _ctx.beforeEnter,
        onEnter: _ctx.enter,
        onAfterEnter: _ctx.afterEnter,
        onBeforeLeave: _ctx.beforeLeave,
        onLeave: _ctx.leave,
        onAfterLeave: _ctx.afterLeave
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["onBeforeEnter", "onEnter", "onAfterEnter", "onBeforeLeave", "onLeave", "onAfterLeave"]))
}