<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="w-full m-auto border-0" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #body="scope">
            <div class="bg-white pt-6 rounded-xl">
                <ActivityTable :title="title" :selectable-record-type-ids="recordTypeIds" :use-records="records" :fetch-own-records="false" @fetch-records="$emit('fetch-records')">
                    <template #top-right>
                        <button type="button" @click="scope.close">
                            <Icon :src="IconSource.Close"></Icon>
                        </button>
                    </template>
                </ActivityTable>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import ActivityTable from '@/components/common/activityTable/ActivityTable.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { ActivityTableRecordType, Record } from '@/types';

export default defineComponent({
    components: { Modal, ActivityTable, CloseModalButton },
    emits: ['fetch-records'],
    props: {
        recordTypeIds: {
            type: Array as () => ActivityTableRecordType[],
            default: [],
        },
        title: {
            type: String,
            default: '',
        },
        records: {
            type: Array as () => Record[],
            default: [],
        },
    },
});
</script>
