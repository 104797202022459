<template>
    <div class="space-y-1 py-3.5 border-b border-b-gray-100">
        <div class="flex items-center gap-x-3.5">
            <DataQualityStatusBarTooltip :quality-assessment-id="qualityAssessmentId" />
            <span class="font-medium text-lg">
                {{ element.label }}
            </span>
        </div>
        <div v-if="element.value" class="text-gray-500">
            {{ element.value }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { FormElement } from '@/types';
import DataQualityStatusBarTooltip from './dataQuality/DataQualityStatusBarTooltip.vue';

interface Props {
    element: FormElement;
}

const props = defineProps<Props>();

const qualityAssessmentId = computed(() => {
    const value = props.element.type_settings?.values?.[0]?.value;

    return value ? parseInt(value) : undefined;
});
</script>
