<template>
    <div class="w-full flex justify-center">
        <div :class="iconContainerClass" class="relative">
            <Icon :class="iconClass" :src="iconSource"></Icon>
            <transition name="fade">
                <div v-show="isFinalStep" class="border-white border absolute bottom-0 right-0 bg-green-600 p-1 rounded-full">
                    <Icon class="m-auto w-3 h-3" :src="IconSource.Check"></Icon>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IconSource } from '@/types';

export default defineComponent({
    props: {
        iconSource: {
            type: [String, Number],
            default: '',
        },
        iconContainerClass: {
            type: String,
            default: '',
        },
        iconClass: {
            type: String,
            default: 'w-16 h-16',
        },
        isFinalStep: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            IconSource,
        };
    },
});
</script>
