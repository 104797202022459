import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "sidebar flex overflow-hidden max-w-dashboard mx-auto" }
const _hoisted_2 = { class: "fixed top-24 h-full z-20 flex flex-shrink-0 bg-gray-100" }
const _hoisted_3 = { class: "flex flex-col h-0 flex-1" }
const _hoisted_4 = { class: "navbar-container flex-1 flex flex-col space-y-8 overflow-y-auto overflow-x-hidden" }
const _hoisted_5 = { class: "py-8 space-y-1 divide-y divide-gray-300" }
const _hoisted_6 = {
  key: 0,
  class: "justify-self-end"
}
const _hoisted_7 = { class: "whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ExpandIcon = _resolveComponent("ExpandIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col border-r border-gray-300 transition-width duration-300", { 'w-[270px]': !_ctx.isMenuCollapsed || (_ctx.isMenuCollapsed && _ctx.isHovered), 'w-[60px]': _ctx.isMenuCollapsed && !_ctx.isHovered }]),
        onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHovered = true)),
        onMouseout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHovered = false))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("nav", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarRouteGroups, (sidebarGroup, groupIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: groupIndex,
                  class: "py-3"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sidebarGroup, (sidebarRoute, routeIndex) => {
                    return (_openBlock(), _createElementBlock("div", { key: routeIndex }, [
                      _createVNode(_component_router_link, {
                        to: sidebarRoute.path,
                        class: _normalizeClass([{
                                            'text-gray-500 font-light border-l border-transparent': _ctx.$route.path !== sidebarRoute.path,
                                            'text-black font-medium bg-primary-700 bg-opacity-10 border-l border-primary-700': _ctx.$route.path === sidebarRoute.path,
                                        }, "w-full transition-colors tracking-tight grid items-center px-4 py-4 gap-x-2 h-[56px]"]),
                        style: {"grid-template-columns":"27px auto 27px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, {
                            src: _ctx.$route.path === sidebarRoute.path || (_ctx.hasChildrenActive(sidebarRoute.children) && !_ctx.isHovered) ? sidebarRoute.activeIcon : sidebarRoute.inactiveIcon
                          }, null, 8, ["src"]),
                          _createElementVNode("span", {
                            class: _normalizeClass(["transition-opacity duration-300 whitespace-nowrap", !_ctx.isMenuCollapsed || _ctx.isHovered ? 'opacity-100' : 'opacity-0 hidden'])
                          }, _toDisplayString(_ctx.$t(sidebarRoute.text) || sidebarRoute.name), 3),
                          (Array.isArray(sidebarRoute.children) && sidebarRoute.children.length && (!_ctx.isMenuCollapsed || _ctx.isHovered))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                _createVNode(_component_ExpandIcon, {
                                  expand: sidebarRoute.expanded,
                                  color: "black"
                                }, null, 8, ["expand"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to", "class"]),
                      _createElementVNode("div", {
                        style: _normalizeStyle([sidebarRoute.expanded && (!_ctx.isMenuCollapsed || _ctx.isHovered) ? `max-height: ${40 * sidebarRoute.children.length}px;` : 'max-height: 0px;', {"transition":"max-height 0.25s"}]),
                        class: "overflow-hidden"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sidebarRoute.children, (sidebarRouteChild, routeChildIndex) => {
                          return (_openBlock(), _createElementBlock("div", { key: routeChildIndex }, [
                            _createVNode(_component_router_link, {
                              tabindex: sidebarRoute.expanded ? null : '-1',
                              to: sidebarRouteChild.path,
                              class: _normalizeClass([_ctx.$route.path === sidebarRouteChild.path ? 'text-black font-medium bg-primary-700 bg-opacity-10 border-l border-primary-700' : 'text-gray-500 font-light', "w-full grid tracking-tight items-center px-4 py-2 gap-x-2"]),
                              style: {"grid-template-columns":"27px auto 27px"}
                            }, {
                              default: _withCtx(() => [
                                _cache[3] || (_cache[3] = _createElementVNode("span", null, null, -1)),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(sidebarRouteChild.text) || sidebarRouteChild.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["tabindex", "to", "class"])
                          ]))
                        }), 128))
                      ], 4)
                    ]))
                  }), 128))
                ]))
              }), 128))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "w-full text-sm text-gray-500 h-12 flex items-center px-4 py-3 hover:bg-gray-200 rounded-l-md transition-colors",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenuCollapsed && _ctx.toggleMenuCollapsed(...args)))
              }, [
                _createVNode(_component_ExpandIcon, {
                  class: _normalizeClass(["flex-shrink-0 transform mx-2 opacity-50", _ctx.isMenuCollapsed ? '-rotate-90' : 'rotate-90']),
                  color: "black"
                }, null, 8, ["class"]),
                _withDirectives(_createElementVNode("span", { class: "whitespace-nowrap" }, _toDisplayString(_ctx.isMenuCollapsed ? _ctx.$t('layouts.dashboard.sideBar.buttons.expandMenu') : _ctx.$t('layouts.dashboard.sideBar.buttons.collapseMenu')), 513), [
                  [_vShow, !_ctx.isMenuCollapsed || _ctx.isHovered]
                ])
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full pb-4 transition-opacity transition-width duration-300", { 'opacity-0 w-0': !_ctx.isLogoShown && !_ctx.isHovered, 'w-full': _ctx.isLogoShown || _ctx.isHovered }])
            }, [
              _createVNode(_component_Icon, {
                class: "w-36 pl-2.75",
                src: _ctx.SIDEBAR_LOGO_SOURCE
              }, null, 8, ["src"])
            ], 2)
          ])
        ])
      ], 34)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full mt-24 transition-margin-left duration-300", _ctx.isMenuCollapsed ? 'ml-[60px]' : 'ml-[270px]'])
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _renderSlot(_ctx.$slots, "footer")
    ], 2)
  ]))
}