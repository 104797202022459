import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "h-full min-h-60 w-128 space-y-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-y-1 gap-x-2" }
const _hoisted_4 = { class: "flex space-x-4 self-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonthPicker = _resolveComponent("MonthPicker")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.config.monthPicker)
        ? (_openBlock(), _createBlock(_component_MonthPicker, {
            key: 0,
            value: _ctx.period,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.period) = $event)),
            class: "col-span-2",
            label: _ctx.config.periodFieldLabel,
            "disable-months": _ctx.config.monthPicker.disableMonths
          }, null, 8, ["value", "label", "disable-months"]))
        : _createCommentVNode("", true),
      (_ctx.config.yearPicker)
        ? (_openBlock(), _createBlock(_component_CustomSelect, {
            key: 1,
            value: _ctx.period,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.period) = $event)),
            label: _ctx.config.periodFieldLabel,
            items: _ctx.config.yearPicker.items,
            "item-text": "text",
            "item-value": "period",
            "return-object": "",
            "show-required-indication": true,
            "allow-empty": false,
            class: "col-span-2",
            rules: [{ validate: (value) => Boolean(value), message: '' }]
          }, null, 8, ["value", "label", "items", "rules"]))
        : _createCommentVNode("", true),
      (_ctx.config.datePicker)
        ? (_openBlock(), _createBlock(_component_DatePicker, {
            key: 2,
            value: _ctx.period,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.period) = $event)),
            "disable-dates": _ctx.config.datePicker.disable,
            required: "",
            max: _ctx.config.datePicker.max,
            label: _ctx.config.periodFieldLabel,
            class: "col-span-2"
          }, null, 8, ["value", "disable-dates", "max", "label"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.numberFields, (numberField) => {
        return (_openBlock(), _createBlock(_component_CustomInput, {
          key: numberField.value,
          value: _ctx.form[numberField.value],
          "onUpdate:value": ($event: any) => ((_ctx.form[numberField.value]) = $event),
          class: _normalizeClass([numberField.colSpan]),
          label: numberField.label,
          "input-type": "number",
          required: "",
          rules: [{ validate: (value) => typeof value === 'number', message: '' }]
        }, null, 8, ["value", "onUpdate:value", "class", "label", "rules"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        disable: !_ctx.validForm,
        onClick: _ctx.onSubmitClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.config.confirmButtonLabel), 1)
        ]),
        _: 1
      }, 8, ["disabled", "disable", "onClick"])
    ])
  ]))
}