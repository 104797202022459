<template>
    <div class="space-y-8 w-full">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.header') }}</h2>
        <div class="outline-none" tabindex="0">
            <div v-if="enableRawMaterialRegister" class="w-full flex justify-center mb-4">
                <AutoComplete
                    class="w-72"
                    :items="rawMaterialRegisterItems"
                    item-text="name"
                    item-value="id"
                    :label="$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.autoCompleteFieldLabel')"
                    @item-clicked="onRawMaterialRegisterItemClicked"
                ></AutoComplete>
            </div>
            <div
                :class="{ 'max-h-24': !enableRawMaterialRegister || value.register_id, 'max-h-0': !value.register_id && enableRawMaterialRegister, 'overflow-y-hidden': hideSectionOverflow }"
                class="transition-all duration-500"
            >
                <div class="space-y-2">
                    <table class="table-headers-medium border-collapse w-full">
                        <thead>
                            <tr class="px-6 my-2 rounded text-dark-gray-800 text-opacity-50 font-medium">
                                <th>{{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.name') }}</th>
                                <th>{{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.price') }}</th>
                                <th>{{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.validFrom') }}</th>
                                <th>{{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.dry_matter') }}</th>
                                <th>{{ feedUnitHeaderText }}</th>
                                <th>{{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.crude_protein') }}</th>
                                <th>{{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.phosphorus') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="my-auto py-2 pl-2 pr-1 bg-feed-600 bg-opacity-60 rounded-l-lg">
                                    <SimpleInput v-model:value="value.name" :readonly="enableRawMaterialRegister && !value.register_id" class="w-full"></SimpleInput>
                                </td>
                                <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                    <SimpleInput v-model:value="value.prices[0].price" type="number" step="any" class="w-full" :readonly="enableRawMaterialRegister && !value.register_id"></SimpleInput>
                                </td>
                                <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                    <FeedDatePicker v-model:value="value.prices[0].valid_from" :disabled="enableRawMaterialRegister && !value.register_id"></FeedDatePicker>
                                </td>
                                <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                    <SimpleInput
                                        :value="value.dry_matter"
                                        type="number"
                                        step="any"
                                        class="w-full"
                                        :readonly="enableRawMaterialRegister && !value.register_id"
                                        @focus="onDryMatterFocus"
                                        @update:value="onDryMatterInput"
                                    ></SimpleInput>
                                </td>
                                <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                    <SimpleInput v-model:value="value.fu" type="number" step="any" class="w-full" :readonly="enableRawMaterialRegister && !value.register_id"></SimpleInput>
                                </td>
                                <td class="my-auto py-2 px-1 bg-feed-600 bg-opacity-60">
                                    <SimpleInput v-model:value="value.crude_protein" type="number" step="any" class="w-full" :readonly="enableRawMaterialRegister && !value.register_id"></SimpleInput>
                                </td>
                                <td class="my-auto pr-2 pl-1 bg-feed-600 bg-opacity-60 rounded-r-lg">
                                    <SimpleInput v-model:value="value.phosphorus" type="number" step="any" class="w-full" :readonly="enableRawMaterialRegister && !value.register_id"></SimpleInput>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="flex space-x-4 h-14 w-full justify-center relative">
                    <div class="flex space-x-4">
                        <div>
                            <FormCheckbox
                                v-model:value="createAsFeedComponent"
                                classes="w-full h-full"
                                label-classes="pl-2 whitespace-nowrap my-auto"
                                :disabled="enableRawMaterialRegister && !chosenRawMaterialRegisterItem"
                                :label="$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.feedComponentCheckboxLabel')"
                            ></FormCheckbox>
                        </div>
                        <div :class="`${createAsFeedComponent ? 'max-w-76' : 'max-w-0'} `" class="transition-all duration-500">
                            <CustomMultiSelect
                                v-model:value="feedComponentFarms"
                                :prepend="$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.feedComponentFarmsLabel')"
                                label=""
                                :items="farms"
                                item-text="name"
                                item-value="id"
                                :show-required-indication="false"
                                dropdown-height-class="max-h-56"
                            ></CustomMultiSelect>
                        </div>
                    </div>
                    <div class="flex ml-auto absolute right-0 top-0 bottom-0">
                        <CustomButton :disabled="disableButtons" :disable="!validForm" class="px-4 py-0" @click="onSubmitButtonClicked">
                            {{ $t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.confirmButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import { FeedUnitType, MixForm, MixType, Module, Price, RawMaterial, RawMaterialRegisterItem, TableHeader } from '@/types';
import AutoComplete from '@/components/ui/AutoComplete.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import FeedDatePicker from '@/components/ui/FeedDatePicker.vue';
import SimpleInput from '@/components/ui/SimpleInput.vue';
import { roundIfNeccessary } from '@/util/numbers';
import FormCheckbox from '@/components/ui/FormCheckbox.vue';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';

export default defineComponent({
    components: { CustomButton, AutoComplete, DatePicker, FeedDatePicker, SimpleInput, FormCheckbox, CustomMultiSelect },
    props: {
        value: {
            type: Object as () => Form<RawMaterial>,
            required: true,
        },
    },
    data() {
        return {
            chosenRawMaterialRegisterItem: null as null | RawMaterialRegisterItem,
            valuesOnLastDryMatterFocus: null as null | {
                fu: number;
                crude_protein: number;
                phosphorus: number;
                dry_matter: number;
            },
            hideSectionOverflow: true,
            disableButtons: false,
            createAsFeedComponent: false,
            feedComponentFarms: [],
        };
    },
    computed: {
        rawMaterialRegisterItems(): RawMaterialRegisterItem[] {
            return store.state.rawMaterialRegisterItems;
        },
        feedUnitHeaderText() {
            return store.state.user?.energy_unit === FeedUnitType.Mj
                ? this.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.mj')
                : this.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.fu');
        },
        enableRawMaterialRegister() {
            return store.getters.hasModuleEnabled(Module.RawMaterialsRegister);
        },
        validForm(): boolean {
            const rules = [
                { key: 'register_id', validation: (value: any) => typeof value === 'number' || !this.enableRawMaterialRegister },
                { key: 'name', validation: (value: any) => typeof value === 'string' && value.length },
                { key: 'dry_matter', validation: (value: any) => value === null || value === '' || (typeof value === 'number' && value >= 0 && value <= 100) },
                { key: 'fu', validation: (value: any) => typeof value === 'number' },
                { key: 'crude_protein', validation: (value: any) => typeof value === 'number' && value >= 0 },
                { key: 'phosphorus', validation: (value: any) => typeof value === 'number' && value >= 0 },
                { key: 'prices', validation: (value: any) => Array.isArray(value) && value.length === 1 && typeof value[0].price === 'number' && value[0].price >= 0 && typeof value[0].valid_from === 'string' },
            ];
            return rules.every((rule) => rule.validation(this.$props.value[rule.key]));
        },
        farms(): { id: number; name: string }[] {
            return store.state.farms.map((farm) => ({ name: farm.name, id: farm.farm_id }));
        },
    },
    watch: {
        'value.register_id': function onNewRegisterId(newValue) {
            if (newValue) {
                setTimeout(() => {
                    this.hideSectionOverflow = false;
                }, 500);
            }
        },
        enableRawMaterialRegister: {
            immediate: true,
            handler(newValue) {
                if (!newValue) {
                    this.hideSectionOverflow = false;
                }
            },
        },
    },
    methods: {
        onDryMatterFocus() {
            this.valuesOnLastDryMatterFocus = {
                fu: this.$props.value.fu,
                crude_protein: this.$props.value.crude_protein,
                phosphorus: this.$props.value.phosphorus,
                dry_matter: this.$props.value.dry_matter,
            };
        },
        onDryMatterInput(newValue: number) {
            if (this.valuesOnLastDryMatterFocus) {
                this.$props.value.fill({
                    fu: roundIfNeccessary((this.valuesOnLastDryMatterFocus.fu / this.valuesOnLastDryMatterFocus.dry_matter) * newValue, 2) || 0,
                    crude_protein: roundIfNeccessary((this.valuesOnLastDryMatterFocus.crude_protein / this.valuesOnLastDryMatterFocus.dry_matter) * newValue, 2) || 0,
                    phosphorus: roundIfNeccessary((this.valuesOnLastDryMatterFocus.phosphorus / this.valuesOnLastDryMatterFocus.dry_matter) * newValue, 2) || 0,
                    dry_matter: newValue,
                });
            }
        },
        onRawMaterialRegisterItemClicked(rawMaterialRegisterItem: RawMaterialRegisterItem) {
            this.chosenRawMaterialRegisterItem = rawMaterialRegisterItem;
            this.$props.value.fill({
                register_id: rawMaterialRegisterItem.id,
                name: rawMaterialRegisterItem.name,
                dry_matter: rawMaterialRegisterItem.dry_matter,
                fu: rawMaterialRegisterItem.fu,
                crude_protein: rawMaterialRegisterItem.crude_protein,
                phosphorus: rawMaterialRegisterItem.phosphorus,
            });
        },
        async onSubmitButtonClicked() {
            if (!this.validForm) {
                return;
            }
            this.disableButtons = true;
            const keys = [
                { registerKey: 'name', formKey: 'name' },
                { registerKey: 'id', formKey: 'register_id' },
                { registerKey: 'dry_matter', formKey: 'dry_matter' },
                { registerKey: 'fu', formKey: 'fu' },
                { registerKey: 'crude_protein', formKey: 'crude_protein' },
                { registerKey: 'phosphorus', formKey: 'phosphorus' },
            ];
            const customized = !this.enableRawMaterialRegister || !keys.every((key) => (this.chosenRawMaterialRegisterItem as any)[key.registerKey] === this.$props.value[key.formKey]);
            this.$props.value.fill({ customized });

            const createRawMaterialResponse: RawMaterial = await store.dispatch(ActionType.CreateRawMaterial, { form: this.$props.value });

            if (this.createAsFeedComponent) {
                const mixForm: MixForm = {
                    type: store.state.mixTypes.find((currentMixType) => currentMixType.id === 3) as MixType,
                    farms: this.feedComponentFarms,
                    prices: createRawMaterialResponse.prices,
                    name: createRawMaterialResponse.name,
                    wgt_avg_fu: createRawMaterialResponse.fu,
                    wgt_avg_crude_protein: createRawMaterialResponse.crude_protein,
                    wgt_avg_phosphorus: createRawMaterialResponse.phosphorus,
                    surcharge: 0,
                    register: null,
                    components: [
                        {
                            id: createRawMaterialResponse.id as number,
                            admixture_percentage: 100,
                            material: createRawMaterialResponse,
                        },
                    ],
                };
                await store.dispatch(ActionType.CreateFeedMix, { form: mixForm });
            }

            if (this.$props.value.successful) {
                store.dispatch(ActionType.GetRawMaterials, { options: { ignoreCache: true } });
                if (this.createAsFeedComponent) {
                    store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
                }
                this.$emit('submit');
            }
        },
    },
});
</script>
