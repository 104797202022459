<template>
    <GenericMultiStepModal :header-icon="IconSource.Food" :is-final-step="isFinalStep" @closing="onModalClose">
        <template #default="scope">
            <slot v-bind="scope" />
        </template>
        <template #component="scope">
            <component :is="currentStep.component" v-model:value="form" :text="submissionStatusText" @submit="next" @close="scope.close"></component>
        </template>
    </GenericMultiStepModal>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import isEqual from 'lodash-es/isEqual';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import RawMaterialProperties from '@/views/dashboard/feed/feedRawMaterials/newRawMaterialModal/RawMaterialProperties.vue';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import useResettableForm from '@/components/common/composables/useResettableForm';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import GenericMultiStepModal from '@/components/common/GenericMultiStepModal.vue';
import GenericSubmissionStatus from '@/components/common/GenericSubmissionStatus.vue';

export default defineComponent({
    components: { GenericMultiStepModal, MultiStepFormHeaderIcon, CloseModalButton },
    setup() {
        store.dispatch(ActionType.GetRawMaterialRegisterItems);
        store.dispatch(ActionType.GetMixTypes);

        const multiSteps = useMultiSteps([
            {
                component: RawMaterialProperties,
            },
            {
                component: GenericSubmissionStatus,
            },
        ]);

        const resettableForm = useResettableForm().setInitial({
            register_id: null,
            name: null,
            dry_matter: null,
            fu: null,
            crude_protein: null,
            phosphorus: null,
            prices: [
                {
                    valid_from: '',
                    price: '',
                },
            ],
        });

        const form = resettableForm.getInstance();

        const onModalClose = (next: (closeModal: boolean) => boolean) => {
            if (multiSteps.currentStepIndex.value === 0 && !isEqual(resettableForm.getInitial(), form.value.data())) {
                if (!window.confirm(i18n.global.t('global.forms.confirmCloseModalMessage'))) {
                    return next(false);
                }
            }
            multiSteps.reset();
            resettableForm.reset();
            return true;
        };

        const submissionStatusText = computed(() => (multiSteps.isFinalStep.value ? i18n.global.t('views.feed.rawMaterials.newRawMaterialModal.confirmation.header') : null));

        return {
            onModalClose,
            ...multiSteps,
            form,
            submissionStatusText,
        };
    },
});
</script>
