<template>
    <Modal close-esc-event="keydown" :close-button="false" :modal-class="`m-auto border-0 max-w-6xl transition-width duration-500`" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128">
                <MultiStepFormHeaderIcon :icon-source="IconSource.Economy" :is-final-step="false"></MultiStepFormHeaderIcon>
                <h2 class="text-xl font-semibold text-center">{{ config.header }}</h2>
                <p class="text-center">
                    <span>{{ config.message }}</span> <span class="font-bold text-primary-700">{{ ` ${item.period}` }}?</span>
                </p>
                <div class="flex space-x-4">
                    <CustomButton :disabled="disableButtons" color-preset="secondary" @click="scope.close">
                        {{ config.cancelButtonLabel }}
                    </CustomButton>
                    <CustomButton :loading="disableButtons" color-preset="warning" @click="onDeleteEntryClicked(scope.close)">
                        {{ config.confirmButtonLabel }}
                    </CustomButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CustomButton from '@/components/ui/CustomButton.vue';
import { EconomyPostPayment, EconomyStatusEntry } from '@/types';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, CustomButton, CloseModalButton },
    emits: ['submit'],
    props: {
        item: {
            type: Object as () => null | (EconomyStatusEntry | EconomyPostPayment),
            default: null,
        },
        config: {
            type: Object as () => {
                header: string;
                message: string;
                cancelButtonLabel: string;
                confirmButtonLabel: string;
                deleteActionType: ActionType;
                fetchActionType: ActionType;
            },
            required: true,
        },
    },
    data() {
        return { disableButtons: false };
    },
    methods: {
        async onDeleteEntryClicked(close: CallableFunction) {
            try {
                this.disableButtons = true;
                await store.dispatch(this.$props.config.deleteActionType, { form: { id: this.$props.item?.id } });
                store.dispatch(this.$props.config.fetchActionType, { options: { ignoreCache: true } });
                this.$emit('submit');
                close();
            } catch (err) {
                this.disableButtons = false;
            }
        },
    },
});
</script>
