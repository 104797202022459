<template>
    <EventButton :events="events">
        <template #button="{ expandDropdown, toggleExpandDropdown }">
            <button class="expand-button bg-white rounded-xl py-2 text-primary-800 hover:bg-gray-100 transition-colors cursor-pointer" @click="toggleExpandDropdown">
                <span class="w-full flex space-x-2 content-center">
                    <span class="grid content-center h-7 min-w-4 justify-center my-auto">
                        <Icon :class="{ 'transform rotate-90': expandDropdown }" class="transition-transform my-auto" style="width: 17px; height: 17px" :src="IconSource.AddAction"></Icon>
                    </span>
                    <span class="font-semibold text-lg hidden lg:block my-auto">
                        {{ $t('layouts.dashboard.topBar.eventButton.label') }}
                    </span>
                </span>
            </button>
        </template>
    </EventButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Event } from '@/types';
import EventButton from '@/components/layouts/dashboardLayout/topBar/events/EventButton.vue';

export default defineComponent({
    components: { DropDown, EventButton },
    computed: {
        events(): Event[] {
            return store.state.events;
        },
    },
    created() {
        store.dispatch(ActionType.GetEvents);
        store.dispatch(ActionType.GetActions);
    },
});
</script>

<style scoped>
.expand-button {
    padding-left: 1rem;
    padding-right: 1rem;
}
@media (min-width: 1120px) {
    .expand-button {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
</style>
