<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.production.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-8">
            <GenericChartWithApiMultiselectOptions
                :chart="dailyGainSpread.name"
                :options-callback="dailyGainSpread.optionsCallback"
                :add-parameters-callback="dailyGainSpread.addParametersCallback"
                :multiselect-options="dailyGainSpread.multiselectOptions"
                :options="dailyGainSpread.options"
                :fetch-options="dailyGainSpread.fetchOptions"
            >
            </GenericChartWithApiMultiselectOptions>
            <MainViewCard class="pt-4 pb-2 px-2">
                <GenericChartWithApi :chart-name="daysInBarn.name" :fetch-options="daysInBarn.fetchOptions" />
            </MainViewCard>
            <MainViewCard class="pt-4 pb-2 px-2">
                <GenericChartWithApi :chart-name="weightSpanChart.name" :fetch-options="weightSpanChart.fetchOptions" />
            </MainViewCard>
            <MainViewCard class="pt-4 pb-2 px-2">
                <GenericChartWithApi :chart-name="pigsTally.name" :chart-type="pigsTally.chartType" :fetch-options="pigsTally.fetchOptions" />
            </MainViewCard>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import GenericChartWithApiMultiselectOptions from '@/components/charts/GenericChartWithApiMultiselectOptions.vue';
import { Chart, ChartType, ChartMultiSelectOptions } from '@/types';
import useSplineChartOptions from '@/components/charts/composables/useSplineChartOptions';
import { i18n } from '@/plugins/internationalization/i18n';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, GenericChartWithApi, GenericChartWithApiMultiselectOptions },
    setup() {
        const weightSpanChart = {
            name: Chart.WeightSpan,
            fetchOptions: {
                usePigtypeFilter: true,
            },
        };
        const daysInBarn = {
            name: Chart.DaysInBarn,
            fetchOptions: {
                usePigtypeFilter: true,
            },
        };
        const pigsTally = {
            name: Chart.ProductionPigsTally,
            chartType: ChartType.SplineChart,
            fetchOptions: {
                usePigtypeFilter: true,
            },
        };
        const dailyGainSpread = computed(() => ({
            name: Chart.DailyGainSpread,
            optionsCallback: useSplineChartOptions,
            type: ChartType.LineChart,
            options: null,
            multiselectOptions: {
                enabled: true,
                items: [
                    { text: i18n.global.t('views.production.content.charts.dailyGainSpread.multiSelectOptions.values'), value: 'values', defaultSelected: true },
                    { text: i18n.global.t('views.production.content.charts.dailyGainSpread.multiSelectOptions.values90Spread'), value: 'value_90spread', defaultSelected: false },
                    { text: i18n.global.t('views.production.content.charts.dailyGainSpread.multiSelectOptions.references'), value: 'references', defaultSelected: false },
                    { text: i18n.global.t('views.production.content.charts.dailyGainSpread.multiSelectOptions.references90Spread'), value: 'references_90spread', defaultSelected: false },
                ],
            },
            addParametersCallback: (multiselectOptions: ChartMultiSelectOptions, selectedMultiselectOptions: { text: string; value: string }[]) =>
                multiselectOptions.items.reduce((accum, current) => `${accum}&${current.value}=${selectedMultiselectOptions.some((currentItem) => currentItem.value === current.value)}`, ''),
            fetchOptions: {
                usePigtypeFilter: true,
            },
        }));
        return { weightSpanChart, dailyGainSpread, daysInBarn, pigsTally };
    },
});
</script>
