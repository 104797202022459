import { I18n, createI18n } from 'vue-i18n';

interface LocalesDataInterface {
    datetimeFormats: any;
    numberFormats: any;
    messages: any;
}

const loadLocalesData = () => {
    const locales = (require as any).context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const localeData: LocalesDataInterface = {
        datetimeFormats: {},
        numberFormats: {},
        messages: {},
    };
    const keys: string[] = locales.keys();
    keys.forEach((key: string) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1].replace('_DK', ''); // Lokalise requires 'da_DK', but i18n requires 'da' for date formatting
            localeData.numberFormats[locale] = locales(key).numberFormats;
            localeData.datetimeFormats[locale] = locales(key).datetimeFormats;
            localeData.messages[locale] = locales(key).messages;
        }
    });

    return localeData;
};

const data = loadLocalesData();

export const i18n = createI18n({
    locale: 'da',
    fallbackLocale: 'da',
    messages: data.messages,
    datetimeFormats: data.datetimeFormats,
    numberFormats: data.numberFormats,
}) as any as I18n<any, any, any, string, true> & {
    global: any;
};
