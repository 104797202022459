<template>
    <div>
        <InvalidScreenSizeWarning v-show="windowWidth < 1024"></InvalidScreenSizeWarning>
        <AuthLayout>
            <router-view></router-view>
        </AuthLayout>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AuthLayout from '@/components/layouts/authLayout/AuthLayout.vue';
import InvalidScreenSizeWarning from '@/components/common/InvalidScreenSizeWarning.vue';
import useWindowWidthListener from '@/components/common/composables/useWindowWidthListener';

export default defineComponent({
    components: { AuthLayout, InvalidScreenSizeWarning },
    setup() {
        return useWindowWidthListener();
    },
});
</script>
