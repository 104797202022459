<template>
    <input ref="input" v-model="computedValue" :type="type" class="py-1 text-center outline-none rounded" :class="[backgroundColor]" @focus="onFocus" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        backgroundColor: {
            type: String,
            default: 'bg-white',
        },
    },
    emits: ['update:value'],
    computed: {
        computedValue: {
            get(): string | number {
                return this.$props.value;
            },
            set(newValue: string) {
                if (this.$props.type === 'number') {
                    if (['', '-', '.', ','].includes(newValue)) {
                        this.$emit('update:value', newValue);
                    } else {
                        this.$emit('update:value', Number(newValue));
                    }
                } else {
                    this.$emit('update:value', newValue);
                }
            },
        },
    },
    methods: {
        onFocus() {
            if (this.computedValue === 0 || this.computedValue === '0') {
                this.computedValue = '';
            }
        },
    },
});
</script>
