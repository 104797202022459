export type EconomyEvent = {
    id: number;
    name: string;
    description: string;
    created_at_utc: string;
    icon_id: number;
    type_id: number;
    action_id: number;
};

export enum EconomyEventType {
    BudgetCreate = 1,
    BudgetEdit = 2,
}
