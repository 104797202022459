<template>
    <table class="w-full">
        <thead>
            <tr class="px-6 flex my-2 rounded text-dark-gray-800 text-opacity-50">
                <slot v-for="header of headers" :name="[`header_${header.value}`]">
                    <th :key="header.value" class="flex flex-1 px-2">
                        <div class="flex font-medium">
                            {{ header.text }}
                        </div>
                    </th>
                </slot>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item of items" :key="item.material.id" class="rounded flex py-4 px-6 my-4 odd:bg-dark-gray-100 border-2 border-opacity-10 rounded-lg">
                <slot v-for="header of headers" :name="[`${header.value}`]" :item="item" :header="header">
                    <td :key="header.value" class="flex px-2 flex-1 my-auto word-break">
                        {{ getItemProperty(header, item, { numberFormat: header.numberFormat }) }}
                    </td>
                </slot>
            </tr>
            <tr class="flex py-4 px-6 my-4 border-1 rounded-lg bg-feed-600 bg-opacity-60">
                <slot v-for="sumHeader of sumHeaders" :name="[`sum_${sumHeader}`]" :sumHeader="sumHeader">
                    <td :key="sumHeader" class="flex px-2 flex-1 my-auto word-break">
                        {{ getItemProperty({ value: sumHeader }, value) }}
                    </td>
                </slot>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TableHeader, MixForm, MixComponent, Mix, FeedUnitType } from '@/types';
import useGetItemProperty from '@/components/common/composables/useGetItemProperty';
import { store } from '@/plugins/store';

export default defineComponent({
    props: {
        value: {
            type: Object as () => MixForm | Mix,
            default: [],
        },
        includeActions: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        items(): MixComponent[] {
            return this.$props.value.components || [];
        },
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.feed.mixes.homeMixRawMaterialsTable.headers.name'),
                    value: 'material.name',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                    summable: false,
                },
                {
                    text: store.state.user?.energy_unit === FeedUnitType.Mj ? this.$t('views.feed.mixes.homeMixRawMaterialsTable.headers.mj') : this.$t('views.feed.mixes.homeMixRawMaterialsTable.headers.fu'),
                    value: 'material.fu',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                    summable: true,
                },
                {
                    text: this.$t('views.feed.mixes.homeMixRawMaterialsTable.headers.crude_protein'),
                    value: 'material.crude_protein',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                    summable: true,
                },
                {
                    text: this.$t('views.feed.mixes.homeMixRawMaterialsTable.headers.phosphorus'),
                    value: 'material.phosphorus',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                    summable: true,
                },
                {
                    text: this.$t('views.feed.mixes.homeMixRawMaterialsTable.headers.price'),
                    value: 'price',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                    summable: true,
                    numberFormat: 'price',
                },
                {
                    text: this.$t('views.feed.mixes.homeMixRawMaterialsTable.headers.admixture_percentage'),
                    value: 'admixture_percentage',
                    align: 'left',
                    sortable: false,
                    searchable: false,
                    summable: true,
                },
                ...(this.includeActions
                    ? [
                          {
                              text: '',
                              value: 'actions',
                              align: 'left',
                              sortable: false,
                              searchable: false,
                              summable: false,
                          } as TableHeader,
                      ]
                    : []),
            ];
        },
        sumHeaders(): string[] {
            return ['name', 'wgt_avg_fu', 'wgt_avg_crude_protein', 'wgt_avg_phosphorus', 'price', 'components', ...(this.includeActions ? ['actions'] : [])];
        },
    },
    methods: {
        getItemProperty: useGetItemProperty,
    },
});
</script>
