<template>
    <form class="space-y-4" @submit.prevent="submit">
        <MultiStepFormHeaderIcon :is-final-step="false" :icon-source="IconSource.ButtonUserManagement"></MultiStepFormHeaderIcon>
        <h2 class="text-2xl font-semibold text-center">{{ $t('views.settings.users.content.modals.create.title') }}</h2>
        <div class="mt-4">
            <CustomInput
                v-model:value="form.email"
                v-focus
                :label="$t('views.settings.users.content.modals.create.emailFieldLabel')"
                required
                show-required-indication
                input-type="email"
                :rules="[]"
                :error-state="!validForm"
                focus-on-render
            ></CustomInput>
        </div>
        <div class="flex space-x-4 mt-4">
            <CustomButton :disabled="disableButtons" color-preset="secondary" @click="$emit('cancel')">
                {{ $t('views.settings.users.content.modals.create.cancelButton') }}
            </CustomButton>
            <CustomButton :loading="disableButtons" :disabled="!validForm" type="submit">
                {{ $t('views.settings.users.content.modals.create.confirmButton') }}
            </CustomButton>
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { Form } from '@makeabledk/vue-ui/support/http';
import Focus from '@makeabledk/vue-ui/directives/focus/src/Focus';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';

export default defineComponent({
    components: { CustomButton, CustomInput, MultiStepFormHeaderIcon },
    directives: { Focus },
    emits: ['cancel', 'submit'],
    data() {
        return {
            form: new Form({
                email: '',
            }) as Form<{ email: string }> & { email: string },
            toast: useToast(),
            disableButtons: false,
        };
    },
    computed: {
        validForm() {
            return this.validateEmail(this.form.email);
        },
    },
    methods: {
        async submit() {
            if (this.disableButtons) {
                return;
            }
            try {
                this.disableButtons = true;
                await store.dispatch(ActionType.CreateUser, { form: this.form, options: { skipHandleError: true } });
                store.dispatch(ActionType.GetDirectoryUsers, { options: { ignoreCache: true } });
                this.toast.success(this.$t('views.settings.users.content.modals.create.createSuccess'));
                this.$emit('submit');
            } catch (error) {
                if ((error as any).status === 404) {
                    this.toast.error(this.$t('views.settings.users.content.modals.create.notFoundErrorMessage'));
                } else {
                    this.toast.error(this.$t('global.errors.unknown'));
                }
            } finally {
                this.disableButtons = false;
            }
        },
        validateEmail(value: string) {
            return (
                value && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase())
            );
        },
    },
});
</script>
